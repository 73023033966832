// 'US' is refer to value in nationalityList at constants/nationalityList.js
export const isUSPerson = (countryCode) => {

    let isUS = 'US'; // United States
    let isUM = 'UM'; // United States Outlying Islands
    let isAS = 'AS'; // American Samoa
    let isGU = 'GU'; // Guam
    let isMP = 'MP'; // Northern Mariana Islands
    let isPR = 'PR'; // Puerto Rico
    let isVI = 'VI'; // U.S. Virgin Islands

    if (countryCode === isUS || countryCode === isUM || countryCode === isAS || countryCode === isGU || countryCode === isMP || countryCode === isPR || countryCode === isVI) {
        return true;
    }

    else {
        return false;
    }
}