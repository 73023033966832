import React from 'react';
import { RelationshipList } from '../../constant';
import { Row, Col, Button } from "reactstrap";
import Dropdown from "../../components/Dropdown";
import Input from "../../components/Input";
import { FormHelper } from '../../services';
import * as data from "../../data/globalData";

class EmergencyContact extends React.PureComponent {

    render() {
        const {
            emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseCompanyName, spouseMobile
        } = this.props;

        return (
            <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="Name (Emergency Contact)"
                            name="emergencyContactName"
                            onChange={this.props.onEmergencyContactNameChange}
                            placeholder="Name (Emergency Contact)"
                            value={emergencyContactName}
                            isValidInput={FormHelper.Validation.isValidName}
                            errText={`Only alphabets and "@ , - , ', / " symbols is allowed.`}
                            maxLength={data.maxlength.emergencyContactName}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Dropdown
                            label="Relationship (Emergency Contact)"
                            name="emergencyContactRelationship"
                            placeholder="Relationship (Emergency Contact)"
                            options={RelationshipList}
                            value={emergencyContactRelationship}
                            onChange={this.props.onEmergencyContactRelationshipChange}
                        />
                    </Col>
                </Row>
                <Row className="my-4 mb-responsive">
                    <Col className="mt-2 col-6">
                        <Input
                            type="tel"
                            label="Mobile Number (Emergency Contact)"
                            specialClass="field-input"
                            name="emergencyContactMobile"
                            onChange={this.props.onEmergencyContactMobileChange}
                            placeholder="Mobile Number (Emergency Contact)"
                            value={emergencyContactMobile}
                            maxLength={data.maxlength.emergencyContactMobileNumber}
                            isValidInput={FormHelper.Validation.isValidLocalMobileNumber}
                            errText={'Please enter a correct mobile number'}
                        />
                    </Col>
                </Row>
                {/* {this.props.isMarried ?
                    (<>
                        <hr />
                        <Row className="mt-4 mb-responsive pb-2">
                            <Col className="mt-2">
                                <Input
                                    specialClass="field-input"
                                    label="Name (Spouse)"
                                    name="spouseName"
                                    onChange={this.props.onSpouseNameChange}
                                    placeholder="Name (Spouse)"
                                    value={spouseName}
                                    isValidInput={FormHelper.Validation.isValidName}
                                    errText={`Only alphabets and "@ , - , ', / " symbols is allowed.`}
                                    maxLength={data.maxlength.spouseName}
                                />
                            </Col>
                            <Col className="mt-2">
                                <Input
                                    specialClass="field-input"
                                    label="Company Name (Spouse)"
                                    name="spouseCompanyName"
                                    onChange={this.props.onSpouseCompanyNameChange}
                                    placeholder="Company Name (Spouse)"
                                    value={spouseCompanyName}
                                    maxLength={data.maxlength.spouseCompanyName}
                                    optional
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-responsive pb-2">
                            <Col className="mt-2 col-6">
                                <Input
                                    type="tel"
                                    label="Mobile Number (Spouse)"
                                    specialClass="field-input"
                                    name="spouseMobile"
                                    onChange={this.props.onSpouseMobileChange}
                                    placeholder="Mobile Number (Spouse)"
                                    value={spouseMobile}
                                    maxLength={data.maxlength.spouseMobileNumber}
                                    isValidInput={FormHelper.Validation.isValidLocalMobileNumber}
                                    errText={'Please enter a correct mobile number'}
                                />
                            </Col>
                        </Row>
                    </>) : null
                } */}

                <Button
                    id="gtm-update-emergency-contact"
                    data-index="3"
                    className="proceedButton"
                    disabled={!this.props.isValidEmergencyContact}
                    color="secondary"
                    onClick={this.props.onEmergencyContactContinuePressed}
                >
                    CONTINUE
                </Button>
            </>
        );
    }
}

export default EmergencyContact;