export const parseQueryParams = (queryString= '') => {
    
    if (!queryString)
        return {};
    
    let processedQueryString = queryString.replace('?', '');
    let queryArray = processedQueryString.split('&');
    let queryParams = {};
    
    queryArray.map(item => {
        let keyValuePair = item.split('=');
        queryParams[keyValuePair[0]] = keyValuePair[1]
    })

    console.log('queryParams:',queryParams);
    
    return queryParams;
}