import React from 'react';
import {
    ResidenceTypeList, LengthOfStayList, ResidenceStatusList,
    PurposeOfBorrowingList, SourceOfFundList, SourceOfWealthList
} from '../../constant';
import { Row, Col, Button } from "reactstrap";
import Dropdown from "../../components/Dropdown";

class MoreAboutYourself extends React.PureComponent {

    render() {
        const {
            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth
        } = this.props;

        return (
            <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2">
                        <Dropdown
                            label="Residence Type"
                            name="residenceType"
                            placeholder="Residence Type"
                            options={ResidenceTypeList}
                            value={residenceType}
                            onChange={this.props.onResidenceTypeChange}
                        />
                    </Col>
                    <Col className="mt-2 col-6">
                        <Dropdown
                            label="Length of stay at current residence"
                            name="lengthOfStay"
                            placeholder="Length of stay at current residence"
                            options={LengthOfStayList}
                            value={lengthOfStay}
                            onChange={this.props.onLengthOfStayChange}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-6">
                        <Dropdown
                            label="Source of Fund"
                            name="sourceOfFund"
                            placeholder="Source of Fund"
                            options={SourceOfFundList}
                            value={sourceOfFund}
                            onChange={this.props.onSourceOfFundChange}
                        />
                        {/* <Dropdown
                            label="Purpose of Borrowing"
                            name="purposeOfBorrowing"
                            placeholder="Purpose of Borrowing"
                            options={PurposeOfBorrowingList}
                            value={purposeOfBorrowing}
                            onChange={this.props.onPurposeOfBorrowingChange}
                        /> */}  
                         {/* <Dropdown
                            label="Residence Status"
                            name="residenceStatus"
                            placeholder="Residence Status"
                            options={ResidenceStatusList}
                            value={residenceStatus}
                            onChange={this.props.onResidenceStatusChange}
                        />                      */}
                    </Col>
                    <Col className="mt-2 col-6">
                        <Dropdown
                            label="Source of Wealth"
                            name="sourceOfWealth"
                            placeholder="Source of Wealth"
                            options={SourceOfWealthList}
                            value={sourceOfWealth}
                            onChange={this.props.onSourceOfWealthChange}
                        />
                    </Col>
                </Row>
                {/* <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-6">
                        <Dropdown
                            label="Source of Wealth"
                            name="sourceOfWealth"
                            placeholder="Source of Wealth"
                            options={SourceOfWealthList}
                            value={sourceOfWealth}
                            onChange={this.props.onSourceOfWealthChange}
                        /> 
                    </Col>
                    {
                        this.isOtherSourceOfFund() ? (
                            <Col className="mt-2 col-6">
                                <Input
                                    label="Others (Please Specify)"
                                    specialClass="field-input"
                                    name="otherSourceOfFund"
                                    onChange={this.props.onOtherSourceOfFundChange}
                                    placeholder="Others (Please Specify)"
                                    value={otherSourceOfFund}
                                />
                            </Col>
                        ) : null
                    } 
                </Row> */}
                {/* <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-6">
                        <Dropdown
                            label="Source of Wealth"
                            name="sourceOfWealth"
                            placeholder="Source of Wealth"
                            options={SourceOfWealthList}
                            value={sourceOfWealth}
                            onChange={this.props.onSourceOfWealthChange}
                        />
                    </Col>
                    {
                        this.isOtherSourceOfWealth() ? (
                            <Col className="mt-2 col-6">
                                <Input
                                    label="Others (Please Specify)"
                                    specialClass="field-input"
                                    name="otherSourceOfWealth"
                                    onChange={this.props.onOtherSourceOfWealthChange}
                                    placeholder="Others (Please Specify)"
                                    value={otherSourceOfWealth}
                                />
                            </Col>
                        ) : null
                    }
                </Row> */}
                <Button
                    id="gtm-update-more-about-yourself"
                    data-index="1"
                    className="proceedButton"
                    disabled={!this.props.hasFilledAllAboutYourself}
                    color="secondary"
                    onClick={this.props.onAboutYourselfContinuePressed}
                >
                    CONTINUE
                </Button>
            </>
        );
    }
}

export default MoreAboutYourself;