import React, { Component } from "react";
import Section from "../../components/Section";
import { Link } from 'react-router-dom'
import "./eligibility.scss";
import { Container, Row, Col, Card, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import Routes from '../../routes';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import * as data from "../../data/globalData";
import { PloanScreensList, FormStatusList } from '../../constant';
import NeedHelp from "../../components/NeedHelp";

class CompletedReceiveMoney extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onCheckStatusPressed = () => {
    this.props.history.push(Routes.checkstatus);
  }

  onHomePressed = () => {
    this.props.history.push(Routes.default);
  }

  render() {
    const maskedAccountNumber = this.props.disbursementBank.bankAccountNumber.replace(/\d(?=\d{4})/g, "*");
    let isAipOff = this.props.calculator.isAipOff

    return (
      <>
      <Header
          version="b"
          currentStage={6}
          progressBarPercentage={100}
        />
        <Section className="completed-receive-money-section">
          <Container>
            <Row className="logo-row">
              <Col>
                <div className="logo-wrapper">
                  <img
                    src={require("../../assets/img/alliance-logo.png")}
                    alt="Alliance Bank"
                  />
                  <span>Personal Financing</span>
                </div>
                <div className="line" />
              </Col>
            </Row>
            <Row>
              <Col>
                <h1>
                  <span>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  THANK YOU, {this.props.eligibility.name}!
                </h1>
                <p className="head-description">
                  Your financing application is being processed. Please expect a phone call from the Bank for verification purposes.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <p>
                    <span>
                      <img
                        src={require("../../assets/img/exclamation-circle-info.png")}
                      />
                    </span>
                    You will be notified via SMS and email of final approval.
                  </p>
                  <p>
                    <span>
                      <img src={require("../../assets/img/email.png")} />
                    </span>
                    {this.props.eligibility.email}
                  </p>{" "}
                  <p>
                    <span>
                      <img src={require("../../assets/img/phone.png")} />
                    </span>
                    {this.props.eligibility.mobile}
                  </p>
                  {isAipOff ? <></> :
                  <div id="gtm-completed-receive-money-ack" className="check-status-container">
                    <p>
                      You can check your financing application status by clicking
                      button below.
                    </p>
                    <button className="mt-4 ab-btn" style={{ padding: 0 }} onClick={this.onCheckStatusPressed}>Check Status</button>
                  </div> }
                  <div className="status-step-container">
                    <ul>
                      <div className="line" />
                      <li>
                        <span>
                          <img
                            src={require("../../assets/img/check-status-completed-icon.png")}
                          />
                        </span>
                        Your financing application submission is completed. The Bank may contact you to request additional supporting documents to process your application.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/img/48hour-process-icon.png")}
                          />
                        </span>
                        It will take up to 48 hours to process the application.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/img/receive-money-2-icon.png")}
                          />
                        </span>
                        Upon approval, you will receive your money in your {this.props.disbursementBank.preferredBank.label} account ending {maskedAccountNumber}
                        <div className="ovelay-white"></div>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <div style={{ marginTop: "20px" }}>
                  <strong style={{ color: "#FF0000" }}>Disclaimer:</strong>
                </div>
                <div>
                  <strong style={{ color: "#FF0000" }}>* </strong>{data.disclamerDesc}
                </div>
              </Col>
            </Row>
            <button className="mb-4 mt-4 ab-btn" style={{ color: "#ffffff", textDecoration:"none" }} onClick={this.onHomePressed}>HOME</button>
          </Container>
          <NeedHelp 
            name={this.props.eligibility.name} 
            mobile={this.props.eligibility.mobile} 
            email={this.props.eligibility.email} 
            currentScreen={PloanScreensList.SCREEN_COMPLETED_PROVIDE_MORE_INFO}
          />
        </Section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  eligibility: state.eligibility,
  disbursementBank: state.preferredBank,
  calculator: state.calculator,
  webserviceConfig: state.webserviceConfig
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CompletedReceiveMoney);
