import React, { Component } from "react";
import "react-step-progress-bar/styles.css";
// import { BrowserRouter as Link } from "react-router-dom";
import "./footer.scss";
import { Container } from "reactstrap";

class Footer extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <div>
        <footer className="footer-wrapper">
          <Container>
            <ul>
              <li>
                <a href="https://www.alliancebank.com.my/Website-Terms-and-Conditions" target="blank">Terms & Conditions</a>
              </li>
              <li>
                <a href="https://www.alliancebank.com.my/privacy-policy.aspx" target="blank">Privacy Policy</a>
              </li>
              <li>
                <a href="https://www.alliancebank.com.my/website-customer-service-charter-aspx" target="blank">Customer Service Charter</a>
              </li>
              <li>
                <a href="https://www.alliancebank.com.my/personal-data-protection-act-2010.aspx" target="blank">Notice & Choice Principle Statement - Personal Data Protection Act 2010</a>
              </li>
              <li>
                Copyright © 2024 Alliance Bank Malaysia Berhad (88103-W)
              </li>
            </ul>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
