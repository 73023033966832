import React, { Component } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import SectionTitle from "../../components/SectionTitle/index";
import Dropdown from "../../components/Dropdown";
import styles from './amendments.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import BaseComponent from '../../class/baseComponent';
import { FormHelper, Webservice, PayloadGenerator, PageHelper } from '../../services';
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import { connect } from 'react-redux';
import { AmendmentsCategoryList, EformDBList, EformAttachmentDBList } from '../../constant';
import * as Listing from '../../constant';
import * as Getter from '../../services/formHelper/getter';
import Input from "../../components/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Section from "../../components/Section";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomDropzone from '../../components/Dropzone';
import Tabs from '../../components/Tabs';
import Routes from '../../routes';
import * as data from "../../data/globalData";

const TabRef = React.createRef();
const NRICFrontRef = React.createRef();
const NRICBackRef = React.createRef();

class Amendments extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            progressBarPercentage: 0,
            activeIndex: 0,
            answers: props.amendments.amendmentModel,
            isShowThankYou: false,
            isSendingAnswer: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onFileSizeExceedError = () => {
        this.props.alert('Oops,the file you choose has exceeded the maximum size.')
    }

    isRenderAdditionalDocumentsField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.AdditionalDocuments:
                return true;
            default:
                return false;
        }
    }

    getAdditionalDocumentDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fields = answers[activeIndex].fields;

        switch (category) {
            case AmendmentsCategoryList.AdditionalDocuments:
                let allLabels = fields.map(item => item.fieldName)
                return { ...allLabels };
            default:
                return new Error('Additional documents not found');
        }
    }

    isRenderIncomeDocumentsField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.IncomeDocuments:
                return true;
            default:
                return false;
        }
    }

    isRenderIdentityDocumentField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.IdentityDocuments:
                return true;
            default:
                return false;
        }
    }

    isRenderDisbursementBankField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.DisbursementDetail:
                return true;
            default:
                return false;
        }
    }

    getDisbursementBankDataBasedCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fields = answers[activeIndex].fields;

        switch (category) {
            case AmendmentsCategoryList.DisbursementDetail:
                let disbursementBank = Getter.getPreferredBankFromValue(fields.filter(item => item.fieldName === EformDBList.DisbursementBank)[0].fieldValue);
                let disbursementBankAccountNumber = fields.filter(item => item.fieldName === EformDBList.DisbursementBankAccountNumber)[0].fieldValue;
                return { disbursementBank, disbursementBankAccountNumber };
            default:
                return new Error('Disbursement bank not found');
        }
    }

    isRenderSpouseDetailField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.SpouseDetail:
                return true;
            default:
                return false;
        }
    }

    getSpouseDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fields = answers[activeIndex].fields;

        switch (category) {
            case AmendmentsCategoryList.SpouseDetail:
                let spouseName = fields.filter(item => item.fieldName === EformDBList.SpouseName)[0].fieldValue;
                let spouseCompanyName = fields.filter(item => item.fieldName === EformDBList.SpouseCompanyName)[0].fieldValue;
                let spouseMobileNumber = fields.filter(item => item.fieldName === EformDBList.SpouseMobileNumber)[0].fieldValue;
                return { spouseName, spouseCompanyName, spouseMobileNumber };
            default:
                return new Error('Spouse data not found');
        }
    }

    isRenderEmergencyContactField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.EmergencyContact:
                return true;
            default:
                return false;
        }
    }

    getEmergencyDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fields = answers[activeIndex].fields;

        switch (category) {
            case AmendmentsCategoryList.EmergencyContact:
                let emergencyContactName = fields.filter(item => item.fieldName === EformDBList.EmergencyContactName)[0].fieldValue;
                let emergencyContactRelationship = Getter.getRelationshipFromValue(fields.filter(item => item.fieldName === EformDBList.EmergenctContactRelationship)[0].fieldValue);
                let emergencyContactMobileNumber = fields.filter(item => item.fieldName === EformDBList.EmergencyContactMobileNumber)[0].fieldValue;
                return { emergencyContactRelationship, emergencyContactName, emergencyContactMobileNumber }
            default:
                return new Error('Emergency Contact not found')
        }
    }

    isRenderEmploymentDateField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.EmploymentDateJoined:
                return true;
            default:
                return false;
        }
    }

    getEmploymentDateDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let answer = answers[activeIndex].fieldValue;

        switch (category) {
            case AmendmentsCategoryList.EmploymentDateJoined:
                return { value: new Date(answer) }
            default:
                return new Error('Date not found');
        }
    }

    isRenderBusinessOrOccupationField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.Occupation:
            case AmendmentsCategoryList.Business:
                return true;
            default:
                return false;
        }
    }

    getBusinessOccupationDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fields = answers[activeIndex].fields;
        let sector, specification;

        switch (category) {
            case AmendmentsCategoryList.Occupation:
                sector = Getter.getEmploymentOccupationSectorFromValue(fields.filter(item => item.fieldName === EformDBList.EmploymentOccupationSector)[0].fieldValue);
                specification = Getter.getEmploymentOccupationFromValue(fields.filter(item => item.fieldName === EformDBList.EmploymentOccupation)[0].fieldValue);
                return { sector, specification, type: category }
            case AmendmentsCategoryList.Business:
                sector = Getter.getEmploymentNatureOfBusinessSectorFromValue(fields.filter(item => item.fieldName === EformDBList.EmploymentNatureOfBusinessSector)[0].fieldValue);
                specification = Getter.getEmploymentNatureOfBusinessFromValue(fields.filter(item => item.fieldName === EformDBList.EmploymentNatureOfBusiness)[0].fieldValue);
                return { sector, specification, type: category }
            default:
                return false;
        }
    }

    isRenderAddressField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.MailingAddress:
            case AmendmentsCategoryList.CompanyAddress:
                return true;
            default:
                return false;
        }
    }

    getAddressFieldsDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fields = answers[activeIndex].fields;
        let addressLine1, addressLine2, country, state, city, postcode;

        switch (category) {
            case AmendmentsCategoryList.MailingAddress:
                addressLine1 = fields.filter(item => item.fieldName === EformDBList.Address1)[0].fieldValue;
                addressLine2 = fields.filter(item => item.fieldName === EformDBList.Address2)[0].fieldValue;
                country = Getter.getCountryFromValue(fields.filter(item => item.fieldName === EformDBList.Country)[0].fieldValue);
                state = fields.filter(item => item.fieldName === EformDBList.State)[0].fieldValue;

                if (country.value === 'MY') {
                    state = Getter.getStateFromValue(state);
                }

                city = fields.filter(item => item.fieldName === EformDBList.City)[0].fieldValue;
                postcode = fields.filter(item => item.fieldName === EformDBList.Postcode)[0].fieldValue;

                return { addressLine1, addressLine2, country, state, city, postcode, type: category }

            case AmendmentsCategoryList.CompanyAddress:
                addressLine1 = fields.filter(item => item.fieldName === EformDBList.EmploymentCompanyAddress1)[0].fieldValue;
                addressLine2 = fields.filter(item => item.fieldName === EformDBList.EmploymentCompanyAddress2)[0].fieldValue;
                country = Getter.getCountryFromValue(fields.filter(item => item.fieldName === EformDBList.EmploymentCompanyCountry)[0].fieldValue);
                state = fields.filter(item => item.fieldName === EformDBList.EmploymentCompanyState)[0].fieldValue;

                if (country.value === 'MY') {
                    state = Getter.getStateFromValue(state);
                }

                city = fields.filter(item => item.fieldName === EformDBList.EmploymentCompanyCity)[0].fieldValue;
                postcode = fields.filter(item => item.fieldName === EformDBList.EmploymentCompanyPostcode)[0].fieldValue;

                return { addressLine1, addressLine2, country, state, city, postcode, type: category }
            default:
                return new Error('Address not found');
        }
    }

    isRenderInputField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.Name:
            case AmendmentsCategoryList.Email:
            case AmendmentsCategoryList.Company:
            case AmendmentsCategoryList.MobileNo:
            case AmendmentsCategoryList.CompanyTel:
            case AmendmentsCategoryList.CompanyEmail:
                return true;
            default:
                return false;
        }
    }

    getTextFieldDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        const { isValidName, isEmailFormat, isValidLocalMobileNumber, isOnlyNumber } = FormHelper.Validation;
        let answer = answers[activeIndex].fieldValue;
        switch (category) {
            case AmendmentsCategoryList.Name:
                return {
                    value: answer,
                    placeholder: 'Full Name (as per NRIC)',
                    label: 'Name',
                    isValidInput: isValidName,
                    errorMessage: `Only alphabets and "@ , - , ' " symbols is allowed.`,
                    maxLength: data.maxlength.name,
                    onChangeInput: this.onInputChanged
                }
            case AmendmentsCategoryList.Email:
                return {
                    value: answer,
                    placeholder: 'Email',
                    label: 'Email',
                    isValidInput: isEmailFormat,
                    errorMessage: `Please enter a correct Email.`,
                    maxLength: data.maxlength.email,
                    onChangeInput: this.onInputChanged
                }
            case AmendmentsCategoryList.Company:
                return {
                    value: answer,
                    placeholder: 'Company',
                    label: 'Company',
                    maxLength: data.maxlength.companyName,
                    onChangeInput: this.onInputChanged
                }
            case AmendmentsCategoryList.MobileNo:
                return {
                    value: FormHelper.phoneSeparator(answer),
                    placeholder: 'Mobile Number',
                    label: 'Mobile Number',
                    isValidInput: isValidLocalMobileNumber,
                    errorMessage: `Please enter a correct mobile number.`,
                    maxLength: data.maxlength.mobileNumber,
                    type: 'tel',
                    onChangeInput: this.onMobileNumberChanged
                }
            case AmendmentsCategoryList.CompanyTel:
                return {
                    value: answer,
                    placeholder: 'Company Tel',
                    label: 'Company Tel',
                    isValidInput: isOnlyNumber,
                    errorMessage: `Please enter a correct company telephone number.`,
                    maxLength: data.maxlength.companyTel,
                    onChangeInput: this.onInputChanged
                }
            case AmendmentsCategoryList.CompanyEmail:
                return {
                    value: answer,
                    placeholder: 'Company Email',
                    label: 'Company Email',
                    isValidInput: isEmailFormat,
                    errorMessage: `Please enter a correct company Email.`,
                    maxLength: data.maxlength.companyEmail,
                    onChangeInput: this.onInputChanged
                }
            default:
                return new Error('Input not found')
        }
    }

    getValidInputBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        const { isValidName, isEmailFormat, isValidLocalMobileNumber, isOnlyNumber } = FormHelper.Validation;
        let answer = answers[activeIndex].fieldValue;

        switch (category) {
            case AmendmentsCategoryList.Name:
                return answer && isValidName(answer)
            case AmendmentsCategoryList.Email:
                return answer && isEmailFormat(answer)
            case AmendmentsCategoryList.Company:
                return answer
            case AmendmentsCategoryList.MobileNo:
                return answer && isValidLocalMobileNumber(FormHelper.phoneSeparator(answer))
            case AmendmentsCategoryList.CompanyTel:
                return answer && isOnlyNumber(answer)
            case AmendmentsCategoryList.CompanyEmail:
                return answer && isEmailFormat(answer)
            case AmendmentsCategoryList.MailingAddress:
                return this.getAddressValidInput(category)
            case AmendmentsCategoryList.CompanyAddress:
                return this.getAddressValidInput(category)
            case AmendmentsCategoryList.Occupation:
            case AmendmentsCategoryList.Business:
                return this.getOccupationBusinessValidInput(category)
            case AmendmentsCategoryList.EmploymentDateJoined:
                return answer
            case AmendmentsCategoryList.EmergencyContact:
                return this.getEmergencyContactValidInput(category)
            case AmendmentsCategoryList.SpouseDetail:
                return this.getSpouseValidInput(category)
            case AmendmentsCategoryList.DisbursementDetail:
                return this.getDisbursementDetailValidInput(category)
            case AmendmentsCategoryList.Gender:
            case AmendmentsCategoryList.MaritalStatus:
            case AmendmentsCategoryList.Race:
            case AmendmentsCategoryList.Education:
            case AmendmentsCategoryList.Nationality:
            case AmendmentsCategoryList.ResidenceType:
            case AmendmentsCategoryList.LengthOfStay:
            case AmendmentsCategoryList.ResidenceStatus:
            case AmendmentsCategoryList.PurposeOfBorrowing:
            case AmendmentsCategoryList.SourceOfFund:
            case AmendmentsCategoryList.SourceOfWealth:
                return answer
            default:
                return false;
        }
    }

    getAddressValidInput = (category = '') => {
        const { isLocalPostcode, isForeignPostcode, isOnlyAlphabetAndSpace } = FormHelper.Validation;
        let validAddress = this.getAddressFieldsDataBasedOnCategory(category)
        let validAddressLine1 = validAddress.addressLine1;
        let validCountry = validAddress.country;
        let validPostcode = validCountry.value === "MY" ? isLocalPostcode(validAddress.postcode) : isForeignPostcode(validAddress.postcode);
        let isPostCodeOptional = validCountry.value === "MY" ? false : true;
        let validState = validCountry.value === "MY" ? validAddress.state : isOnlyAlphabetAndSpace(validAddress.state);
        let isStateOptional = validCountry.value === "MY" ? false : true;
        let validCity = validAddress.city && isOnlyAlphabetAndSpace(validAddress.city);

        return validAddressLine1 && validCountry &&
            (!isPostCodeOptional && validAddress.postcode && validPostcode) &&
            (!isStateOptional && validAddress.state && validState) &&
            validCity
    }

    getOccupationBusinessValidInput = (category = '') => {
        let validData = this.getBusinessOccupationDataBasedOnCategory(category)
        return validData.sector && validData.specification
    }

    getEmergencyContactValidInput = (category = '') => {
        const { isValidLocalMobileNumber, isValidName } = FormHelper.Validation;
        let validData = this.getEmergencyDataBasedOnCategory(category)
        let validName = validData.emergencyContactName && isValidName(validData.emergencyContactName)
        let validMobileNumber = validData.emergencyContactMobileNumber && isValidLocalMobileNumber(FormHelper.phoneSeparator(validData.emergencyContactMobileNumber))
        return validName && validData.emergencyContactRelationship && validMobileNumber
    }

    getSpouseValidInput = (category = '') => {
        const { isValidLocalMobileNumber, isValidName } = FormHelper.Validation;
        let validData = this.getSpouseDataBasedOnCategory(category)
        let validName = validData.spouseName && isValidName(validData.spouseName)
        let validMobileNumber = validData.spouseMobileNumber && isValidLocalMobileNumber(FormHelper.phoneSeparator(validData.spouseMobileNumber))
        return validName && validMobileNumber
    }

    getDisbursementDetailValidInput = (category = '') => {
        const { isOnlyNumber } = FormHelper.Validation;
        let validData = this.getDisbursementBankDataBasedCategory(category)
        let validBankAccountNumber = validData.disbursementBankAccountNumber && isOnlyNumber(validData.disbursementBankAccountNumber)
        return validData.disbursementBank && validBankAccountNumber
    }

    isRenderDropdownField = (category = '') => {
        switch (category) {
            case AmendmentsCategoryList.Gender:
            case AmendmentsCategoryList.MaritalStatus:
            case AmendmentsCategoryList.Race:
            case AmendmentsCategoryList.Education:
            case AmendmentsCategoryList.Nationality:
            case AmendmentsCategoryList.ResidenceType:
            case AmendmentsCategoryList.LengthOfStay:
            case AmendmentsCategoryList.ResidenceStatus:
            case AmendmentsCategoryList.PurposeOfBorrowing:
            case AmendmentsCategoryList.SourceOfFund:
            case AmendmentsCategoryList.SourceOfWealth:
                return true;
            default:
                return false;
        }
    }

    getDropdownDataBasedOnCategory = (category = '') => {
        const { answers, activeIndex } = this.state;
        let fieldValue = answers[activeIndex].fieldValue;
        let answer;

        switch (category) {
            case AmendmentsCategoryList.Gender:
                answer = Getter.getGenderTypeFromValue(fieldValue);
                return { label: 'Gender', name: 'gender', placeholder: 'Gender', options: Listing.GenderList, value: answer }
            case AmendmentsCategoryList.MaritalStatus:
                answer = Getter.getMaritlStatusTypeFromValue(fieldValue);
                return { label: 'Marital Status', name: 'maritalStatus', placeholder: 'Marital Status', options: Listing.MaritalStatusList, value: answer }
            case AmendmentsCategoryList.Race:
                answer = Getter.getRaceFromValue(fieldValue);
                return { label: 'Race', name: 'race', placeholder: 'Race', options: Listing.RaceList, value: answer }
            case AmendmentsCategoryList.Education:
                answer = Getter.getEducationLevelFromValue(fieldValue);
                return { label: 'Highest Education Level', name: 'educationLevel', placeholder: 'Highest Education Level', options: Listing.EducationLevelList, value: answer }
            case AmendmentsCategoryList.Nationality:
                answer = Getter.getNationalityTypeFromValue(fieldValue);
                return { label: 'Nationality', name: 'nationality', placeholder: 'Nationality', options: Listing.NationalityList, value: answer }
            case AmendmentsCategoryList.ResidenceType:
                answer = Getter.getResidenceTypeFromValue(fieldValue);
                return { label: 'Residence Type', name: 'residenceType', placeholder: 'Residence Type', options: Listing.ResidenceTypeList, value: answer }
            case AmendmentsCategoryList.LengthOfStay:
                answer = Getter.getLengthOfStayFromValue(fieldValue);
                return { label: 'Length of stay at current residence', name: 'lengthOfStay', placeholder: 'Length of stay at current residence', options: Listing.LengthOfStayList, value: answer }
            case AmendmentsCategoryList.ResidenceStatus:
                answer = Getter.getResidenceStatusFromValue(fieldValue);
                return { label: 'Residence Status', name: 'residenceStatus', placeholder: 'Residence Status', options: Listing.ResidenceStatusList, value: answer }
            case AmendmentsCategoryList.PurposeOfBorrowing:
                answer = Getter.getPurposeOfBorrowingFromValue(fieldValue);
                return { label: 'Purpose of Borrowing', name: 'purposeOfBorrowing', placeholder: 'Purpose of Borrowing', options: Listing.PurposeOfBorrowingList, value: answer }
            case AmendmentsCategoryList.SourceOfFund:
                answer = Getter.getSourceOfFundFromValue(fieldValue);
                return { label: 'Source of Fund', name: 'sourceOfFund', placeholder: 'Source of Fund', options: Listing.SourceOfFundList, value: answer }
            case AmendmentsCategoryList.SourceOfWealth:
                answer = Getter.getSourceOfWealthFromValue(fieldValue);
                return { label: 'Source of Wealth', name: 'sourceOfWealth', placeholder: 'Source of Wealth', options: Listing.SourceOfWealthList, value: answer }
            default:
                return new Error('Dropdown Not Found!');
        }
    }

    componentSwitcher = () => {
        const { activeIndex, answers } = this.state;
        let category = answers[activeIndex].fieldCategory;

        if (this.isRenderDropdownField(category)) {
            return this.renderDropdown(this.getDropdownDataBasedOnCategory(category))
        }

        if (this.isRenderInputField(category)) {
            return this.renderInputField(this.getTextFieldDataBasedOnCategory(category))
        }

        if (this.isRenderAddressField(category)) {
            return this.renderAddressFields(this.getAddressFieldsDataBasedOnCategory(category))
        }

        if (this.isRenderBusinessOrOccupationField(category)) {
            return this.renderBusinessOrOccupationFields(this.getBusinessOccupationDataBasedOnCategory(category))
        }

        if (this.isRenderEmploymentDateField(category)) {
            return this.renderEmploymentDateField(this.getEmploymentDateDataBasedOnCategory(category))
        }

        if (this.isRenderEmergencyContactField(category)) {
            return this.renderEmergencyContactFields(this.getEmergencyDataBasedOnCategory(category))
        }

        if (this.isRenderSpouseDetailField(category)) {
            return this.renderSpouseDetailFields(this.getSpouseDataBasedOnCategory(category))
        }

        if (this.isRenderDisbursementBankField(category)) {
            return this.renderDisbursementFields(this.getDisbursementBankDataBasedCategory(category))
        }

        if (this.isRenderIdentityDocumentField(category)) {
            return this.renderIdentityDocumentFields();
        }

        if (this.isRenderIncomeDocumentsField(category)) {
            return this.renderIncomeDocumentsField()
        }

        if (this.isRenderAdditionalDocumentsField(category)) {
            return this.renderAdditionalDocmentField(this.getAdditionalDocumentDataBasedOnCategory(category))
        }
    }

    sendAmendment = async () => {
        let isSuccess = false;
        const { eformUuid, amendmentUuid } = this.props.amendments;
        const { answers, activeIndex } = this.state;
        let payload = PayloadGenerator.genAmendmentFieldsPayload(eformUuid, amendmentUuid, answers[activeIndex]);

        try {
            await Webservice.Eform.updateAmendmentsByEformUuid(eformUuid, payload).then(resp => {
                console.log('sendAmendment:resp:', resp)
                isSuccess = true;
            }).catch(error => {
                throw error;
            })
        } catch (error) {
            console.log('sendAmendment:error:', error);
            this.baseErrorHandler(error);
        }

        return isSuccess;
    }

    onNextPress = async () => {
        const { activeIndex } = this.state;
        const { amendmentModel } = this.props.amendments;
        let amendmentModelLength = amendmentModel.length - 1;

        this.setState({ isSendingAnswer: true })
        let isSendSuccess = await this.sendAmendment();
        this.setState({ isSendingAnswer: false })

        if (isSendSuccess) {
            if ((activeIndex + 1) <= amendmentModelLength) {
                this.setState(prevState => ({
                    activeIndex: prevState.activeIndex + 1
                }))
            } else {
                this.setState({ isShowThankYou: true })
            }
        }
    }

    onOk = () => {
        this.props.history.push(Routes.default);
    }

    onDropdownChange = (option) => {
        const { activeIndex, answers } = this.state;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex)
                return { ...item, fieldValue: option.value };

            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderDropdown = (data) => {
        const { label, name, placeholder, options, value } = data;
        return (
            <Row className="mt-4 mb-responsive pb-2">
                <Col className="mt-2 col-sm-6">
                    <Dropdown
                        label={label}
                        name={name}
                        placeholder={placeholder}
                        options={options}
                        value={value}
                        onChange={this.onDropdownChange}
                        isDisabled={this.state.isSendingAnswer}
                    />
                </Col>
            </Row>
        )
    }

    onInputChanged = (value) => {
        const { activeIndex, answers } = this.state;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex)
                return { ...item, fieldValue: value };

            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    onMobileNumberChanged = (value) => {
        const { activeIndex, answers } = this.state;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex)
                return { ...item, fieldValue: FormHelper.Replace.replaceDash(FormHelper.phoneSeparator(value)) };

            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderInputField = (data) => {
        const { value, placeholder, label, isValidInput, errorMessage, maxLength, type, onChangeInput } = data;
        return (
            <Row className="mt-4 mb-responsive pb-2">
                <Col className="mt-2 col-sm-6">
                    <Input
                        specialClass="field-input"
                        label={label}
                        onChange={onChangeInput}
                        placeholder={placeholder}
                        value={value}
                        disabled={this.state.isSendingAnswer}
                        isValidInput={isValidInput}
                        errText={errorMessage}
                        maxLength={maxLength}
                        type={type}
                    />
                </Col>
            </Row>
        )
    }

    onAddressChange = (input = '', fieldName = '') => {
        const { activeIndex, answers } = this.state;
        let isDropdown = fieldName === EformDBList.State || fieldName === EformDBList.Country || fieldName === EformDBList.EmploymentCompanyState || fieldName === EformDBList.EmploymentCompanyCountry;
        let isPostCode = fieldName === EformDBList.Postcode || EformDBList.EmploymentCompanyPostcode;
        let isState = fieldName === EformDBList.State || EformDBList.EmploymentCompanyState;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex) {
                let newFields = answers[index].fields.map((item2, index2) => {

                    if (item2.fieldName === fieldName)
                        return { ...item2, fieldValue: isDropdown ? input.value : input }

                    if (isPostCode && (item2.fieldName === EformDBList.State || item2.fieldName === EformDBList.EmploymentCompanyState))
                        return { ...item2, fieldValue: FormHelper.getStateByPostcode(input) }

                    if (isState && (item2.fieldName === EformDBList.Postcode || item2.fieldName === EformDBList.EmploymentCompanyPostcode))
                        return { ...item2, fieldValue: '' }

                    return item2;
                })
                return { ...item, fields: newFields };
            }
            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderAddressFields = (data) => {
        const { addressLine1, addressLine2, country, state, city, postcode, type } = data;
        let addressLine1Label, addressLine2Label,
            addressLine1PlaceHolder, addressLine2PlaceHolder,
            addressLine1FieldName, addressLine2FieldName,
            postCodeFieldName, cityFieldName, stateFieldName, countryFieldName;

        if (type === AmendmentsCategoryList.MailingAddress) {
            addressLine1Label = 'Mailing Address Line 1';
            addressLine2Label = 'Mailing Address Line 2';
            addressLine1PlaceHolder = 'Mailing Address Line 1';
            addressLine2PlaceHolder = 'Mailing Address Line 2';
            addressLine1FieldName = EformDBList.Address1;
            addressLine2FieldName = EformDBList.Address2;
            postCodeFieldName = EformDBList.Postcode;
            cityFieldName = EformDBList.City;
            stateFieldName = EformDBList.State;
            countryFieldName = EformDBList.Country;
        }

        if (type === AmendmentsCategoryList.CompanyAddress) {
            addressLine1Label = 'Company Address Line 1';
            addressLine2Label = 'Company Address Line 2';
            addressLine1PlaceHolder = 'Company Address Line 1';
            addressLine2PlaceHolder = 'Company Address Line 2';
            addressLine1FieldName = EformDBList.EmploymentCompanyAddress1;
            addressLine2FieldName = EformDBList.EmploymentCompanyAddress2;
            postCodeFieldName = EformDBList.EmploymentCompanyPostcode;
            cityFieldName = EformDBList.EmploymentCompanyCity;
            stateFieldName = EformDBList.EmploymentCompanyState;
            countryFieldName = EformDBList.EmploymentCompanyCountry;
        }

        return (
            <>
                <Row className="mb-responsive mt-4 pb-2">
                    <Col className="mt-2 col-6">
                        <Input
                            specialClass="field-input"
                            label={addressLine1Label}
                            onChange={(value) => { this.onAddressChange(value, addressLine1FieldName) }}
                            placeholder={addressLine1PlaceHolder}
                            value={addressLine1}
                            disabled={this.state.isSendingAnswer}
                            maxLength={data.maxlength.addressLine1}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label={addressLine2Label}
                            onChange={(value) => { this.onAddressChange(value, addressLine2FieldName) }}
                            placeholder={addressLine2PlaceHolder}
                            value={addressLine2}
                            disabled={this.state.isSendingAnswer}
                            maxLength={data.maxlength.addressLine2}
                            optional
                        />
                    </Col>
                </Row>
                <Row className="my-3 mb-responsive">
                    <Col className="mt-2">
                        <Input
                            type={country && country.value === 'MY' ? "tel" : "text"}
                            specialClass="field-input"
                            label="Post Code"
                            name="postcode"
                            onChange={(value) => { this.onAddressChange(value, postCodeFieldName) }}
                            placeholder="Post Code"
                            value={postcode}
                            maxLength={data.maxlength.postcode}
                            isValidInput={country && country.value === "MY" ? FormHelper.Validation.isLocalPostcode : FormHelper.Validation.isForeignPostcode}
                            errText="Please enter a correct postcode"
                            disabled={this.state.isSendingAnswer}
                            optional={country && country.value === "MY" ? false : true}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="City"
                            name="city"
                            onChange={(value) => { this.onAddressChange(value, cityFieldName) }}
                            placeholder="City"
                            value={city}
                            maxLength={data.maxlength.city}
                            isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                            errText="Only alphabet is allowed"
                            disabled={this.state.isSendingAnswer}
                        />
                    </Col>
                    <Col className="mt-2">
                        {country && country.value === 'MY' ?
                            (<Dropdown
                                label="State"
                                name="state"
                                placeholder="State"
                                options={Listing.StateList}
                                value={state}
                                onChange={(value) => { this.onAddressChange(value, stateFieldName) }}
                                isDisabled={this.state.isSendingAnswer}
                            />) :
                            (<Input
                                specialClass="field-input"
                                label="State"
                                name="foreignState"
                                onChange={(value) => { this.onAddressChange(value, stateFieldName) }}
                                placeholder="State"
                                value={state}
                                maxLength={data.maxlength.foreignState}
                                isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                                errText="Only alphabet is allowed"
                                disabled={this.state.isSendingAnswer}
                                optional
                            />)
                        }
                    </Col>
                    <Col className="mt-2">
                        <Dropdown
                            label="Country"
                            name="country"
                            placeholder="Country"
                            options={Listing.CountriesList}
                            value={country}
                            onChange={(value) => { this.onAddressChange(value, countryFieldName) }}
                            isDisabled={this.state.isSendingAnswer}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    onOccupationOrBusinessChange = (option = {}, fieldName = '') => {
        const { activeIndex, answers } = this.state;
        let isSector = fieldName === EformDBList.EmploymentOccupationSector || fieldName === EformDBList.EmploymentNatureOfBusinessSector;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex) {
                let newFields = answers[index].fields.map((item2, index2) => {
                    if (item2.fieldName === fieldName)
                        return { ...item2, fieldValue: option.value }

                    if (isSector && item2.fieldValue !== option.value)
                        return { ...item2, fieldValue: '' }

                    return item2;
                })
                return { ...item, fields: newFields };
            }
            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    filterBusinessDropdownBasedOnSector = (sectorData, optionList) => {
        let newDropdown = optionList.filter(item => item.sector === sectorData.value)
        let newSortedDropdown = newDropdown.sort((item1, item2) => item1.label.localeCompare(item2.label))
        return newSortedDropdown;
    }

    renderBusinessOrOccupationFields = (data) => {
        const { sector, specification, type } = data;

        let sectorLabel, specificationLabel, sectorPlaceholder,
            specificationPlaceholder, sectorOptions, specificationOptions,
            sectorFieldName, specificationFieldName;

        if (type === AmendmentsCategoryList.Occupation) {
            sectorLabel = 'Occupation Sector';
            specificationLabel = 'Occpation';
            sectorPlaceholder = 'Occupation Sector';
            specificationPlaceholder = 'Occupation';
            sectorOptions = Listing.OccupationSectorList.sort((item1, item2) => item1.label.localeCompare(item2.label));
            specificationOptions = this.filterBusinessDropdownBasedOnSector(data.sector, Listing.OccupationList)
            sectorFieldName = EformDBList.EmploymentOccupationSector;
            specificationFieldName = EformDBList.EmploymentOccupation;
        }

        if (type === AmendmentsCategoryList.Business) {
            sectorLabel = 'Nature of Business Sector';
            specificationLabel = 'Nature of Business';
            sectorPlaceholder = 'Nature of Business Sector';
            specificationPlaceholder = 'Nature of Business';
            sectorOptions = Listing.BusinessSectorList.sort((item1, item2) => item1.label.localeCompare(item2.label));
            specificationOptions = this.filterBusinessDropdownBasedOnSector(data.sector, Listing.BusinessList)
            sectorFieldName = EformDBList.EmploymentNatureOfBusinessSector;
            specificationFieldName = EformDBList.EmploymentNatureOfBusiness;
        }

        return (
            <Row className="mb-responsive">
                <Col className="mt-2 my-4">
                    <Dropdown
                        label={sectorLabel}
                        placeholder={sectorPlaceholder}
                        options={sectorOptions}
                        value={sector}
                        onChange={(option) => { this.onOccupationOrBusinessChange(option, sectorFieldName) }}
                        isDisabled={this.state.isSendingAnswer}
                    />
                </Col>
                <Col className="mt-2 my-4">
                    <Dropdown
                        label={specificationLabel}
                        placeholder={specificationPlaceholder}
                        options={specificationOptions}
                        value={specification}
                        onChange={(option) => { this.onOccupationOrBusinessChange(option, specificationFieldName) }}
                        isDisabled={this.state.isSendingAnswer}
                    />
                </Col>
            </Row>
        )
    }

    onEmploymentDateChanged = (date) => {
        const { activeIndex, answers } = this.state;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex)
                return { ...item, fieldValue: date }

            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderEmploymentDateField = (data) => {
        const { value } = data;
        return (
            <Row className="mt-4 mb-responsive pb-2">
                <Col className="mt-2 col-sm-6">
                    <label htmlFor="dateJoined" className={`date-label ${value ? "show" : ""}`}>
                        Date Joined
                    </label>
                    <span data-name="dateJoined">
                        <DatePicker
                            maxDate={new Date()}
                            className="field-input date-picker"
                            selected={value}
                            onChange={this.onEmploymentDateChanged}
                            placeholderText="Date Joined"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="d-MMM-yyyy"
                        />
                    </span>
                    {value && (
                        <div className="form-tick-date">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    )}
                </Col>
            </Row>
        )
    }

    onEmergencyContactChanged = (input = '', fieldName = '') => {
        const { activeIndex, answers } = this.state;

        let isDropdown = fieldName === EformDBList.EmergenctContactRelationship;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex) {
                let newFields = answers[index].fields.map((item2, index2) => {
                    if (item2.fieldName === fieldName && item2.fieldName !== EformDBList.EmergencyContactMobileNumber)
                        return { ...item2, fieldValue: isDropdown ? input.value : input }

                    if (item2.fieldName === fieldName && item2.fieldName === EformDBList.EmergencyContactMobileNumber)
                        return { ...item2, fieldValue: FormHelper.Replace.replaceDash(FormHelper.phoneSeparator(input)) };

                    return item2;
                })
                return { ...item, fields: newFields };
            }
            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderEmergencyContactFields = (data) => {
        const { emergencyContactRelationship, emergencyContactName, emergencyContactMobileNumber } = data;

        return (
            <div>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="Name (Emergency Contact)"
                            name="emergencyContactName"
                            onChange={(input) => { this.onEmergencyContactChanged(input, EformDBList.EmergencyContactName) }}
                            placeholder="Name (Emergency Contact)"
                            value={emergencyContactName}
                            isValidInput={FormHelper.Validation.isValidName}
                            errText={`Only alphabets and "@ , - , ', / " symbols is allowed.`}
                            maxLength={data.maxlength.emergencyContactName}
                            disabled={this.state.isSendingAnswer}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Dropdown
                            label="Relationship (Emergency Contact)"
                            name="emergencyContactRelationship"
                            placeholder="Relationship (Emergency Contact)"
                            options={Listing.RelationshipList}
                            value={emergencyContactRelationship}
                            onChange={(input) => { this.onEmergencyContactChanged(input, EformDBList.EmergenctContactRelationship) }}
                            isDisabled={this.state.isSendingAnswer}
                        />
                    </Col>
                </Row>
                <Row className="my-4 mb-responsive">
                    <Col className="mt-2 col-6">
                        <Input
                            type="tel"
                            label="Mobile Number (Emergency Contact)"
                            specialClass="field-input"
                            name="emergencyContactMobile"
                            onChange={(input) => { this.onEmergencyContactChanged(input, EformDBList.EmergencyContactMobileNumber) }}
                            placeholder="Mobile Number (Emergency Contact)"
                            value={FormHelper.phoneSeparator(emergencyContactMobileNumber)}
                            maxLength={data.maxlength.emergencyContactMobileNumber}
                            isValidInput={FormHelper.Validation.isValidLocalMobileNumber}
                            errText={'Please enter a correct mobile number'}
                            disabled={this.state.isSendingAnswer}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    onSpouseDetailChange = (input = '', fieldName = '') => {
        const { activeIndex, answers } = this.state;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex) {
                let newFields = answers[index].fields.map(item2 => {

                    if (item2.fieldName === fieldName && item2.fieldName !== EformDBList.SpouseMobileNumber)
                        return { ...item2, fieldValue: input }

                    if (item2.fieldName === fieldName && item2.fieldName === EformDBList.SpouseMobileNumber)
                        return { ...item2, fieldValue: FormHelper.Replace.replaceDash(FormHelper.phoneSeparator(input)) };

                    return item2;
                })
                return { ...item, fields: newFields };
            }
            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderSpouseDetailFields = (data) => {
        const { spouseName, spouseCompanyName, spouseMobileNumber } = data;

        return (
            <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="Name (Spouse)"
                            name="spouseName"
                            onChange={(value) => { this.onSpouseDetailChange(value, EformDBList.SpouseName) }}
                            placeholder="Name (Spouse)"
                            value={spouseName}
                            isValidInput={FormHelper.Validation.isValidName}
                            errText={`Only alphabets and "@ , - , ', / " symbols is allowed.`}
                            maxLength={data.maxlength.spouseName}
                            disabled={this.state.isSendingAnswer}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="Company Name (Spouse)"
                            name="spouseCompanyName"
                            onChange={(value) => { this.onSpouseDetailChange(value, EformDBList.SpouseCompanyName) }}
                            placeholder="Company Name (Spouse)"
                            value={spouseCompanyName}
                            maxLength={data.maxlength.spouseCompanyName}
                            optional
                            disabled={this.state.isSendingAnswer}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-6">
                        <Input
                            type="tel"
                            label="Mobile Number (Spouse)"
                            specialClass="field-input"
                            name="spouseMobile"
                            onChange={(value) => { this.onSpouseDetailChange(value, EformDBList.SpouseMobileNumber) }}
                            placeholder="Mobile Number (Spouse)"
                            value={FormHelper.phoneSeparator(spouseMobileNumber)}
                            maxLength={data.maxlength.spouseMobileNumber}
                            isValidInput={FormHelper.Validation.isValidLocalMobileNumber}
                            errText={'Please enter a correct mobile number'}
                            disabled={this.state.isSendingAnswer}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    onDisbursementChanged = (input = '', fieldName = '') => {
        const { activeIndex, answers } = this.state;
        let isDropdown = fieldName === EformDBList.DisbursementBank;

        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex) {
                let newFields = answers[index].fields.map(item2 => {
                    if (item2.fieldName === fieldName)
                        return { ...item2, fieldValue: isDropdown ? input.value : input }

                    return item2;
                })
                return { ...item, fields: newFields };
            }
            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderDisbursementFields = (data) => {
        const { disbursementBank, disbursementBankAccountNumber } = data;

        return (
            <div>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-6">
                        <Dropdown
                            label="Preferred Bank"
                            name="preferredBank"
                            placeholder="Preferred Bank"
                            options={Listing.PreferredBankList}
                            value={disbursementBank}
                            onChange={(option) => { this.onDisbursementChanged(option, EformDBList.DisbursementBank) }}
                            isDisabled={this.state.isSendingAnswer}
                        />
                        <p className="tips-warning">
                            {disbursementBank ? "" : "Choose your preferred bank that you wish to receive the bank financing disbursement."}
                        </p>
                    </Col>
                </Row>
                <Row className="mt-2 mb-responsive pb-2">
                    <Col className="col-6">
                        <Input
                            type="tel"
                            specialClass="field-input"
                            label="Bank Account Number"
                            name="bankAccountNumber"
                            onChange={(option) => { this.onDisbursementChanged(option, EformDBList.DisbursementBankAccountNumber) }}
                            placeholder="Bank Account Number"
                            value={disbursementBankAccountNumber}
                            maxLength={data.maxlength.disbursementBankAccountNumber}
                            isValidInput={FormHelper.Validation.isOnlyNumber}
                            errText="Only numeric is allowed"
                            disabled={this.state.isSendingAnswer}
                        />
                        <p className="tips-warning">
                            {disbursementBankAccountNumber ? "" : "Bank account number is required"}
                        </p>
                    </Col>
                </Row>
            </div>
        )
    }

    onMykadUpdate = (object = {}, fieldName = '') => {
        const { activeIndex, answers } = this.state;
        // TODO: Set the output from CustomDropzone to the format of Amendments
        let newAnswers = answers.map((item, index) => {
            if (index === activeIndex) {
                let newFields = answers[index].fields.map(item2 => {
                    if (item2.fieldName === fieldName)
                        return { ...item2, content: object.base64 }

                    return item2;
                })
                return { ...item, fields: newFields };
            }
            return item;
        })

        this.setState({
            answers: newAnswers
        })
    }

    renderIdentityDocumentFields = () => {
        const { activeIndex, answers } = this.state;
        let myKadCategory = answers[activeIndex].filter(item => item.fieldCategory === AmendmentsCategoryList.IdentityDocuments);
        let myKadFront = myKadCategory.find(item => item.fieldName === EformAttachmentDBList.MykadFront).content;
        let mykadBack = myKadCategory.find(item => item.fieldName === EformAttachmentDBList.MykadBack).content;
        //TODO; parse myKadFront and mykadBack to the format that CustomDropzone can understand

        return (
            <Row className="mt-4 mb-responsive">
                <Col className="col-3 pl-1 nric-col">
                    <div className="upload-nric-container">
                        <CustomDropzone label='mykadFront'
                            categoryFiles={myKadFront}
                            updateCategoryFiles={(object) => { this.onMykadUpdate(object, EformAttachmentDBList.MykadFront) }}
                            type='image'
                            imageSectionLabel={'MyKad Front'}
                            maxSize={2097152}
                            ref={NRICFrontRef}
                            onFileSizeExceedError={this.onFileSizeExceedError}
                        />
                        <Button onClick={() => NRICFrontRef.current.open()}>
                            Upload
                    </Button>
                    </div>
                </Col>
                <Col className="col-3 pl-1 nric-col">
                    <div className="upload-nric-container">
                        <CustomDropzone label='mykadBack'
                            categoryFiles={mykadBack}
                            updateCategoryFiles={(object) => { this.onMykadUpdate(object, EformAttachmentDBList.MykadBack) }}
                            type='image'
                            imageSectionLabel={'MyKad Back'}
                            maxSize={2097152}
                            ref={NRICBackRef}
                            onFileSizeExceedError={this.onFileSizeExceedError}
                        />
                        <Button onClick={() => NRICBackRef.current.open()}>
                            Upload
                    </Button>
                    </div>
                </Col>
            </Row>
        )
    }

    onIncomeStatementsUpdate = (incomeStatements) => {
        //TODO: update income statements based on Amendment format
        this.setState({ incomeStatements })
    }

    renderIncomeStatementDropzoneElement = (item, documentName, documentDescription, icon) => {
        const IncomeStatementsRef = React.createRef();
        //TODO: incomeStatements should be based on Amendment format
        const { incomeStatements } = this.state;
        let sectionIncomeStatements = incomeStatements.filter(doc => doc.section === item);
        let nonSectionIncomeStatements = incomeStatements.filter(doc => doc.section !== item);
        let maxSizeInByte = 2097152;
        return (
            <>
                <div className="upload-kwsp-container" id="epf" style={{ borderTopLeftRadius: '0px' }}>
                    <div className="left-panel-upload">
                        <img
                            alt="kwsp logo"
                            className="upload-panel-logo"
                            src={icon}
                            style={{ height: '50px', width: 'auto' }}
                        />
                        <h1>{documentName}</h1>
                        <div className="upload-desc" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FontAwesomeIcon icon={faCheck} />
                                <p>{documentDescription}</p>
                            </div>
                            {
                                documentName === 'LHDN' ? (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <p>{'Acknowledgement receipt'}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <p>{'Validated receipt of payment to LHDN OR print screen of bank statement if it is online bank statement (if applicable)'}</p>
                                        </div>
                                    </>
                                ) : (null)
                            }
                        </div>
                        <span>Supported format files are JPG, PNG, PDF. Max {Math.round(maxSizeInByte / 1000000)}MB for PDF file.</span>
                        <Button
                            onClick={() => IncomeStatementsRef.current.open()}
                        >
                            Upload
                </Button>
                    </div>
                    <div className="right-panel-upload">
                        <CustomDropzone label='incomeStatements'
                            categoryFiles={sectionIncomeStatements}
                            //TODO: updateCategoryFiles based on Amendments format
                            updateCategoryFiles={(result) => { this.onIncomeStatementsUpdate(result.concat(nonSectionIncomeStatements)) }}
                            fileLimit={100}
                            maxSize={maxSizeInByte}
                            ref={IncomeStatementsRef}
                            section={item}
                            onItemClick={this.onDocumentClick}
                            onFileSizeExceedError={this.onFileSizeExceedError}
                        />
                    </div>
                </div>
                <Button
                    id="gtm-upload-income-doc"
                    data-index="2"
                    className="proceedButton"
                    disabled={!this.canProceedNextOnUploadIncomeDocument(item)}
                    onClick={this.continueToUploadMykadDocument}
                >
                    CONTINUE
            </Button>
            </>
        )
    }

    renderIncomeDocumentsField = () => {
        const { selectedDoc } = this.props.calculator;
        let selectedDocArray = selectedDoc.value.split('+');

        let toRender = selectedDocArray.map(item => {

            let months = item.substring(item.indexOf('[') + 1, item.indexOf(']')).replace(/[^\d]/gi, '');
            let documentType = item.substring(item.indexOf(']') + 1);
            let documentName = '';
            let documentDescription = '';
            let icon;

            switch (documentType) {
                case 'SSM':
                    documentName = "SSM";
                    documentDescription = 'SSM Registration';
                    icon = require('../../assets/img/ssm-icon.png')
                    break;

                case 'LHDN':
                    documentName = "LHDN";
                    documentDescription = 'Form B/BE';
                    icon = require('../../assets/img/lhdn-icon.png')
                    break;

                case 'BANK_STATEMENT':
                    documentName = "BANK STATEMENT";
                    documentDescription = `Latest ${months} ${parseInt(months) > 1 ? 'months' : 'month'} ${documentName}`;
                    icon = require('../../assets/img/bank-icon.png')
                    break;

                case 'SALARY':
                    documentName = "SALARY SLIP";
                    documentDescription = `Latest ${months} ${parseInt(months) > 1 ? 'months' : 'month'} ${documentName}`;
                    icon = require('../../assets/img/payslip-icon.png')
                    break;

                case 'EPF':
                    documentName = "EPF";
                    documentDescription = `Latest ${months} ${parseInt(months) > 1 ? 'months' : 'month'} ${documentName}`;
                    icon = require('../../assets/img/kwsp-icon.png')
                    break;

                case 'COMMISION_STATEMENT':
                    documentName = "COMMISION STATEMENT";
                    documentDescription = `Latest ${months} ${parseInt(months) > 1 ? 'months' : 'month'} ${documentName}`;
                    icon = require('../../assets/img/commission-icon.png')
                    break;
            }

            return { tabName: documentName, tabComponent: this.renderIncomeStatementDropzoneElement(item, documentName, documentDescription, icon) }
        })

        return <Tabs tabs={toRender} ref={TabRef} />
    }

    renderAdditionalDocmentField = (data) => {
        //TODO: render Additional Document Field
    }

    render() {
        const { answers, activeIndex, isShowThankYou, isSendingAnswer } = this.state;
        let questionLabel = PageHelper.getAmendmentsQuestionsLabel(answers[activeIndex].fieldCategory);

        if (answers.length <= 0)
            return (
                <p>Initializing</p>
            )

        return (
            <>
                <Header
                    version="b"
                    progressBarPercentage={0}
                    currentStage={2}
                />
                <Section className="amendments-section">
                    <Container>
                        {
                            isShowThankYou ? (
                                <>
                                    <SectionTitle text="Thanks for your submission" />
                                    <Row>
                                        <Col>
                                            <div className={styles.card}>
                                                <div className={styles['card-body']}>
                                                    <div className={styles['card-text']}>
                                                        Thank you, {this.props.eligibility.name} ! Our Bank staff will contact you within 1-2 working days.
                                                </div>
                                                    <div className={styles['card-footer']} style={{ marginTop: 40 }}>
                                                        <button type="button" className={`ab-btn`} onClick={this.onOk}>Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                    <>
                                        <SectionTitle text="Verify Information" />
                                        <span style={{ display: 'block', marginBottom: '10px' }}>Some information seem incorrect. Could you identify them and change if incorrect?</span>
                                        <Row>
                                            <Col>
                                                <div className={styles.card}>
                                                    <div className={styles['card-body']}>
                                                        <h6>Question {activeIndex + 1}</h6>
                                                        <h5 className={styles['card-title']}>{questionLabel}</h5>
                                                        <div className={styles['card-text']}>
                                                            {
                                                                this.componentSwitcher()
                                                            }
                                                        </div>
                                                        <div className={styles['card-footer']}>
                                                            <button type="button" className={`ab-btn`} onClick={this.onNextPress} disabled={!this.getValidInputBasedOnCategory(answers[activeIndex].fieldCategory) || isSendingAnswer}>
                                                                {isSendingAnswer ? 'Updating' : 'Next'}
                                                                <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            </button>
                                                            <span>{activeIndex + 1} of {answers.length}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )
                        }
                    </Container>
                </Section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => ({
    calculator: state.calculator,
    amendments: state.amendments,
    eligibility: state.eligibility
});

const mapDispatchToProps = dispatch => ({
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(Amendments)));