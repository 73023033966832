import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import styles from './ending.module.scss';
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import BaseComponent from '../../class/baseComponent';
import SectionTitle from "../../components/SectionTitle/index";
import Routes from '../../routes';
import { connect } from 'react-redux';
import { ErrorList } from '../../constant';
import { FormHelper } from '../../services';

class Ending extends Component {

  constructor(props) {
    super(props);
    console.log("Get Error Ending page:::", props.location.state);
    this.state = {
        errorMessage : props.location.state
    };
  }

  onOk = () => {
    this.props.history.replace(Routes.default);
  }

  setSectionTitle = () => {
    const { errorMessage } = this.state;
    let errorCode = FormHelper.Getter.getErrorCodeFromMessage(errorMessage);
    switch (ErrorList[errorCode]) {
        case 'COOLDOWN_APPLICATION':
            return 'Thanks for your submission';
        case 'CCRIS_DOWN_TIME':
            return 'Service Temporarily Unavailable';   
        default:
            return null;
    }
    }

   setSectionBody = () => {
      const { errorMessage } = this.state;
      let message = FormHelper.Getter.getPureErrorMessage(errorMessage);
      return message;
    }

  render() {
    return (
      <>
        <Header
          version="b"
          progressBarPercentage={0}
          currentStage={2}
        />
        <Container className="min-height">
          <SectionTitle text={this.setSectionTitle()} />
          <Row>
            <Col>
              <div className={styles.card}>
                <div className={styles['card-body']}>
                  <div className={styles['card-text']}>
                    {this.setSectionBody()}
                  </div>
                  <div className={styles['card-footer']} style={{ marginTop: 40 }}>
                    <button type="button" className={`ab-btn`} onClick={this.onOk}>Ok</button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  });
  
  const mapDispatchToProps = dispatch => ({
  });

export default connect(mapStateToProps, mapDispatchToProps)(Ending);
