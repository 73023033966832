import React from 'react';
import { FatcaDeclarationList, CountriesFullList } from '../../constant';
import { Row, Col, Button } from "reactstrap";
import Dropdown from "../../components/Dropdown";
import { FormHelper } from '../../services';
import FatcaDeclaration from "./fatcaDeclaration";

class Fatca extends React.PureComponent {

    render() {
        const { 
            fatcaDeclaration, fatcaCountryBirth
        } = this.props;

        return (
            <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="my-2 mb-3">
                        <p className="tips-msg tips-msg-2">
                            <strong>
                                FOREIGN ACCOUNT TAX COMPLIANCE ACT (FATCA) SELF CERTIFICATION
                            </strong>
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-4">
                        <Dropdown
                            label="I declare that"
                            name="fatcaDeclaration"
                            placeholder="I declare that"
                            options={FatcaDeclarationList}
                            value={fatcaDeclaration}
                            onChange={this.props.onFatcaDeclarationChange}
                            errText={fatcaDeclaration && fatcaDeclaration.value === true ? "US person is not allowed to apply" : ''}
                        />
                    </Col>
                    <Col className="mt-2 col-4">
                        <Dropdown
                            label="Country of Birth"
                            name="fatcaCountryBirth"
                            placeholder="Country of Birth"
                            options={CountriesFullList}
                            value={fatcaCountryBirth}
                            onChange={this.props.onFatcaCountryBirthChange}
                            errText={fatcaCountryBirth && FormHelper.isUSPerson(fatcaCountryBirth.value) ? "US person is not allowed to apply" : ''}
                        />
                    </Col>
                </Row>
                <Row className="mt-3 mb-responsive">
                    <Col>
                        <FatcaDeclaration />
                    </Col>
                </Row>
                <Button
                    id="gtm-update-fatca-crs"
                    data-index="5"
                    className="proceedButton"
                    disabled={!this.props.isValidFatcaCrs}
                    color="secondary"
                    onClick={this.props.onFatcaCrsContinuePressed}
                >
                    CONTINUE
                </Button>
            </>
        );
    }
}

export default Fatca;