import { types } from '../actions/eligibility';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    isFromAOP: false,
    name: '', 
    gender: '',  
    email: '', 
    nationality: '',  
    bumi: '', 
    race: '',
    address: '', 
    address2: '', 
    country: { value: 'MY', label: 'Malaysia' }, 
    state: '', 
    foreignState: '', 
    city: '', 
    postcode:'',
    company: '',
    occupationSector: '', 
    occupation: '', 
    businessSector: '', 
    natureBusiness: '',
    monthlyNetIncome: '', 
    mobile: '+60', 
    isAcceptTnc: false, 
    isAcceptedPDPDConsent: null,  
    insurancePremium: { "value" : false, "label": "No, I do not want to include insurance premium" }, 
    maritalStatus: '', 
    educationLevel: '', 
    companyAddress: '',
    companyAddress2: '',
    companyPostcode: '',
    companyCity: '',
    companyLocalState: '',
    companyForeignState: '',
    companyCountry: { value: 'MY', label: 'Malaysia' },
    companyAddressVersion: '',
    preferredMailingAddress: '',
    isAW: false
}

export default function eligibility(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_ELIGIBILITY_INFO:
        console.log("eligibility::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_ELIGIBILITY_INFO:
            return initialState;

        default:
            return state;
    }
}