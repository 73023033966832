export const removeCharacter = (str, char_pos) => {
    let part1 = str.substring(0, char_pos);
    let part2 = str.substring(char_pos + 1, str.length);
    return (part1 + part2);
}

export const maskEmail = (input) => {
    let split = input.split('@')
    if (split[0].length < 4) {
        return input
    }
    return input.substr(0,4) + new Array(split[0].length - 4).fill('x').join('') + "@" + split[1]
}

export const maskMobileNumber = (input) => {
    return input.substr(0,5) + ' ' + input.substr(5,3) + ' ' + new Array(input.length - 8).fill('x').join('')
}

export const maskICNumber = (input) => {
    let output = input.replace(/-/g, '');
    
    let rgx = /(\d{6})(\d{2})(\d{4})/;
    if (output && output.length > 6) {
        output = output.replace(rgx, '$1' + '-' + 'xx' + '-' + 'xxxx');
    }
    return output;
}

export const maskAddressLine1 = (input) => {
    if (input.length < 6) {
        return input
    }
    else {
        return input.substr(0,6) + new Array(input.length - 6).fill('x').join('')
    }
}

export const maskAddressLine2 = (input) => {
    return new Array(input.length).fill('x').join('')
}

export const maskPostcode= (input) => {
    return new Array(input.length).fill('x').join('')
}

export const maskCity = (input) => {
    return new Array(input.length).fill('x').join('')
}

export const maskCountry = (input) => {
    return new Array(input.length).fill('x').join('')
}

export const phoneSeparator = (input) => {
    let output = input.toString().replace(/[^0-9]/g, '');

    if(output.length < 3)
    return '+60';
    
    let rgx = /(\d{4})(\d{1,8})/;
    if (output && output.length > 2) {
        output = output.replace(rgx, '$1' + '-' + '$2');
    }
    return '+' + output;
}

export const phoneSeparatorForCompanyTel = (input) => {
    let output = input.toString().replace(/[^0-9]/g, '');
    if(output.length < 2)
    return '+6';
    
    let rgx = /(\d{3})(\d{4})(\d{1,7})/;
    if (output && output.length > 1) {
        output = output.replace(rgx, '$1' + ' ' + '$2' + ' ' + '$3');
    }
    return '+' + output;
}

//add dash separator inside IC
export const icDashSeparator = (input) => {
    var input2 = input.replace(/-/g, '');
    var x = input2.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d{6})(\d{2})(\d{4})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '-' + '$2' + '-' + '$3');
    }
    var processedData = x1 + x2;
    return processedData;
}

//add thousand separator 
export const thousandSeparator = (input) => {
    var input2 = input.toString().replace(/,/g, '');
    var x = input2.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    var processedData = x1 + x2;
    return processedData;
}

//add decimal if no decimal is inputed
export const decimalAdder = (input) => {
    var x = input.split('.');
    var x1 = x[0];
    var x2;
    if (x.length > 1) {
        if (x[1] == '' || x[1] == undefined || x[1] == null) {
            // if the use only input dot but no decimal
            x2 = '.00';
        } else {
            if (x[1].length < 2) {
                // auto add in a zero if user only input one decimal
                x2 = '.' + x[1] + '0';
            } else {
                // only take last 2 decimal
                x2 = '.' + x[1].substr(0, 2);
            }
        }

    } else {
        x2 = '.00'
    }

    var processedData = x1 + x2;
    return processedData;
}

export const toNumberObject = (object) =>{
    let result = {}
    for(let key in object){

        if(typeof object[key] === 'string')
        result[key] = Number(object[key])

        if(typeof object[key] === 'object')
        result[key] = toNumberObject(object[key])

        if(typeof object[key] === 'number')
        result[key] = object[key]
    }
    console.log('toNumberObject:result:',result)
    return result;
}