export const types = {
    UPDATE_FATCA_CRS_INFO: 'UPDATE_FATCA_CRS_INFO',
    CLEAR_FATCA_CRS_INFO: 'CLEAR_FATCA_CRS_INFO',
}

export function updateFatcaCrsInfo(info) {
    return {
        type: types.UPDATE_FATCA_CRS_INFO,
        info: info
    }
}

export function clearFatcaCrsInfo() {
    return { type: types.CLEAR_FATCA_CRS_INFO }
}