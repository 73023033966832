import React from 'react';
import { Container, Row, Col } from "reactstrap";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import SectionTitle from "../../components/SectionTitle/index";
import styles from './NeedHelpThanks.module.scss';
import BaseComponent from '../../class/baseComponent';
import Routes from '../../routes';
import Section from "../../components/Section";

class NeedHelpThanks extends BaseComponent {
    constructor(props) {
        super(props);
    }

    onOk = () => {
        this.props.history.push(Routes.default);
    }

    render() {
        return (
            <>
                <Header version="c" />
                <Section>
                    <Container className="min-height">
                        <SectionTitle text="Thanks for your need help request submission" />
                        <Row>
                            <Col>
                                <div className={styles.card}>
                                    <div className={styles['card-body']}>
                                        <div className={styles['card-text']}>
                                            Thank you! we will get in touch with you shortly.
                                    </div>
                                        <div className={styles['card-footer']} style={{ marginTop: 40 }}>
                                            <button type="button" className={`ab-btn`} onClick={this.onOk}>Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Footer />
            </>
        );
    }
}

export default NeedHelpThanks;