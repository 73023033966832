import React from 'react';

class FatcaDeclaration extends React.PureComponent {

    render() {
        return (
            <ul className="submit-more-info-description-list" style={{ paddingLeft: "0" }}>

                <li style={{ fontWeight: "400", fontSize: "14px" }}>
                    The term U.S. person or United States person means a person described in section 7701(a) (30) of
                    Internal Revenue Code:
                </li>
                <li style={{ fontWeight: "400", fontSize: "14px" }}>
                    (A) a citizen or resident of the United States,
                </li>
                <li style={{ fontWeight: "400", fontSize: "14px" }}>
                    (B) a United States partnership,
                </li>
                <li style={{ fontWeight: "400", fontSize: "14px" }}>
                    (C) a United State corporation,
                </li>
                <li style={{ fontWeight: "400", fontSize: "14px" }}>
                    (D) any estate (other than an estate the income of which, from sources without the United States
                    which is not effectively connected with the conduct of a trade or business within the United
                    States, is not includible in gross income under the Internal Revenue Code), and
                </li>
                <li style={{ fontWeight: "400", fontSize: "14px" }}>
                    (E) any trust if
                    <ul>
                        <li style={{ fontWeight: "400", fontSize: "14px" }}>
                            (a) A court within the United States is able to exercise primary supervision over the
                            administration of the trust, and
                        </li>
                        <li style={{ fontWeight: "400", fontSize: "14px" }}>
                            (b) One or more United States persons have the authority to control all substantial
                            decisions of trust.
                        </li>
                    </ul>
                </li>
            </ul>
        );
    }
}

export default FatcaDeclaration;



