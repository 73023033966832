import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

class PLOANPrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        }
    }

    onPrint = (e) => {
        console.log('onPrint');

        this.setState({
            isModalOpen: true
        }, this.props.continueProcess(e))
    }

    onToggleModal = () => {
        this.setState(prevState => {
            return {
                isModalOpen: !prevState.isModalOpen
            }
        })
    }

    renderInvisibleButton = () => {
        return (
            <Button color="primary" style={{ display: 'none' }}>
                <i className="fa fa-print"></i> &nbsp; Print
            </Button>
        );
    }

    printContent = () => {
        console.log('printContent');
        this.printerRef.triggerRef.onClick();
    }

    render() {
        return (
            <div>
                <ReactToPrint
                    ref={ref => this.printerRef = ref}
                    trigger={() => this.renderInvisibleButton()}
                    content={() => this.toPrintRef}
                    onAfterPrint={this.onToggleModal}
                />
                <Button
                    className="download-print-button"
                    id="gtm-download-print-loan-offer"
                    onClick={this.onPrint}
                    data-index="1"
                >
                    { this.props.buttonText }
                </Button>
                <Modal isOpen={this.state.isModalOpen} onOpened={this.printContent} size='lg'>
                    <ModalHeader toggle={this.onToggleModal}>Print</ModalHeader>
                    <ModalBody>
                        <div ref={ref => this.toPrintRef = ref}>
                            {this.props.toPrint}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

PLOANPrint.defaultProps = {
    continueProcess:()=>{},
    toPrint:()=>{},
    buttonText:'DOWNLOAD & PRINT'
}

PLOANPrint.propTypes = {
    continueProcess:PropTypes.func.isRequired,
    toPrint:PropTypes.func.isRequired,
    buttonText:PropTypes.string
}

export default PLOANPrint;



