
import Environment from '../environment';

export default {
    amendments: Environment.webContext + 'amendments',
    complete: Environment.webContext + 'complete',
    receiveMoney: Environment.webContext + 'receiveMoney',
    submitMoreInfo : Environment.webContext + 'submitMoreInfo',
    checkstatus: Environment.webContext + 'checkstatus',
    aipOffcheckstatus: Environment.webContext + 'aipOffcheckstatus',
    initialthanks: Environment.webContext + 'initialthanks',
    initialOffer: Environment.webContext + 'initialOffer',
    securityQuestion:Environment.webContext + 'securityQuestions',
    verifyIdentity: Environment.webContext + 'verifyIdentity',
    login: Environment.webContext + 'login',
    pageNotFound: Environment.webContext + 'pageNotFound',
    ending: Environment.webContext + 'ending',
    salesStatusList: Environment.webContext + 'salesStatusList',
    needHelpThanks: Environment.webContext + 'needHelpThanks',
    default: Environment.webContext,
    dev:"/"
}