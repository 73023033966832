import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Button, Progress } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faPaperclip
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import { Buffer } from 'buffer';
import fileType from 'file-type';
import { Util, ImageCompressor } from '../../services';

class CustomDropzone extends React.Component {

  intervalObjects = {};

  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { categoryFiles, type } = this.props;

    if (type === 'doc' && this.isDifferentJsonArray(categoryFiles, prevProps.categoryFiles)) {
      Object.keys(this.intervalObjects).map((key) => clearInterval(this.intervalObjects[key]))
      this.intervalObjects = {};
      this.progressTick();
    }
  }

  isDifferentJsonArray = (array1, array2) => {
    if (array1.length !== array2.length)
      return true;

    let isArrayElementDifferent = false;

    array1.map((element, index) => {
      if (JSON.stringify(element) !== JSON.stringify(array2[index]))
        isArrayElementDifferent = true;
    })

    return isArrayElementDifferent;
  }

  cleanInterval = (key) => {
    clearInterval(this.intervalObjects[key])
    delete this.intervalObjects[key]
  }

  isAcceptableFileType = (fileTypeInfo) => {
    if(!fileTypeInfo)
    return false;
    
    let acceptFileTypes = this.props.validFileTypes;
    return acceptFileTypes.includes(fileTypeInfo.mime)
  }

  onDrop = (e) => {
    console.log('onDrop...')
    const { fileLimit, type, validImageTypes, categoryFiles, updateCategoryFiles, label, section, maxSize } = this.props;
    let fileArray = [];

    if (e.length > 0) {
      e.map((f, i) => {
        
        if( (f.type.indexOf('image') < 0) && f.size > maxSize)
        {
          this.props.onFileSizeExceedError()
          return;
        }
        
        let reader = new FileReader();
        reader.readAsDataURL(f);

        reader.onload = async() => {

          // console.log(reader.result);

          let file = [];
          let url = null;

          if (validImageTypes.includes(f.type)) {
            url = URL.createObjectURL(f);
          }

          let rawBase64 = reader.result.split('base64,')[1];
          let buffer = Buffer.from(rawBase64, 'base64');
          let fileTypeInfo = fileType(buffer)
          console.log('fileTypeInfo:',fileTypeInfo)

          if(!this.isAcceptableFileType(fileTypeInfo))
          {
            this.props.onFileTypeError();
            return;
          }

          const key = btoa(Date.now() + f.name);
          file = { label: label, name: f.name, key: key, file: f, type: type, progress: 0, base64: reader.result, uploading: true };

          if (type === 'image')
            file.url = url;

          if (section)
            file.section = section;
          
          fileArray.push(file)
          if (fileArray.length === e.length) {
            let resultArray = fileArray.concat(categoryFiles).splice(0, fileLimit);
            updateCategoryFiles(resultArray); //update first to start loading bar
            
            let compressedDocumentArray= await this.computeCompressedSize(resultArray);
            updateCategoryFiles(compressedDocumentArray); //update with compressed files
          }
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
        };
      });
    }
  }

  computeCompressedSize = async(documentArray) => {

    let newDocumentArrayPromise = documentArray.map(file => {
      console.log("computeCompressedSize file:", file)
      if(file.file.type.includes("image"))
      {
        console.log("enter here leh 1")
        return new Promise(async(resolve)=>{
          // console.log('before:',Util.calculateImageSize(file.base64))
          let newBase64 = await ImageCompressor.compressImage(file);
          let compressedSize = Util.calculateImageSize(newBase64);
          resolve({
            ...file,base64:newBase64, compressedSize
          })
        })
      }else{
        console.log("enter here leh 2")
        return new Promise(resolve => {
          let compressedSize = Util.calculateImageSize(file.base64);
          resolve({
            ...file, compressedSize
          })
        })
      }
    })

    let newDocumentArray = await Promise.all(newDocumentArrayPromise);
    console.log('newDocumentArray:',newDocumentArray)
    return newDocumentArray;
  }

  progressTick = () => {

    const { categoryFiles, updateCategoryFiles } = this.props;
    let newCategoryFiles = categoryFiles;
    categoryFiles.map((item, index) => {
      const newInterval = setInterval(() => {
        if (item.progress < 100) {
          newCategoryFiles[index].progress = categoryFiles[index].progress + 5;
        } else {
          newCategoryFiles[index].uploading = false;
          this.cleanInterval(index)
        }
        updateCategoryFiles(newCategoryFiles);
      }, 50);
      this.intervalObjects[index] = newInterval;
    })
  }

  onCancel = e => {
    e.stopPropagation();
    const key = e.currentTarget.dataset.key;
    const { categoryFiles, updateCategoryFiles } = this.props;

    if (categoryFiles.length > 0) {
      let newCategoryFiles = categoryFiles.slice(0, categoryFiles.length);//copy new array
      categoryFiles.map((f, i) => {
        if (f.key == key) {
          newCategoryFiles.splice(i, 1);
          this.cleanInterval(i)
        }
      });
      updateCategoryFiles(newCategoryFiles)
    }
  }

  onItemClick = (event, file) =>{
    event.preventDefault();
    event.stopPropagation();
    this.props.onItemClick(file);
  }

  fileUpload = (getInputProps) => {
    const { categoryFiles, maxSize } = this.props
    if (categoryFiles.length <= 0) {
      return (
        <>
          <div className="upload-field-wrapper">
            <div className="upload-field">
              <div className="upload-field-content-wrapper">
                <img
                  alt="upload icon"
                  src={require("../../assets/img/upload.png")}
                />
                <p className="upload-title">Drop your file here.</p>
                <input className="upload-field-input" {...getInputProps()} />
                <p className="upload-description">
                  Supported format files are JPG, PNG, PDF. Max {Math.round(maxSize/1000000)}MB for PDF file.
                    </p>
              </div>
            </div>
          </div>
          <div className="upload-button-container">
            <div className="upload-button">Upload</div>
            <span>Click Here to Upload</span>
          </div>
        </>
      );
    } else {
      const { categoryFiles } = this.props;
      return (
        <div className="upload-field-details">
          <div
            className={`upload-field ${
              categoryFiles.length > 1 ? "multi-field-container" : ""
              }`}
          >
            <ul className="upload-field-content-wrapper">
              {this.filesStatus(categoryFiles)}
            </ul>
            <p className="upload-title">Upload complete</p>
            <input className="upload-field-input" {...getInputProps()} />
            <p className="upload-description">
              Click “<strong>CONTINUE</strong>” button below to complete your
              financing application
                    </p>
          </div>
          <div className="upload-button-container">
            <div className="upload-button">Upload</div>
            <span>Click Here to Upload</span>
          </div>
        </div>
      );
    }
  }

  filesStatus = (categoryFiles) => {
    return categoryFiles.map(file => {
      const key = file.key;
      const name = file.name;
      const progress = file.progress;
      const compressedSize = file.compressedSize;
      if (categoryFiles.length > 1) {
        return (
          <li
            key={key + name}
            className="file-progress-wrapper multi-file-progress-wrapper"
          >
            <div className="upload-field-progress-wrapper">
              <img
                alt="pdf icon"
                src={
                  progress === 100
                    ? require("../../assets/img/file.png")
                    : require("../../assets/img/filered.png")
                }
              />
              <div className="upload-files-progress-inner-wrapper">
                <div className="upload-field-progress">
                  <div className="file-name-wrapper">
                    {
                      (progress < 100)?(
                        <p className="file-name">{name}</p>
                      ):(
                        <a href='#' className="successUploadFileName" title={name} onClick={(event)=>{this.onItemClick(event, file)}}>{name}</a>
                      )
                    }
                    {progress === 100 ? (
                      <div className="successUploadWrapper">
                         <span className="successUploadCompressedSize">
                           ({compressedSize ? ((compressedSize / Math.pow(1024, 2)).toFixed(2)) : ((file.file.size / Math.pow(1024, 2)).toFixed(2))} MB)
                         </span>
                         <FontAwesomeIcon icon={faCheck} />
                       </div>
                    ) : (
                        <span
                          data-key={key}
                          onClick={this.onCancel}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      )}
                  </div>
                  {/* <div
                            className={`file-progress-bar ${
                              progress === 100 ? "" : "uploading"
                            }`}
                          >
                            <div
                              className={`file-progress-bar-indicator ${
                                progress === 100 ? "" : "uploading"
                              }`}
                              style={{ width: `${progress}%` }}
                            />
                          </div> */}
                  {
                    progress === 100 ? (null) : (<Progress bar animated color="warning" value="100">Loading</Progress>)
                  }
                </div>
                <Button
                  className="remove-file-button"
                  data-key={key}
                  onClick={this.onCancel}
                >
                  REMOVE
                        </Button>
              </div>
            </div>
          </li>
        );
      } else {
        return (
          <li key={key + name} className="file-progress-wrapper">
            <img
              alt="pdf icon"
              src={
                progress === 100
                  ? require("../../assets/img/pdficon.png")
                  : require("../../assets/img/redpdficon.png")
              }
            />
            <div className="upload-field-progress">
              <div className="file-name-wrapper">
                {
                  (progress < 100)?(
                    <p className="file-name">{name}</p>
                  ):(
                    <a href='#' onClick={(event)=>{this.onItemClick(event, file)}}>{name}</a>
                  )
                }
                {progress === 100 ? (
                  <span
                    data-key={key}
                    onClick={this.onCancel}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                ) : (
                    <span
                      data-key={key}
                      onClick={this.onCancel}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  )}
              </div>
              {/* <div
                        className={`file-progress-bar ${
                          progress === 100 ? "" : "uploading"
                        }`}
                      >
                        <div
                          className={`file-progress-bar-indicator ${
                            progress === 100 ? "" : "uploading"
                          }`}
                          style={{ width: `${progress}%` }}
                        />
                        
                      </div> */}
              {
                progress === 100 ? (null) : (<Progress bar animated color="warning" value="100">Loading</Progress>)
              }
            </div>
            <Button
              className="remove-file-button"
              data-key={key}
              onClick={this.onCancel}
            >
              REMOVE
            </Button>
          </li>
        );
      }
    });
  }

  imageUpload = (getInputProps) => {
    const { categoryFiles, imageSectionLabel, maxSize } = this.props;
    if (categoryFiles.length > 0) {
      let imageUrl = categoryFiles[0].url;
      return (
        <React.Fragment>
          <span className="verifyy-icon">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          {imageUrl ? <img className="nric-image" alt="nric" src={imageUrl} /> : <img className="nric-image" alt="nric" src={require("../../assets/img/default-image.svg")} />}
          <h1>{imageSectionLabel}</h1>
          <span><FontAwesomeIcon icon={faPaperclip} />{categoryFiles[0].name}</span>
          <input className="upload-field-input" {...getInputProps()} hidden />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <img
            alt="nric icon"
            className="upload-panel-logo"
            src={require("../../assets/img/nric.png")}
          />
          <h1>{imageSectionLabel}</h1>
          <span>Supported format files are JPG, PNG, PDF. Max {Math.round(maxSize/1000000)}MB for PDF file.</span>
          <input className="upload-field-input" {...getInputProps()} hidden />
        </React.Fragment>
      );
    }
  }


  render() {
    const { label, sectionUploadDescription, type, forwardedRef, maxSize } = this.props;
    return (
      <Dropzone
        accept={['.png', '.jpg', '.pdf']}
        // maxSize={maxSize}
        name={label}
        ref={forwardedRef}
        onDrop={this.onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className="dropzone-container"
            {...getRootProps({
              onClick: evt => evt.preventDefault()
            })}
          >
            <div className="upload-field-container">
              {!!sectionUploadDescription && (<p className="section-upload-description">
                {sectionUploadDescription}
              </p>)}
              {
                type === 'doc' ? this.fileUpload(getInputProps) : type === 'image' ? this.imageUpload(getInputProps) : null
              }
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
}

CustomDropzone.defaultProps = {
  label: 'custom-dropzone-default-label',
  fileLimit: 1,
  sectionUploadDescription: '',
  validImageTypes: ['image/gif', 'image/jpeg', 'image/png'],
  categoryFiles: [],
  updateCategoryFiles: () => { },
  type: 'doc',
  imageSectionLabel: '',
  getRef: () => { },
  maxSize: 5242880,
  section: '',
  onItemClick:()=>{},
  onFileSizeExceedError:() => {},
  onFileTypeError:() => {},
  validFileTypes: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'application/pdf']
}

CustomDropzone.propTypes = {
  label: PropTypes.string.isRequired,
  fileLimit: PropTypes.number.isRequired,
  sectionUploadDescription: PropTypes.string,
  validImageTypes: PropTypes.array,
  categoryFiles: PropTypes.array,
  updateCategoryFiles: PropTypes.func.isRequired,
  type: PropTypes.string,
  imageSectionLabel: PropTypes.string,
  getRef: PropTypes.func,
  maxSize: PropTypes.number,
  section: PropTypes.string,
  onItemClick:PropTypes.func,
  onFileSizeExceedError:PropTypes.func,
  onFileTypeError:PropTypes.func,
  validFileTypes:PropTypes.array
}

export default React.forwardRef((props, ref) => <CustomDropzone {...props} forwardedRef={ref} />);