import { Session, CustomError } from '../../core';
import { HTTP } from '../../core';
import Environment from '../../environment';

let tokenTimer = null;
const timeMarginInSecond = 120;

export const login = (username, password) => {

    return Session.login(username, password).then((response) => {

        console.log("login response:", response);

        if (!response.error) {
            clearSession();
            requestSuccess(response);
            storeToken(response);
            return Promise.resolve();
        } else {
            clearSession();
            const loginError = new CustomError().loginError(response.error_description);
            return Promise.reject(loginError);
        }

    }).catch((error) => {
        clearSession();
        const loginError = new CustomError().loginError(error.message);
        return Promise.reject(loginError);
    })
}

export const refreshSession = () => {

    const refreshToken = sessionStorage.getItem('dbos:ploan-web:refreshToken')

    if (refreshToken) {
        Session.refreshSession(refreshToken).then(response => {

            console.log("refreshSession response:", response);

            if (!response.error) {
                clearSession();
                requestSuccess(response);
                storeToken(response);
            } else {
                alert(response.error + ":" + response.error_description);
                clearSession();
            }
        }).catch((error) => {
            console.log("Unable to refresh token:reason:", error.response.error_description);
            alert("Your session has timed-out. Please sign-in again.");
            clearSession();
        })
    } else {
        clearSession();
        console.log("Invalid token,unable to refresh session.Please login again.")
        alert("Your session has timed-out. Please sign-in again.");
    }

}

export const logout = (isProtectedWebService = true) => {
    console.log('logout ploan')

    if (isProtectedWebService && !isValidSession()) {
        const error = new CustomError().refreshTokenExpired();
        return Promise.reject(error);
    }
    let params = {};
    return HTTP.postData(Environment.logoutUrl, params, isProtectedWebService);
}

export const clearSession = () => {
    clearTimeout(tokenTimer);
    sessionStorage.removeItem('dbos:ploan-web:accessToken');
    sessionStorage.removeItem('dbos:ploan-web:refreshToken');
}

const storeToken = (response) => {
    sessionStorage.setItem('dbos:ploan-web:accessToken', response["access_token"]);
    sessionStorage.setItem('dbos:ploan-web:refreshToken', response["refresh_token"]);
}

const requestSuccess = (response) => {
    tokenTimer = setTimeout(() => { refreshSession() }, ((response.expires_in - timeMarginInSecond) * 1000));
}

const getRoles = (accessToken) => {

    const jwt = accessToken.split('.');

    const payload = JSON.parse(atob(jwt[1]));

    return payload.realm_access.roles;

}

export const isOfficer = (accessToken) => {

    const roles = getRoles(accessToken);

    return (roles.indexOf('branch_officer') >= 0);
}

export const isSupervisor = (accessToken) => {

    const roles = getRoles(accessToken);

    return (roles.indexOf('branch_manager') >= 0);
}

export const getLoginUsername = () => {
    const accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        const username = payload.username;
        return username;
    }
    return;
}

export const getHeaderUsername = () => {
    const accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        const name = payload.name;
        return name;
    }
    return;
}

export const getSupervisorPayload = () => {
    const accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        if (isSupervisor(accessToken)) {
            const jwt = accessToken.split('.');
            const payload = JSON.parse(atob(jwt[1]));
            return payload;
        }
    }
    return;
}

export const getUserPayload = () => {
    const accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    if (accessToken !== undefined && accessToken !== null) {
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        return payload;
    }
    return;
}

export const isValidSession = () => {
    let accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    let refreshToken = sessionStorage.getItem('dbos:ploan-web:refreshToken');

    if (!!accessToken && !!refreshToken)
        return true;

    return false;
}

export const canViewLoanApplication = () => {
    const accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    if (accessToken === undefined || accessToken === null) {
        return false;
    }else{
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        return payload.user_access?payload.user_access.canViewLoanApplication:false;
    }
}

export const canApplyLoanApplication = () => {
    const accessToken = sessionStorage.getItem('dbos:ploan-web:accessToken');
    if (accessToken === undefined || accessToken === null) {
        return false;
    }else{
        const jwt = accessToken.split('.');
        const payload = JSON.parse(atob(jwt[1]));
        return payload.user_access?payload.user_access.canApplyLoanApplication:false;
    }
}

