import * as Getter from '../formHelper/getter';
import { FormHelper } from '../../services';

export const genCalculatorStoreFromPayload = (payload) => {
    return {
        loanAmount: payload.loanAmount,
        selectedTenure: Getter.getLoanTenureFromValue(payload.loanTenure),
        selectedLoanType: Getter.getLoanTypeValue(payload.isIslamic),
        monthlyInstallment: parseFloat(payload.loanMonthlyInstallment),
        interestRate: parseFloat(payload.loanInterestRate),
        grossMonthlyIncome: parseFloat(payload.monthlyGrossIncome),
        icNo: payload.nricNo,
        selectedEmploymentType: Getter.getEmploymentTypeFromValue(payload.employmentType),
        selectedDoc: Getter.getSelectedDocFromValue(payload.employmentType, payload.chosenDocument),
        isAipOff : payload.isAipOff,
        cashBack: payload.cashback,
        isCashBackCampaign: payload.isCashbackCampaign,
        totalCashBack: payload.totalCashback,
        installmentAfterCashBack: payload.installmentAfterCashback
    }
}

export const genEligibilityStoreFromPayload = (payload) => {
    return {
        name: payload.fullName,
        gender: Getter.getGenderTypeFromValue(payload.gender),
        email: payload.email,
        nationality: Getter.getNationalityTypeFromValue(payload.nationality),
        bumi: Getter.getBumiStatusFromValue(payload.bumiStatus),
        race: Getter.getRaceFromValue(payload.race),
        address: payload.address1,
        address2: payload.address2,
        country: Getter.getCountryFromValue(payload.country),
        state: payload.country === 'MY' ? Getter.getStateFromValue(payload.state) : '',
        foreignState: payload.country === 'MY' ? '' : payload.state,
        city: payload.city,
        postcode: payload.postcode,
        company: payload.employmentCompanyName,
        occupationSector: Getter.getEmploymentOccupationSectorFromValue(payload.employmentOccupationSector),
        occupation: Getter.getEmploymentOccupationFromValue(payload.employmentOccupation),
        businessSector: Getter.getEmploymentNatureOfBusinessSectorFromValue(payload.employmentNatureOfBusinessSector),
        natureBusiness: Getter.getEmploymentNatureOfBusinessFromValue(payload.employmentNatureOfBusiness),
        monthlyNetIncome: payload.monthlyNettIncome ? payload.monthlyNettIncome : '',
        mobile: payload.mobileNo,
        isAcceptedPDPDConsent: payload.isPdpaConsent,
        insurancePremium: Getter.getInsurancePremiumFromValue(payload.isAcceptInsurance),
        maritalStatus: Getter.getMaritlStatusTypeFromValue(payload.maritalStatus),
        educationLevel: Getter.getEducationLevelFromValue(payload.education),
        companyAddress: payload.employmentCompanyAddress1,
        companyAddress2: payload.employmentCompanyAddress2,
        companyPostcode: payload.employmentCompanyPostcode,
        companyCity: payload.employmentCompanyCity,
        companyLocalState: payload.employmentCompanyCountry === 'MY' ? Getter.getStateFromValue(payload.employmentCompanyState) : '',
        companyForeignState: payload.employmentCompanyCountry === 'MY' ? '' : payload.employmentCompanyState,
        companyCountry: payload.employmentCompanyCountry ? Getter.getCountryFromValue(payload.employmentCompanyCountry) : Getter.getCountryFromValue('MY'),
        companyAddressVersion: payload.version,
        preferredMailingAddress: Getter.getPreferredMailingAddressFromValue(payload.preferredMailingAddress),
        nonBankDebtAmt: payload.otherNonBankDebt ? payload.otherNonBankDebt : '',
        isAW: payload.awCustomer
    }
}

export const genInitialLoanOfferStoreFromPayload = (resp) => {
    return {
        selectedInsuranceAmount: resp.loanInsuranceAmount,
        selectedLoanAmount: resp.loanAmount,
        selectedMonthlyInstallmentAmount: resp.loanMonthlyInstallment,
        selectedTenure: resp.loanTenure,
        selectedStampDuty: resp.loanStampDuty,
        selectedSellingAmount: resp.sellingAmount,
        selectedInterestRate: resp.loanInterestRate,
        installmentAfterCashback: resp.installmentAfterCashback,
        cashBack: resp.cashback,
        totalCashBack: resp.totalCashback,
        isCashBackCampaign: resp.isCashbackCampaign
    }
}

export const genMoreAboutYourselfStoreFromPayload = (resp) => {
    return {
        residenceType: Getter.getResidenceTypeFromValue(resp.residenceType),
        lengthOfStay: Getter.getLengthOfStayFromValue(resp.residenceLengthOfStay),
        residenceStatus: Getter.getResidenceStatusFromValue(resp.residenceStatus),
        purposeOfBorrowing: Getter.getPurposeOfBorrowingFromValue(resp.purposeOfBorrowing),
        sourceOfFund: Getter.getSourceOfFundFromValue(resp.sourceOfFund),
        sourceOfWealth: Getter.getSourceOfWealthFromValue(resp.sourceOfWealth)
    }
}

export const genEmploymentDetailStoreFromPayload = (resp) => {
    return {
        currentEmployer: resp.employmentCompanyName,
        dateJoinedCompany: resp.employmentJoinedDate?new Date(resp.employmentJoinedDate):null,
        companyTel: resp.employmentCompanyTelNo ? Getter.getEmploymentCompanyTelNo(resp.employmentCompanyTelNo) : FormHelper.phoneSeparatorForCompanyTel('+6' + 0),
        companyEmail: resp.employmentCompanyEmail
    }
}

export const genEmergencyContactStoreFromPayload = (resp) => {
    return {
        emergencyContactName: resp.emergencyContactName, 
        emergencyContactRelationship: Getter.getRelationshipFromValue(resp.emergencyContactRelationship),
        emergencyContactMobile: resp.emergencyContactMobileNumber, 
        spouseName: resp.spouseName, 
        spouseCompanyName: resp.spouseCompanyName,
        spouseMobile: resp.spouseMobileNumber
    }
}

export const genPreferredBankStoreFromPayload = (resp) => {
    return {
        preferredBank: resp.awCustomer ? Getter.getPreferredBankFromValue('ABMB') : Getter.getPreferredBankFromValue(resp.disbursementBank), 
        bankAccountNumber: resp.disbursementBankAccountNumber
    }
}

export const genFatcaCrsStoreFromPayload = (resp) => {
    return {
        fatcaDeclaration: Getter.getFatcaDeclarationFromValue(resp.isFatca), 
        fatcaCountryBirth: Getter.getFullCountryFromValue(resp.fatcaCountryBirth),
        taxResidence: Getter.getTaxResidenceFromValue(resp.crsFlag)
    }
}

export const genCrsTINStoreFromPayload = (resp) => {
    return {
        taxJurisCountry: Getter.getTaxJurisCountryFromValue(resp.taxJurisdictionCountry), 
        tinNumber: resp.tinNo, 
        isNoTin: resp.reason ? true : false, 
        reasonOfNoTin: resp.reason ? Getter.getReasonOfNoTinFromValue(resp.reason) : "", 
        noTinOtherReason: resp.otherReason
    }
}