import { types } from '../actions/amendments';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    eformUuid: '',
    amendmentUuid: '',
    amendmentFields: [],
    amendmentAttachments: [],
    amendmentModel: []
}

export default function amendments(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_AMENDMENTS:
            return assign({}, modifiedState, action.amendments);

        case types.CLEAR_AMENDMENTS:
            return initialState;

        default:
            return state;
    }
}