import React, { Component, Fragment } from "react";
import styles from "./Header.module.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import "./header.scss";
import Routes from '../../routes';
import { Redirect } from 'react-router'
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, NavItem,
  NavLink, Breadcrumb, BreadcrumbItem, Button
} from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Session } from '../../services';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    this.state = {
      navIsOpen: false,
      isLoggedIn: false,
      isRedirect: false,
      isToSearch: false,
      isToHome: false
    };
  }

  async componentDidMount() {
    const username = await Session.getHeaderUsername();
    console.log("username", username)
    if (username) {
      this.setState({ isLoggedIn: true })
    }
  }

  onLogout = () => {
    this.setState({ isLoggedIn: false })
    Session.logout().then(resp => {
      console.log('logout successfully:', resp);
    }).catch(error => {
      console.log('logout failed:', error);
    })
    Session.clearSession();
    this.logoutRedirect();
  }

  onSearch = () => {
    this.setState({ isToSearch: true })
  }

  onHome = () => {
    this.setState({ isToHome: true })
  }

  logoutRedirect = () => {
    this.setState({ isRedirect: true })
  }

  renderSearchButton = () => {
    return (
      <div className="menu-left-button">
        <Button className="menu-left-text" onClick={this.onSearch}>Search</Button>
      </div>
    )
  }

  renderHomeButton = () => {
    return (
      <div className="menu-left-button">
        <Button className="menu-left-text" onClick={this.onHome}>Home</Button>
      </div>
    )
  }

  renderLogoutButton = () => {
    return (
      <div className="logout-wrapper">
        <Button className="logout-btn" onClick={this.onLogout}>Logout</Button>
      </div>
    )
  }

  toggleNav() {
    this.setState(prevState => ({
      navIsOpen: !prevState.navIsOpen
    }));
  }

  fixNavHeight(version) {
    let height;
    let className;
    switch (version) {
      case "a":
      case "c":
      case "d":
      case "e":
        height = 63;
        break;
      case "b":
        height = 60;
        className = "nav-bar-b-fixed-height-mb-responsive";
        break;
      default:
        break;
    }
    return <div className={className ? className : ""} style={{ height }} />;
  }

  mbResponsiveBreadCrump(currentStage) {
    const menu = [
      "Verify Identity",
      "Get Initial Financing Offer",
      "Submit More Info",
      "Receive money"
    ];

    switch (currentStage) {
      case 1:
      case 2:
        return (
          <Fragment key={0}>
            <BreadcrumbItem key={0 + "-home"} active>
              Home
            </BreadcrumbItem>
            <BreadcrumbItem key={0}>{menu[0]}</BreadcrumbItem>
          </Fragment>
        );
      case 3:
        return (
          <Fragment key={1}>
            <BreadcrumbItem key={1 + "-home"} active>
              Home
            </BreadcrumbItem>
            <BreadcrumbItem key={1}>{menu[1]}</BreadcrumbItem>
          </Fragment>
        );
      case 4:
        return (
          <Fragment key={2}>
            <BreadcrumbItem key={2 + "-home"} active>
              Home
            </BreadcrumbItem>
            <BreadcrumbItem key={2}>{menu[2]}</BreadcrumbItem>
          </Fragment>
        );
      case 5:
        return (
          <Fragment key={3}>
            <BreadcrumbItem key={3 + "-home"} active>
              Home
            </BreadcrumbItem>
            <BreadcrumbItem key={3}>{menu[3]}</BreadcrumbItem>
          </Fragment>
        );
    }
  }

  render() {
    let anchors;

    if (this.props.items !== undefined) {
      anchors = this.props.items.map(i => i + "Anchor");
    }

    return (
      <div>
        <Navbar
          light
          fixed={`top`}
          expand="xl"
          className={styles["alliance-navbar"]}
        >
          {this.props.version === "a" && (
            <Container className="nav-bar-a">
              {
                this.state.isLoggedIn && this.renderSearchButton()
              }
              <NavbarBrand href="/" className={styles["alliance-brand"]}>
                <img
                  src={require("../../assets/img/logo.svg")}
                  alt="Alliance Bank"
                />
                <span>| Personal Financing</span>
              </NavbarBrand>
              <NavbarToggler onClick={this.toggleNav} />
              <Collapse
                isOpen={this.state.navIsOpen}
                navbar
                className={styles["alliance-navbar-collapse"]}
              >
                <Scrollspy
                  offset={this.props.offset}
                  items={anchors}
                  currentClassName={styles["is-current"]}
                  className={`ml-auto navbar-nav ${
                    styles["alliance-navbar-nav"]
                    }`}
                >
                  {
                    this.props.items ?
                      (
                        <>
                          <NavItem id="navitem">
                            <NavLink href={`#${this.props.items[0]}`}>
                              Why Alliance
                        </NavLink>
                          </NavItem>
                          <NavItem id="navitem">
                            <NavLink href={`#${this.props.items[2]}`}>
                              Fees & Charges
                        </NavLink>
                          </NavItem>
                          <NavItem id="navitem">
                            <NavLink href={`#${this.props.items[3]}`}>
                              Important Documents
                        </NavLink>
                          </NavItem>
                          <NavItem id="navitem">
                            <NavLink href={`#${this.props.items[1]}`}>
                              Eligibility
                        </NavLink>
                          </NavItem>
                        </>
                      ) : null
                  }
                </Scrollspy>
              </Collapse>
              <div className="landing-logout-wrapper">
              {
                this.state.isLoggedIn ? this.renderLogoutButton() : null
              }
              </div>
              <div style={{ height: "63px" }} />
            </Container>
          )}
          {this.props.version === "b" && (
            <>
              <div className="nav-bar-b-mb">
                <NavbarBrand href="/">
                  <img
                    src={require("../../assets/img/logo.svg")}
                    alt="Alliance Bank"
                  />
                  <span>Personal Financing</span>
                </NavbarBrand>
                <Breadcrumb className="breadcrumb-container">
                  {this.mbResponsiveBreadCrump(this.props.currentStage)}
                </Breadcrumb>
              </div>
              <div className="nav-bar-b">
                {
                  this.state.isLoggedIn ? this.renderSearchButton() : this.renderHomeButton()
                }
                <Container>
                  <div className="navbar-wrapper">
                    <div className="alliance-logo-icon">
                      <img
                        src={require("../../assets/img/logoicon.png")}
                        className="logo-alliance-icon"
                        alt="Alliance Bank"
                      />
                    </div>
                    <div className="progress-bar-list">
                      <ProgressBar
                        percent={
                          this.props.progressBarPercentage
                            ? this.props.progressBarPercentage
                            : 0
                        }
                        filledBackground="#002559"
                        unfilledBackground="#CCCCCC"
                      >
                        <Step transition="scale">
                          {({ accomplished }) => (
                            <div className="progress-bar-step">
                              <div
                                className={`circle-wrapper ${
                                  accomplished ? "accomplished" : null
                                  }`}
                              >
                                <FontAwesomeIcon icon={faCircle} />
                              </div>
                              <span
                                className={
                                  accomplished ? "accomplished-txt" : null
                                }
                              >
                                verify identity
                              </span>
                            </div>
                          )}
                        </Step>
                        <Step transition="scale">
                          {({ accomplished }) => (
                            <div className="progress-bar-step">
                              <div
                                className={`circle-wrapper ${
                                  accomplished ? "accomplished" : null
                                  }`}
                              >
                                <FontAwesomeIcon icon={faCircle} />
                              </div>
                              <span
                                className={
                                  accomplished ? "accomplished-txt" : null
                                }
                              >
                                get initial financing offer
                              </span>
                            </div>
                          )}
                        </Step>
                        <Step transition="scale">
                          {({ accomplished }) => (
                            <div className="progress-bar-step">
                              <div
                                className={`circle-wrapper ${
                                  accomplished ? "accomplished" : null
                                  }`}
                              >
                                <FontAwesomeIcon icon={faCircle} />
                              </div>
                              <span
                                className={
                                  accomplished ? "accomplished-txt" : null
                                }
                              >
                                submit more info
                              </span>
                            </div>
                          )}
                        </Step>
                        <Step transition="scale">
                          {({ accomplished }) => (
                            <div className="progress-bar-step">
                              <div
                                className={`circle-wrapper ${
                                  accomplished ? "accomplished" : null
                                  }`}
                              >
                                <FontAwesomeIcon icon={faCircle} />
                              </div>
                              <span
                                className={
                                  accomplished ? "accomplished-txt" : null
                                }
                              >
                                receive money
                              </span>
                            </div>
                          )}
                        </Step>
                      </ProgressBar>
                    </div>
                  </div>
                  <div className="secure-application-icon">
                    <span>SECURE APPLICATION</span>
                    <img
                      src={require("../../assets/img/lock.png")}
                      alt="Home"
                    />
                    {
                      this.state.isLoggedIn ? this.renderLogoutButton() : null
                    }
                  </div>
                </Container>
              </div>
            </>
          )}
          {this.props.version === "c" && (
            <div className="nav-bar-c">
              <Container>
                {
                  this.state.isLoggedIn ? this.renderSearchButton() : this.renderHomeButton()
                }
                <NavbarBrand href="/" className={styles["alliance-brand"]}>
                  <img
                    src={require("../../assets/img/logo.svg")}
                    alt="Alliance Bank"
                  />
                  <span>| Personal Financing</span>
                </NavbarBrand>
                <div className="menu-image-wrapper">
                  <div className="menu-secure-application">
                    <span>Secure Application</span>
                    <img
                      src={require("../../assets/img/lock.png")}
                      alt="Secure"
                    />
                    {
                      this.state.isLoggedIn ? this.renderLogoutButton() : null
                    }
                  </div>
                </div>
              </Container>
            </div>
          )}
          {this.props.version === "d" && (
            <div className="nav-bar-c">
              <Container>
                {this.renderHomeButton()}
                <NavbarBrand href="/" className={styles["alliance-brand"]}>
                  <img
                    src={require("../../assets/img/logo.svg")}
                    alt="Alliance Bank"
                  />
                  <span>| Personal Financing</span>
                </NavbarBrand>
                <div className="menu-image-wrapper">
                  <div className="menu-secure-application">
                    {
                      this.state.isLoggedIn ? this.renderLogoutButton() : null
                    }
                  </div>
                </div>
              </Container>
            </div>
          )}
          {this.props.version === "e" && (
            <div className="nav-bar-c">
              <Container>
                <NavbarBrand href="/" className={styles["alliance-brand"]}>
                  <img
                    src={require("../../assets/img/logo.svg")}
                    alt="Alliance Bank"
                  />
                  <span>| Personal Financing</span>
                </NavbarBrand>
              </Container>
            </div>
          )}

        </Navbar>

        {/* SPACE FIX */}
        {this.fixNavHeight(this.props.version)}
        {this.state.isRedirect ? (<Redirect to={Routes.login} />) : null}
        {this.state.isToSearch ? (<Redirect to={Routes.salesStatusList} />) : null}
        {this.state.isToHome ? (<Redirect to={Routes.default} />) : null}
      </div>
    );
  }
}

export default Header;
