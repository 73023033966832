import React, { Component } from 'react';
import { isValidSession } from '../../services/session';
import { withCustomAlert } from '../../hoc';
import IdleTimer from 'react-idle-timer'
import { Session } from '../../services';
import { Redirect } from 'react-router';
import Routes from '../../routes';
import { FormHelper } from '../../services';

const withIdlerHandler = (WrappedComponent) => {
    class IdlerHandler extends Component {

        idleTimer = null;
        logoutTimer = null;
        idleTimeout = 1000 * 60 * 5;
        logoutTimeout = 1000 * 60 * 10;

        constructor(props) {
            super(props);
            this.state = {
                isRedirect: false
            }
        }

        onActive = () => {
            clearTimeout(this.logoutTimer)
        }

        onIdle = () => {
            let isRenderExtraButtonInPopUp = false;

            this.props.alert("Your session is expiring in 10 minutes. Please press OK button to stay connected.",
                isRenderExtraButtonInPopUp, 'Session Expiring')

            this.logoutTimer = setTimeout(() => {
                this.onLogout();
                this.props.dismissAlert();
            }, this.logoutTimeout)
        }

        onLogout = () => {
            Session.logout().then(resp => {
                console.log('logout successfully:', resp);
            }).catch(error => {
                console.log('logout failed:', error);
            })
            this.logoutRedirect();
        }

        logoutRedirect = () => {
            this.setState({ isRedirect: true })
        }

        determineRedirectPage = () => {
            this.setState({ isRedirect: false })
            if (isValidSession()) {
                Session.clearSession();
                return Routes.login;
            } else {
                let { pathname } = window.location;
                let newPathName;
                let ecid;
                let xuid;
                let cmsid;
                let aopId;

                if (window.location.search) {
                    ecid = FormHelper.UrlHelper.parseQueryParams(window.location.search).ecid;
                    xuid = FormHelper.UrlHelper.parseQueryParams(window.location.search).dxid;
                    cmsid = FormHelper.UrlHelper.parseQueryParams(window.location.search).cmsid;
                    aopId = FormHelper.UrlHelper.parseQueryParams(window.location.search).aopId;
                }

                switch (pathname) {
                    case Routes.initialOffer:
                    case Routes.submitMoreInfo:
                    case Routes.receiveMoney:
                    case Routes.complete:
                        newPathName = Routes.checkstatus;
                        break;

                    case Routes.login:
                        newPathName = Routes.login;
                        break;

                    case Routes.checkstatus:
                        newPathName = Routes.checkstatus;
                        break;

                    case Routes.aipOffcheckstatus:
                        newPathName = Routes.aipOffcheckstatus;
                        break;

                    default:
                        if (ecid || xuid || cmsid || aopId) {

                            const defaultRoute = Routes.default.substring(0, Routes.default.length - 1)

                            const allArray = [
                                { label: 'ecid', value: ecid },
                                { label: 'dxid', value: xuid },
                                { label: 'cmsid', value: cmsid },
                                { label: 'aopId', value: aopId },
                            ]
                            // remove from array if value is empty
                            const newArray = allArray.filter(o => !!o.value);

                            const labelValue = newArray.map(item => {
                                const endValue = item.label + `=` + item.value
                                return endValue
                            })

                            newPathName = defaultRoute + `?` + labelValue.join('&')

                            // if(ecid && xuid && cmsid)
                            // {
                            //     newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?ecid=${ecid}&dxid=${xuid}&cmsid=${cmsid}`;

                            // }
                            // if(ecid && xuid)
                            // {
                            //     newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?ecid=${ecid}&dxid=${xuid}`;

                            // }
                            // if(xuid && cmsid)
                            // {
                            //     newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?dxid=${xuid}&cmsid=${cmsid}`;

                            // }
                            // if(ecid && cmsid)
                            // {
                            //     newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?ecid=${ecid}&cmsid=${cmsid}`;

                            // }
                            // else{
                            //     if(ecid)
                            //     {
                            //         newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?ecid=${ecid}`;
                            //     }

                            //     if(xuid)
                            //     {
                            //         newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?dxid=${xuid}`;
                            //     }

                            //     if(cmsid)
                            //     {
                            //         newPathName = Routes.default.substring(0, Routes.default.length - 1) + `?cmsid=${cmsid}`;
                            //     }
                            // }
                        } else {
                            newPathName = Routes.default;
                        }

                }

                return newPathName;
            }
        }


        render() {
            return (
                <React.Fragment>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        debounce={250}
                        timeout={this.idleTimeout} />
                    <WrappedComponent {...this.props} />
                    {this.state.isRedirect ? (<Redirect to={this.determineRedirectPage()} />) : null}
                </React.Fragment>
            )
        }
    }

    return withCustomAlert(IdlerHandler);
}

export default withIdlerHandler;