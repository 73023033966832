import React from 'react';

class CrsDeclaration extends React.PureComponent {

    render() {
        return (
            <>
                <p className="section-description-2" style={{ color: "#000000", fontWeight: "400" }}>
                    I understand that the information supplied by me is governed by the full provisions of
                    the terms and conditions governing my relationship with Alliance Bank Malaysia Berhad
                    and/or its subsidiaries, including Alliance Bank Malaysia Berhad (ABMB)/Alliance Islamic Bank Berhad (AIS) setting out how
                    Alliance Bank Malaysia Berhad (ABMB)/Alliance Islamic Bank Berhad (AIS) may use and share the information supplied by me.
                </p>
                <p className="section-description-2" style={{ color: "#000000", fontWeight: "400" }}>
                    I acknowledge that the information contained in this Form and information regarding
                    myself and any reportable account(s) may be provided to the tax authorities of another
                    country/jurisdictions in which I may be a tax resident of, pursuant to the
                    intergovernmental agreements to exchange financial account information.
                </p>
                <p className="section-description-2" style={{ color: "#000000", fontWeight: "400" }}>
                    I certify that I am the beneficial owner of all the account(s) to which this Form
                    relates.
                </p>
                <p className="section-description-2" style={{ color: "#000000", fontWeight: "400", margin: "0" }}>
                    I undertake to advise Alliance Bank Malaysia Berhad (ABMB)/Alliance Islamic Bank Berhad (AIS) within 30 days of any change in circumstances which
                    affects the status of my tax residency or causes the information contained herein to
                    become incorrect or incomplete, and to provide Alliance Bank Malaysia Berhad (ABMB)/Alliance Islamic Bank Berhad (AIS) a suitably updated
                    self-certification and Declaration within 30 days of such change in circumstance.
                </p>
            </>
        );
    }
}

export default CrsDeclaration;



