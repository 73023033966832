import React from 'react';
import { TaxResidenceList, TaxJurisCountryList, ReasonOfNoTinList } from '../../constant';
import { Row, Col, Button, Card, CardText } from "reactstrap";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import { FormHelper } from '../../services';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CrsDeclaration from "./crsDeclaration";
import * as data from "../../data/globalData";

class Crs extends React.PureComponent {

    renderCrs = () => {
        return (
            <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="my-2 mb-3">
                        <p className="tips-msg tips-msg-2">
                            <strong>
                                COMMON REPORTING STANDARD (CRS) SELF CERTIFICATION
                            </strong>
                        </p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="my-2 col-4">
                        <Dropdown
                            label="Tax Resident"
                            name="taxResidence"
                            placeholder="Tax Resident"
                            options={TaxResidenceList}
                            value={this.props.taxResidence}
                            onChange={this.props.onTaxResidenceChange}
                        />
                    </Col>
                </Row>
                {
                    this.props.isNonMalaysianTaxResident ? (
                        <>
                            {this.renderArrayOfTIN()}
                            <Button
                                color="link"
                                style={{ color: '#4a90e2', textDecoration: 'underline', paddingLeft: 0 }}
                                onClick={this.props.onAddMoreTinPressed}
                            >
                                Add More Tax Jurisdiction
                            </Button>
                        </>
                    ) : null
                }
                <Row className="mt-2 mb-responsive">
                    <Col>
                        <CrsDeclaration />
                    </Col>
                </Row>
                <Button
                    id="gtm-update-fatca-crs"
                    data-index="5"
                    className="proceedButton"
                    disabled={!this.props.isValidFatcaCrs}
                    color="secondary"
                    onClick={this.props.onFatcaCrsContinuePressed}
                >
                    CONTINUE
                </Button>
            </>
        );
    }

    renderArrayOfTIN = () => {
        return this.props.tinArray.map((tinObject, i) => {
            return this.renderTIN(tinObject, i);
        })
    }

    renderTIN = (tinObject, i) => {
        return (
            <div key={i} >
                <hr />
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-4">
                        <Dropdown
                            label="Tax Jurisdiction"
                            name="taxJurisCountry"
                            placeholder="Tax Jurisdiction"
                            options={TaxJurisCountryList}
                            value={tinObject.taxJurisCountry}
                            onChange={(itemValue) => { this.props.onTaxJurisCountryChange(itemValue, i); }}
                        />
                    </Col>
                    <Col className="mt-2 col-4">
                        <Input
                            specialClass="field-input"
                            label="Tax Identification Number (TIN)"
                            name="tinNumber"
                            onChange={(result) => this.props.onTinNumberChange(result, i)}
                            placeholder="Tax Identification Number (TIN)"
                            value={tinObject.tinNumber}
                            maxLength={data.maxlength.tinNumber}
                            isValidInput={FormHelper.Validation.isOnlyAlphanumeric}
                            errText="Only alphanumeric is allowed"
                            disabled={tinObject.isNoTin}
                            optional={tinObject.isNoTin ? true : false}
                        />
                    </Col>
                    <Col className="mt-2 col-4">
                        <Card className="tnc-wrapper" style={{ paddingLeft: "0" }}>
                            <div
                                data-name="isNoTin"
                                onClick={() => this.props.onNoTinPressed(i)}
                                className={`box-checker-box ${tinObject.isNoTin !== undefined && tinObject.isNoTin ? "box-checked-box" : ""}`}
                            >
                                <FontAwesomeIcon icon={faCheck} />

                            </div>
                            <CardText style={{ color: "#000000", fontSize: "14px" }}> No TIN </CardText>
                            {
                                i !== 0 ? (
                                    <Button color="link" style={{ color: '#ee495d' }} onClick={() => this.props.onRemoveTinPressed(i)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                ) : null
                            }
                        </Card>

                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    {
                        tinObject.isNoTin === true ?
                            (
                                <Col className="mt-2 col-4">
                                    <Dropdown
                                        label="Reason"
                                        name="reasonOfNoTin"
                                        placeholder="Reason"
                                        options={ReasonOfNoTinList}
                                        value={tinObject.reasonOfNoTin}
                                        onChange={(itemValue) => { this.props.onReasonOfNoTinChange(itemValue, i); }}
                                    />
                                </Col>
                            ) : null
                    }
                    {
                        tinObject.reasonOfNoTin && tinObject.reasonOfNoTin.value === "3" ?
                            (
                                <Col className="mt-2 col-4">
                                    <Input
                                        specialClass="field-input"
                                        label="Other Reason (Please Specify)"
                                        name="noTinOtherReason"
                                        onChange={(result) => this.props.onNoTinOtherReasonChange(result, i)}
                                        placeholder="Other Reason (Please Specify)"
                                        value={tinObject.noTinOtherReason}
                                        maxLength={data.maxlength.noTinOtherReason}
                                    />
                                </Col>
                            ) : null
                    }
                </Row>

            </div>
        )
    }

    render() {
        return (
            this.renderCrs()
        );
    }
}

export default Crs;