export const types = {
    UPDATE_EMERGENCY_CONTACT_INFO: 'UPDATE_EMERGENCY_CONTACT_INFO',
    CLEAR_EMERGENCY_CONTACT_INFO: 'CLEAR_EMERGENCY_CONTACT_INFO',
}

export function updateEmergencyContactInfo(info) {
    return {
        type: types.UPDATE_EMERGENCY_CONTACT_INFO,
        info: info
    }
}

export function clearEmergencyContactInfo() {
    return { type: types.CLEAR_EMERGENCY_CONTACT_INFO }
}