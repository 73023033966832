import { types } from '../actions/emergencyContact';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    emergencyContactName: '', 
    emergencyContactRelationship: '',
    emergencyContactMobile: '+60', 
    spouseName: '', 
    spouseCompanyName: '',
    spouseMobile: '+60'
}

export default function emergencyContact(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_EMERGENCY_CONTACT_INFO:
        console.log("EMERGENCY CONTACT::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_EMERGENCY_CONTACT_INFO:
            return initialState;

        default:
            return state;
    }
}