import RSA from './rsa';

/** 
 * General Settings
*/

const webContext = '/ploan-web/';

const protectedPrefix = "/protected";

const publicPrefix = "/public";

const internalPefix = "/internal";

const version = "/v1/";

const recaptchaKey = '6Lei3sQUAAAAANE9sHmj3IWKk58yrUY_dnqkLBl5';

const clientIdSecret = "cGxvYW4tYXBwOjJhYTkyYWY5LTYzNjgtNGVlYS05NWYxLTAwNTkwMWMxOWMxNA==";

const rsaKey = RSA.uatKey;

const incomeDocumentDropdownVersion = 7;

/**
 * Prefixes
 */
const productPrefix = "/ploan/product";

const eformPrefix = "/ploan/eform";

const soapPrefix = "/ploan/soap";

const authPrefix = "/dbob/auth";

/**
 * Endpoints Url
 */
const productUrl = productPrefix + publicPrefix + version;

const protectedEformUrl = eformPrefix + protectedPrefix + version;

const publicEformUrl = eformPrefix + publicPrefix + version;

const soapUrl = soapPrefix + publicPrefix + version;

const loginUrl = authPrefix + publicPrefix + version + "auth/e2ee";

const refreshSessionUrl = authPrefix + publicPrefix + version + "auth";

const logoutUrl = authPrefix + publicPrefix + version + "logout";

const dashboardUrl = eformPrefix + protectedPrefix + version + 'matchingEforms';

export default { webContext, productUrl, protectedEformUrl, publicEformUrl, 
    soapUrl, loginUrl, refreshSessionUrl, logoutUrl, dashboardUrl, recaptchaKey, clientIdSecret, rsaKey, incomeDocumentDropdownVersion }
