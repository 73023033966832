import React, { Component } from 'react';
import styles from './Section.module.css'

/* eslint-disable */

class Section extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const navFix = (`${this.props.id}Anchor`)
        return (
            <section id={navFix} className={styles['section-anchor']}>
                <a id={this.props.id} className="anchor"></a>
                <div style={this.props.style} className={this.props.className}>
                    {this.props.children}
                </div>
            </section>
        );
    }
}

export default Section;
