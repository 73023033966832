import React, { Component } from "react";
import PropTypes from 'prop-types';
import Input from "../Input";
import { FormHelper } from '../../services';
import Timer from '../timer';
import './style.scss';
import * as data from "../../data/globalData";

class MobileNumberTac extends Component {

    timerPeriod = 300;
    coldDownPeriod = 60;

    constructor(props) {
        super(props);
        this.state = {
            enableResend: false
        }
    }

    onChangeTac = (result) => {
        this.props.onChangeTac(result);
        return;
    }

    onClickRequestTac = () => {
        this.setState({ enableResend: false })
        this.props.onClickRequestTac();
    }

    onClickResend = () => {
        if (this.state.enableResend) {
            this.onClickRequestTac();
            this.timerRef.startCountDown();
        }
    }

    onTick = (seconds) => {
        if ((this.timerPeriod - seconds) > this.coldDownPeriod)
            this.setState({ enableResend: true });
    }

    renderLoadingIndicator = () => {
        return (
            <div className="tac-loading-indicator" style={this.props.loadingIndicatorStyle}>
                <img alt="loading gif" src={require("../../assets/img/loading-indicator.gif")} />
            </div>
        );
      };

    renderResendButton = () => {
        if (this.state.enableResend && !this.props.disabled) {
            return <button className="resend-code active" onClick={this.onClickResend}>RESEND CODE</button>
        }
        if (!this.state.enableResend && this.props.disabled) {
            return null
        }
        else {
            return <button className="resend-code">RESEND CODE</button>
        }
    }

    verifyMobileNumberComponent = () => {
        const { tac, shouldShowLoadingIndicator, timerColor, timerStyle, disabled } = this.props;

        return (
            <>
                <Input
                    type="tel"
                    specialClass="field-input"
                    label="Verification Code"
                    name="verificationCode"
                    onChange={this.onChangeTac}
                    placeholder="Verification Code"
                    value={tac}
                    disabled={(shouldShowLoadingIndicator ? true : false)||disabled}
                    maxLength={data.maxlength.verificationCode}
                    isValidInput={FormHelper.Validation.isOnlyNumber}
                    errText="Only numeric is allowed"
                    shouldShowGreenTick={false}
                />
                { shouldShowLoadingIndicator && this.renderLoadingIndicator()}
                {this.renderResendButton()}
                {
                    !disabled && (
                        <Timer timerStyle={timerStyle} color={timerColor} 
                        ref={ref => { this.timerRef = ref }} seconds={this.timerPeriod} onTick={this.onTick} />
                    )
                }
            </>
        )
    };

    render() {
        return (
            this.verifyMobileNumberComponent()
        );
    }
}

MobileNumberTac.defaultProps = {
    onTick: () => { },
    onClickResend: () => { },
    shouldShowLoadingIndicator:false,
    timerColor:'black',
    loadingIndicatorStyle:{},
    disabled:false,
    onClickRequestTac: () => { },
    onChangeTac: () => { }
}

MobileNumberTac.propTypes = {
    tac: PropTypes.string,
    onClickRequestTac: PropTypes.func.isRequired,
    onTick: PropTypes.func.isRequired,
    onClickResend: PropTypes.func.isRequired,
    onChangeTac: PropTypes.func.isRequired,
    shouldShowLoadingIndicator: PropTypes.bool.isRequired,
    timerColor:PropTypes.string,
    timerStyle:PropTypes.object,
    loadingIndicatorStyle:PropTypes.object,
    disabled:PropTypes.bool
}

export default MobileNumberTac;
