export const getPayloadFromJWT = (jwt) =>{

    if(!jwt)
    return {};

    let jwtArray = jwt.split('.');

    if(jwtArray.length < 3)
    return {};

    return JSON.parse(atob(jwtArray[1]));
}