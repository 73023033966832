import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardText } from "reactstrap";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle/index";
import "./checkstatus.scss";
import Input from "../../components/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FormHelper, Webservice, PayloadGenerator, JWT, ReduxHelper } from '../../services';
import MobileNumberTac from '../../components/tac';
import { connect } from 'react-redux';
import {
  eligibility, webserviceConfig, calculator, initialLoanOffer, moreAboutYourself, employmentDetails,
  emergencyContact, preferredBank, fatcaCrs, amendments
} from '../../reduxStore/actions';
import { FormStatusList, AmendmentsCategoryList } from '../../constant';
import Routes from '../../routes';
import * as data from "../../data/globalData";
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import { isValidSession } from '../../services/session';
import BaseComponent from '../../class/baseComponent';
import { PageHelper } from '../../services';
import styles from "./checkstatus.scss";

class CheckStatus extends BaseComponent {

  otpUuid = '';

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef()
    this.state = {
      nric: "",
      nricFocus: false,
      hasRetrievedCustomerInfo: false,
      verificationCode: "",
      shouldShowLoadingIndicator: false,
      requestedOTP: false,
      hasAmendments: false
    };
  }

  componentDidMount() {
    this.retrieveLandingPageParams();
    if (isValidSession())
      this.retrieveCustomerInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      verificationCode
    } = this.state;

    const prevVerificationCode = prevState.verificationCode;

    if (verificationCode !== prevVerificationCode && verificationCode.length >= 6) {
      this.validateTac();
    }
  }

  scrollToScrollRef = () => {
    const { hasAmendments } = this.state;
    
    if(!hasAmendments)
      window.scrollTo(0, this.scrollRef.current.offsetTop - 180)
  }

  onIcNoChange = nric => {
    this.setState({ nric });
  };

  onNricFocusOrBlur = e => {
    this.setState(prevState => ({ nricFocus: !prevState.nricFocus }))
  };

  onVerificationCodeChange = (verificationCode) => {
    this.setState({
      verificationCode
    });
  };

  shouldDisableRequestTacButton = () => {
    const { requestedOTP, nric } = this.state;

    if (requestedOTP)
      return true;

    if (!this.isValidICNo(nric))
      return true;

    return false;
  }

  isValidICNo = (nric) => {
    if (FormHelper.Validation.isICFormat(nric) && FormHelper.Validation.isValidICBasedOnFirst6Digit(nric)) {
      return true;
    } else {
      return false;
    }
  }

  requestTac = () => {
    const { nric } = this.state;
    let requestTacPayload = PayloadGenerator.genRequestTacPayloadByIcNo(nric);
    this.setState({ requestedOTP: true })
    Webservice.Tac.requestTac(requestTacPayload).then(resp => {
      console.log('requestTac:', resp)

      if (!resp.error) {
        this.otpUuid = resp.details.uuid;
      } else {
        this.baseErrorHandler(resp.error)
      }

    }).catch(err => {
      console.log('requestTac:', err);
      this.baseErrorHandler(err);
    })
  }

  validateTac = () => {
    const { nric, verificationCode } = this.state;
    let validateTacPayload = PayloadGenerator.genValidateTacPayloadByIcNo(nric, verificationCode, this.otpUuid);

    this.setState({ shouldShowLoadingIndicator: true })

    Webservice.Tac.validateTac(validateTacPayload).then(resp => {
      console.log('validateTac:', resp)

      if (resp.jwt) {
        if (!isValidSession()) {
          this.props.updateWebserviceConfig({ jwt: resp.jwt })
        }
        this.retrieveCustomerInfo();
      }
      else {
        this.setState({ shouldShowLoadingIndicator: false })
        this.baseErrorHandler(resp.error);
      }

    }).catch(err => {
      console.log('validateTac:', err);
      this.setState({ shouldShowLoadingIndicator: false })
      this.baseErrorHandler(err);
    })
  }

  retrieveLandingPageParams = () => {
    Webservice.Product.getLandingPageParams(false).then(resp => {
      console.log("retrieveLandingPageParams:::", resp)
      if (!!resp.isAipOff) {
        this.props.updateCalculatorStore({ isAipOff: resp.isAipOff })
      }
      const isCashBackCampaign = resp.isCashBackCampaign;
      this.setState({ isCashBackCampaign })
    })
  }

  retrieveCustomerInfo = () => {
    let uuid;

    if (isValidSession()) {
      console.log("validsession::", uuid)
      uuid = this.props.webserviceConfig.eformUuid;
  } 
  else {
      const jwt = this.props.webserviceConfig.jwt;
      console.log("jwt::", jwt)
      uuid = JWT.getPayloadFromJWT(jwt).eformUuid
      console.log("else validsession::", uuid)
  }

    // this.checkIfCustomerHasAmendments(uuid);

    this.getAllCustomerInfo(uuid);
  }

  getAllCustomerInfo = (uuid) => {

    Webservice.Eform.getEformByUuid(uuid).then(async (resp) => {
      console.log('retrieveCustomerInfo:', resp)

      let calculatorStore = ReduxHelper.genCalculatorStoreFromPayload(resp);
      let eligibilityStore = ReduxHelper.genEligibilityStoreFromPayload(resp);
      let initialLoanOfferSore = ReduxHelper.genInitialLoanOfferStoreFromPayload(resp);
      let moreAboutYourselfStore = ReduxHelper.genMoreAboutYourselfStoreFromPayload(resp);
      let employmentDetailStore = ReduxHelper.genEmploymentDetailStoreFromPayload(resp);
      let emergencyContactStore = ReduxHelper.genEmergencyContactStoreFromPayload(resp);
      let preferredBankStore = ReduxHelper.genPreferredBankStoreFromPayload(resp);
      let fatcaCrsStore = ReduxHelper.genFatcaCrsStoreFromPayload(resp);

      this.props.updateCalculatorStore(calculatorStore);
      this.props.updateEligibilityStore(eligibilityStore);
      this.props.updateInitialLoanOfferStore(initialLoanOfferSore);
      this.props.updateWebserviceConfig({ eformUuid: uuid, eformStatus: resp.statusCode })
      this.props.updateMoreAboutYourselfStore(moreAboutYourselfStore);
      this.props.updateEmploymentDetailStore(employmentDetailStore);
      this.props.updateEmergencyContactStore(emergencyContactStore);
      this.props.updatePreferredBankStore(preferredBankStore);
      this.props.updateFatcaCrsStore(fatcaCrsStore);

      //only retrieved CRS infomation if customer is Non-Malaysian Tax Residence OR Malaysian and Non-Malaysian Tax Residence
      if (resp.crsFlag && (resp.crsFlag === 'MNTR' || resp.crsFlag === 'NTR')) {
        await this.retrieveCustomerCrsTinInfo(uuid, fatcaCrsStore);
      }
      console.log("initialLoanOfferSore::", initialLoanOfferSore)
      if(this.props.calculator.isAipOff) {
        this.props.updateInitialLoanOfferStore({
          ...initialLoanOfferSore,
          selectedSellingAmount: FormHelper.Calculator.calculateSellingPrice(initialLoanOfferSore.selectedLoanAmount, initialLoanOfferSore.selectedInterestRate, initialLoanOfferSore.selectedTenure),
        });
      }

      this.retrieveLandingPageParams();

      this.setState({ hasRetrievedCustomerInfo: true }, this.scrollToScrollRef)
    }).catch(err => {
      console.log('retrieveCustomerInfo:', err);
      this.baseErrorHandler(err);
    }).finally(() => {
      this.setState({
        shouldShowLoadingIndicator: false
      })
    })
  }

  retrieveCustomerCrsTinInfo = async (uuid, fatcaCrsStore) => {
    try {
      await Webservice.Eform.getCrsTINByUuid(uuid).then(resp => {
        console.log('retrieveCustomerCrsTinInfo:', resp)

        let crsTINStore = resp.map(item => ReduxHelper.genCrsTINStoreFromPayload(item));
        this.props.updateFatcaCrsStore(
          {
            fatcaDeclaration: fatcaCrsStore.fatcaDeclaration,
            fatcaCountryBirth: fatcaCrsStore.fatcaCountryBirth,
            taxResidence: fatcaCrsStore.taxResidence,
            tinArray: crsTINStore
          }
        );

      }).catch(error => {
        console.log('retrieveCustomerCrsTinInfo:error:', error)
        throw error;
      })
    } catch (error) {
      this.baseErrorHandler(error);
      return false;
    }

    return true;
  }


  retrieveIntialLoanOffer = async () => {
    const { eformUuid } = this.props.webserviceConfig;
    try {
      await Webservice.Eform.getInitialLoanOffer(eformUuid).then(resp => {
        console.log('retrieveIntialLoanOffer:', resp)

        let initialLoanOffer = FormHelper.toNumberObject(resp);

        this.props.updateInitialLoanOfferStore({
          ...initialLoanOffer,
          minLoanAmount: initialLoanOffer.min_loan_amount,
          stampDutyRate: initialLoanOffer.stamp_duty_rate,
          interestRateMapping: initialLoanOffer.proposedInterestRate,
          insuranceRateMapping: initialLoanOffer.proposedInsuranceRate,
          proposedStampDuty: FormHelper.Calculator.calculateStampDuty(initialLoanOffer.proposedLoanAmount, initialLoanOffer.stamp_duty_rate),
          proposedSellingAmount: FormHelper.Calculator.calculateSellingPrice(initialLoanOffer.proposedLoanAmount, initialLoanOffer.proposedInterestRate[initialLoanOffer.proposedTenure], initialLoanOffer.proposedTenure),
          proposedMonthlyInstallmentAmount: FormHelper.Calculator.calculateMonthlyRepayment(initialLoanOffer.proposedLoanAmount, initialLoanOffer.proposedInterestRate[initialLoanOffer.proposedTenure], initialLoanOffer.proposedTenure),
          cashBackRate: initialLoanOffer.cashBackRate,
        });

      }).catch(error => {
        console.log('retrieveIntialLoanOffer:error:', error)
        throw error;
      })
    } catch (error) {
      this.baseErrorHandler(error);
      return false;
    }

    return true;
  }

  checkIfCustomerHasAmendments = async (uuid) => {
    try {
      await Webservice.Eform.getAmendmentsByEformUuid(uuid).then(resp => {
        console.log('checkIfCustomerHasAmendments:resp:',resp)
        if (Object.keys(resp).length > 0){
          this.props.updateAmendments(resp);
          this.initAmendmentModel();
          this.setState({ hasAmendments:true })
        }
      }).catch(error => { 
        throw error 
      })
    }catch(error) {
      console.log('checkIfCustomerHasAmendments:error:',error)
      this.baseErrorHandler(error);
    }
  }

  shouldResumeToGeInitialLoanOffer = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return (!this.props.calculator.isAipOff && eformStatus === FormStatusList.APPROVAL_IN_PRINCIPLE_SUCCEEDED) 
  }

  shouldResumeToSubmitMoreInfoAndDocument = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return (this.props.calculator.isAipOff && eformStatus === FormStatusList.APPROVAL_IN_PRINCIPLE_SUCCEEDED) || 
    (eformStatus === FormStatusList.LOAN_AMOUNT_CONFIRMED) || 
    (this.props.calculator.isAipOff && eformStatus !== FormStatusList.AIPOFF_FORM_COMPLETED)
  }

  shouldResumeToThankYouPage = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return this.props.calculator.isAipOff && (eformStatus === FormStatusList.AIPOFF_FORM_COMPLETED || eformStatus === FormStatusList.APPROVED)
  }

  isEformSigned = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.SIGNED
  }

  isApplicationCompleted = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.APPROVED
  }

  isApplicationPending = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.SUBMITTED 
  }

  isApplicationRejected = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.REJECTED ||
    eformStatus === FormStatusList.DECLINED
  }

  isCCRISAnswerFailed = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.CCRIS_ANSWER_FAILED ||
    eformStatus === FormStatusList.CTOS_SERVICE_UNAVAILABLE 
  }

  isApplicationStatusHitError = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.AIP_HIT_ERROR ||
      eformStatus === FormStatusList.RPA_CREATION_FAILED ||
      eformStatus === FormStatusList.APPROVAL_IN_PRINCIPLE_FAILED ||
      eformStatus === FormStatusList.APPROVAL_IN_PRINCIPLE_REJECTED ||
      eformStatus === FormStatusList.RPA_HIT_ERROR_ONE ||
      eformStatus === FormStatusList.PENDING_CREDIT_SCORE ||
      eformStatus === FormStatusList.RPA_CREATION_SUCCEEDED 
  }

  isApplicationStatusHitErrorTwo = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.RPA_HIT_ERROR_TWO 
  }

  isApplicationCancelled = () => {
    const { eformStatus } = this.props.webserviceConfig;
    return eformStatus === FormStatusList.CANCELLED 
  }

  continue = async () => {

    if (this.shouldResumeToGeInitialLoanOffer()) {
      await this.retrieveIntialLoanOffer()
      this.props.history.push(Routes.initialOffer)
      return;
    }

    if (this.shouldResumeToSubmitMoreInfoAndDocument()) {
      this.props.history.push(Routes.submitMoreInfo)
      return;
    }

    if (this.shouldResumeToThankYouPage()) {
      this.props.history.push(Routes.complete)
      return;
    }

  }

  initAmendmentModel = () => {
    const { amendmentFields, amendmentAttachments } = this.props.amendments;
    let allAmendmentsItem = [...amendmentFields, ...amendmentAttachments];
    console.log('allAmendmentsItem:',allAmendmentsItem)
    let allAmendmentsItemLength = allAmendmentsItem.length;
    let answerModel = []

    for(let index = 0; index < allAmendmentsItemLength; index ++){
        let answerItem = {};
        let category = allAmendmentsItem[index].fieldCategory;
        let allFieldsOfSameCategory = allAmendmentsItem.filter(item => item.fieldCategory === category);
        
        if(!(answerModel.filter(item => item.fieldCategory === category).length > 0)) // if answerModel already contain the fieldCategory, don't push to answerModel
        {
            if(allFieldsOfSameCategory.length > 1){
                answerItem.fieldCategory = category;
                answerItem.fields = allFieldsOfSameCategory.map(item => {
                    let newItem = JSON.parse(JSON.stringify(item)); // hard cloned the item;
                    delete newItem.fieldCategory;
                    return newItem;
                })
            }else{
                answerItem = allFieldsOfSameCategory[0]
            }
            answerModel.push(answerItem)
        }
    }
    console.log('answerModel:',answerModel);
    this.props.updateAmendments({amendmentModel:answerModel})
}

  renderGreetingMessage = () => {
    const { hasAmendments } = this.state;
    const { name } = this.props.eligibility;
    let greetingMessage = "";
    
    if(this.shouldResumeToGeInitialLoanOffer())
      greetingMessage = "Your initial financing offer is ready.";
    
    if(hasAmendments)
      greetingMessage = "Your financing application is being reviewed by the Bank."

    return (
      <> Hi, <strong>{name}</strong>! {greetingMessage} </>
    )
    
  }

  renderAmendmentsInstruction = () => {
    const { amendments } = this.props;
    const { selectedDoc } = this.props.calculator;

    console.log('renderAmendmentsInstruction:',amendments)
    
    let toRender = amendments.amendmentModel.map((item, index) => {
      let isIncomeDocumentCategory = item.fieldCategory === AmendmentsCategoryList.IncomeDocuments;
      let isAdditionalDocumentCategory = item.fieldCategory === AmendmentsCategoryList.AdditionalDocuments;
      let text; 

      if(isIncomeDocumentCategory)
        text = selectedDoc.label;

      if(isAdditionalDocumentCategory)
        text = 'Additional Documents'; //TODO: Put correct label 

      return (
              <Row className="mt-3 check-status-container">
                <Col>
                  <span>{(index + 1)}. {PageHelper.getAmendmentsDisplayingFieldLabel(item.fieldCategory, text)}</span>
                </Col>
              </Row>
              )
    })

    return ( 
      <>
      {toRender}
        <Row className="mt-3 check-status-container">
            <Col>
              <Button className="continue-button" onClick={()=> { this.props.history.push(Routes.amendments) }}> 
                CONTINUE 
              </Button>
            </Col>
        </Row>
      </>
    )
  }

  renderDescription = () => {

    const { name } = this.props.eligibility;
    const { hasAmendments } = this.state;

    if (this.isApplicationCompleted()) {
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>Congratulations! Your Alliance Personal Financing has been approved.
          </CardText>
        </Card>
      )
    }

    if(hasAmendments){
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>{`Click “CONTINUE” to provide following information or documents.`}
          </CardText>
        </Card>
      )
    }


    if (this.isApplicationPending()) {
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>{`Hang on! Your Alliance Personal Financing is being processed. 
            ${ isValidSession() ? 'Please try again later.' : 'You will receive a call from the Bank for further assistance in the next business day if required.'}`}
          </CardText>
        </Card>
      )
    }

    if (this.isEformSigned())
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>You will hear from us within 1-2 working days.
        </CardText>
        </Card>
      );

    if (this.isCCRISAnswerFailed() || this.isApplicationStatusHitError())
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>{`Thank you ${name}! Our Bank staff will contact you within 1-2 working days.`}
          </CardText>
        </Card>
      );

    if (this.isApplicationStatusHitErrorTwo())
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>{`Thank you ${name}! You will receive a call from the Bank for further assistance in the next business day if required.`}
          </CardText>
        </Card>
      );

    if (this.isApplicationRejected())
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>{`Thank you for your interest and time in applying Alliance Personal Financing with us. We regret to inform that your application is unsuccessful.`}
          </CardText>
        </Card>
      );

    if (this.isApplicationCancelled())
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>{`Your application is no longer valid. Kindly resubmit.`}
          </CardText>
        </Card>
      );

    if (this.shouldResumeToGeInitialLoanOffer() || this.shouldResumeToSubmitMoreInfoAndDocument() || this.shouldResumeToThankYouPage())
      return (
        <Card>
          <div className="icon">
            <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
          </div>
          <CardText className="description">
            <span></span>Click "<strong style={{ display: "inline" }}> CONTINUE </strong>" to submit your application.
            Upon submission, you will receive your money within 1-2 working days.
          </CardText>
        </Card>
      );

    return (
      <Card>
        <div className="icon">
          <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
        </div>
        <CardText className="description">
          <span></span>{`Thank you ${name}! Our Bank staff will contact you within 1-2 working days.`}
          </CardText>
      </Card>
    );
  }

  renderTac = () => {
    const { shouldShowLoadingIndicator, verificationCode, hasRetrievedCustomerInfo } = this.state;
    return (
        <Col className="check-status-input-row-2">
          {/* <div className="input-Wrapper"> */}
            <MobileNumberTac
              tac={verificationCode}
              onClickRequestTac={this.requestTac}
              onChangeTac={this.onVerificationCodeChange}
              shouldShowLoadingIndicator={shouldShowLoadingIndicator && verificationCode.length >= 6}
              timerColor={'black'}
              loadingIndicatorStyle={styles["tac-loading-indicator"]}
              disabled={hasRetrievedCustomerInfo}
            />
            {/* <p className="tips-msg">
                {verificationCode.length >= 6 ? "" : "A 6 digit verification has been sent to 01x-xxxx 998."}
              </p> */}
            {/* {validIdentity && verificationCode.length < 6 ?
                <button className="resend-code">RESEND CODE</button> : ""
              } */}
          {/* </div> */}
        </Col>
    );
  };

  disableCheckStatusProgressBar = () => {
    return  this.isCCRISAnswerFailed() || this.isApplicationStatusHitError() || this.isApplicationStatusHitErrorTwo() || this.isApplicationRejected() || this.isApplicationCancelled()
  }

  renderApplicationStatusTab = () => {
    return (
      <Row className="check-status-container pb-5">
        <Col className="mb-5">
          <ul className="check-status-list-wrapper">
            <div className="line">
              <ul>
                <li>
                  <div className="status-label">10mins</div>
                  <img alt="arrow icon" src={require('../../assets/img/arrow.png')} />
                </li>
                {this.props.calculator.isAipOff ? <></> : <><li>
                  <div className="status-label">5mins</div>
                  <img alt="arrow icon" src={require('../../assets/img/arrow.png')} />
                </li></> }
                <li>
                  <div className="status-label">1-2days</div>
                  <img alt="arrow icon" src={require('../../assets/img/arrow.png')} />
                </li>
              </ul>
            </div>
            <li className={`check-status-list ${this.disableCheckStatusProgressBar() ? 'disabled' : ""}`}>
              <div className="check-status-list-item">
                <div className="check-status-img">
                  <img alt="verify identity icon" src={require('../../assets/img/verify-identity-icon.png')} />
                </div>
                <div className="check-status-icon-wrapper">
                  {(this.shouldResumeToGeInitialLoanOffer() ||
                    this.shouldResumeToSubmitMoreInfoAndDocument() ||
                    this.isEformSigned() ||
                    this.isApplicationCompleted() ||
                    this.shouldResumeToThankYouPage()
                  ) ?
                    <div className="check-status-icon">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    :
                    <div className={`check-status-icon ${this.disableCheckStatusProgressBar() ? 'disabled' : ''}`}>
                      1
                    </div>
                  }
                </div>
                <p className="check-status-label">Verify Identity</p>
              </div>
            </li>
            <div className="status-time-spend-mb-responsive"><span>10mins</span></div>
            {this.props.calculator.isAipOff ? <></> : <>
            <li className={`check-status-list ${this.shouldResumeToGeInitialLoanOffer() ? "check-status-list-active" : (this.shouldResumeToSubmitMoreInfoAndDocument() || this.shouldResumeToThankYouPage() || this.isEformSigned() || this.isApplicationCompleted()) ? '' : "disabled"}`}>
              <div className="check-status-list-item">
                <div className="check-status-img">
                  <img alt="initial load offer icon" src={require('../../assets/img/initial-loan-offer-icon.png')} />
                </div>
                <div className="check-status-icon-wrapper">
                  {this.shouldResumeToSubmitMoreInfoAndDocument() || this.isEformSigned() || this.isApplicationCompleted() ?
                    <div className="check-status-icon">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    :
                    <div className={`check-status-icon ${this.disableCheckStatusProgressBar() ? 'disabled' : ''}`}>
                      {this.props.calculator.isAipOff ? 2 : 3}
                    </div>
                  }
                </div>
                <p className="check-status-label">Get Initial Financing Offer </p>
              </div>
              {this.shouldResumeToGeInitialLoanOffer() && <button id="gtm-check-status-continue" className="check-status-btn" onClick={this.continue}>CONTINUE</button>}
            </li>
            <div className="status-time-spend-mb-responsive"><span>5mins</span></div> </>
            }
            <li className={`check-status-list ${(this.shouldResumeToSubmitMoreInfoAndDocument() || this.shouldResumeToThankYouPage())? "check-status-list-active" : (this.isEformSigned() || this.isApplicationCompleted()) ? '' : "disabled"}`}>
              <div className="check-status-list-item">
                <div className="check-status-img">
                  <img alt="submit more info icon" src={require('../../assets/img/submit-more-info-icon.png')} />
                </div>
                <div className="check-status-icon-wrapper">
                  {(this.isEformSigned() || this.isApplicationCompleted()) ?
                    <div className="check-status-icon">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    :
                    (this.shouldResumeToSubmitMoreInfoAndDocument() || this.shouldResumeToThankYouPage()) ?
                      <div className="check-status-icon" style={{ backgroundColor: "#002559" }}>{this.props.calculator.isAipOff ? 2 : 3}</div>
                      :
                      <div className="check-status-icon" style={{ backgroundColor: "#fcdead" }}>{this.props.calculator.isAipOff ? 2 : 3}</div>
                  }
                </div>
                <p className="check-status-label">Submit More Info & Document</p>
              </div>
              {(this.shouldResumeToSubmitMoreInfoAndDocument() || this.shouldResumeToThankYouPage()) && <button id="gtm-check-status-continue" className="check-status-btn" onClick={this.continue}>CONTINUE</button>}
            </li>
            <div className="status-time-spend-mb-responsive"><span>1-2days</span></div>
            <li className={`check-status-list ${this.isApplicationCompleted() ? "" : "disabled"}`}>
              <div className="check-status-list-item">
                <div className="check-status-img">
                  <img alt="receive money icon" src={require('../../assets/img/receive-money-icon.png')} />
                </div>
                <div className="check-status-icon-wrapper">
                  <div className="check-status-icon check-status-icon-2">
                    {this.isApplicationCompleted() ? <img alt="happy disabled icon" src={require('../../assets/img/happy.png')} /> : <img alt="happy disabled icon" src={require('../../assets/img/happy-disabled.png')} />}

                  </div>
                </div>
                <p className="check-status-label">Receive Money</p>
                <button className="check-status-btn" style={{ opacity: 0 }}>CONTINUE</button>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    )
  }

  renderContent = () => {
    const { hasAmendments } = this.state;
    
    if(hasAmendments)
      return this.renderAmendmentsInstruction();

    if(!this.isApplicationPending())
      return this.renderApplicationStatusTab();

    return ( <Row>
              <Col>
                <button className="check-status-again-button" onClick={this.retrieveCustomerInfo}>Retry</button>
              </Col>
            </Row> )
  }

  render() {
    const { nric, requestedOTP, hasRetrievedCustomerInfo, hasAmendments, isCashBackCampaign } = this.state
    return (
      <>
        <Header version="c" />
        <Section className={`${ !!isCashBackCampaign ? 'heroWtMinsCashBack' : 'hero' }`}>
          <Container className="hero-container">
            {/* <div className="circles">
              <div className="circle-one">
                <span>
                  As low as <strong style={{ fontSize: "50px" }}>3.99%</strong> flat rate p.a
                </span>
              </div>
              <div className="circle-two">
                <span>
                  <strong style={{ fontSize: "30px" }}>ZERO</strong> Processing{" "}
                  <br />
                  Fees
                </span>
              </div>
              <div className="circle-three">
                <span>
                  up to <strong style={{ fontSize: "30px" }}>48hrs</strong> to
                  receive <br />
                  your money
                </span>
              </div>
            </div> */}
          </Container>
        </Section>
        <Section style={{ backgroundColor: "#ffffff" }}>
          <Container className="min-height">
          <SectionTitle text="Check your personal financing application status" />
            {
              !isValidSession() ? (<>
                <Row className="mt-4 check-status-container">
                  <Col className="check-status-input-row-1">
                    {/* <div className="nric-wrapper">
                <label
                htmlFor={'nric'}
                  className={`date-label ${
                    nric || nricFocus || (nricFocus === false && !nric)
                    ? "show"
                    : ""
                }
              `}
              >
              {'NRIC/MILITARY/POLICE NO'}
              </label>
                <NumberFormat
                  name="nric"
                  className={`field-input nric-field-input ${nricFocus === false && !nric ? "form-control is-invalid invalid-input" : ""}`}
                  onChange={this.onIcNoChange}
                  value={nric}
                  placeholder={'NRIC Number'}
                  onFocus={this.onNricFocusOrBlur}
                  onBlur={this.onNricFocusOrBlur}
                  format="######-##-####"
                  disabled={requestedOTP}
                  mask="_"
                />
                {hasVerifiedOTP? (
                    <div className="form-tick">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  ) : ""}
                  </div> */}
                    <Input
                      type="tel"
                      label="NRIC/MILITARY/POLICE NO"
                      name="nric"
                      onChange={this.onIcNoChange}
                      placeholder="NRIC Number"
                      value={nric}
                      isValidInput={this.isValidICNo}
                      errText={"Please enter a valid Malaysia IC Number."}
                      format="######-##-####"
                      mask="_"
                      disabled={requestedOTP}
                    />
                    <Button
                      className="form-button"
                      disabled={this.shouldDisableRequestTacButton()}
                      onClick={this.requestTac}
                    >
                      GO
                </Button>
                  </Col>
                </Row>
                <Row className="mt-4 mb-3 check-status-container">
                  {requestedOTP && this.renderTac()}
                </Row>
                {this.state.requestedOTP && !this.state.hasRetrievedCustomerInfo ? 
                <Row className="mt-3 mb-3 check-status-container">
                  <Col className="resend-code-info">
                    If you didn't receive verification code, click "RESEND CODE" button after 1 minute.
                  </Col>
                </Row> : ''}
              </>
              ) : null
            }
            {hasRetrievedCustomerInfo &&
              <>
                <hr ref={this.scrollRef} />

                <Row className="check-status-container">
                  <Col className="mt-3">
                    <h1 className="title">
                      {this.renderGreetingMessage()}
                    </h1>
                  </Col>
                </Row>
                <Row className="check-status-container mt-4">
                  <Col>
                    {this.renderDescription()}
                  </Col>
                </Row>
                {
                  this.renderContent()
                }
                <div style={{ marginTop: "20px" }}>
                  <strong style={{ color: "#FF0000" }}>Disclaimer:</strong>
                </div>
                <div>
                  <strong style={{ color: "#FF0000" }}>* </strong>{data.disclamerDesc}
                </div>
              </>
            }
          </Container>
        </Section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  calculator: state.calculator,
  eligibility: state.eligibility,
  webserviceConfig: state.webserviceConfig,
  amendments: state.amendments
});

const mapDispatchToProps = dispatch => ({

  updateCalculatorStore: (info) => {
    dispatch(calculator.updateCalculatorInfo(info))
  },
  clearCalculatorStore: () => {
    dispatch(calculator.clearCalculatorInfo())
  },
  updateEligibilityStore: (info) => {
    dispatch(eligibility.updateEligibilityInfo(info))
  },
  clearEligibilityStore: () => {
    dispatch(eligibility.clearEligibilityInfo())
  },
  updateWebserviceConfig: (info) => {
    dispatch(webserviceConfig.updateWebserviceConfig(info))
  },
  clearWebserviceConfig: () => {
    dispatch(webserviceConfig.clearWebserviceConfig())
  },
  updateInitialLoanOfferStore: (info) => {
    dispatch(initialLoanOffer.updateInitialLoanOfferInfo(info))
  },
  clearInitialLoanOfferStore: () => {
    dispatch(initialLoanOffer.clearInitialLoanOfferInfo())
  },
  updateMoreAboutYourselfStore: (info) => {
    dispatch(moreAboutYourself.updateMoreAboutYourselfInfo(info))
  },
  updateEmploymentDetailStore: (info) => {
    dispatch(employmentDetails.updateEmploymentDetailsInfo(info))
  },
  updateEmergencyContactStore: (info) => {
    dispatch(emergencyContact.updateEmergencyContactInfo(info))
  },
  updatePreferredBankStore: (info) => {
    dispatch(preferredBank.updatePreferredBankInfo(info))
  },
  updateFatcaCrsStore: (info) => {
    dispatch(fatcaCrs.updateFatcaCrsInfo(info))
  },
  updateAmendments: (amendmentsItems) => {
    dispatch(amendments.updateAmendments(amendmentsItems))
  }
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(CheckStatus)));
