import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './style.scss';
import { Getter } from '../../services/formHelper';

const withCustomAlert = (WrappedComponent) => {

    return class extends Component {

        onDimissCallback = null;
        
        constructor(props) {
            super(props);
            this.state = {
                isOpen: false,
                text: null,
                extraButton: null,
                header:'Alert'
            }
        }

        showAlert = (input, extraButton = null, header='Alert', onDismiss = () => {} ) => {
            let message = (input instanceof Error) ? input.message : (typeof input === 'object') ? JSON.stringify(input) : input;
            this.setState({
                isOpen: true,
                text: Getter.getPureErrorMessage(message),
                extraButton,
                header
            })
            this.onDimissCallback = onDismiss;
        }

        onDismiss = () => {
            this.setState({
                isOpen: false,
                text: null,
                extraButton: null
            })

            if(this.onDimissCallback && typeof this.onDimissCallback === 'function')
                this.onDimissCallback()
        }

        render() {
            return (
                <React.Fragment>
                    <Modal className="error-modal" isOpen={this.state.isOpen} toggle={this.onDismiss}>
                        <ModalHeader toggle={this.onDismiss}>{this.state.header}</ModalHeader>
                        <ModalBody>
                            <div className="error-box">
                                <div className="icon">
                                    <img alt="exclamation icon" src={require('../../assets/img/exclamation-circle-info.png')} />
                                </div>
                                <span>{this.state.text}</span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {
                                this.state.extraButton ? (
                                    <>
                                        <Button className="back-btn" onClick={this.onDismiss}>CLOSE</Button>
                                        { this.state.extraButton }
                                    </>
                                ) : (<Button color="primary" onClick={this.onDismiss}>OK</Button>)
                            }
                        </ModalFooter>
                    </Modal>
                    <WrappedComponent alert={this.showAlert} dismissAlert={this.onDismiss} {...this.props} />
                </React.Fragment>
            )
        }
    }
}

export default withCustomAlert;