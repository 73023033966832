import { store } from '../../App';
import { Replace } from '../formHelper';
import * as ImageCompressor from '../imageCompressor';

export const genInitEformPayload = () => {
    let checkEligibility = store.getState().eligibility;
    let calculator = store.getState().calculator;
    let state = checkEligibility.country.value === 'MY' ? checkEligibility.state.value : checkEligibility.foreignState;
    let companyState = checkEligibility.companyCountry.value === 'MY' ? checkEligibility.companyLocalState.value : checkEligibility.companyForeignState;
    return {
        loanAmount: calculator.loanAmount,
        sellingAmount: calculator.loanAmount,
        loanTenure: parseInt(calculator.selectedTenure.value),
        loanMonthlyInstallment: parseFloat(calculator.monthlyInstallment),
        loanInterestRate: parseFloat(calculator.interestRate),
        isIslamic: calculator.selectedLoanType.value === '1' ? false : true,
        fullName: checkEligibility.name,
        nricNo: Replace.replaceAllExceptNumber(calculator.icNo),
        email: checkEligibility.email,
        bumiStatus: checkEligibility.bumi.value,
        race: checkEligibility.race.value,
        address1: checkEligibility.address,
        address2: checkEligibility.address2,
        country: checkEligibility.country.value,
        state: state,
        city: checkEligibility.city,
        postcode: checkEligibility.postcode,
        employmentCompanyName: checkEligibility.company,
        employmentType: calculator.selectedEmploymentType.value,
        employmentOccupationSector: checkEligibility.occupationSector.value,
        employmentOccupation: checkEligibility.occupation.value,
        employmentNatureOfBusinessSector: checkEligibility.businessSector.value,
        employmentNatureOfBusiness: checkEligibility.natureBusiness.value,
        monthlyGrossIncome: calculator.grossMonthlyIncome,
        ...checkEligibility.monthlyNetIncome ? { monthlyNettIncome: parseInt(Replace.replaceAllExceptNumber(checkEligibility.monthlyNetIncome)) } : {},
        mobileNo: Replace.replaceDash(checkEligibility.mobile),
        isPdpaConsent: checkEligibility.isAcceptedPDPDConsent,
        gender: checkEligibility.gender.value,
        maritalStatus: checkEligibility.maritalStatus.value,
        education: checkEligibility.educationLevel.value,
        isAcceptInsurance: checkEligibility.insurancePremium.value,
        nationality: checkEligibility.nationality.value,
        chosenDocument: calculator.selectedDoc.value,
        ...calculator.ecid?{ecid:calculator.ecid}:{},
        ...calculator.xuid?{xuid:calculator.xuid}:{},
        ...calculator.cmsid?{cmsid:calculator.cmsid}:{},
        employmentCompanyAddress1: checkEligibility.companyAddress,
        employmentCompanyAddress2: checkEligibility.companyAddress2,
        employmentCompanyPostcode: checkEligibility.companyPostcode,
        employmentCompanyCity: checkEligibility.companyCity,
        employmentCompanyState: companyState,
        employmentCompanyCountry: checkEligibility.companyCountry.value,
        preferredMailingAddress: checkEligibility.preferredMailingAddress.value,
        awCustomer: checkEligibility.isAW,
        isCashbackCampaign: calculator.isCashBackCampaign,
        cashback: calculator.cashBack,
        totalCashback: calculator.totalCashBack,
        installmentAfterCashback: calculator.installmentAfterCashBack,
        otherNonBankDebt: checkEligibility.nonBankDebtAmt,
    }
}

export const genUpdateInitialLoanOfferPayload = () => {
    let calculator = store.getState().calculator;
    let initialLoanOffer = store.getState().initialLoanOffer;
    return {
        loanAmount: initialLoanOffer.selectedLoanAmount,
        sellingAmount: initialLoanOffer.selectedSellingAmount,
        loanTenure: initialLoanOffer.selectedTenure,
        loanMonthlyInstallment: !!calculator.isAipOff ? calculator.monthlyInstallment : initialLoanOffer.selectedMonthlyInstallmentAmount,
        loanInterestRate: !!calculator.isAipOff ? calculator.interestRate: initialLoanOffer.selectedInterestRate,
        loanStampDuty: initialLoanOffer.selectedStampDuty,
        loanInsuranceAmount: initialLoanOffer.selectedInsuranceAmount,
        installmentAfterCashback: !!calculator.isAipOff ? calculator.installmentAfterCashBack: initialLoanOffer.selectedInstallmentAfterCashBack,
        cashback: !!calculator.isAipOff ? calculator.cashBack :initialLoanOffer.selectedCashBack,
        totalCashback: !!calculator.isAipOff ? calculator.totalCashBack : initialLoanOffer.selectedTotalCashBack,
    }
}

export const genUpdateAboutYourselfPayload = () => {
    let yourself = store.getState().moreAboutYourself;
    return {
        residenceType: yourself.residenceType.value,
        residenceLengthOfStay: parseInt(yourself.lengthOfStay.value),
        residenceStatus: yourself.residenceStatus.value,
        // residenceStatus: '0',
        purposeOfBorrowing: yourself.purposeOfBorrowing.value,
        // purposeOfBorrowing: 'PU',
        sourceOfFund: yourself.sourceOfFund.value,
        sourceOfWealth: yourself.sourceOfWealth.value,
        // ...yourself.otherSourceOfFund ? { otherSourceOfFund: yourself.otherSourceOfFund } : {}, // need backend add on
        // ...yourself.otherSourceOfWealth ? { otherSourceOfWealth: yourself.otherSourceOfWealth } : {} // need backend add on
    }
}

export const genUpdateEmploymentDetailsPayload = () => {
    let yourCompany = store.getState().employmentDetails;
    let yourEligibility = store.getState().eligibility;
    let companyState = yourEligibility.companyCountry.value === 'MY' ? yourEligibility.companyLocalState.value : yourEligibility.companyForeignState;
    let version = store.getState().eligibility.companyAddressVersion;

    return {
        employmentCompanyName: yourCompany.currentEmployer,
        employmentJoinedDate: yourCompany.dateJoinedCompany,
        employmentCompanyTelNo: Replace.replaceSpace(yourCompany.companyTel),
        employmentCompanyEmail: yourCompany.companyEmail,
        ...version === 1 ? { employmentCompanyAddress1: yourEligibility.companyAddress } : {},
        ...version === 1 ? { employmentCompanyAddress2: yourEligibility.companyAddress2 } : {},
        ...version === 1 ? { employmentCompanyPostcode: yourEligibility.companyPostcode } : {},
        ...version === 1 ? { employmentCompanyCity: yourEligibility.companyCity } : {},
        ...version === 1 ? { employmentCompanyState: companyState } : {},
        ...version === 1 ? { employmentCompanyCountry: yourEligibility.companyCountry.value } : {}
    }
}

export const genUpdateEmergencyContactPayload = () => {
    let emergencyContact = store.getState().emergencyContact;
    return {
        emergencyContactName: emergencyContact.emergencyContactName,
        emergencyContactRelationship: emergencyContact.emergencyContactRelationship.value,
        emergencyContactMobileNumber: emergencyContact.emergencyContactMobile,
        ...emergencyContact.spouseName ? { spouseName: emergencyContact.spouseName } : {},
        ...emergencyContact.spouseCompanyName ? { spouseCompanyName: emergencyContact.spouseCompanyName } : {},
        ...emergencyContact.spouseMobile ? { spouseMobileNumber: emergencyContact.spouseMobile } : {}
    }
}

export const genUpdatePreferredBankPayload = () => {
    let preferredBank = store.getState().preferredBank;
    return {
        disbursementBank: preferredBank.preferredBank.value,
        disbursementBankAccountNumber: preferredBank.bankAccountNumber,
    }
}

export const genUpdateFatcaCrsPayload = () => {
    let fatcaCrs = store.getState().fatcaCrs;
    return {
        isFatca: fatcaCrs.fatcaDeclaration.value,
        fatcaCountryBirth: fatcaCrs.fatcaCountryBirth.value,
        // crsFlag: fatcaCrs.taxResidence.value,
    }
}

export const genCrsTinPayload = (tinItem) => {
    return {
        taxJurisdictionCountry: tinItem.taxJurisCountry.value,
        tinNo: tinItem.tinNumber,
        reason: tinItem.reasonOfNoTin.value,
        otherReason: tinItem.noTinOtherReason
    }
}

export const genAttachmentPayload = async (attachment) => {
    return {
        label: attachment.label,
        fileType: attachment.file.type,
        fileName: attachment.name,
        content: attachment.base64,
        key: attachment.key
    }
}

export const genRequestTacPayloadByMobileNo = (phone, icNo, username, email) => {
    return {
        mobileNo: Replace.replaceDash(phone),
        transactionTypeCode: "VERI_OTP"
    }
}

export const genRequestTacPayloadByIcNo = (icNo) => {
    return {
        idNo: Replace.replaceAllExceptNumber(icNo),
        transactionTypeCode: "VERI_OTP"
    }
}

export const genValidateTacPayloadByMobileNo = (phone, icNo, username, email, tac, otpUuid) => {

    return {
        tac,
        mobileNo: Replace.replaceDash(phone),
        transactionTypeCode: "VERI_OTP",
        uuid: otpUuid
    }
}

export const genValidateTacPayloadByIcNo = (icNo, tac, otpUuid) => {

    return {
        tac,
        idNo: Replace.replaceAllExceptNumber(icNo),
        transactionTypeCode: "VERI_OTP",
        uuid: otpUuid
    }
}

export const genVerifySecurityAnswerPayload = (securityAnswerGroup) => {

    return {
        question: securityAnswerGroup
    }
}

export const genAmendmentFieldsPayload = (eformUuid = '', amendmentUuid = '', answerModel = {}) => {
    
    let amendmentFields = [];

    //TODO: send in attachment from answerModel
    let amendmentAttachments = [];

    if(Object.keys(answerModel).length > 0)
    {
        if(answerModel.fields){
            answerModel.fields.map(item => {
                amendmentFields.push({...item, fieldCategory:answerModel.fieldCategory})
            })
        }else{
            amendmentFields.push(answerModel)
        }
    }

    return { 
        eformUuid,
        amendmentUuid,
        amendmentFields,
        amendmentAttachments
    }
}

export const genNeedHelpCustomerInfoPayload = (fullName, mobileNo, email, screen) => {
    return {
        fullName,
        mobileNo: Replace.replaceDash(mobileNo),
        email,
        screen
    }
}

export const genGetSecurityQuestionPayload = () => {
    return {
        isAipOff: store.getState().calculator.isAipOff
    }
}