import React from 'react';
import { Card, CardText, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input";
import PropTypes from 'prop-types';
import { FormHelper} from '../../services';
import * as data from "../../data/globalData";

class Sst extends React.PureComponent {

    render() {
        const {
            sstRegNo
        } = this.props;

        return (
            <Card className="share-info-wrapper">
                <CardText className="share-info-desc">
                Do you have SST Registration Number?
                </CardText>
                <Row className="share-info-input-wrapper">
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="hasSstRegNo"
                                onClick={()=>{this.props.onChange(true)}}
                                className={`box-checker ${
                                    this.props.hasSstRegNo !== undefined && this.props.hasSstRegNo ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">YES</label>
                        </div>
                    </Col>
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="hasSstRegNo"
                                onClick={()=>{this.props.onChange(false)}}
                                className={`box-checker ${
                                    this.props.hasSstRegNo !== undefined && this.props.hasSstRegNo === false ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">NO</label>
                        </div>
                    </Col>
                </Row>
                { this.props.hasSstRegNo ?
                    <Row className="my-3 mb-responsive">
                        <Col className="mb-4">
                            <p> </p>
                            <Input
                            specialClass="field-input"
                            label="What is your SST Registration Number?"
                            name="sstRegNo"
                            isValidInput={FormHelper.Validation.isValidSst}
                            maxLength={17}
                            errText={`Please enter a valid SST (maximum of 17 characters, no special characters allowed, except for dash (-))`}
                            onChange={this.props.onSstNoChange}
                            placeholder="Enter SST Registration Number"
                            value={sstRegNo}
                            />
                        </Col>
                    </Row>
                    : (null)
                }
            </Card>
        );
    }

}

Sst.defaultProps = {
    onChange: (value) => value,
    hasSstRegNo: undefined,
}

Sst.propTypes = {
    onChange: PropTypes.func,
    hasSstRegNo: PropTypes.bool
}

export default Sst;