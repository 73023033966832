import { types } from '../actions/preferredBank';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    preferredBank: '', 
    bankAccountNumber: '',
    isNotJoinAccNo: ''
}

export default function preferredBank(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_PREFERRED_BANK_INFO:
        console.log("PREFERRED BANK::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_PREFERRED_BANK_INFO:
            return initialState;

        default:
            return state;
    }
}