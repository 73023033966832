import { types } from '../actions/calculator';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    loanAmount:0,
    selectedTenure: '',
    selectedLoanType: '',
    monthlyInstallment:0,
    interestRate:3.99,
    maxLoanAmountMapping:{
        SESB:150000,
        SESP:150000,
        GS:150000,
        PE:150000,
        CE:150000
    },
    maxAgeMapping:{
        SESB:65,
        SESP:65,
        GS:60,
        PE:60,
        CE:60
    },
    maxLoanAmount:150000,
    minLoanAmount:5000,
    tenureOptions: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
    ],
    tenureInterestMapping: {
        1: 3.99,
        2: 4.99,
        3: 5.99,
        4: 8.38,
        5: 8.38,
        6: 8.38,
        7: 8.38
    },
    minAgeEligibility:21,
    maxAgeEligibility:60,
    grossMonthlyIncome:0,
    minMonthlyIncome:0,
    icNo:'',
    // selectedEmploymentType:{ "value": "PE", "label": "Private Employee"},
    selectedEmploymentType:'',
    selectedDoc:{},
    isCurrentEmploymentSufficient:null,
    ecid:'',
    xuid:'',
    cmsid:'',
    isAipOff: false,
    isCashBackCampaign: false,
    cashBackTenureInterestMapping: '',
    installmentBeforeCashBack: 0,
    installmentAfterCashBack: 0,
    cashBack: 0,
    totalCashBack: 0,
}

export default function calculator(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_CALCULATOR_INFO:
        console.log("CALCULATOR::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_CALCULATOR_INFO:
            return initialState;

        default:
            return state;
    }
}