import React from 'react';
import "./style.scss";
import { FormHelper } from '../../services';
import * as data from "../../data/globalData";
import Declaration from "../Declaration";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { Page, Text, View, Document, StyleSheet, pdf, Image, Font, Link } from '@react-pdf/renderer';

Font.register({
  family:'Arial',
  fonts:[
    {
      src:require('../../assets/font/arial-normal.ttf')
    },
    {
      src:require('../../assets/font/arial-bold.ttf'),
      fontWeight:'bold'
    }
  ]
})

class LoanOfferTemplate extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showDeclarationModal: false,
      stampDuty: 0,
      interestRateCharged: 0,
      totalRepaymentAmount: 0,
    }
  }

  componentDidMount() {
    console.log(this.props);
    this.calculateNewStampDuty();
    this.calculateInterestCharged();
    this.calculateTotalRepaymentAmount();
  }

  onDeclarationClick = (event) => {
    event.preventDefault();
    this.setState({
      showDeclarationModal:true
    })
  }

  toogleDeclarationModal = () => {
    this.setState(prevState => ({
        showDeclarationModal: !prevState.showDeclarationModal
      })
    )
  }

  renderDeclarationModal = () => {
    return (
      <React.Fragment>
        <Modal className="share-permission-modal" isOpen={this.state.showDeclarationModal} toggle={this.toogleDeclarationModal}>
          <ModalHeader toggle={this.toogleDeclarationModal}><b>DECLARATION</b></ModalHeader>
          <ModalBody>
            <Declaration isEnableHyperlink={false} isIslamic={this.isIslamic()} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toogleDeclarationModal}>OK</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  };

  renderTinInfo = (tinInfo, key) => {
    return (
      <div class="tin-info">
        <div className="tin-info-item1">
          <div className="index">
            <b>{(key + 1).toString()} </b>
          </div>
          <div className="taxJuris">
            <b> Tax Jurisdiction : </b>
            {tinInfo.taxJurisCountry.label.toLowerCase()}
          </div>
          <div className="tinNo">
            <b>Tax Identification Number (TIN) : </b>
            {tinInfo.tinNumber ? tinInfo.tinNumber : '-'}
          </div>
        </div>
        {
          (tinInfo.reasonOfNoTin !== '') ? (

            <div className="reasonOfTin" >
              <b>Reason Of No TIN : </b>
              {tinInfo.reasonOfNoTin.label}
              {
                (tinInfo.noTinOtherReason !== '') ? (<> ({tinInfo.noTinOtherReason}) </>) : null
              }
            </div>
          ) : null
        }
      </div>
    )
  }

  isIslamic = () => {
    return this.props.calculator.selectedLoanType.value === '2';
  }

  haveInsurance = () => {
    return this.props.basicInfo.insurancePremium.value == true;
  }

  isEmptyTinArray = () => {
    const {
      tinArray
    } = this.props.fatcaCrs;

    if (tinArray.length < 0)
      return true;

    if (!tinArray[0].taxJurisCountry)
      return true;

    if (!tinArray[0].taxJurisCountry.value)
      return true;

    return false;
  }

  renderSignatureAndDate = (signature) => {
    let currentDate = new Date()
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    return (
      <div className="signature-paste-area">
        <div className="signature-area">
          <img src={signature} className="signature-signed-img" />
        </div>
        <div className="signature-date-area">
          <span className="date-text">{day < 10 ? `0${day}` : `${day}`}</span>
          <span className="date-text">{month < 10 ? `0${month}` : `${month}`}</span>
          <span className="date-text">{year}</span>
        </div>
      </div>
    );
  }

  renderSignatureAndDateForPDF = (signature) => {
    
    let currentDate = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    return(
      <View style={{ position:'absolute', top:50, right:0, width:300, height:300}}>
        <Image src={signature} style={{width:80, top:0, left: 150}}/>
         <View style={{position:'absolute', width:130, flexDirection:'row',top:50, left:175, justifyContent:'flex-end'}}>
          <Text style={{fontSize:12, marginHorizontal:8, letterSpacing:6}}>{day < 10 ? `0${day}` : `${day}`}</Text>
          <Text style={{fontSize:12, marginHorizontal:8, letterSpacing:6}}>{month < 10 ? `0${month}` : `${month}`}</Text>
          <Text style={{fontSize:12, marginHorizontal:8, letterSpacing:6}}>{year}</Text>
        </View>
      </View>
    )
  }

  getLoanTemplatePDFInBase64 = (signature) => {
    return pdf(this.renderLoanOfferPdf(signature)).toBlob().then(blob => {
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function(){
            let base64Data = reader.result;
            resolve(base64Data)
        }
      })
    }).catch(error => {
      return Promise.reject(error)
    })
  }

  calculateNewStampDuty = () => {
    const { loanAmount, isAipOff, isCashBackCampaign } = this.props.calculator;
    const { selectedLoanAmount } = this.props.initialLoanOffer;
    let newStampDuty;
    if(!!isCashBackCampaign) {
      if(!!isAipOff) {
        newStampDuty = FormHelper.Calculator.calculateStampDuty(loanAmount, 0.5);
      } else 
      {
        newStampDuty = FormHelper.Calculator.calculateStampDuty(selectedLoanAmount, 0.5);        
      }
    } else {
      newStampDuty = FormHelper.Calculator.calculateStampDuty(loanAmount, 0.5);
    }
    this.setState({ stampDuty: newStampDuty })
  }

  calculateInterestCharged = () => {
    const { loanAmount, isAipOff, isCashBackCampaign, interestRate } = this.props.calculator;
    const { selectedLoanAmount, selectedInterestRate } = this.props.initialLoanOffer;
    //this.props.calculator.selectedTenure, this.props.initialLoanOffer.selectedTenure;
    let interestRateCarged;
    if (!!isCashBackCampaign) {
      if (!!isAipOff) {
        interestRateCarged = FormHelper.Calculator.calculateInterestCharged(loanAmount, this.props.calculator.selectedTenure.value, interestRate );
      } else {
        interestRateCarged = FormHelper.Calculator.calculateInterestCharged(selectedLoanAmount, this.props.initialLoanOffer.selectedTenure, selectedInterestRate);
      }
    } else {
      interestRateCarged = FormHelper.Calculator.calculateInterestCharged(loanAmount, this.props.calculator.selectedTenure.value, interestRate);
    }
    this.setState({ interestRateCharged: interestRateCarged })
  }

  calculateTotalRepaymentAmount = () => {
    const { loanAmount, isAipOff, isCashBackCampaign, interestRate } = this.props.calculator;
    const { selectedLoanAmount, selectedInterestRate } = this.props.initialLoanOffer;
    //this.props.calculator.selectedTenure, this.props.initialLoanOffer.selectedTenure;
    let totalRepaymentAmount;
    if (!!isCashBackCampaign) {
      if (!!isAipOff) {
        totalRepaymentAmount = FormHelper.Calculator.calculateTotalRepaymentAmount(loanAmount, this.props.calculator.selectedTenure.value, interestRate );
      } else {
        totalRepaymentAmount = FormHelper.Calculator.calculateTotalRepaymentAmount(selectedLoanAmount, this.props.initialLoanOffer.selectedTenure, selectedInterestRate);
      }
    } else {
      totalRepaymentAmount = FormHelper.Calculator.calculateTotalRepaymentAmount(loanAmount, this.props.calculator.selectedTenure.value, interestRate);
    }
    this.setState({ totalRepaymentAmount: totalRepaymentAmount })
  }

  renderLoanOfferPdf = (signature) => {
    const {
      name, gender, email, bumi, race, address, address2, country, state, foreignState,
      city, postcode, company, occupationSector, occupation, nationality, businessSector,
      natureBusiness, mobile, isAcceptedPDPDConsent, maritalStatus, educationLevel, preferredMailingAddress,
      companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState, companyForeignState,
      companyCountry, companyAddressVersion, nonBankDebtAmt
    } = this.props.basicInfo;

    const {
      selectedLoanType, icNo, selectedEmploymentType, isAipOff, loanAmount, interestRate, monthlyInstallment, isCashBackCampaign
    } = this.props.calculator;

    const {
      lengthOfStay, residenceStatus, purposeOfBorrowing,
      sourceOfFund, sourceOfWealth
    } = this.props.moreAboutYourself;

    const {
      dateJoinedCompany, companyTel, companyEmail
    } = this.props.employmentDetails;

    const {
      emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
      spouseName, spouseCompanyName, spouseMobile
    } = this.props.emergencyContact;

    const {
      preferredBank, bankAccountNumber
    } = this.props.disbursementBank;

    const {
      fatcaDeclaration, taxResidence, tinArray
    } = this.props.fatcaCrs;

    const {
      selectedInsuranceAmount, selectedLoanAmount, selectedMonthlyInstallmentAmount,
      selectedStampDuty, selectedSellingAmount, selectedInterestRate, installmentAfterCashback, cashBack, totalCashBack
    } = this.props.initialLoanOffer;

    const tncUrl = window.location.origin + `${this.isIslamic() ? data.docPath.tnc_islamic : data.docPath.tnc}`;
    const pdsUrl = window.location.origin + `${this.isIslamic() ? data.docPath.pds_islamic : data.docPath.pds}`;
  
    return (
      <Document>
      <Page size="A4" style={styles.page}>

        <View id="banner" style={{flexDirection:'row'}}>
          <View style={{flex:1, flexDirection:'row'}}> 
            <Text style={{fontWeight:'bold', fontSize:15, fontFamily:'Arial'}}>{ !!isAipOff ? 'FINANCING APPLICATION' : 'FINANCING OFFER' }</Text><Text style={{color:'red',fontWeight: 600,fontSize:16}}>*</Text>
          </View>
          <View style={{align:'right', display:'flex', justifyContent:'flex-end', flex:1, alignItems:'center', flexDirection:'row'}}>
            <Image src={require("../../assets/img/logo.png")} style={{width:100, marginTop:0, marginRight:5, marginBottom:5, marginLeft:0}}></Image>
            <Text style={{fontSize:10, color:'black', opacity:'0.9'}}>| Personal Financing</Text>
          </View>
        </View>

        <View style={styles.loanSummaryContainer}>
          
          <View style={styles.loanSummaryItem}>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryLabel}>{this.isIslamic() ? 'FINANCING' : 'LOAN'} AMOUNT</Text><Text style={{color:'red',fontWeight: 600,fontSize:10}}>*</Text>
            </View>
            <View style={{flex:1}}>
              <Text style={styles.loanSummaryKey}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(loanAmount).toFixed(2)) : selectedLoanAmount ? FormHelper.thousandSeparator(Number(selectedLoanAmount).toFixed(2)) : 0} </Text>
            </View>
          </View>

        {!!isCashBackCampaign == true ? (
          <>
          <View style={styles.loanSummaryItem}>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryLabelCompact}>MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} BEFORE CASH BACK</Text><Text style={{color:'red',fontWeight: 600,fontSize:10}}>*</Text>
            </View>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryKeyCompact}>RM {isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</Text>
            </View>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryLabelCompact}>MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} AFTER CASH BACK</Text><Text style={{color:'red',fontWeight: 600,fontSize:10}}>*</Text>
            </View>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryKeyCompact}>RM {FormHelper.thousandSeparator(Number(installmentAfterCashback).toFixed(2))}</Text>
            </View>
          </View>
          </>
        ) : (
          <>
          <View style={styles.loanSummaryItem}>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryLabel}>MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'}</Text><Text style={{color:'red',fontWeight: 600,fontSize:10}}>*</Text>
            </View>
            <View style={{flex:1}}>
              <Text style={styles.loanSummaryKey}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</Text>
            </View>
          </View>
          </>
        )}
          

          <View style={styles.loanSummaryItem}>
            <View style={{flex:1, flexDirection:'row'}}>
              <Text style={styles.loanSummaryLabel}>{this.isIslamic() ? 'PROFIT' : 'INTEREST'} RATE</Text><Text style={{color:'red',fontWeight: 600,fontSize:10}}>*</Text>
            </View>
            <View style={{flex:1}}>
              <Text style={styles.loanSummaryKey}>{!!isAipOff ? interestRate.toFixed(2) : selectedLoanAmount ? selectedInterestRate.toFixed(2) : 0} %</Text>
            </View>
              <Text style={styles.loanSummaryLabel}>FLAT RATE P.A OVER {!!isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure} {(!!isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure) > 1 ? "YEARS" : "YEAR"}</Text>
          </View>

          {this.isIslamic() ?
            <View style={styles.loanSummaryItem}>
              <View style={{flex:1, flexDirection:'row'}}>
                <Text style={{fontSize:8}}>SELLING PRICE</Text><Text style={{color:'red',fontWeight: 600,fontSize:10}}>*</Text>
              </View>
              <View style={{flex:1}}>
                <Text style={styles.loanSummaryKey}>RM {FormHelper.thousandSeparator(Number(selectedSellingAmount).toFixed(2))}</Text>
              </View>
            </View>:null
          }

        </View>

        <View>
          <Text style={{fontSize:7, fontWeight:'bold', fontFamily:'Arial'}}>Disclaimer:</Text>
          <View syle={{flexDirection:'row', backgroundColor:'red', height:100, flexWrap:'wrap'}}>
            <Text style={{color:'red',fontSize:7}}>*</Text><Text style={{paddingRight:5, fontSize:7, position:'absolute', left:5}}>{data.disclamerDesc}</Text>
          </View>
        </View>

        <View style={styles.section}>
          
          <View style={{flexDirection:'row', marginBottom:10}}>
            <Image src={require('../../assets/img/rectangle.png')} style={{size:8}}/>
            <Text style={styles.sectionHeaderLabel}>PERSONAL INFORMATION</Text>
          </View>

          <View style={styles.sectionBody}>
            
           <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Full Name (as per IC)</Text>
                  <Text style={styles.val}>{name.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Bumi-Status</Text>
                  <Text style={styles.val}>{bumi.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>  
                  <Text style={styles.key}>Mobile No</Text>
                  <Text style={styles.val}>{mobile.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Race</Text>
                  <Text style={styles.val}>{race.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>NRIC Number</Text>
                  <Text style={styles.val}>{icNo.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Marital Status</Text>
                  <Text style={styles.val}>{maritalStatus.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Email</Text>
                  <Text style={styles.val}>{email.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Education</Text>
                  <Text style={styles.val}>{educationLevel.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Gender</Text>
                  <Text style={styles.val}>{gender.label.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Nationality</Text>
                  <Text style={styles.val}>{nationality.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Home Address</Text>
                  <Text style={styles.val}>
                    {`${address + (address2 ? ' '+ address2 : '')}`.toUpperCase()}
                  </Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Residency Status</Text>
                  <Text style={styles.val}>{residenceStatus.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Country</Text>
                  <Text style={styles.val}>{country.label.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Length of Stay</Text>
                  <Text style={styles.val}>{lengthOfStay.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>State</Text>
                  <Text style={styles.val}>{country.value === 'MY' ? `${state.label.toLowerCase()}`.toUpperCase() : foreignState.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Source of Fund</Text>
                  <Text style={styles.val}>{sourceOfFund.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>City</Text>
                  <Text style={styles.val}>{city.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Source of Wealth</Text>
                  <Text style={styles.val}>{sourceOfWealth.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Postcode</Text>
                  <Text style={styles.val}>{postcode.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>{preferredMailingAddress ? "Preferred Mailing Address" : "" }</Text>
                  <Text style={styles.val}>{preferredMailingAddress ? (companyAddressVersion === 1 ? 'HOME ADDRESS' : preferredMailingAddress.label.toUpperCase()) : ""}</Text>
              </View> 
                
            </View>
          
          </View> 
        </View>

        <View style={{flexDirection:'column', marginTop:10}}>
          <Text style={{paddingRight:5, fontSize:7, left:5}}>
            Thanks for choosing Alliance Bank again! Kindly note that Notice of Disbursement for current loan applied will be sent to the permanent address, which you have registered with the bank previously.
            For more information, please contact Customer Service at 03-5516 9988.
          </Text>
        </View>

        <View style={styles.section}>
          
          <View style={{flexDirection:'row', marginBottom:10}}>
            <Image src={require('../../assets/img/rectangle.png')} style={{size:8}}/>
            <Text style={styles.sectionHeaderLabel}>EMPLOYMENT</Text>
          </View>

          <View style={styles.sectionBody}>
            
           <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Company Name</Text>
                  <Text style={styles.val}>{company.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Employment Type</Text>
                  <Text style={styles.val}>{selectedEmploymentType.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>  
                  <Text style={styles.key}>Company Address</Text>
                  <Text style={styles.val}>{
                    ` ${companyAddress}${companyAddress2 ? ' ' + companyAddress2 : ''}${companyPostcode ? ' ' + companyPostcode : ''} ${' ' + companyCity}${companyCountry.value === 'MY' ? ' ' + companyLocalState.label.toLowerCase() : ' ' + companyForeignState}${' ' + companyCountry.label.toLowerCase()}`.toUpperCase()
                    }
                </Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Occupation</Text>
                  <Text style={styles.val}>{occupation.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Date Joined</Text>
                  <Text style={styles.val}>{dateJoinedCompany.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Occupation Sector</Text>
                  <Text style={styles.val}>{occupationSector.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Nature of Business Sector</Text>
                  <Text style={styles.val}>{businessSector.label.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Nature of Business</Text>
                  <Text style={styles.val}>{natureBusiness.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Company Email</Text>
                  <Text style={styles.val}>{companyEmail.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Company Tel</Text>
                  <Text style={styles.val}>{companyTel.toUpperCase()}</Text>
              </View>
            </View>

          </View> 
        </View>

        <View style={styles.section}>
          
          <View style={{flexDirection:'row', marginBottom:10}}>
            <Image src={require('../../assets/img/rectangle.png')} style={{size:8}}/>
            <Text style={styles.sectionHeaderLabel}>EMERGENCY & SPOUSE CONTACT</Text>
          </View>

          <View style={styles.sectionBody}>
            
           <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Name (Emergency)</Text>
                  <Text style={styles.val}>{emergencyContactName.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Relationship (Emergency)</Text>
                  <Text style={styles.val}>{emergencyContactRelationship.label.toUpperCase()}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>  
                  <Text style={styles.key}>Mobile No. (Emergency)</Text>
                  <Text style={styles.val}>{emergencyContactMobile}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}></Text>
                  <Text style={styles.val}></Text>
              </View>
            </View>

            {/* {
              maritalStatus.value === 'M' ? (
                <View style={styles.sectionBodyRow}>
                  <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}>Name (Spouse</Text>
                      <Text style={styles.val}>{spouseName.toUpperCase()}</Text>
                  </View>
                  {
                    spouseCompanyName?(
                      <View style={styles.sectionBodyRowItem}>
                        <Text style={styles.key}>Company Name (Spouse)</Text>
                        <Text style={styles.val}>{spouseCompanyName.toUpperCase()}</Text>
                      </View>
                    ):null
                  }
                 
                </View>):null
            }
            {
              maritalStatus.value==='M'?(
                <View style={styles.sectionBodyRow}>
                  <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}>Mobile No. (Spouse)</Text>
                      <Text style={styles.val}>{spouseMobile.toUpperCase()}</Text>
                  </View>
                  <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}></Text>
                      <Text style={styles.val}></Text>
                  </View>
                </View>
              ):null
            }
             */}
          </View> 
        </View>

        <View style={styles.section}>
          
          <View style={{flexDirection:'row', marginBottom:10}}>
            <Image src={require('../../assets/img/rectangle.png')} style={{size:8}}/>
            <Text style={styles.sectionHeaderLabel}>DISBURSEMENT</Text>
          </View>

          <View style={styles.sectionBody}>
            
           <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Prefered Bank</Text>
                  <Text style={styles.val}>{preferredBank.label.toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Bank Account No</Text>
                  <Text style={styles.val}>{bankAccountNumber.toUpperCase()}</Text>
              </View>
            </View>
   
          </View> 
        </View>

        <View style={styles.section}>
          
          <View style={{flexDirection:'row', marginBottom:10}}>
            <Image src={require('../../assets/img/rectangle.png')} style={{size:8}}/>
            <Text style={styles.sectionHeaderLabel}>FINANCING</Text>
          </View>

          <View style={styles.sectionBody}>
            
           <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Financing Type</Text>
                  <Text style={styles.val}>{(this.isIslamic() ? 'CashVantage Personal Financing-i' : 'CashFirst Personal Loan').toUpperCase()}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Financing Amount</Text>
                  <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(loanAmount).toFixed(2)) : selectedLoanAmount ? FormHelper.thousandSeparator(Number(selectedLoanAmount).toFixed(2)) : 0}</Text>
              </View>
            </View>

            <View style={styles.sectionBodyRow}>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Tenure</Text>
                  <Text style={styles.val}>{!!isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure} {(!!isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure) > 1 ? "YEARS" : "YEAR"}</Text>
              </View>
              <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Stamp Duty</Text>
                  <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.state.stampDuty).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedStampDuty).toFixed(2))}</Text>
              </View>
            </View>
            
            {!!isCashBackCampaign ? (
              <>
              <View style={styles.sectionBodyRow}>
                <View style={styles.sectionBodyRowItem}>
                    <Text style={styles.key}>{this.isIslamic() ? 'Profit' : 'Interest'} Rate</Text>
                    <Text style={styles.val}>{`${!!isAipOff ? interestRate.toFixed(2) : selectedLoanAmount ? selectedInterestRate.toFixed(2) : 0} % FLAT RATE P.A`.toUpperCase()}</Text>
                </View>
                <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>{this.isIslamic() ? 'Profit Payable' : 'Interest Rate Charged'}</Text>
                  <Text style={styles.val}>RM {FormHelper.thousandSeparator(Number(this.state.interestRateCharged).toFixed(2))}</Text>
                </View>
              </View>
              </>
            ) : null}
            {!!isCashBackCampaign ? (
              <>
              <View style={styles.sectionBodyRow}>
                <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>{this.isIslamic() ? 'Selling Price': 'Total Repayment Amount'}</Text>
                  <Text style={styles.val}>RM {FormHelper.thousandSeparator(Number(this.state.totalRepaymentAmount).toFixed(2))}</Text>
                </View>
                <View style={styles.sectionBodyRowItem}>
                    <Text style={styles.key}>Total Cash Back Amount</Text>
                    <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.totalCashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(totalCashBack).toFixed(2))}</Text>
                </View>
              </View>
              </>) : null}
            <View style={styles.sectionBodyRow}>
              {
                !!isCashBackCampaign ? (
                  <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}>Monthly Payment Amount Before Cash Back</Text>
                      <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</Text>
                  </View>) : (
                  <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'}</Text>
                      <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</Text>
                  </View>
                )
              }
              {!!isCashBackCampaign ? (
                <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>Cash Back (During Eligible Months)</Text>
                  <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.cashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(cashBack).toFixed(2))} per month</Text>
                </View>) : 
                <View style={styles.sectionBodyRowItem}>
                  <Text style={styles.key}>{this.isIslamic() ? 'Profit' : 'Interest'} Rate</Text>
                  <Text style={styles.val}>{`${!!isAipOff ? interestRate.toFixed(2) : selectedLoanAmount ? selectedInterestRate.toFixed(2) : 0} % FLAT RATE P.A`.toUpperCase()}</Text>
                </View>
              }
            </View>
            {
              !!isCashBackCampaign ? (
                <>
                  <View style={styles.sectionBodyRow}>

                    <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} Amount After Cash Back</Text>
                      <Text style={styles.val}>RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.installmentAfterCashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(installmentAfterCashback).toFixed(2))}</Text>
                    </View>
                    <View style={styles.sectionBodyRowItem}>
                      <Text style={styles.key}></Text>
                      <Text style={styles.val}></Text>
                    </View>

                  </View>
                </>
              ) : null
            }
            {
              this.haveInsurance() && !!isCashBackCampaign == false ?
                (
                  <View style={styles.sectionBodyRow}>
                    <View style={styles.sectionBodyRowItem}>
                        <Text style={styles.key}>Insurance Premium</Text>
                        <Text style={styles.val}>RM {FormHelper.thousandSeparator(Number(selectedInsuranceAmount).toFixed(2))}</Text>
                    </View>
                    <View style={styles.sectionBodyRowItem}>
                        <Text style={styles.key}></Text>
                        <Text style={styles.val}></Text>
                    </View>
                  </View>
                ) : null
            }
          </View> 
        </View>

        <View style={{flexDirection:'column', marginTop:5, borderTopWidth: 1, borderTopStyle:'solid', borderTopColor:'#cccccc'}}>

            <View style={{paddingTop:10}}>
              <Text style={{fontSize:8, flex:1}}> I agree to 
                <Link src={tncUrl} style={{color:'red'}}> Terms and Conditions</Link> , the
                <Link src={pdsUrl} style={{color:'red'}}> Product Disclosure Sheet</Link>  and
                <Link style={{color:'red'}}> Declaration </Link>
                 and fully understand that disbursement of the financing amount is subject to Bank’s final approval *.
              </Text>
            </View>

            <View style={{flexDirection:'row', marginTop:10}}>
              <Text style={styles.sectionHeaderLabel}>PDPA: </Text>
              <Text style={{fontSize:8}}>{isAcceptedPDPDConsent ? 'I provided consent.' : 'I do not provide consent.'}</Text>
            </View>
            
            <View style={{flexDirection:'row'}}>
              <Text style={styles.sectionHeaderLabel}>FATCA: </Text>
              <Text style={{fontSize:8}}>{fatcaDeclaration.value === true ? "I'm a U.S. Person." : "I'm not U.S. Person."}</Text>
            </View>

            <View style={{flexDirection:'row'}}>
              <Text style={styles.sectionHeaderLabel}>Tax Identification Number (TIN): </Text>
              <Text style={{fontSize:8}}>{!!tinArray[0].tinNumber ? tinArray[0].tinNumber : "I do not have a Malaysia Tax Identification Number."}</Text>
            </View>

            <View style={{flexDirection:'row'}}>
              <Text style={styles.sectionHeaderLabel}>SST Registration Number: </Text>
              <Text style={{fontSize:8}}>{!!tinArray[0].sstNumber ? tinArray[0].sstNumber : "I do not have a SST Registration Number."}</Text>
            </View>

            <View style={{flexDirection:'row'}}>
              <Text style={styles.sectionHeaderLabel}>Non-Bank Debt: </Text>
              <Text style={{fontSize:8}}>{!!nonBankDebtAmt ? "RM" + FormHelper.thousandSeparator(Number(nonBankDebtAmt).toFixed(2)) : "I do not have non-bank debt."}</Text>
            </View>

            {/* <View style={{flexDirection:'row', marginTop:10}}>
              <Text style={{fontSize:8}}>CRS: </Text>
              <Text style={{fontSize:8}}>{taxResidence.label}</Text>
            </View>
            Render TIN Info is not required at this moment */}

            <View style={{position:'absolute', right:0, top:40 }}>
              <Image src={require('../../assets/img/signature.png')} style={{width:200}}/>
            </View>

            {
                !!signature&&this.renderSignatureAndDateForPDF(signature)
            }
        
        </View>

      </Page>
    </Document>   
    )
  }

  render() {
    const {
      name, gender, email, bumi, race, address, address2, country, state, foreignState,
      city, postcode, company, occupationSector, occupation, nationality, businessSector,
      natureBusiness, mobile, isAcceptedPDPDConsent, maritalStatus, educationLevel, preferredMailingAddress,
      companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState, companyForeignState,
      companyCountry, companyAddressVersion, nonBankDebtAmt
    } = this.props.basicInfo;

    const {
      selectedLoanType, icNo, selectedEmploymentType, isAipOff, loanAmount, interestRate, monthlyInstallment, isCashBackCampaign
    } = this.props.calculator;

    const {
      lengthOfStay, residenceStatus, purposeOfBorrowing,
      sourceOfFund, sourceOfWealth
    } = this.props.moreAboutYourself;

    const {
      dateJoinedCompany, companyTel, companyEmail
    } = this.props.employmentDetails;

    const {
      emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
      spouseName, spouseCompanyName, spouseMobile
    } = this.props.emergencyContact;

    const {
      preferredBank, bankAccountNumber
    } = this.props.disbursementBank;

    const {
      fatcaDeclaration, taxResidence, tinArray
    } = this.props.fatcaCrs;

    const {
      selectedInsuranceAmount, selectedLoanAmount, selectedMonthlyInstallmentAmount,
      selectedStampDuty, selectedSellingAmount, selectedInterestRate, installmentAfterCashback, cashBack,totalCashBack
    } = this.props.initialLoanOffer;

    const { signature, id } = this.props;

    return (
      <React.Fragment>
        <div className="loan-offer-template-container" id={id}>
          <div id="banner">
            <div>FINANCING {!!isAipOff ? "APPLICATION" : "OFFER" }<strong class="starIcon">*</strong></div>
            <div align="right" className="alliance-logo">
              <img src={require("../../assets/img/logo.png")} alt="Alliance Bank" />
              <span>| Personal Financing</span>
            </div>
          </div>
          <br />

          <div id="loan-summary">
            <div className="loan-summary-item">
              <div className="box-label">{this.isIslamic() ? 'FINANCING' : 'LOAN'} AMOUNT <strong className="starIcon">*</strong></div>
              <div className="box-value">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(loanAmount).toFixed(2)) : selectedLoanAmount ? FormHelper.thousandSeparator(Number(selectedLoanAmount).toFixed(2)) : 0}</div>
            </div>
            {
                !!isCashBackCampaign ? (this.isIslamic() ? (
                  <>
                  <div className="loan-summary-item">
                    <div className="box-label-compact-i">MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} BEFORE CASHBACK<strong className="starIcon"> *</strong> </div>
                    <div className="box-value-compact">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</div>
                    <div className="box-label-compact-i">MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} AFTER CASHBACK<strong className="starIcon"> *</strong> </div>
                    <div className="box-value-compact">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.installmentAfterCashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(installmentAfterCashback).toFixed(2))}</div>
                  </div>
                  </>
                ) : (
                  <>
                  <div className="loan-summary-item">
                    <div className="box-label-compact">MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} BEFORE CASHBACK<strong className="starIcon"> *</strong> </div>
                    <div className="box-value-compact">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</div>
                    <div className="box-label-compact">MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} AFTER CASHBACK<strong className="starIcon"> *</strong> </div>
                    <div className="box-value-compact">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.installmentAfterCashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(installmentAfterCashback).toFixed(2))}</div>
                  </div>
                  </>
                ))
                 : (
                  <div className="loan-summary-item">
                    <div className="box-label">MONTHLY {this.isIslamic() ? 'PAYMENT' : 'REPAYMENT'} <strong className="starIcon">*</strong></div>
                    <div className="box-value">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</div>
                    <div />
                    <div />
                  </div>
                )
            }
            <div className="loan-summary-item">
              <div className="box-label">{this.isIslamic() ? 'PROFIT' : 'INTEREST'} RATE <strong className="starIcon">*</strong></div>
              <div className="box-value">{!!isAipOff ? interestRate.toFixed(2) : selectedLoanAmount ? selectedInterestRate.toFixed(2) : 0} %</div>
              <div className="box-label">
                FLAT RATE P.A OVER {!!isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure} {(!!isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure) > 1 ? "YEARS" : "YEAR"}
              </div>
            </div>
            {this.isIslamic() ?
              (<div className="loan-summary-item">
                <div className="box-label">SELLING PRICE <strong className="starIcon">*</strong></div>
                <div className="box-value">RM {FormHelper.thousandSeparator(Number(selectedSellingAmount).toFixed(2))}</div>
              </div>) : null
            }

          </div>
          <span className="disclaimer-label">Disclaimer:</span>
          <div className="disclaimer-desc">
            <strong style={{ color: "#FF0000" }}>* </strong>{data.disclamerDesc}
            <br /><br />
          </div>

          <div className="section-title">
            <h1><span><img src={require('../../assets/img/rectangle.png')} /></span> PERSONAL INFORMATION</h1>
          </div>

          <div className="section-body">
          <div className="section-body-row">
            <div className="key">Full Name (as per IC)</div>
            <div className="val">{name.toUpperCase()}</div>
            <div className="key">Bumi-Status</div>
            <div className="val">{bumi.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Mobile No</div>
            <div className="val">{mobile}</div>
            <div className="key">Race</div>
            <div className="val">{race.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">NRIC Number</div>
            <div className="val">{icNo}</div>
            <div className="key">Marital Status</div>
            <div className="val">{maritalStatus.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Email</div>
            <div className="val noApplyTextTransform">{email.toUpperCase()}</div>
            <div className="key">Education</div>
            <div className="val">{educationLevel.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Gender <br /></div>
            <div className="val">{gender.label.toUpperCase()}</div>
            <div className="key">Nationality</div>
            <div className="val">{nationality.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Home Address</div>
            <div className="val">
              {address.toUpperCase()}
              {address2 ? ' ' + address2.toUpperCase() : ''}
            </div>
            <div className="key">Residency Status</div>
            <div className="val">{residenceStatus.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Country</div>
            <div className="val">{country.label.toUpperCase()}</div>
            <div className="key">Length of Stay</div>
            <div className="val">{lengthOfStay.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">State</div>
            <div className="val">{country.value === 'MY' ? state.label.toUpperCase() : foreignState.toUpperCase()}</div>
            <div className="key">Source of Fund</div>
            <div className="val">{sourceOfFund.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">City</div>
            <div className="val">{city.toUpperCase()}</div>
            <div className="key">Source of Wealth</div>
            <div className="val">{sourceOfWealth.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Postcode</div>
            <div className="val">{postcode.toUpperCase()}</div>
            {preferredMailingAddress ? 
            <>
            <div className="key">Preferred Mailing Address</div>
            <div className="val">{companyAddressVersion === 1 ? 'HOME ADDRESS' : preferredMailingAddress.label.toUpperCase()}</div>
            </> : null
            }
          </div>

        </div>
        <br />
        <div className="disclaimer-desc">
          Thanks for choosing Alliance Bank again! Kindly note that Notice of Disbursement for current loan applied will be sent to the permanent address, which you have registered with the bank previously.
          For more information, please contact Customer Service at 03-5516 9988.
          <br /><br />
        </div>

          <div className="section-title">
            <h1><span><img src={require('../../assets/img/rectangle.png')} /></span> EMPLOYMENT</h1>
          </div>

          <div className="section-body">
          <div className="section-body-row">
            <div className="key">Company Name</div>
            <div className="val">{company.toUpperCase()}</div>
            <div className="key">Employment Type</div>
            <div className="val">{selectedEmploymentType.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Company Address</div>
            <div className="val">
              {companyAddress.toUpperCase()}
              {companyAddress2 ? ' ' + companyAddress2.toUpperCase() : ''}
              {companyPostcode ? ' ' + companyPostcode.toUpperCase() : ''}
              {' ' + companyCity.toUpperCase()}
              {companyCountry.value === 'MY' ? ' ' + companyLocalState.label.toUpperCase() : ' ' + companyForeignState.toUpperCase()}
              {' ' + companyCountry.label.toUpperCase()}
            </div>
            <div className="key">Occupation</div>
            <div className="val">{occupation.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Date Joined</div>
            <div className="val">{dateJoinedCompany.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}</div>
            <div className="key">Occupation Sector</div>
            <div className="val">{occupationSector.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Nature of Business Sector</div>
            <div className="val">{businessSector.label.toUpperCase()}</div>
            <div className="key">Nature of Business</div>
            <div className="val">{natureBusiness.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Company Email</div>
            <div className="val noApplyTextTransform">{companyEmail.toUpperCase()}</div>
            <div className="key">Company Tel</div>
            <div className="val">{companyTel}</div>
          </div>

        </div>
        <br />

          <div className="section-title">
            <h1><span><img src={require('../../assets/img/rectangle.png')} /></span> EMERGENCY & SPOUSE CONTACT</h1>
          </div>

          <div className="section-body">
          <div className="section-body-row">
            <div className="key">Name (Emergency)</div>
            <div className="val">{emergencyContactName.toUpperCase()}</div>
            <div className="key">Relationship (Emergency)</div>
            <div className="val">{emergencyContactRelationship.label.toUpperCase()}</div>
          </div>

          <div className="section-body-row">
            <div className="key">Mobile No. (Emergency)</div>
            <div className="val">{emergencyContactMobile}</div>
            <div className="key"></div>
            <div className="val"></div>
          </div>

        </div>
        <br />

          {/* {maritalStatus.value === 'M' ?
            (<div className="section-body">
              <div className="section-body-row">
              <div className="key">Name (Spouse)</div>
              <div className="val">{spouseName}</div>
              {
                spouseCompanyName ? (
                  <>
                    <div className="key">Company Name (Spouse)</div>
                    <div className="val">{spouseCompanyName}</div>
                  </>
                ) : null
              }
            </div>
            <div className="section-body-row">
              <div className="key">Mobile No. (Spouse)</div>
              <div className="val">{spouseMobile}</div>
              <div className="key"></div>
              <div className="val"></div>
            </div>
           </div>) : null
          } */}
          <br />

          <div className="section-title">
            <h1><span><img src={require('../../assets/img/rectangle.png')} /></span> DISBURSEMENT</h1>
          </div>

          <div className="section-body">
          <div className="section-body-row">
            <div className="key">Prefered Bank</div>
            <div className="val">{preferredBank.label.toUpperCase()}</div>
            <div className="key">Bank Account No</div>
            <div className="val">{bankAccountNumber}</div>
          </div>
        </div>

        <br />

          <div className="section-title">
            <h1><span><img src={require('../../assets/img/rectangle.png')} /></span> FINANCING</h1>
          </div>

          <div className="section-body">
            <div className="section-body-row">
              <div className="key">Financing Type</div>
              <div className="val">{(this.isIslamic() ? 'CashVantage Personal Financing-i' : 'CashFirst Personal Loan').toUpperCase()}</div>
              <div className="key">Financing Amount</div>
              <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(loanAmount).toFixed(2)) : selectedLoanAmount ? FormHelper.thousandSeparator(Number(selectedLoanAmount).toFixed(2)) : 0}</div>
              
            </div>

            <div className="section-body-row">
              <div className="key">Tenure</div>
              <div className="val">{isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure} {(isAipOff ? this.props.calculator.selectedTenure.value : this.props.initialLoanOffer.selectedTenure) > 1 ? "YEARS" : "YEAR"}</div>
              <div className="key">Stamp Duty</div>
              <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.state.stampDuty).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedStampDuty).toFixed(2))}</div>
            </div>
            {
              !!isCashBackCampaign ? (
                <>
                <div className="section-body-row">
                  <div className="key">{this.isIslamic() ? 'Profit' : 'Interest'} Rate</div>
                  <div className="val">{!!isAipOff ? interestRate.toFixed(2) : selectedLoanAmount ? selectedInterestRate.toFixed(2) : 0} % FLAT RATE P.A</div>
                  <div className="key">{this.isIslamic() ? 'Profit Payable' : 'Interest Rate Charged'} </div>
                  <div className="val">RM {(this.state.interestRateCharged).toFixed(2)}</div>
                </div>
                </>
              ) : null
            }

            {!!isCashBackCampaign ? (
              <>
              <div className="section-body-row">
                <div className="key">{this.isIslamic() ? 'Selling Price': 'Total Repayment Amount'}</div>
                <div className="val">RM {(this.state.totalRepaymentAmount).toFixed(2)}</div>
                <div className="key">Total Cash Back Amount</div>
                <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.totalCashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(totalCashBack).toFixed(2))}</div>
              </div>
              </>
            ) : null 
            }

            <div className="section-body-row">
              {!!isCashBackCampaign ? (
                <>
                <div className="key">Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} Amount Before Cash Back</div>
                <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</div>
                <div className="key">Cash Back (During Eligible Months)</div>
                <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.cashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(cashBack).toFixed(2))} per month </div>
                </>
              ) : (
                <>
                <div className="key">Monthly {this.isIslamic() ? 'Payment' : 'Repayment'}</div>
                <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</div>
                <div className="key">{this.isIslamic() ? 'Profit' : 'Interest'} Rate</div>
                <div className="val">{!!isAipOff ? interestRate.toFixed(2) : selectedLoanAmount ? selectedInterestRate.toFixed(2) : 0} % FLAT RATE P.A</div>
                </>
              )}
              
            </div>
            {!!isCashBackCampaign ? (
              <>
              <div className="section-body-row">
                <div className="key">Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} After Cash Back</div>
                <div className="val">RM {!!isAipOff ? FormHelper.thousandSeparator(Number(this.props.calculator.installmentAfterCashBack).toFixed(2)) : FormHelper.thousandSeparator(Number(installmentAfterCashback).toFixed(2))}</div>
                <div className="key"></div>
                <div className="val"></div>
              </div>
              </>
            ) : null 
            }
            {
              this.haveInsurance() && !!isCashBackCampaign == false ?
                (
                  <>
                    <div className="key">Insurance Premium</div>
                    <div className="val">RM {FormHelper.thousandSeparator(Number(selectedInsuranceAmount).toFixed(2))}</div>
                    <div className="key"></div>
                    <div className="val"></div>
                  </>
                ) : null
            }
          </div>
          <br />
          <hr />
          <div id="disclaimer">
            <div className="disclaimer-ul">
              <div>I agree to
              <a href={this.isIslamic() ? data.docPath.tnc_islamic : data.docPath.tnc} download className="hyperlink"> Terms and Conditions</a>, the
              <a href={this.isIslamic() ? data.docPath.pds_islamic : data.docPath.pds} download className="hyperlink"> Product Disclosure Sheet</a> and
              <a href='#' className="hyperlink" onClick={this.onDeclarationClick}> Declaration</a> and fully understand that disbursement of the financing amount is subject to Bank’s final approval *.
            </div>
              <br />
              <div><b>PDPA</b>: {isAcceptedPDPDConsent ? 'I provided consent.' : 'I do not provide consent.'} </div>
              <div><b>FATCA</b>: {fatcaDeclaration.value === true ? "I'm a U.S. Person." : "I'm not U.S. Person."} </div>
              <div><b>Tax Identification Number (TIN)</b>: {!!tinArray[0].tinNumber ? tinArray[0].tinNumber : "I do not have a Malaysia Tax Identification Number."}</div>
              <div><b>SST Registration Number</b>: {!!tinArray[0].sstNumber ? tinArray[0].sstNumber : "I do not have a SST Registration Number."}</div>
              <div><b>Non-Bank Debt</b>: {!!nonBankDebtAmt ? "RM" + FormHelper.thousandSeparator(Number(nonBankDebtAmt).toFixed(2)) : "I do not have non-bank debt."}</div>
              {/* <div><b>CRS</b>: {taxResidence.label}</div>
              {
                !this.isEmptyTinArray() ?
                  (<div>
                    {tinArray.map((item, key) =>
                      <div key={key.toString()}>{this.renderTinInfo(item, key)}</div>
                    )}
                  </div>
                  ) : null
              } */}
            </div>
          </div>
          <br />
          <div id="signature">
          {
            !!signature ? this.renderSignatureAndDate(signature) : <div className="signature-paste-area" />
          }
          <div className="signature-template"><img src={require('../../assets/img/signature.png')} /></div>
        </div>
        </div>
        {this.renderDeclarationModal()}
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  page: {
    backgroundColor:'white',
    padding:20
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  loanSummaryContainer:{
    flexDirection:'row',
    flex:1,
    marginVertical:10,
    height:70,
    maxHeight:70
  },
  loanSummaryItem:{
    flex:1,
    flexDirection:'column',
    borderWidth:1,
    borderStyle:'solid',
    borderColor:'#cccccc',
    backgroundColor:'#fafafa',
    padding:10,
    marginRight:10,
    alignItems:'center'
  },
  loanSummaryLabel:{
    fontSize:8
  },
  loanSummaryKey:{
    fontSize:13,
    fontFamily:'Arial',
    fontWeight:'bold'
  },
  loanSummaryLabelCompact:{
    fontSize:5,
    textOverflow: 'breakword'
  },
  loanSummaryKeyCompact: {
    fontSize:9,
    fontFamily:'Arial',
    fontWeight:'bold'
  },
  section:{
    flexDirection:'column',
    marginTop:20
  },
  sectionHeaderLabel:{
    fontSize:8,
    fontFamily:'Arial',
    fontWeight:'bold'
  },
  sectionBody:{
    flexDirection:'column',
    paddingLeft:20
  },
  sectionBodyRow:{
    flexDirection:'row',
    flex:1,
    marginVertical:2
  },
  sectionBodyRowItem:{
    flexDirection:'row',
    flex:1,
    flexWrap:'wrap',
    paddingHorizontal:10
  },
  key:{
    flex:1,
    fontSize:7, 
    color:'grey',
    marginRight:5,
    textAlign:'right'
  },
  val:{
    fontSize:7,
    flex:2
  },
  valNotes: {
    fontSize: 6,
    color: 'red',
    flex: 2
  }
});


export default LoanOfferTemplate;