export const types = {
    UPDATE_ELIGIBILITY_INFO: 'UPDATE_ELIGIBILITY_INFO',
    CLEAR_ELIGIBILITY_INFO: 'CLEAR_ELIGIBILITY_INFO',
}

export function updateEligibilityInfo(info) {
    return {
        type: types.UPDATE_ELIGIBILITY_INFO,
        info: info
    }
}

export function clearEligibilityInfo() {
    return { type: types.CLEAR_ELIGIBILITY_INFO }
}