export const docPath = {
    tnc:'http://www.alliancebank.com.my/Alliance/media/Documents/Financing/CashFirst-Personal-Loan-TnC.pdf',
    tnc_islamic: 'http://www.alliancebank.com.my/Alliance/media/Documents/Financing/CashVantage-i-Personal-Financing-TnC.pdf',
    pds:'https://www.alliancebank.com.my/Alliance/media/Documents/Financing/CashFirst-Personal-Loan-PDS.pdf',
    pds_islamic: 'http://www.alliancebank.com.my/Alliance/media/Documents/Financing/CashVantage-Financing-i-PDS_EN.pdf',
    ncp_eng:'https://www.alliancebank.com.my/Alliance/media/Pdf/Personal%20Data%20Protection%20Act%202010/NCP-for-website-ENG.pdf',
    cashback:'https://www.alliancebank.com.my/Alliance/media/Pdf/Alliance-Personal-Financing-30-Cash-Back-Campaign-TnC.pdf'
}

export const maxlength = {
    icNo: 7,
    loanAmount: 7,
    name: 50,
    mobileNumber: 14,
    email: 30,
    verificationCode: 6,
    companyName: 40,
    companyTel: 15,
    companyEmail: 30,
    addressLine1: 40,
    addressLine2: 40,
    postcode: 5,
    city: 20,
    foreignState: 20,
    emergencyContactName: 50,
    emergencyContactMobileNumber: 14,
    spouseName: 50,
    spouseCompanyName: 40,
    spouseMobileNumber: 14,
    disbursementBankAccountNumber: 40,
    tinNumber: 40,
    noTinOtherReason: 80,
    monthlyGrossIncome: 10,
    monthlyNetIncome: 10,
    nonBankDebtAmt: 10
}

export const disclamerDesc = "The rate displayed is an indicative rate. The Terms and Conditions of the financing including but not limited to the financing rate, amount, instalment amount, tenure and disbursement of financing which is subject to the final approval by Alliance Bank Malaysia Berhad (ABMB)/Alliance Islamic Bank Berhad (AIS) at its discretion."