import React from 'react';
import { PreferredBankList } from '../../constant';
import { Row, Col, Button, Card, CardText } from "reactstrap";
import Dropdown from "../../components/Dropdown";
import Input from "../../components/Input";
import { FormHelper } from '../../services';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import * as data from "../../data/globalData";

class DisbursementBank extends React.PureComponent {

    render() {
        const {
            preferredBank, bankAccountNumber, isNotJoinAccNo, isAW
        } = this.props;

            return (
                <>                         
                    <Row className="mt-4 mb-responsive pb-2">
                        <Col className="mt-2 col-6">                                              
                            <Dropdown                               
                                isDisabled = {isAW}
                                label="Preferred Bank"
                                name="preferredBank"
                                placeholder="Preferred Bank"
                                options={PreferredBankList}
                                value={preferredBank}
                                onChange={this.props.onPreferredBankChange}
                            />
                            <p className="tips-warning">
                                {preferredBank ? "" : "Choose your preferred bank that you wish to receive the bank financing disbursement."}
                            </p>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-responsive pb-2">
                        <Col className="col-6">
                            <Input
                                disabled = {isAW}
                                type="tel"
                                specialClass="field-input"
                                label="Bank Account Number"
                                name="bankAccountNumber"
                                onChange={this.props.onBankAccountNumberChange}
                                placeholder="Bank Account Number"
                                value={bankAccountNumber}
                                maxLength={data.maxlength.disbursementBankAccountNumber}
                                isValidInput={FormHelper.Validation.isOnlyNumber}
                                errText="Only numeric is allowed"
                            />
                            <p className="tips-warning">
                                {bankAccountNumber ? "" : "Bank account number is required"}
                            </p>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-responsive">
                        <Col>
                            <Card className="tnc-wrapper" style={{ paddingLeft: "0" }}>
                                <div
                                    data-name="isNotJoinAccNo"
                                    onClick={this.props.onDeclareJoinAccChange}
                                    className={`box-checker-box ${isNotJoinAccNo !== undefined && isNotJoinAccNo ? "box-checked-box" : ""}`}
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                <CardText style={{ color: "#000000", fontSize: "14px" }}>
                                    I DECLARE that the account number above is{" "}<strong>NOT A JOINT ACCOUNT</strong>.
                                </CardText>
                            </Card>
                        </Col>
                    </Row>
                    <Button
                        id="gtm-update-disbursement-bank"
                        data-index="4"
                        className="proceedButton"
                        disabled={!this.props.isValidPreferredBank}
                        color="secondary"
                        onClick={this.props.onPreferredBankContinuePressed}
                    >
                        CONTINUE
                    </Button>
                </>
            );      
    }
}

export default DisbursementBank;