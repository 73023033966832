export const calculateMonthlyRepayment = (loanAmount, interest, appliedTenure) => {
    console.log('calculateMonthlyRepayment:loanAmount:interest:appliedTenure:', loanAmount, interest, appliedTenure)
    let loanAmountForCalculation = parseFloat(loanAmount);
    let interestForCalculation = parseFloat(interest);
    let appliedTenureForCalculation = parseFloat(appliedTenure);

    const monthlyInstalment = (loanAmountForCalculation + (loanAmountForCalculation * interestForCalculation / 100 * appliedTenureForCalculation)) / (appliedTenureForCalculation * 12);
    console.log('monthlyInstalment',monthlyInstalment);
    if (isNaN(monthlyInstalment))
        return 0;

    return parseFloat(monthlyInstalment.toFixed(2));
}

export const calculateInsurancePremium = (loanAmount, insuranceRate) => {
    console.log('calculateInsurancePremium:loanAmount:insuranceRate:',loanAmount, insuranceRate);

    let loanAmountForCalculation = parseFloat(loanAmount);
    let interestForCalculation = parseFloat(insuranceRate);

    const insurancePremium = loanAmountForCalculation * interestForCalculation;

    if (isNaN(insurancePremium))
        return 0;

    return parseFloat(insurancePremium.toFixed(2));
}

export const calculateStampDuty = (loanAmount, stampDutyRate) => {
    console.log('calculateStampDuty:loanAmount:stampDutyRate:',loanAmount, stampDutyRate);

    let loanAmountForCalculation = parseFloat(loanAmount);
    let interestForCalculation = parseFloat(stampDutyRate);

    const stampDuty = loanAmountForCalculation * interestForCalculation/100;

    if (isNaN(stampDuty))
        return 0;

    return parseFloat(stampDuty.toFixed(2));
}

export const calculateSellingPrice = (loanAmount, interestRate, tenure) => {
    console.log('calculateSellingPrice:loanAmount:interestRate:tenure:',loanAmount, interestRate, tenure);

    let loanAmountForCalculation = parseFloat(loanAmount);
    let interestForCalculation = parseFloat(interestRate);
    let tenureForCalculation = parseFloat(tenure);

    const sellingPrice = loanAmountForCalculation * ( 1 + ((interestForCalculation/100) * tenureForCalculation));

    if (isNaN(sellingPrice))
        return 0;

    return parseFloat(sellingPrice.toFixed(2));
}

export const calculateInterestCharged = (loanAmount, tenure, interestRate) => {
    console.log('calculateInterestCharged:loanAmount:tenure:interestRate:',loanAmount, tenure, interestRate);

    let loanAmountForCalculation = parseFloat(loanAmount);
    let interestForCalculation = parseFloat(interestRate);

    const interestRateCharged = loanAmountForCalculation * interestForCalculation/100 * tenure;
    console.log(interestRateCharged)
    if (isNaN(interestRateCharged))
        return 0;

    return parseFloat(interestRateCharged.toFixed(2));
}

export const calculateTotalRepaymentAmount = (loanAmount, tenure, interestRate) => {
    console.log('calculateInterestCharged:loanAmount:tenure:interestRate:',loanAmount, tenure, interestRate);

    let loanAmountForCalculation = parseFloat(loanAmount);
    let interestForCalculation = parseFloat(interestRate);

    const totalRepaymentAmount = loanAmountForCalculation + (loanAmountForCalculation * interestForCalculation/100 * tenure);
    console.log(totalRepaymentAmount)
    if (isNaN(totalRepaymentAmount))
        return 0;

    return parseFloat(totalRepaymentAmount.toFixed(2));
}