import React, { Component } from "react";
import PropTypes from 'prop-types';

const timerStyle = {
  position:'relative'
}

class Timer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      seconds: null,
      minutes: null
    }
  }

  componentDidMount() {
    this.startCountDown();
  }

  componentWillUnmount(){
    clearInterval(this.intervalHandle);
  }

  tick = () => {
    const { min, sec } = this.getMinAndSec(this.secondsRemaining);
    this.setMinutesAndSeconds(min, sec)
    if (min === 0 & sec === 0) {
      clearInterval(this.intervalHandle);
    }
    --this.secondsRemaining;
    this.props.onTick(this.secondsRemaining);
  }

  getMinAndSec = (seconds) => {
    let min = Math.floor(seconds / 60);
    let sec = seconds - (min * 60);
    return { min, sec };
  }

  setMinutesAndSeconds = (min, sec) => {
    this.setState({
      minutes: min < 10 ? '0' + min : min,
      seconds: sec < 10 ? '0' + sec : sec
    })
  }

  startCountDown = () => {
    const { min, sec } = this.getMinAndSec(this.props.seconds);
    this.setMinutesAndSeconds(min, sec);
    clearInterval(this.intervalHandle);
    this.secondsRemaining = this.props.seconds;
    this.intervalHandle = setInterval(this.tick, 1000);
  }

  render() {
    return (
        <span style={{...this.props.timerStyle, color:this.props.color}}>{this.state.minutes}:{this.state.seconds}</span>
    );
  }
}

Timer.defaultProps = {
  timerStyle: timerStyle,
  color: '#ffffff'
}

Timer.propTypes = {
  timerStyle: PropTypes.object,
  color:PropTypes.string
}

export default Timer;