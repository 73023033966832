const uatKey = `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApAxZ9U+HtxJ9WtgDfq23
                O0FhWyTpgduMXhdry1peXDGJ0+mdnoUpJzpwhyMPCMiksK+VxZiOVZXmv9yPgoxb
                xzRnnx/u5o3WDf7/OHk1J+2+J4YiGbHhS5KkkdksuvC8gJpqENnB5dSSUW7y2+hp
                igYEudnvAS1tLrvXtF1VmByM7fa3ftLvUh9NFVu52KAoVzuGCJGLta4bLzlIBXTz
                GtX1AWyDo5kq1mOHiUrExQ+vImpuNt0cSpDTsNC2Cg2D3cvFeu/s8Z929QlH6cbL
                pwI0ZCGDh+ythkpJ0XEXJiiCrIF5+fYaNY9BupzNUbmzeqBA+KfssOwqhQEAJaDG
                RwIDAQAB
                -----END PUBLIC KEY-----
                `;

const prodKey = `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApAxZ9U+HtxJ9WtgDfq23
                O0FhWyTpgduMXhdry1peXDGJ0+mdnoUpJzpwhyMPCMiksK+VxZiOVZXmv9yPgoxb
                xzRnnx/u5o3WDf7/OHk1J+2+J4YiGbHhS5KkkdksuvC8gJpqENnB5dSSUW7y2+hp
                igYEudnvAS1tLrvXtF1VmByM7fa3ftLvUh9NFVu52KAoVzuGCJGLta4bLzlIBXTz
                GtX1AWyDo5kq1mOHiUrExQ+vImpuNt0cSpDTsNC2Cg2D3cvFeu/s8Z929QlH6cbL
                pwI0ZCGDh+ythkpJ0XEXJiiCrIF5+fYaNY9BupzNUbmzeqBA+KfssOwqhQEAJaDG
                RwIDAQAB
                -----END PUBLIC KEY-----
                `;

const aes128BitKey = '6XDgOpDlOXrJPzO7'

export default {
    uatKey, prodKey, aes128BitKey
}