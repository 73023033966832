export const types = {
    UPDATE_PREFERRED_BANK_INFO: 'UPDATE_PREFERRED_BANK_INFO',
    CLEAR_PREFERRED_BANK_INFO: 'CLEAR_PREFERRED_BANK_INFO',
}

export function updatePreferredBankInfo(info) {
    return {
        type: types.UPDATE_PREFERRED_BANK_INFO,
        info: info
    }
}

export function clearPreferredBankInfo() {
    return { type: types.CLEAR_PREFERRED_BANK_INFO }
}