import React from 'react';
import { Card, CardText, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input";
import PropTypes from 'prop-types';
import { FormHelper} from '../../services';
import * as data from "../../data/globalData";

class Nonbankdebt extends React.PureComponent {

    render() {
        const {
            nonBankDebtAmt
        } = this.props;

        return (
            <Card className="share-info-wrapper">
                <CardText className="share-info-desc">
                Do you have any other non-bank debt?
                </CardText>
                <Row className="share-info-input-wrapper">
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="hasNonbankdebt"
                                onClick={()=>{this.props.onChange(true)}}
                                className={`box-checker ${
                                    this.props.hasNonbankdebt !== undefined && this.props.hasNonbankdebt ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">YES</label>
                        </div> 
                    </Col>
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="hasNonbankdebt"
                                onClick={()=>{this.props.onChange(false)}}
                                className={`box-checker ${
                                    this.props.hasNonbankdebt !== undefined && this.props.hasNonbankdebt === false ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">NO</label>
                        </div>
                    </Col>
                </Row>
                { this.props.hasNonbankdebt ? 
                    <Row className="my-3 mb-responsive">
                        <Col className="mb-4">
                            <p> </p>
                            <Input
                            specialClass="field-input"
                            label="How much is your monthly non-bank debt amount?"
                            name="nonBankDebtAmt"
                            onChange={this.props.onNonBankDebtAmtChange}
                            placeholder="Enter Amount"
                            value={nonBankDebtAmt}
                            maxLength={data.maxlength.nonBankDebtAmt}
                            prefixLabel="RM "
                            isValidInput={FormHelper.Validation.isValidCurrency}
                            errText="Only numeric is allowed"
                            thousandSeparator
                            decimalSeparator={false}
                            />
                        </Col>
                    </Row>
                    : (null)
                }
            </Card>
        );
    }

}

Nonbankdebt.defaultProps = {
    onChange: (value) => value,
    hasNonbankdebt: undefined,
}

Nonbankdebt.propTypes = {
    onChange: PropTypes.func,
    hasNonbankdebt: PropTypes.bool
}

export default Nonbankdebt;