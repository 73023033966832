import React from 'react';
import { Card, CardText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

class TncDeclaration extends React.PureComponent {

    render() {
        return (
            <Card className="tnc-wrapper">
                <div className="share-info-check-wrapper">
                    <div data-name="isAcceptTnc" onClick={this.props.onChange}
                        className={`box-checker-box ${
                            this.props.isAcceptTnc !== undefined && this.props.isAcceptTnc ? "box-checked-box" : ""
                            }`}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                </div>
                <CardText><i>
                    I have read the{" "}
                    <span data-name="declaration" className="declaration" onClick={this.props.toggle}>
                        Declaration
                  </span>{" "}
                    provided in the hyperlinks herein above before submitting the Alliance Personal Financing Application and I agree to the same.
                    </i>
                </CardText>
            </Card>
        );
    }
}

export default TncDeclaration;
