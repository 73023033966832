import { types } from '../actions/moreAboutYourself';
import { assign, cloneDeep } from 'lodash';

const initialState = { 
    residenceType: '',
    lengthOfStay: '', 
    residenceStatus:{
        value: "0",
        label: "I am a Malaysian residing in Malaysia"
    }, 
    purposeOfBorrowing: {
        value: "PU",
        label: "Personal Use"
    },
    sourceOfFund: '',
    sourceOfWealth: '',
    // otherSourceOfFund: '',
    // otherSourceOfWealth: ''
}

export default function moreAboutYourself(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_MORE_ABOUT_YOURSELF_INFO:
        console.log("moreAboutYourself::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_MORE_ABOUT_YOURSELF_INFO:
            return initialState;

        default:
            return state;
    }
}