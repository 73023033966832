import { HTTP } from '../../../core';
import { CustomError } from '../../../core';
import Environment from '../../../environment';

export const sendAttachment = (eformUuid = '', attachment = {}, isProtectedWebService = true) => {
    console.log("sendAttachment:eformUuid:attachment:", eformUuid, attachment);
    const url = Environment.protectedEformUrl + 'eform/' + eformUuid + '/attachment';
    return HTTP.postData(url, attachment, isProtectedWebService);
}

export const sendMultipleAttachments = (eformUuid = '', attachments = [], isProtectedWebService = true) => {
    console.log('sendMultipleAttachments:eform:attachments:', eformUuid, attachments);
    let consolidatedSendAttahcmentsServices = [];
    for (let item of attachments) {
        consolidatedSendAttahcmentsServices.push(
            sendAttachment(eformUuid, { ...item, key:undefined }, isProtectedWebService)
                .then(resp => ({...item, hasSent:true}))
                .catch(err => ({...item, hasSent:false, error:err }))
        );
    }
    return Promise.all(consolidatedSendAttahcmentsServices);
}