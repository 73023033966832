export const types = {
    UPDATE_EMPLOYMENT_DETAILS_INFO: 'UPDATE_EMPLOYMENT_DETAILS_INFO',
    CLEAR_EMPLOYMENT_DETAILS_INFO: 'CLEAR_EMPLOYMENT_DETAILS_INFO',
}

export function updateEmploymentDetailsInfo(info) {
    return {
        type: types.UPDATE_EMPLOYMENT_DETAILS_INFO,
        info: info
    }
}

export function clearEmploymentDetailsInfo() {
    return { type: types.CLEAR_EMPLOYMENT_DETAILS_INFO}
}