/**
 * This helper function returns a state code based on a postcode.
 * @param {string} postCodeInput 
 */
export default function getStateByPostcode(postCodeInput) {
    const postCode = parseInt(postCodeInput);

    if (postCode >= 50000 && postCode <= 60000)//'W.P. KUALA LUMPUR'
        return 'W';
    if (postCode >= 62000 && postCode <= 62988)//'W.P. PUTRAJAYA'
        return 'E';
    if (postCode >= 87000 && postCode <= 87033)//'W.P. LABUAN'
        return 'L';
    if ((postCode >= 40000 && postCode <= 48300) || (postCode >= 63000 && postCode <= 68100))//'SELANGOR'
        return 'B';
    if (postCode >= 20000 && postCode <= 24300)//'TERENGGANU'
        return 'T';
    if (postCode >= 93000 && postCode <= 98859)//'SARAWAK'
        return 'Q';
    if (postCode >= 88000 && postCode <= 91309)//'SABAH'
        return 'S';
    if (postCode >= 5000 && postCode <= 9810)//'KEDAH'
        return 'K';
    if (postCode >= 15000 && postCode <= 18500)//'KELANTAN'
        return 'D';
    if (postCode >= 70000 && postCode <= 73509)//'NEGERI SEMBILAN'
        return 'N';
    if (postCode >= 10000 && postCode <= 14400)//'PULAU PINANG'
        return 'P';
    if (postCode >= 79000 && postCode <= 86900)//'JOHOR'
        return 'J';
    if (postCode >= 75000 && postCode <= 78309)//'MELAKA'
        return 'M';
    if (postCode >= 1000 && postCode <= 2999)//'PERLIS'
        return 'R';
    if (postCode >= 30000 && postCode <= 36810)//'PERAK'
        return 'A';
    if ((postCode >= 25000 && postCode <= 28800) || (postCode >= 39000 && postCode <= 39200) || postCode == 49000 || postCode == 69000)//'PAHANG'
        return 'C';

    return null;
}