import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Collapse,
  Button
} from "reactstrap";
import styles from "./InitialOffer.module.scss";
import "./initialOffer.scss";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle/index";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { FormHelper, Webservice, PayloadGenerator } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Routes from '../../routes';
import { connect } from 'react-redux';
import { initialLoanOffer } from '../../reduxStore/actions'
import SlidingErrorAlert from '../../components/SlidingErrorAlert';
import * as data from "../../data/globalData";
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import BaseComponent from '../../class/baseComponent';
import { PloanScreensList } from '../../constant';
import NeedHelp from "../../components/NeedHelp";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused
      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
      : state.isSelected
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : "",
    borderColor: state.isFocused
      ? "#80bdff"
      : state.isSelected
        ? "#80bdff"
        : "#ced4da",
    "&:hover": {
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      transition: "all 200ms"
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "hsl(0, 0%, 20%)" : "#495057"
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#333333",
    color: "white"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#2096de"
      : state.isSelected
        ? "#2096de"
        : "rgba(255,255,255,0)",
    ":active": {
      backgroundColor: "#20aaff"
    }
  }),
  indicatorSeparator: () => ({
    display: "none"
  })
};

class InitialOffer extends BaseComponent {
  constructor(props) {
    super(props);

    const { minLoanAmount, proposedInsuranceAmount, interestRateMapping, proposedLoanAmount,
      proposedMonthlyInstallmentAmount, proposedTenure, insuranceRateMapping,
      stampDutyRate, name, selectedLoanType, cashBack, totalCashBack,
      proposedStampDuty, proposedSellingAmount, cashBackRate, installmentAfterCashback } = this.props;
    console.log('this.props', this.props);
    const { isCashBackCampaign, monthlyInstallment } = this.props.calculator;
    let tenureOptions = Object.keys(interestRateMapping).map(item => ({ label: item, value: Number(item) }))

    this.state = {
      collapse1: true, index: 1, usingProposedAmount: true, minLoanAmount, proposedInsuranceAmount,
      interestRateMapping, proposedLoanAmount, proposedMonthlyInstallmentAmount, proposedSellingAmount,
      proposedTenure, insuranceRateMapping, stampDutyRate, name, selectedLoanType, proposedStampDuty, tenureOptions,
      selectedInsuranceAmount: proposedInsuranceAmount,
      selectedLoanAmount: proposedLoanAmount,
      selectedMonthlyInstallmentAmount: proposedMonthlyInstallmentAmount,
      selectedInstallmentAfterCashBack: 0,
      selectedTenure: proposedTenure,
      selectedStampDuty: proposedStampDuty,
      selectedSellingAmount: proposedSellingAmount,
      selectedInterestRate: !!isCashBackCampaign == true ? cashBackRate[proposedTenure] : interestRateMapping[proposedTenure],
      proposedInterestRate: !!isCashBackCampaign == true ? cashBackRate[proposedTenure] : interestRateMapping[proposedTenure],
      cashBackRate,
      isCashBackCampaign, 
      monthlyInstallment, 
      installmentAfterCashback,
      cashBack, totalCashBack,
      selectedCashBack: cashBack,
      selectedTotalCashBack: 0,
      proposedCashBack: 0,
      proposedTotalCashBack: 0,
      interestRateCharged: 0,
      totalRepaymentAmount: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //pre set the cashback
    const { isCashBackCampaign } = this.props.calculator;
    if(!!isCashBackCampaign == true) {
      this.calculateCashBack();
      this.calculateProposedAmountCashBack();
      this.calculateProposedInterestCharged();
      this.calculateProposedTotalRepaymentAmount();
    } else {
      this.calculateNewMonthlyInstallment();
      this.calculateNewInsurancePremium();
      this.calculateNewStampDuty();
      this.calculateSellingPrice();
    }
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedLoanAmount, selectedTenure, isCashBackCampaign, usingProposedAmount } = this.state;

    if(isCashBackCampaign == true) {
      if (selectedLoanAmount !== prevState.selectedLoanAmount) {
        this.calculateCashBack();
        this.calculateInterestCharged();
        this.calculateTotalRepaymentAmount();
        this.calculateNewInsurancePremium();
        this.calculateNewStampDuty();
        this.calculateSellingPrice();
      }
    } else {
      if (selectedLoanAmount !== prevState.selectedLoanAmount) {
        this.calculateNewMonthlyInstallment();
        this.calculateNewInsurancePremium();
        this.calculateNewStampDuty();
        this.calculateSellingPrice();
      }
    }

    if(isCashBackCampaign == true) {
      if (selectedTenure !== prevState.selectedTenure) {
        this.calculateCashBack();
        this.calculateInterestCharged();
        this.calculateTotalRepaymentAmount();
        this.calculateNewInsurancePremium();
        this.calculateSellingPrice();
      }
    } else {
      if (selectedTenure !== prevState.selectedTenure) {
        this.calculateNewMonthlyInstallment();
        this.calculateNewInsurancePremium();
        this.calculateNewStampDuty();
        this.calculateSellingPrice();
      }
    }

    if(isCashBackCampaign == true) {
      if(usingProposedAmount !== prevState.usingProposedAmount) {
        if(usingProposedAmount === true) {
          this.calculateProposedAmountCashBack();
          this.calculateProposedInterestCharged();
          this.calculateProposedTotalRepaymentAmount();
        } else {
          this.calculateCashBack();
          this.calculateInterestCharged();
          this.calculateTotalRepaymentAmount();
        }
      }
    }
  }

  calculateNewMonthlyInstallment = () => {
    const { selectedLoanAmount, selectedInterestRate, selectedTenure } = this.state;
    let newMonthlyInstallment = FormHelper.Calculator.calculateMonthlyRepayment(selectedLoanAmount, selectedInterestRate, selectedTenure);

    this.setState({
      selectedMonthlyInstallmentAmount: newMonthlyInstallment
    })
  }

  calculateCashBack = () => {
    const { selectedLoanAmount, selectedTenure } = this.state;
    let { eformUuid } = this.props;

    if(selectedLoanAmount > 0 && selectedTenure > 0) {
        Webservice.Eform.calculateCashBackProtected(eformUuid, selectedLoanAmount, selectedTenure).then((resp) =>
        {
            this.setState({
              selectedMonthlyInstallmentAmount: resp.installmentBeforeCashback,
              selectedInstallmentAfterCashBack: Number(resp.installmentAfterCashback.toFixed(2)),
              selectedCashBack: resp.cashback,
              selectedTotalCashBack: resp.totalCashback
            })
        })
    }
  }

  calculateProposedAmountCashBack = () => {
    const { proposedLoanAmount, proposedTenure } = this.state;
    let { eformUuid } = this.props;
    if(proposedLoanAmount > 0 && proposedTenure > 0) {
        Webservice.Eform.calculateCashBackProtected(eformUuid, proposedLoanAmount, proposedTenure).then((resp) =>
          this.setState({
            proposedMonthlyInstallmentAmount: resp.installmentBeforeCashback, 
            installmentAfterCashback: resp.installmentAfterCashback, 
            proposedCashBack: resp.cashback,
            proposedTotalCashBack: resp.totalCashback
          })
        )
    }
  }

  calculateProposedInterestCharged = () => {
    const { proposedLoanAmount, proposedTenure, proposedInterestRate } = this.state;

    let interestRateCarged;
        interestRateCarged = FormHelper.Calculator.calculateInterestCharged(proposedLoanAmount, proposedTenure, proposedInterestRate );
  
    this.setState({ interestRateCharged: interestRateCarged })
  }

  calculateProposedTotalRepaymentAmount = () => {
    const { proposedLoanAmount, proposedTenure, proposedInterestRate } = this.state;
    //this.props.calculator.selectedTenure, this.props.initialLoanOffer.selectedTenure;
    let totalRepaymentAmount;
    totalRepaymentAmount = FormHelper.Calculator.calculateTotalRepaymentAmount(proposedLoanAmount, proposedTenure, proposedInterestRate );

    this.setState({ totalRepaymentAmount: totalRepaymentAmount })
  }

  calculateInterestCharged = () => {
    const { selectedLoanAmount, selectedTenure, selectedInterestRate } = this.state;

    let interestRateCarged;
        interestRateCarged = FormHelper.Calculator.calculateInterestCharged(selectedLoanAmount, selectedTenure, selectedInterestRate );
  
    this.setState({ interestRateCharged: interestRateCarged })
  }

  calculateTotalRepaymentAmount = () => {
    const { selectedLoanAmount, selectedTenure, selectedInterestRate } = this.state;
    //this.props.calculator.selectedTenure, this.props.initialLoanOffer.selectedTenure;
    let totalRepaymentAmount;
    totalRepaymentAmount = FormHelper.Calculator.calculateTotalRepaymentAmount(selectedLoanAmount, selectedTenure, selectedInterestRate );

    this.setState({ totalRepaymentAmount: totalRepaymentAmount })
  }

  calculateNewInsurancePremium = () => {
    const { selectedLoanAmount, selectedTenure, insuranceRateMapping } = this.state;
    let insuranceRate = insuranceRateMapping[selectedTenure];
    let newInsurancePremium = FormHelper.Calculator.calculateInsurancePremium(selectedLoanAmount, insuranceRate);

    if(this.haveInsurance()){
      this.setState({
        selectedInsuranceAmount: newInsurancePremium
      })
    }
    else{
      this.setState({
        selectedInsuranceAmount: 0
      })
    }
  }

  calculateNewStampDuty = () => {
    const { selectedLoanAmount, stampDutyRate } = this.state;
    let newStampDuty = FormHelper.Calculator.calculateStampDuty(selectedLoanAmount, stampDutyRate);
    this.setState({
      selectedStampDuty: newStampDuty
    })
  }

  calculateSellingPrice = () => {
    const { selectedLoanAmount, selectedInterestRate, selectedTenure } = this.state;
    let newSellingPrice = FormHelper.Calculator.calculateSellingPrice(selectedLoanAmount, selectedInterestRate, selectedTenure);

    this.setState({
      selectedSellingAmount: newSellingPrice
    })
  }

  onSelectTenure = (option) => {
    const { interestRateMapping, isCashBackCampaign, cashBackRate } = this.state;
    if(isCashBackCampaign == false) {
      this.setState({
        selectedTenure: option.value,
        selectedInterestRate: interestRateMapping[option.value]
      });
    } else {
      this.setState({
        selectedTenure: option.value,
        selectedInterestRate: cashBackRate[option.value]
      });
    }
  };

  onChangeLoanAmount = event => {
    let value = event.target.value;

    if (!FormHelper.Validation.isOnlyNumber(value)) {
      value = FormHelper.Replace.replaceAllExceptNumber(event.target.value);
    }

    value = value ? parseInt(value) : "";
    this.setState({ selectedLoanAmount: value });
  };

  onChangeLoanAmountSlider = e => {
    let value = e.target.value
    this.setState({
      selectedLoanAmount: parseInt(value)
    });
  };

  toggleOption = value => {
    this.setState({
      usingProposedAmount: value
    });
  };

  onContinue = async () => {

    const {
      usingProposedAmount, proposedInsuranceAmount,
      interestRateMapping, proposedLoanAmount, proposedMonthlyInstallmentAmount,
      proposedTenure, proposedStampDuty, proposedSellingAmount, selectedInsuranceAmount, selectedLoanAmount,
      selectedMonthlyInstallmentAmount, selectedTenure, selectedStampDuty,
      selectedSellingAmount, selectedInterestRate, isCashBackCampaign, cashBackRate, 
      selectedInstallmentAfterCashBack, selectedCashBack, selectedTotalCashBack, cashBack, totalCashBack, installmentAfterCashback, totalRepaymentAmount ,
      proposedCashBack, proposedTotalCashBack,
    } = this.state;
      console.log('here', selectedCashBack, cashBack, selectedTotalCashBack, totalCashBack)
    if (usingProposedAmount) {
      this.props.updateInitialLoanOfferStore({
        selectedInsuranceAmount: proposedInsuranceAmount, selectedLoanAmount: proposedLoanAmount,
        selectedMonthlyInstallmentAmount: proposedMonthlyInstallmentAmount,
        selectedInstallmentAfterCashBack: installmentAfterCashback,
        selectedTenure: proposedTenure, selectedStampDuty: proposedStampDuty,
        selectedSellingAmount: !!isCashBackCampaign == true ? totalRepaymentAmount : proposedSellingAmount, 
        selectedInterestRate: !!isCashBackCampaign == true ? cashBackRate[proposedTenure] : interestRateMapping[proposedTenure],
        selectedCashBack: proposedCashBack,
        selectedTotalCashBack: proposedTotalCashBack,
        installmentAfterCashback: installmentAfterCashback,
        cashBack: proposedCashBack,
        totalCashBack: proposedTotalCashBack
      })
    } else {
      this.props.updateInitialLoanOfferStore({
        selectedInsuranceAmount, selectedLoanAmount, selectedMonthlyInstallmentAmount, selectedInstallmentAfterCashBack,
        selectedTenure, selectedStampDuty, 
        selectedSellingAmount: !!isCashBackCampaign == true ? totalRepaymentAmount :selectedSellingAmount, 
        selectedInterestRate,
        selectedCashBack: selectedCashBack,
        selectedTotalCashBack: selectedTotalCashBack,
        installmentAfterCashback: selectedInstallmentAfterCashBack,
        cashBack: selectedCashBack,
        totalCashBack: selectedTotalCashBack
      })
    }
    if (await this.updateInitialLoanOffer()) {
      this.props.history.push(Routes.submitMoreInfo)
    }
  };

  updateInitialLoanOffer = async () => {
    try {
      let { eformUuid } = this.props;
      let params = PayloadGenerator.genUpdateInitialLoanOfferPayload();
      await Webservice.Eform.updateInitialLoanOffer(eformUuid, params).then(resp => {
        console.log('updateInitialLoanOffer:resp:', resp);
      }).catch(error => {
        throw error;
      })

    } catch (error) {
      console.log('updateInitialLoanOffer:error:', error)
      this.baseErrorHandler(error)
      return false;
    }
    return true;
  }

  isLoanAmountWithinLimit = () => {
    const { selectedLoanAmount, proposedLoanAmount, minLoanAmount } = this.state;
    if (selectedLoanAmount >= minLoanAmount && selectedLoanAmount <= proposedLoanAmount)
      return true;

    return false;
  };

  isMonthlyInstallmentMoreThanProposedMonthlyInstallment = () => {
    const { selectedMonthlyInstallmentAmount, proposedMonthlyInstallmentAmount } = this.state;

    if (selectedMonthlyInstallmentAmount > proposedMonthlyInstallmentAmount)
      return true;

    return false;
  }

  shouldDisableNextButton = () => {

    if (this.state.usingProposedAmount)
      return false;

    if (!this.isLoanAmountWithinLimit())
      return true;

    if (this.isMonthlyInstallmentMoreThanProposedMonthlyInstallment())
      return true;

    return false;
  };

  calculateNetReceivable = (loanAmount, insurancePremium, stampDuty) => {
      return (loanAmount - insurancePremium - stampDuty).toFixed(2)
  }

  isIslamic = () => {
    const { selectedLoanType } = this.state;
    return selectedLoanType.value === '2';
  }

  haveInsurance = () => {
    const { insurance } = this.props;
    return insurance.value == true;
  }

  render() {
    let { selectedLoanAmount, proposedLoanAmount, proposedMonthlyInstallmentAmount,
      usingProposedAmount, proposedTenure, name, selectedStampDuty,
      proposedInterestRate, selectedInterestRate, selectedInsuranceAmount, proposedInsuranceAmount,
      proposedStampDuty, interestRateMapping, minLoanAmount, selectedTenure,
      tenureOptions, selectedMonthlyInstallmentAmount, selectedInstallmentAfterCashBack, proposedSellingAmount, selectedSellingAmount, 
      isCashBackCampaign, cashBackRate, monthlyInstallment, installmentAfterCashback, 
      proposedCashBack, proposedTotalCashBack, selectedCashBack, selectedTotalCashBack, interestRateCharged, totalRepaymentAmount } = this.state;
      console.log('tenure', proposedTenure, selectedTenure)
      console.log('interest rate', proposedInterestRate, selectedInterestRate)
    return (
      <>
        <Header
          version="b"
          progressBarPercentage={33}
          currentStage={3}
        />
        <SlidingErrorAlert
          isShow={!usingProposedAmount && this.isMonthlyInstallmentMoreThanProposedMonthlyInstallment()}
          message={`Your monthly installment should not more than RM${FormHelper.thousandSeparator(proposedMonthlyInstallmentAmount)}.`}
          showDismissButton={false}
        />

        <SlidingErrorAlert
          isShow={!usingProposedAmount && !this.isLoanAmountWithinLimit()}
          message={`Your financing amount should within RM${FormHelper.thousandSeparator(minLoanAmount)} and RM${FormHelper.thousandSeparator(proposedLoanAmount)}.`}
          showDismissButton={false}
        />

        <Section className="initial-loan-offer-section">
          <Container>
            <Row>
              <Col>
                <SectionTitle text={`Good news, ${name}!`} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="mb-4">
                <ListGroup className="submit-more-info-form-list">
                  <ListGroupItem className="justify-content-between">
                    <div className="submit-more-info-form-label" data-index="1">
                      <FontAwesomeIcon className="mr-2 mt-4" icon={faMinus} />
                      Initial Financing Offer<strong><span style={{ color: "#FF0000" }}> * </span></strong>
                    </div>
                    <Collapse
                      className="ml-4 mb-responsive-collapse"
                      isOpen={this.state.collapse1}
                    >
                      <Row className="mt-4 mb-responsive initial-offer-row">
                        <Col>
                          <span
                            style={{ fontSize: "18px", marginBottom: "10px", color: "#133263", fontWeight: "600" }}
                          >
                            Exclusive offer* just for you ! Get going with instant cash, {this.isIslamic() ? 'profit' : 'interest'} rate from as low as  {" "}
                            {isCashBackCampaign == true ? cashBackRate[proposedTenure].toFixed(2) : interestRateMapping[proposedTenure].toFixed(2)}% flat rate p.a (subject to change).
                          </span>
                          <span style={{ fontSize: "16px", color: "#133263" }}>
                            Please choose from the options below to fulfil your lifestyle needs with Alliance Personal Financing today.
                          </span>
                          <div className={`${usingProposedAmount && "selected"} loan-offer-panel`} >
                            <div className="loan-offer-panel-title">
                              <div
                                className={`box-checker ${usingProposedAmount && "box-checked"}`}
                                onClick={() => {
                                  this.toggleOption(true);
                                }}
                              >
                              </div>
                              <span>
                                I {this.isIslamic() ? 'need' : 'want'} RM{FormHelper.thousandSeparator(proposedLoanAmount)}, {" "}
                                {proposedTenure} {(proposedTenure > 1) ? 'years' : 'year'} {this.isIslamic() ? 'payment' : 'repayment'} period, {" "}
                                {isCashBackCampaign == true ? cashBackRate[proposedTenure].toFixed(2) : interestRateMapping[proposedTenure].toFixed(2)}% flat rate p.a {this.isIslamic() ? 'profit' : 'interest'} rate.
                              </span>
                            </div>
                            {usingProposedAmount && (
                              <>
                                {isCashBackCampaign == false ? (
                                <ul>
                                  <li>
                                    <i>{this.isIslamic() ? 'Financing' : 'Loan'} Type</i>
                                    <span>{this.isIslamic() ? 'CashVantage Personal Financing-i' : 'CashFirst Personal Loan'}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Financing' : 'Loan'} Amount</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(proposedLoanAmount).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>Tenure</i>
                                    <span>{proposedTenure} {(proposedTenure > 1) ? 'years' : 'year'}</span>
                                  </li>
                                  <li>
                                    <i>Stamp Duty</i>
                                    <span>- RM{FormHelper.thousandSeparator(Number(proposedStampDuty).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Profit' : 'Interest'} Rate</i>
                                    <span>{interestRateMapping[proposedTenure].toFixed(2)}% flat rate p.a</span>
                                  </li>
                                  {
                                    this.haveInsurance() ?
                                      (
                                        <li>
                                          <i>Premium Insurance</i>
                                          <span>- RM{FormHelper.thousandSeparator(Number(proposedInsuranceAmount).toFixed(2))}</span>
                                        </li>
                                      ) : null
                                  }
                                  <li>
                                    <i>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'}</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(proposedMonthlyInstallmentAmount).toFixed(2))}</span>
                                  </li>
                                  <li className="highlight">
                                    <i>
                                      <img
                                        alt="payloan icon"
                                        src={require("../../assets/img/payloanicon.png")}
                                      />
                                      You will receive
                                    </i>
                                    <span>RM
                                      {FormHelper.thousandSeparator(this.calculateNetReceivable(proposedLoanAmount, proposedInsuranceAmount, proposedStampDuty))}
                                    </span>
                                  </li>
                                  {this.isIslamic() && !!isCashBackCampaign == false && (
                                    <>
                                      <li>
                                        <i>Selling Price</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(proposedSellingAmount).toFixed(2))}</span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                                ) : (
                                <ul>
                                  <li>
                                    <i>Financing Type</i>
                                    <span>{this.isIslamic() ? 'CashVantage Personal Financing-i' : 'CashFirst Personal Loan'}</span>
                                  </li>
                                  <li>
                                    <i>Financing Amount</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(proposedLoanAmount).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>Tenure</i>
                                    <span>{proposedTenure} {(proposedTenure > 1) ? 'years' : 'year'}</span>
                                  </li>
                                  <li>
                                    <i>Stamp Duty</i>
                                    <span>- RM{FormHelper.thousandSeparator(Number(proposedStampDuty).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Profit' : 'Interest'} Rate</i>
                                    <span>{cashBackRate[proposedTenure]}% flat rate p.a</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Profit Payable' : 'Interest Charged'}</i>
                                    <span>RM {interestRateCharged.toFixed(2)}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Selling Price' : 'Total Repayment Amount'}</i>
                                    <span>RM {totalRepaymentAmount.toFixed(2)}</span>
                                  </li>
                                  <li>
                                    <i>Total Cash Back Amount</i>
                                    <span>RM {proposedTotalCashBack.toFixed(2)}</span>
                                  </li>
                                  {
                                    this.haveInsurance() && !!isCashBackCampaign == false ?
                                      (
                                        <li>
                                          <i>Premium Insurance</i>
                                          <span>- RM{FormHelper.thousandSeparator(Number(proposedInsuranceAmount).toFixed(2))}</span>
                                        </li>
                                      ) : null
                                  }
                                  <li>
                                    <i style={{ overflowInline: 'break-word', width:'100px' }}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} Before Cash Back</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(proposedMonthlyInstallmentAmount).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i style={{ overflowInline: 'break-word', width:'100px' }}>Cash Back (During Eligible Months)</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(proposedCashBack).toFixed(2))} per month</span>
                                  </li>
                                  <li>
                                    <i style={{ overflowInline: 'break-word', width:'100px' }}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} After Cash Back</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(installmentAfterCashback).toFixed(2))}</span>
                                  </li>
                                  <li className="highlight">
                                    <i>
                                      <img
                                        alt="payloan icon"
                                        src={require("../../assets/img/payloanicon.png")}
                                      />
                                      You will receive
                                    </i>
                                    <span>RM
                                      {FormHelper.thousandSeparator(this.calculateNetReceivable(proposedLoanAmount, proposedInsuranceAmount, proposedStampDuty))}
                                    </span>
                                  </li>
                                  {this.isIslamic() && !!isCashBackCampaign == false && (
                                    <>
                                      <li>
                                        <i>Selling Price</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(proposedSellingAmount).toFixed(2))}</span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                                )}
                              </>
                            )}
                          </div>

                          <div
                            className={`${!usingProposedAmount &&
                              "selected"} loan-offer-panel`}
                          >
                            <div className="loan-offer-panel-title">
                              <div
                                className={`box-checker ${!usingProposedAmount && "box-checked"}`}
                                onClick={() => {
                                  this.toggleOption(false);
                                }}
                              >
                              </div>
                              <span>
                                I {this.isIslamic() ? 'need' : 'want'} to change financing amount or {this.isIslamic() ? 'payment' : 'repayment'}
                                {' '} period.
                              </span>
                            </div>
                            {!usingProposedAmount && (
                              <>
                                <div className="loan-offer-adjust-panel">
                                  <span>
                                    <img
                                      src={require("../../assets/img/exclamation-circle-info.png")}
                                      alt="Info"
                                    />
                                    You can change the values below to meet your lifestyle needs.
                                  </span>
                                  <div className="calc-wrapper">
                                    <div className="line-one">
                                      <i>I {this.isIslamic() ? 'need' : 'want'} to borrow</i>
                                      <div
                                        className={`input-group mr-3 mb-2 ${
                                          styles["loan-amt"]
                                          }`}
                                      >
                                        <div className="input-group-prepend">
                                          <div className="input-group-text">
                                            RM
                                          </div>
                                        </div>
                                        <NumberFormat
                                          type="tel"
                                          name="selectedLoanAmount"
                                          className="form-control"
                                          maxLength={data.maxlength.loanAmount}
                                          suffix={",000"}
                                          onChange={this.onChangeLoanAmount}
                                          value={FormHelper.thousandSeparator(selectedLoanAmount)}
                                          placeholder="enter amount"
                                        />
                                        {!this.isLoanAmountWithinLimit() && (
                                          <div
                                            className={`tooltip-bubble tooltip-top tooltip-error`}
                                          >
                                            <div className="tooltip-message tooltip-error-message">
                                              Min RM
                                              {FormHelper.thousandSeparator(
                                                minLoanAmount
                                              )}{" "}
                                              up to RM
                                              {FormHelper.thousandSeparator(
                                                proposedLoanAmount
                                              )}
                                            </div>
                                          </div>
                                        )}
                                        <div
                                          className={styles["range-wrapper"]}
                                        >
                                          <input
                                            className="loan-view-offer-amt-slider e-range"
                                            id="loan-amt-slider"
                                            type="range"
                                            min={minLoanAmount}
                                            max={proposedLoanAmount}
                                            value={selectedLoanAmount}
                                            onChange={
                                              this.onChangeLoanAmountSlider
                                            }
                                            step="1000"
                                          />
                                          <div className={styles["label"]}>
                                            <div className={styles["amt-min"]}>
                                              {FormHelper.thousandSeparator(minLoanAmount)}
                                            </div>
                                            <div className={styles["amt-max"]}>
                                              {FormHelper.thousandSeparator(proposedLoanAmount)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="line-two">
                                      <h4>over </h4>
                                      <div
                                        className={`input-group ml-3 mr-3 mb-2 ${
                                          styles["loan-tenure"]
                                          }`}
                                      >
                                        <Select
                                          name="tenure"
                                          value={{ label: selectedTenure, value: selectedTenure }}
                                          onChange={this.onSelectTenure}
                                          options={tenureOptions}
                                          classNamePrefix="year-select"
                                          className="year-select-container"
                                          isSearchable={false}
                                          placeholder="-"
                                          onFocus={this.toggleFlash}
                                          styles={selectStyles}
                                        />
                                        <div className="input-group-append">
                                          <span
                                            className="input-group-text"
                                            id="basic-addon2"
                                          >
                                            {(selectedTenure > 1) ? 'years' : 'year'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {isCashBackCampaign == true ? (
                                  <ul>
                                  <li>
                                    <i>Financing Type</i>
                                    <span>{this.isIslamic() ? 'CashVantage Personal Financing-i' : 'CashFirst Personal Loan' }</span>
                                  </li>
                                  <li>
                                    <i>Financing Amount</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(selectedLoanAmount).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>Tenure</i>
                                    <span>{selectedTenure} {(selectedTenure > 1) ? 'years' : 'year'}</span>
                                  </li>
                                  <li>
                                    <i>Stamp Duty</i>
                                    <span>- RM{FormHelper.thousandSeparator(Number(selectedStampDuty).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Profit' : 'Interest'} Rate</i>
                                    <span>{selectedInterestRate.toFixed(2)}% flat rate p.a</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Profit Payable' : 'Interest Charged'}</i>
                                    <span>RM {interestRateCharged.toFixed(2)}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Selling Price' : 'Total Repayment Amount'}</i>
                                    <span>RM {totalRepaymentAmount.toFixed(2)}</span>
                                  </li>
                                  <li>
                                    <i>Total Cash Back Amount</i>
                                    <span>RM {selectedTotalCashBack.toFixed(2)}</span>
                                  </li>
                                  {
                                    this.haveInsurance() && !!isCashBackCampaign == false ?
                                      (
                                        <li>
                                          <i>Premium Insurance</i>
                                          <span>- RM{FormHelper.thousandSeparator(Number(selectedInsuranceAmount).toFixed(2))}</span>
                                        </li>
                                      ) : null
                                  }
                                  {
                                    !!isCashBackCampaign == true ? (
                                      <li>
                                        <i style={{ overflowInline: 'break-word', width:'100px' }}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} Before Cash</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</span>
                                      </li>
                                     ) : 
                                    (
                                      <li>
                                        <i style={{ overflowInline: 'break-word', width:'100px' }}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'}</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</span>
                                      </li>
                                    )
                                  }
                                  {
                                    !!isCashBackCampaign == true ? (
                                      <li>
                                        <i style={{ overflowInline: 'break-word', width:'100px' }}>Cash Back (During Eligible Months)</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(selectedCashBack).toFixed(2))} per month</span>
                                      </li>
                                    ) : null
                                  }
                                  {
                                    !!isCashBackCampaign == true ? (
                                      <li>
                                        <i style={{ overflowInline: 'break-word', width:'100px' }}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} After Cash Back</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(selectedInstallmentAfterCashBack).toFixed(2))}</span>
                                      </li>
                                    ): null
                                  }
                                  <li className="highlight">
                                    <i>
                                      <img
                                        alt="payloan icon"
                                        src={require("../../assets/img/payloanicon.png")}
                                      />
                                      You will receive
                                    </i>
                                    <span>RM
                                    {FormHelper.thousandSeparator(this.calculateNetReceivable(selectedLoanAmount, selectedInsuranceAmount, selectedStampDuty))}
                                    </span>
                                  </li>
                                  {this.isIslamic() && !!isCashBackCampaign == false && (
                                    <>
                                      <li>
                                        <i>Selling Price</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(selectedSellingAmount).toFixed(2))}</span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                                ): (
                                  <ul>
                                  <li>
                                    <i>{this.isIslamic() ? 'Financing' : 'Loan'} Type</i>
                                    <span>{this.isIslamic() ? 'CashVantage Personal Financing-i' : 'CashFirst Personal Loan' }</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Financing' : 'Loan'} Amount</i>
                                    <span>RM{FormHelper.thousandSeparator(Number(selectedLoanAmount).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>Tenure</i>
                                    <span>{selectedTenure} {(selectedTenure > 1) ? 'years' : 'year'}</span>
                                  </li>
                                  <li>
                                    <i>Stamp Duty</i>
                                    <span>- RM{FormHelper.thousandSeparator(Number(selectedStampDuty).toFixed(2))}</span>
                                  </li>
                                  <li>
                                    <i>{this.isIslamic() ? 'Profit' : 'Interest'} Rate</i>
                                    <span>{selectedInterestRate.toFixed(2)}% flat rate p.a</span>
                                  </li>
                                  {
                                    this.haveInsurance() && !!isCashBackCampaign == false ?
                                      (
                                        <li>
                                          <i>Premium Insurance</i>
                                          <span>- RM{FormHelper.thousandSeparator(Number(selectedInsuranceAmount).toFixed(2))}</span>
                                        </li>
                                      ) : null
                                  }
                                  <li>
                                    <i style={{ overflowInline: 'break-word', width:'100px' }}>Monthly {this.isIslamic() ? 'Payment' : 'Repayment'} </i>
                                    <span>RM{FormHelper.thousandSeparator(Number(selectedMonthlyInstallmentAmount).toFixed(2))}</span>
                                  </li>
                                  <li className="highlight">
                                    <i>
                                      <img
                                        alt="payloan icon"
                                        src={require("../../assets/img/payloanicon.png")}
                                      />
                                      You will receive
                                    </i>
                                    <span>RM
                                    {FormHelper.thousandSeparator(this.calculateNetReceivable(selectedLoanAmount, selectedInsuranceAmount, selectedStampDuty))}
                                    </span>
                                  </li>
                                  {this.isIslamic() && !!isCashBackCampaign == false && (
                                    <>
                                      <li>
                                        <i>Selling Price</i>
                                        <span>RM{FormHelper.thousandSeparator(Number(selectedSellingAmount).toFixed(2))}</span>
                                      </li>
                                    </>
                                  )}
                                </ul>
                                )}
                                
                              </>
                            )}
                          </div>

                          <div style={{ marginTop: "20px" }}>
                            <strong style={{ color: "#FF0000" }}>Disclaimer:</strong>
                          </div>
                          <div>
                            <strong style={{ color: "#FF0000" }}>* </strong>{data.disclamerDesc}
                            <br /><br />
                            <span style={{ fontWeight: "500" }}>By clicking <strong>"CONTINUE" </strong>, you are required to submit more information to the Bank
                            as part of the final approval process. This will only take 5 minutes of your time.</span>
                          </div>
                          <Button
                            id="gtm-initial-offer-continue"
                            className="proceedButton"
                            color="secondary"
                            onClick={this.onContinue}
                            disabled={this.shouldDisableNextButton()}
                          >
                            CONTINUE
                          </Button>
                        </Col>
                      </Row>
                    </Collapse>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </Container>
          <NeedHelp 
            name={this.props.eligibility.name} 
            mobile={this.props.eligibility.mobile} 
            email={this.props.eligibility.email} 
            currentScreen={PloanScreensList.SCREEN_INITIAL_LOAN_OFFER}
          />
          <Footer />
        </Section>
      </>
    );
  }
}


const mapStateToProps = state => ({
  ...state.initialLoanOffer,
  eligibility: state.eligibility,
  name: state.eligibility.name,
  insurance: state.eligibility.insurancePremium,
  selectedLoanType: state.calculator.selectedLoanType,
  eformUuid: state.webserviceConfig.eformUuid,
  calculator: state.calculator,
  initialLoanOffer: state.initialLoanOffer
});

const mapDispatchToProps = dispatch => ({
  updateInitialLoanOfferStore: (info) => {
    dispatch(initialLoanOffer.updateInitialLoanOfferInfo(info))
  },
  clearInitialLoanOfferStore: () => {
    dispatch(initialLoanOffer.clearInitialLoanOfferInfo())
  }
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(InitialOffer)));
