import React, { Component } from "react";
import styles from "./Input.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      focused: false
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  }

  handleBlur = () => {
    this.setState({
      touched: true,
      focused: false
    }, this.props.onBlur);
  }

  handleChange = (e) => {
    const value = e.target.value;
    if (this.props.prefixLabel)
      this.props.onChange(this.removePrefix(value));
    else
      this.props.onChange(value);
  }

  removePrefix = (val) => {
    const prefix = this.props.prefixLabel;
    const removedPrefixValue = val.replace(prefix, '');
    return removedPrefixValue;
  }

  renderLabel = () => {
    return (
      <label htmlFor={this.props.name} className={ styles["show"] }>
        {this.props.label}
      </label>
    );
  }

  renderGreenTick = () => {
    return (
      <div className={styles["form-tick"]}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
    );
  };

  shouldRenderGreenTick = () =>{
    const { shouldShowLoadingIndicator, value, shouldShowGreenTick, isValidInput } = this.props;
    return value && isValidInput(value) && !shouldShowLoadingIndicator && shouldShowGreenTick;
  }

  shouldShowLabel = () =>{
    const { focused } = this.state;
    const { isValidInput, value } = this.props;
    return (focused || isValidInput(value)) && value !== '';
  }

  shouldShowError = () =>{
    const { touched } = this.state;
    const { optional, isValidInput, value } = this.props;
    return touched && (value ? !isValidInput(value) : true) && !optional;
  }

  shouldShouldNoteText = () =>{
    const { touched } = this.state;
    const { isValidInput, value } = this.props;
    return (!value && !touched) || (touched && isValidInput(value))
  }

  render() {
    const { prefixLabel, format, thousandSeparator, mask, decimalSeparator, 
      errText, noteText, value, width, name, placeholder, spellCheck, 
      autoComplete, disabled, id, maxLength, required, specialClass, type, optional } = this.props;

    return (
      <div>
        <div
          className={`kyi-input-wrapper ${styles["kyi-input-wrapper"]}`}
          style={{
            position: "relative",
            width:
              width === "quarter"
                ? "25%"
                : width === "half"
                  ? "50%"
                  : width === "full"
                    ? "100%"
                    : "100%"
          }}
        >
          {this.shouldShowLabel() && this.renderLabel()}
          {
            format || prefixLabel || thousandSeparator || decimalSeparator || mask ?
              (<NumberFormat
                type="tel"
                name={name}
                className={`form-control input-field ${styles["kyi-input"]} ${this.shouldShowError() ? "is-invalid" : ""} ${specialClass}`}
                onChange={this.handleChange}
                value={value}
                placeholder={placeholder}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                format={format}
                mask={mask}
                spellCheck={spellCheck}
                autoComplete={autoComplete}
                disabled={disabled}
                thousandSeparator={thousandSeparator?thousandSeparator:','}
                prefix={prefixLabel}
                decimalSeparator={decimalSeparator?decimalSeparator:'.'}
              />) :
              (<input
                id={id}
                name={name}
                type={type}
                className={`form-control input-field ${styles["kyi-input"]} ${this.shouldShowError() ? "is-invalid" : ""} ${specialClass}`}
                required={optional ? false : required}
                spellCheck={spellCheck}
                autoComplete={autoComplete}
                maxLength={maxLength}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
              />)
          }
          {this.shouldRenderGreenTick()&&this.renderGreenTick()}
        </div>
        {this.shouldShowError() ? <p className="tips-msg">{errText}</p> : ""}
        { this.shouldShouldNoteText() ? <p className="tips-msg">{noteText}</p> : ""}
      </div>
    );
  }
}

Input.defaultProps = {
  onChange: () =>{ },
  onBlur: () => { },
  prefixLabel: null,
  shouldShowGreenTick:true,
  isValidInput: ()=>true,
  value:'',
  optional:false,
  name:'input-1',
  label:'',
  shouldShowLoadingIndicator:false,
  format:null,
  thousandSeparator:false,
  decimalSeparator:false,
  mask:'',
  placeholder: '',
  spellCheck:'false',
  autoComplete:'false',
  disabled:false,
  id:'input-id-1',
  maxLength:99999,
  required:true,
  errText:'',
  noteText:'',
  specialClass:'',
  width:'full',
  type : 'text'
}

Input.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  prefixLabel: PropTypes.string,
  shouldShowGreenTick: PropTypes.bool,
  isValidInput: PropTypes.func,
  value: PropTypes.any,
  optional:PropTypes.bool,
  name:PropTypes.string,
  label:PropTypes.string,
  shouldShowLoadingIndicator:PropTypes.bool,
  prefixLabel:PropTypes.string,
  format:PropTypes.any,
  thousandSeparator:PropTypes.bool,
  decimalSeparator:PropTypes.bool,
  mask:PropTypes.string,
  placeholder:PropTypes.string,
  spellCheck:PropTypes.string,
  autoComplete:PropTypes.string,
  disabled:PropTypes.bool,
  id:PropTypes.string,
  maxLength:PropTypes.number,
  required:PropTypes.bool,
  errText:PropTypes.string,
  noteText:PropTypes.string,
  specialClass:PropTypes.string,
  width:PropTypes.string,
  type:PropTypes.string
}

export default Input;
