import React, { Component } from 'react';
import Main from './Main'
import reducers from './reduxStore/reducers';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { withIdlerHandler } from './hoc';

const appReducers = combineReducers({ ...reducers });

const store = createStore(appReducers);

class App extends Component {
 
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <Main />
        </Provider>
      </div>
    );
  }
}

export { store };
export default withIdlerHandler(App);
