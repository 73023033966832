export const types = {
    UPDATE_WEBSERVICE_CONFIG: 'UPDATE_WEBSERVICE_CONFIG',
    CLEAR_WEBSERVICE_CONFIG: 'CLEAR_WEBSERVICE_CONFIG',
}

export function updateWebserviceConfig(info) {
    return {
        type: types.UPDATE_WEBSERVICE_CONFIG,
        info: info
    }
}

export function clearWebserviceConfig() {
    return { type: types.CLEAR_WEBSERVICE_CONFIG }
}