import { HTTP } from '../../../core';
import { CustomError } from '../../../core';
import Environment from '../../../environment';

export const requestTac = (params = {}, isProtectedWebService = false) => {
    console.log("requestTac:params:", params);
    const url = Environment.publicEformUrl + 'eform/requestTac';
    return HTTP.postData(url, params, isProtectedWebService);
}

export const validateTac = (params = {}, isProtectedWebService = false) => {
    console.log("validateTac:params:", params);
    const url = Environment.publicEformUrl + 'eform/validateTac';
    return HTTP.postData(url, params, isProtectedWebService);
}