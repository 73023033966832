import { types } from '../actions/fatcaCrs';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    fatcaDeclaration: '', 
    fatcaCountryBirth: '',
    taxResidence: '',
    tinArray: [{ taxJurisCountry: '', tinNumber: '', isNoTin: false, reasonOfNoTin: '', noTinOtherReason: '' }],
}

export default function fatcaCrs(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_FATCA_CRS_INFO:
        console.log("FATCA_CRS::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_FATCA_CRS_INFO:
            return initialState;

        default:
            return state;
    }
}