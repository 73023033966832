import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import { Container, Row, Col } from "reactstrap";
import SectionTitle from "../../components/SectionTitle";
import styles from "./InitialThanks.module.scss";
import { Session , Webservice } from '../../services';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import Routes from '../../routes';
import { PloanScreensList } from '../../constant';
import NeedHelp from "../../components/NeedHelp";

class InitialThanks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDisplayCrossSellBanner: false
    };
  }

  componentDidMount() {
    this.retrieveLandingPageParams();
    window.scrollTo(0, 0);
  }

  onCheckStatusClicked = () => {
    this.props.history.replace(Session.isValidSession() ? Routes.salesStatusList : Routes.default);
  }

  retrieveLandingPageParams = () => {
    Webservice.Product.getLandingPageParams(false).then(resp => {
      console.log("retrieveLandingPageParams:::", resp)
      
      const isDisplayCrossSellBanner = resp.isDisplayCrossSellBanner;
      this.setState({ isDisplayCrossSellBanner });
    })
  }

  render() {
    const { isDisplayCrossSellBanner } = this.state
    var bannerLinkUrl = "https://bit.ly/3YqXFDi";
    return (
      <>
        <Header
          version="b"
          currentStage={3}
          progressBarPercentage={33}
        />
        <Container>
          <SectionTitle text="Thanks for your submission" />
          <Row>
            <Col>
              <div id="gtm-initial-thanks-ack" className={`${styles["panel"]}`}>
                <h1>
                  Awesome, {this.props.eligibility.name}! Your initial financing results will be ready in 10
                  minutes.
                </h1>
                <Row className="check-status-container">
                  <Col className="mb-2">
                    <ul className="check-status-list-wrapper">
                      <div className={`line ${styles['line-fix']}`} style={{ top: '70px' }}>
                        <ul>
                          <li>
                            <div className="status-label">10mins</div>
                            <img alt="arrow icon" src={require('../../assets/img/arrow.png')} />
                          </li>
                          <li>
                            <div className="status-label">5mins</div>
                            <img alt="arrow icon" src={require('../../assets/img/arrow.png')} />
                          </li>
                          <li>
                            <div className="status-label">1-2days</div>
                            <img alt="arrow icon" src={require('../../assets/img/arrow.png')} />
                          </li>
                        </ul>
                      </div>
                      <li className={`check-status-list z-index-reset ${styles['check-status-list-fix']}`}>
                        <div className="check-status-list-item">
                          <div className="check-status-img">
                            <img alt="verify identity icon" src={require('../../assets/img/verify-identity-icon.png')} />
                          </div>
                          <div className="check-status-icon-wrapper">
                            <div className="check-status-icon">
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                          </div>
                          <p className="check-status-label">Verify Identity</p>
                        </div>
                      </li>
                      <div className="status-time-spend-mb-responsive"><span>10mins</span></div>
                      <li className={`check-status-list z-index-reset ${styles['check-status-list-fix']} disabled`}>
                        <div className="check-status-list-item">
                          <div className="check-status-img">
                            <img alt="initial load offer icon" src={require('../../assets/img/initial-loan-offer-icon.png')} />
                          </div>
                          <div className="check-status-icon-wrapper">
                            <div className="check-status-icon" style={{ backgroundColor: "#fcdead" }}>
                              2
                            </div>
                          </div>
                          <p className="check-status-label">Get Initial Financing Offer </p>
                        </div>
                      </li>
                      <div className="status-time-spend-mb-responsive"><span>5mins</span></div>
                      <li className={`check-status-list z-index-reset disabled`}>
                        <div className="check-status-list-item">
                          <div className="check-status-img">
                            <img alt="submit more info icon" src={require('../../assets/img/submit-more-info-icon.png')} />
                          </div>
                          <div className="check-status-icon-wrapper">
                            <div className="check-status-icon" style={{ backgroundColor: "#fcdead" }}>3</div>
                          </div>
                          <p className="check-status-label">Submit More Info & Document</p>
                        </div>

                      </li>
                      <div className="status-time-spend-mb-responsive"><span>1-2days</span></div>
                      <li className={`check-status-list z-index-reset ${styles['check-status-list-fix']} disabled`}>
                        <div className="check-status-list-item">
                          <div className="check-status-img">
                            <img alt="receive money icon" src={require('../../assets/img/receive-money-icon.png')} />
                          </div>
                          <div className="check-status-icon-wrapper">
                            <div className="check-status-icon check-status-icon-2">
                              <img alt="happy disabled icon" src={require('../../assets/img/happy-disabled.png')} />
                            </div>
                          </div>
                          <p className="check-status-label">Receive Money</p>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
                {/* <img
                  src={require("../../assets/img/flow-step-1.png")}
                  alt="Flow"
                /> */}
                <ul>
                  <li>
                    <h2>
                      While waiting for your initial financing offer to be ready, please make sure that you have:
                    </h2>
                  </li>
                  <li>1) Softcopy of your {this.props.calculator.selectedDoc.label}</li>
                  <li>2) Softcopy of your MyKad front and back.</li>
                </ul>
                <div className={styles.elevated}>
                  <div>
                    <div>
                      <img
                        src={require("../../assets/img/exclamation-circle-info.png")}
                        alt="Info"
                        style={{ height: "20px" }}
                      />
                    </div>
                    <span>
                      You will be notified via <strong>SMS</strong> and{" "}
                      <strong>email</strong> for the next instructions once initial financing offer
                      is ready.{" "}
                    </span>
                  </div>
                  <div>
                    <div>
                      <img
                        src={require("../../assets/img/email.png")}
                        alt="Info"
                        style={{ height: "20px" }}
                      />
                    </div>
                    <span>{this.props.eligibility.email}</span>
                  </div>
                  <div>
                    <div>
                      <img
                        src={require("../../assets/img/phone.png")}
                        alt="Info"
                        style={{ height: "20px" }}
                      />
                    </div>
                    <span>{this.props.eligibility.mobile}</span>
                  </div>
                </div>
                <button className="mt-4 ab-btn" onClick={this.onCheckStatusClicked}>{Session.isValidSession() ? "Check Status" : "Home"}</button>
                { 
                  isDisplayCrossSellBanner ? (
                    <div className={`${styles['cross-sell-banner']}`}>
                      <a href={bannerLinkUrl}>
                        <img
                          src={require("../../assets/img/saveplus-banner-mobile.jpg")}
                          alt="Save Plus Banner"
                          className={`${styles['save-plus-banner-mobile']}`}
                        />
                      </a>
                      <img
                        src={require("../../assets/img/saveplus-banner-web.jpg")}
                        alt="Save Plus Banner"
                        className={`${styles['save-plus-banner']}`}
                      />
                    </div>
                  ) : null 
                }
              </div>
              
            </Col>
          </Row>
        </Container>
        <NeedHelp 
          name={this.props.eligibility.name} 
          mobile={this.props.eligibility.mobile} 
          email={this.props.eligibility.email} 
          currentScreen={PloanScreensList.SCREEN_INITIAL_THANKS}
        />
        <Footer />
      </>
    );
  }
}
const mapStateToProps = state => ({
  eligibility: state.eligibility,
  calculator: state.calculator
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(InitialThanks);
