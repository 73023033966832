import React from 'react';
import { Container, Row, Col } from "reactstrap";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer/index";
import SectionTitle from "../../components/SectionTitle/index";
import Dropdown from "../../components/Dropdown";
import styles from './SecurityQuestions.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { YesNoList, PloanScreensList } from '../../constant';
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import BaseComponent from '../../class/baseComponent';
import Routes from '../../routes';
import { Webservice, PayloadGenerator } from '../../services';
import { connect } from 'react-redux';
import NeedHelp from "../../components/NeedHelp";
import { calculator } from '../../reduxStore/actions';

class SecurityQuestions extends BaseComponent {
    constructor(props) {
        super(props);
        console.log("Get CCRIS Payload from verifyIdentity page:::", props.location.state);
        let { question } = props.location.state;
        this.state = {
            progressBarPercentage: 0,
            isAnsweringGroup: [],
            securityAnswerGroup: [{ id: '', answer: '' }],
            question,
            isShowStaffContactLaterPage: false,
            isCallingWebService: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setInitialIsAnsweringGroup();
    }

    setInitialIsAnsweringGroup = () => {
        // To set boolean represent each question, isAnsweringGroup = [true, false, false, false, false]
        const newIsAnsweringGroup = [...this.state.question].map((boolean, index) => {
            if (index === 0) return true;
            return false;
        });
        const newSecurityAnswer = this.state.question.map(questionItem => {
            return { answer: '', id: questionItem.id };
        });
        this.setState({ isAnsweringGroup: newIsAnsweringGroup, securityAnswerGroup: newSecurityAnswer });
    }

    onSecurityAnswerChange = (answer, id) => {
        const newSecurityAnswer = this.state.securityAnswerGroup.map((answerObject, index) => {
            if (id !== answerObject.id) return answerObject;
            return { ...answerObject, answer: answer };
        });
        this.setState({ securityAnswerGroup: newSecurityAnswer });
    };

    onNext = async (id, i) => {
        const { isAnsweringGroup, securityAnswerGroup } = this.state;
        const newIsAnsweringGroup = isAnsweringGroup.map((answerBoolean, index) => {
            if (index === (i + 1)) return true;
            return false;
        });

        if (securityAnswerGroup.filter(item => !item.answer).length > 0) {
            this.setState({ isAnsweringGroup: newIsAnsweringGroup });
        }
        else {
            this.setState({ isCallingWebService: true })
            
            if (!await this.verifyAllAnswer()) {
                this.setState({ isCallingWebService: false })
                return;
            }

            this.setState({ isCallingWebService: false })
        }
    }

    verifyAllAnswer = async () => {
        const { securityAnswerGroup } = this.state;

        const securityAnswerWthLabel = securityAnswerGroup.map(answerObject => {
            return { ...answerObject, answer: answerObject.answer.value };
        });

        try {
            let eformUuid = this.props.webserviceConfig.eformUuid;
            let answerPayload = PayloadGenerator.genVerifySecurityAnswerPayload(securityAnswerWthLabel);

            await Webservice.Eform.getVerifiedSecurityAnswer(eformUuid, answerPayload).then(resp => {
                console.log('getVerifiedSecurityAnswer:success:', resp)
                if (resp.isAllCorrect) {
                    this.props.history.push(Routes.initialthanks)
                }
                if (resp.isAipOff) {
                    this.props.updateCalculatorStore({
                        isAipOff: resp.isAipOff
                    })
                    this.props.history.push(Routes.submitMoreInfo)   
                }  
                else {
                    this.setState({ isShowStaffContactLaterPage: true })
                }
            })
        } catch (error) {
            console.log('getVerifiedSecurityAnswer:err:', error)
            this.baseErrorHandler(error)
            return false;
        }

        return true;
    }

    renderQuestion = (questionObject, i) => {
        const isAnswering = this.state.isAnsweringGroup[i];
        const answerGroup = this.state.securityAnswerGroup[i];
        return (
            isAnswering &&
            <div key={i} className={styles['card-body']}>
                <h6>Question {i + 1}</h6>
                <h5 className={styles['card-title']}>{questionObject.question}</h5>
                <div className={styles['card-text']}>
                    <Dropdown
                        className={styles['dropdown-wrapper']}
                        htmlFor="yesno"
                        name="yesno"
                        placeholder="Select an answer"
                        options={YesNoList}
                        value={answerGroup.answer}
                        onChange={(answer) => { this.onSecurityAnswerChange(answer, questionObject.id) }}
                        isDisabled = {this.state.isCallingWebService}
                    />
                </div>
                <div className={styles['card-footer']}>
                    <button
                        type="button"
                        className={`ab-btn`}
                        data-index={i + 1}
                        disabled={!answerGroup.answer || (answerGroup.answer && this.state.isCallingWebService)}
                        onClick={() => { this.onNext(questionObject.id, i) }}
                    >
                        {(answerGroup.answer && this.state.isCallingWebService) ? "LOADING" : "NEXT"}<FontAwesomeIcon icon={faLongArrowAltRight} />
                    </button>
                    <span>{i + 1} of {this.state.question.length}</span>
                </div>
            </div>
        )
    }

    onOk = () => {
        this.props.history.push(Routes.default);
    }

    render() {
        return (
            <>
                <Header
                    version="b"
                    progressBarPercentage={0}
                    currentStage={2}
                />
                <Container>
                    {
                        this.state.isShowStaffContactLaterPage ? (
                            <>
                                <SectionTitle text="Thanks for your submission" />
                                <Row>
                                    <Col>
                                        <div className={styles.card}>
                                            <div className={styles['card-body']}>
                                                <div className={styles['card-text']}>
                                                    Thank you, {this.props.eligibility.name} ! Our Bank staff will contact you within 1-2 working days.
                                                </div>
                                                <div className={styles['card-footer']} style={{ marginTop: 40 }}>
                                                    <button type="button" className={`ab-btn`} onClick={this.onOk}>Ok</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                                <>
                                    <SectionTitle text="Identity Verification" />
                                    <span style={{ display: 'block', marginBottom: '10px' }}>Please answer these security questions correctly.</span>
                                    <Row>
                                        <Col>
                                            <div className={styles.card}>
                                                {
                                                    this.state.question.map((questionObject, i) => this.renderQuestion(questionObject, i))
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )
                    }
                </Container>
                <NeedHelp 
                    name={this.props.eligibility.name} 
                    mobile={this.props.eligibility.mobile} 
                    email={this.props.eligibility.email} 
                    currentScreen={PloanScreensList.SCREEN_SECURITY_QUESTION}
                />
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => ({
    webserviceConfig: state.webserviceConfig,
    eligibility: state.eligibility,
    calculator: state.calculator
  });
  
  const mapDispatchToProps = dispatch => ({
    updateCalculatorStore: (info) => {
        dispatch(calculator.updateCalculatorInfo(info))
    },
  });

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(SecurityQuestions)));