import React, { Component } from 'react';
import styles from './SectionTitle.module.css'


class SectionTitle extends Component {
    // constructor(props) {
    //     super(props);

    // }

    render() {
        return (
            <h1 className={styles.title}>{this.props.text}</h1>
        );
    }
}

export default SectionTitle;