import { HTTP } from '../../../core';
import { CustomError } from '../../../core';
import Environment from '../../../environment';

export const getLandingPageParams = (isAW = '', isProtectedWebService = false) => {
    console.log("getLandingPageParams");
    const url = Environment.productUrl + `getLandingPageParams?awCustomer=${isAW}`;
    return HTTP.getData(url, isProtectedWebService);
}

export const calculateCashBack = (loanAmount, tenure, isProtectedWebService = false) => {
    console.log("calculateCashBack", loanAmount, tenure);
    const url = Environment.productUrl + `calculateCashBack?loanAmount=${loanAmount}&tenure=${tenure}`;
    return HTTP.getData(url, isProtectedWebService);
}

