import * as Constant from '../../constant';
import { FormHelper } from '../../services';
import CryptoJS from 'crypto-js';
import RSA from '../../environment/rsa';

export const getAgeFromICNo = (icNo) =>{
    
    if(!icNo)
    return 0;

    let icNoFirst6Digit = icNo.substring(0,6);
    let icYear = Number(icNoFirst6Digit.substring(0, 2));
    let icMonth = Number(icNoFirst6Digit.substring(2,4));
    let icDate = Number(icNoFirst6Digit.substring(4,6));

    let yearIn4Digit  = (icYear < 90) ? 2000 + icYear : 1900 + icYear;
    
    let today = new Date();
    if (yearIn4Digit > today.getFullYear()){
        yearIn4Digit = 1900 + icYear;
    }

    let differentInYear = today.getFullYear() - yearIn4Digit;
    let differentInMonth = today.getMonth() + 1 - icMonth; 
    let differentInDay = today.getDate() - icDate;
    let age;

    if(differentInMonth>=0)
    {
        if(differentInMonth>0)
        {
            age = differentInYear;
        }else if(differentInMonth === 0)
            {
                if(differentInDay>=0)
                    age = differentInYear
                else
                    age = differentInYear - 1;
            }
    }else{
        age = differentInYear - 1 ;
    }

    return age;
}

export const getBumiStatusFromValue = (value) =>{
    return Constant.BumiList.find(item => item.value === value)
}

export const getRaceFromValue = (value) =>{
    return Constant.RaceList.find(item => item.value === value)
}

export const getCountryFromValue = (value) =>{
    return Constant.CountriesList.find(item => item.value === value)
}

export const getEducationLevelFromValue = (value) =>{
    return Constant.EducationLevelList.find(item => item.value === value)
}

export const getEmploymentNatureOfBusinessFromValue = (value) =>{
    return Constant.BusinessList.find(item => item.value === value)
}

export const getEmploymentNatureOfBusinessSectorFromValue = (value) =>{
    return Constant.BusinessSectorList.find(item => item.value === value)
}

export const getEmploymentOccupationFromValue = (value) =>{
    return Constant.OccupationList.find(item => item.value === value)
}

export const getEmploymentOccupationSectorFromValue = (value) =>{
    return Constant.OccupationSectorList.find(item => item.value === value)
}

export const getEmploymentTypeFromValue = (value) =>{
    return Constant.EmploymentTypeList.find(item => item.value === value)
}

export const getGenderTypeFromValue = (value) =>{
    return Constant.GenderList.find(item => item.value === value)
}

export const getMaritlStatusTypeFromValue = (value) =>{
    return Constant.MaritalStatusList.find(item => item.value === value)
}

export const getNationalityTypeFromValue = (value) =>{
    return Constant.NationalityList.find(item => item.value === value)
}

export const getStateFromValue = (value) =>{
    return Constant.StateList.find(item => item.value === value)
}

export const getLoanTenureFromValue = (value) =>{
    return { value, label: value }
}

export const getLoanTypeValue = (isIslamicLoan) =>{
    return Constant.LoanTypeList.find(item => isIslamicLoan?item.value === '2': item.value === '1' )
}

export const getSelectedDocFromValue = (selectedEmploymentType, chosenDoc) => {
    return Constant.EmploymentTypeDocument[selectedEmploymentType].find(item => item.value === chosenDoc)
}

export const getInsurancePremiumFromValue = (value) =>{
    return Constant.InsurancePremium.find(item => item.value === value);
}

export const getErrorCodeFromMessage = (errorMessage) =>{
    return errorMessage.substr((errorMessage.indexOf('[') + 1), (errorMessage.indexOf(']') - 1))
}

export const getPureErrorMessage = (errorMessage) =>{
    return errorMessage.substr((errorMessage.indexOf(']') + 1), errorMessage.length)
} 

export const getResidenceTypeFromValue = (value) =>{
    return Constant.ResidenceTypeList.find(item => item.value === value)
}

export const getLengthOfStayFromValue = (value) =>{
    return Constant.LengthOfStayList.find(item => item.value == value)
}

export const getResidenceStatusFromValue = (value) =>{
    return Constant.ResidenceStatusList.find(item => item.value === value)
}

export const getPurposeOfBorrowingFromValue = (value) =>{
    return Constant.PurposeOfBorrowingList.find(item => item.value === value)
}

export const getSourceOfFundFromValue = (value) =>{
    return Constant.SourceOfFundList.find(item => item.value === value)
}

export const getSourceOfWealthFromValue = (value) =>{
    return Constant.SourceOfWealthList.find(item => item.value === value)
}

export const getRelationshipFromValue = (value) =>{
    return Constant.RelationshipList.find(item => item.value === value)
}

export const getPreferredBankFromValue = (value) =>{
    return Constant.PreferredBankList.find(item => item.value === value)
}

export const getTaxResidenceFromValue = (value) =>{
    return Constant.TaxResidenceList.find(item => item.value === value)
}

export const getTaxJurisCountryFromValue = (value) =>{
    return Constant.TaxJurisCountryList.find(item => item.value === value)
}

export const getFatcaDeclarationFromValue = (value) =>{
    return Constant.FatcaDeclarationList.find(item => item.value == value)
}

export const getReasonOfNoTinFromValue = (value) =>{
    return Constant.ReasonOfNoTinList.find(item => item.value === value)
}

export const getFullCountryFromValue = (value) =>{
    return Constant.CountriesFullList.find(item => item.value === value)
}

export const getPreferredMailingAddressFromValue = (value) =>{
    return Constant.PreferredMailingAddressList.find(item => item.value === value)
}

export const getEmploymentCompanyTelNo = (value) =>{
    let nohaveplussixvalue = value.indexOf("+6") === -1
    let firstTwoDigit = value.substr(0, 2)
    let emptyValue = value === "" || value == undefined
    // if backend return below :
    // Example 1: "" / undefined
    if (emptyValue) {
        return FormHelper.phoneSeparatorForCompanyTel('+6' + 0)
    }
    if (!emptyValue && nohaveplussixvalue) {
        // Example 2: 60169607140
        // if (firstTwoDigit === '60' || firstTwoDigit === '65') { 
        if (firstTwoDigit === '60') { 
            return FormHelper.phoneSeparatorForCompanyTel('+' + value)
        }
        // Example 3: 0169607140
        else return FormHelper.phoneSeparatorForCompanyTel('+6' + value)
    }
    // Example 4: +60169607140
    else {
        return FormHelper.phoneSeparatorForCompanyTel(value)
    }
}

export const getDecryptedDataFromPayload = (payload, iv) =>{
    let rawData = CryptoJS.enc.Base64.parse(payload);
    let rawKey = CryptoJS.enc.Utf8.parse(RSA.aes128BitKey);
    let rawIv = CryptoJS.enc.Utf8.parse(iv);
    let cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: rawData, keySize: 128 });

    let plaintextData = CryptoJS.AES.decrypt(
        cipherParams, rawKey,
        { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: rawIv }
    )

    let plainText = CryptoJS.enc.Utf8.stringify(plaintextData)

    return plainText
}

export const getBumiStatusBasedOnRace =  (raceValue) => {
    if (raceValue === '4') //OTHER-BUMIPUTERA
      return Constant.BumiList.find(item => item.value === '1'); //Bumi

    if (raceValue === 'CHN') //CHINESE
      return Constant.BumiList.find(item => item.value === '2'); //Non-Bumi

    if (raceValue === 'IND') //INDIAN
      return Constant.BumiList.find(item => item.value === '2'); //Non-Bumi

    if (raceValue === '1') //MALAY
      return Constant.BumiList.find(item => item.value === '1');//Bumi

    if (raceValue === '9') //OTHER-NONBUMIPUTERA
      return Constant.BumiList.find(item => item.value === '2');//Non-Bumi
  }