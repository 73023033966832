import { types } from '../actions/webserviceConfig';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    eformUuid:'',
    eformStatus:'',
    jwt:''
}

export default function webserviceConfig(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_WEBSERVICE_CONFIG:
            return assign({}, modifiedState, action.info);

        case types.CLEAR_WEBSERVICE_CONFIG:
            return initialState;

        default:
            return state;
    }
}