import React, { Component } from 'react';
import styles from './Calculator.module.scss'
import NumberFormat from "react-number-format";
import Select from "react-select";
import { FormHelper, Webservice } from '../../services';
import { Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import * as data from "../../data/globalData";

const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused
            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
            : state.isSelected
                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                : "",
        borderColor: state.isFocused
            ? "#80bdff"
            : state.isSelected
                ? "#80bdff"
                : "#ced4da",
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            transition: "all 200ms"
        }
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? "hsl(0, 0%, 20%)" : "#495057",
        fontSize: '1rem',
        fontWeight: '500'
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "#333333",
        color: "white"
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? "#2096de"
            : state.isSelected
                ? "#2096de"
                : "rgba(255,255,255,0)",
        ":active": {
            backgroundColor: "#20aaff"
        }
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '1rem',
        fontWeight: '400'
    })
};

const errorStyles = {
    control: (provided, state) => (
        {
            ...provided,
            boxShadow: state.isFocused
                ? "0 0 0 0.2rem rgba(220,53,69, 0.25)"
                : state.isSelected
                    ? "0 0 0 0.2rem rgba(220,53,69, 0.25)"
                    : "#dc3545",
            borderColor: "#dc3545",
            "&:hover": {
                borderColor: state.isFocused ? "#dc3545" : "#ced4da",
                transition: "all 200ms"
            }
        }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? "hsl(0, 0%, 20%)" : "#495057",
        fontSize: '1rem',
        fontWeight: '500'
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "#333333",
        color: "white"
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? "#2096de"
            : state.isSelected
                ? "#2096de"
                : "rgba(255,255,255,0)",
        ":active": {
            backgroundColor: "#20aaff"
        }
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: '1rem',
        fontWeight: '400'
    })
};

class Calculator extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef()
        this.state = {
            flash: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.calculateMonthlyRepayment();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { interestRate, loanAmount, selectedTenure, grossMonthlyIncome, monthlyInstallment, isCashBackCampaign } = this.props;
        if (prevProps.interestRate !== interestRate
            || prevProps.loanAmount !== loanAmount
            || prevProps.selectedTenure.value !== selectedTenure.value) {
                if(isCashBackCampaign === false) {
                    this.calculateMonthlyRepayment();
                } else {
                    this.calculateCashBack();
                }
            
        }
    }

    calculateMonthlyRepayment = () => {
        const loanAmount = this.props.loanAmount;
        const interest = this.props.interestRate;
        const appliedTenure = this.props.selectedTenure.value;
        const newMonthlyRepayment = FormHelper.Calculator.calculateMonthlyRepayment(loanAmount, interest, appliedTenure)
        this.props.onMonthlyInstallmentChange(newMonthlyRepayment)
    }

    calculateCashBack = () => {
        const loanAmount = this.props.loanAmount;
        const interest = this.props.interestRate;
        const appliedTenure = this.props.selectedTenure.value;
        if(loanAmount > 0 && appliedTenure > 0) {
            Webservice.Product.calculateCashBack(loanAmount, appliedTenure).then((resp) => {
                this.props.onInstallmentBeforeCashback(resp.installmentBeforeCashback);
                this.props.onInstallmentAfterCashback(resp.installmentAfterCashback);
                this.props.onCashBack(resp.cashback);
                this.props.onTotalCashBack(resp.totalCashback);
            })
                
        }
    }

    isLoanAmountWithinLimit = () => {
        const { loanAmount, maxLoanAmount, minLoanAmount } = this.props;
        if (loanAmount >= minLoanAmount && loanAmount <= maxLoanAmount)
            return true;
        return false;
    }

    onLoanAmountChange = (event) => {
        let value = event.target.value;
        if (!FormHelper.Validation.isOnlyNumber(value)) {
            value = FormHelper.Replace.replaceAllExceptNumber(value);
        }
        value = value ? parseInt(value) : "";
        this.props.onLoanAmountChange(value);
    };

    onGrossIncomeChange = (event) => {
        let value = event.target.value;
        if (!FormHelper.Validation.isOnlyNumber(value)) {
            value = FormHelper.Replace.replaceAllExceptNumber(value);
        }
        value = value ? parseInt(value) : "";
        this.props.onGrossIncomeChange(value);
    }

    onTenureChange = (value) => {
        this.props.onTenureChange(value);
    };

    onLoanTypeChange = (value) => {
        // const y = this.props.y ? this.props.y : 0
        // if (this.props.y) {
        //     window.scrollTo(0, y);
        // }
        if (this.props.scroll) {
            const scrollPos = (this.scrollRef.current.offsetTop - 75)
            window.scrollTo(0, scrollPos);
        }

        this.props.onLoanTypeChange(value);
    };

    showRedOnTenure = () => {
        const { isAgeMoreThanMaxAgeEligibility, isIncomeLessThanInstalment } = this.props;
        return isAgeMoreThanMaxAgeEligibility || isIncomeLessThanInstalment;
    }

    showRedOnLoanAmount = () => {
        const {
            isIncomeLessThanInstalment, isLoanAmountLessThanMinLoanAmount,
            isLoanAmountNotWithinRangeBasedOnEmploymentType
        } = this.props;

        return isIncomeLessThanInstalment || isLoanAmountLessThanMinLoanAmount
            || isLoanAmountNotWithinRangeBasedOnEmploymentType;
    }

    showRedOnGrossIncome = () => {
        const { isIncomeLessThanMinIncome } = this.props;
        return isIncomeLessThanMinIncome;
    }

    setNumberTag = (value) => {
        let remainder = value % 10;
        console.log('rd',remainder);
        if(value == 11 || value == 12 || value == 13) {
            return 'th';
        } else {
            switch (remainder) {
                case 0:
                  return 'th';
                case 1:
                  return 'st';
                case 2:
                  return 'nd';
                case 3:
                  return 'rd';
                default:
                  return 'th';
              }
        }
      }

    render() {
        let { loanAmount, monthlyInstallment, interestRate, loanTypes, selectedTenure, 
            grossMonthlyIncome, selectedLoanType, tenureOptions, isCashBackCampaign, 
            installmentBeforeCashBack, installmentAfterCashBack,
            cashBackMonth, withoutCashBackMonthBefore, withoutCashBackMonthAfter,
            
         } = this.props;
        //label st, nd, rd, th
        let cashBackMonthLabel = this.setNumberTag(cashBackMonth);
        let withoutCashBackMonthBeforeLabel = this.setNumberTag(withoutCashBackMonthBefore);
        let withoutCashBackMonthAfterLabel = this.setNumberTag(withoutCashBackMonthAfter);
        
        console.log('here', cashBackMonth, withoutCashBackMonthBefore, withoutCashBackMonthAfter)
        return (
            <>
                <h1 className={styles.heading}>
                    <strong>1. How much do I need?</strong> Please enter your
                    accurate information below.
                    </h1>
                <div className={`mt-4 ${styles["eligibility-row"]}`}>
                    <span className={styles['question-one']}>I need</span>
                    <div
                        className={`input-group ml-3 mr-3 mb-2 ${styles["loan-amt"]}`}
                    >
                        <div className="input-group-prepend">
                            <div className="input-group-text">RM</div>
                        </div>
                        <NumberFormat
                            type="tel"
                            name="loanAmount"
                            className={`form-control ${this.showRedOnLoanAmount() ? "is-invalid" : ""}`}
                            maxLength={data.maxlength.loanAmount}
                            suffix={",000"}
                            onChange={this.onLoanAmountChange}
                            value={loanAmount ? FormHelper.thousandSeparator(loanAmount) : ''}
                            placeholder="enter amount"
                            onFocus={this.toggleFlash}
                        />
                    </div>
                    <span className={styles['question-two']}>over </span>
                    <div
                        className={`input-group ml-3 mr-3 ${
                            styles["loan-tenure"]
                            }`}
                    >
                        <Select
                            name="tenure"
                            value={selectedTenure}
                            onChange={this.onTenureChange}
                            options={tenureOptions}
                            classNamePrefix="year-select"
                            className="year-select-container"
                            isSearchable={false}
                            placeholder="-"
                            styles={this.showRedOnTenure() ? errorStyles : selectStyles}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">
                                {!selectedTenure
                                    ? "years"
                                    : selectedTenure > 1
                                        ? "years"
                                        : "year"}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={`${styles["eligibility-row"]} ${styles["eligibility-row-two"]}`}>
                    <span className={styles['question-three']}>My monthly gross income is </span>
                    <div
                        className={`input-group ml-3 mr-3 mb-2 ${styles["loan-amt"]}`}
                    >
                        <div className="input-group-prepend">
                            <div className="input-group-text">RM</div>
                        </div>
                        <input
                            type="tel"
                            name="grossMonthlyIncome"
                            className={`form-control ${this.showRedOnGrossIncome() ? "is-invalid" : ""}`}
                            maxLength={data.maxlength.monthlyGrossIncome}
                            onChange={this.onGrossIncomeChange}
                            value={grossMonthlyIncome ? FormHelper.thousandSeparator(grossMonthlyIncome) : ''}
                            placeholder="enter amount"
                            onFocus={this.toggleFlash}
                        />
                    </div>
                    <div className={styles['question-four-row']} ref={this.scrollRef}>
                        <span className={styles['question-four']}>
                            and I prefer{" "}
                            {!selectedLoanType
                                ? ""
                                : selectedLoanType.value === "1" &&
                                "a "}{" "}
                        </span>
                        <div
                            className={`input-group ml-3 mr-3 ${
                                styles["loan-tenure"]
                                }`}
                        >
                            <Select
                                name="loanType"
                                value={selectedLoanType}
                                onChange={this.onLoanTypeChange}
                                options={loanTypes}
                                classNamePrefix="loan-select"
                                className="loan-select-container"
                                isSearchable={false}
                                placeholder="select"
                                onFocus={this.toggleFlash}
                                styles={selectStyles}
                            />
                        </div>
                        <span>financing</span>
                    </div>

                </div>
                {
                    isCashBackCampaign === true ? (<div>
                        <div className={`${styles["loan-calc"]}`}>
                            <Row id="calculatordiv" className={styles['loan-calc-row']}>
                                <Col xs="3" className={styles["loan-calc-col"]}>
                                    <span>
                                        Total Financing Amount / Loan Amount*
                                        <h1>RM {loanAmount ? FormHelper.thousandSeparator(loanAmount) : '-'}</h1>
                                    </span>
                                </Col>
                                <Col xs="3" className={styles["loan-calc-col"]}>
                                    <span>
                                        Profit/Interest Rate*
                                        <h1>{interestRate ? Number(interestRate).toFixed(2) : '-'} %</h1>
                                        flat rate p.a
                                    </span>
                                </Col>
                                <Col xs="3" className={styles["loan-calc-col"]}>
                                    <span>
                                        Monthly Payment Amount Before Cash Back
                                        <h1>RM {installmentBeforeCashBack ? FormHelper.thousandSeparator(Number(installmentBeforeCashBack).toFixed(2)) : '-'}</h1>
                                    </span>
                                </Col>
                                <Col xs="3" className={styles["loan-calc-col"]}>
                                    <span>
                                        Monthly Payment Amount After Cash Back
                                        <h1>RM {installmentAfterCashBack ? FormHelper.thousandSeparator(Number(installmentAfterCashBack).toFixed(2)) : '-'}</h1>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <div className={`${styles.parent}`}>
                            <Row id="topArrow" className={styles['loan-calc-row']}>
                                <Col xs="7">
                                </Col>
                                <Col xs="5">
                                    <div className={`${styles.topArrow}`}>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="imagePosition" className={styles['loan-calc-row']}>
                                <Col xs="12" md="4">
                                    <div className={`${styles.leftMostRectangle}`}>
                                        <div className={`${styles.leftMostText}`}>
                                            <div>&bull; Before Cash Back Commence</div>
                                            <i>1<sup>st</sup> - 4<sup>th</sup> Month</i>
                                        </div>
                                    </div>
                                    <div className={`${styles.leftSecondRectangle}`}>
                                        <div className={`${styles.leftMostBottomText}`}>
                                            <div className={`${styles.innerTopText}`}>RM {installmentBeforeCashBack ? FormHelper.thousandSeparator(installmentBeforeCashBack) : '-'} </div>
                                            <i className={`${styles.innerText}`}>per month</i>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="8" />
                            </Row>
                            <Row id="imagePosition" className={styles['loan-calc-row']}>
                                <div className={`${styles.arrowDown}`}></div>
                            </Row>
                            <Row id="imagePosition" className={styles['loan-calc-row']}>
                                <Col xs="12" md="4"></Col>
                                <Col xs="12" md="4">
                                    <div className={`${styles.middleRectangle}`}>
                                        <div className={`${styles.middleText}`}>
                                            <div>&bull; Cash Back Period</div>
                                            <i>5<sup>th</sup> - {cashBackMonth}<sup>{cashBackMonthLabel}</sup> Month</i>
                                        </div>
                                    </div>
                                    <div className={`${styles.middleSecondRectangle}`}>
                                        <div className={`${styles.middleSecondBottomText}`}>
                                            <div className={`${styles.innerTopText}`}>RM {installmentAfterCashBack ? FormHelper.thousandSeparator(Number(installmentAfterCashBack).toFixed(2)) : '-'} </div>
                                            <i className={`${styles.innerText}`}>per month</i>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" md="4"></Col>
                            </Row>
                            <Row id="imagePosition" className={styles['loan-calc-row']}>
                                <div className={`${styles.arrowDown}`}></div>
                            </Row>
                            <Row id="imagePosition" className={styles['loan-calc-row']}>
                                <Col md="8"></Col>
                                <Col xs="12" md="4">
                                    <div className={`${styles.rightMostRectangle}`}>
                                        <div className={`${styles.rightMostText}`}>
                                            <div>&bull; After Cash Back Period</div>
                                            <i>{withoutCashBackMonthBefore}<sup>{withoutCashBackMonthBeforeLabel}</sup> - {withoutCashBackMonthAfter}<sup>{withoutCashBackMonthAfterLabel}</sup> Month</i>
                                        </div>
                                    </div>
                                    <div className={`${styles.rightSecondRectangle}`}>
                                        <div className={`${styles.rightSecondText}`}>
                                            <div className={`${styles.innerTopText}`}>RM {installmentBeforeCashBack ? FormHelper.thousandSeparator(Number(installmentBeforeCashBack).toFixed(2)) : '-'} </div>
                                            <i className={`${styles.innerText}`}>per month</i>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="bottomArrow" className={styles['loan-calc-row']}>
                                <Col xs="3">
                                </Col>
                                <Col xs="9">
                                    <div className={`${styles.bottomArrow}`}>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>) :
                        (<div className={`${styles["loan-calc"]}`}>
                            <Row id="calculatordiv" className={styles['loan-calc-row']}>
                                <Col xs="3" className={styles["loan-calc-col"]}>
                                    <span>
                                        Financing Amount
                                    <h1>RM {loanAmount ? FormHelper.thousandSeparator(loanAmount) : '-'}</h1>
                                    </span>
                                </Col>
                                <Col xs="3" className={styles["loan-calc-col"]}>
                                    <span>
                                        Indicative Profit/Interest Rate*
                                    <h1>{loanAmount ? Number(interestRate).toFixed(2) : '-'} %</h1>
                                        flat rate p.a
                                </span>
                                </Col>
                                <Col xs="3" className={`${styles["loan-calc-col"]} ${styles["loan-calc-col-last"]} `}>
                                    <span>
                                        Monthly Payment Amount
                                    <h1>RM {monthlyInstallment ? FormHelper.thousandSeparator(Number(monthlyInstallment).toFixed(2)) : '-'}</h1>
                                    </span>
                                </Col>
                            </Row>
                        </div>)
                }
                <div style={{ marginTop: "20px" }}>
                    <strong style={{ color: "#FF0000" }}>Disclaimer:</strong>
                </div>
                <div>
                    <strong style={{ color: "#FF0000" }}>* </strong>{data.disclamerDesc}
                </div>
            </>
        );
    }
}

Calculator.defaultProps = {
    scroll: true,
    isAgeMoreThanMaxAgeEligibility: false,
    isIncomeLessThanInstalment: false,
    isIncomeLessThanMinIncome: false,
    isLoanAmountLessThanMinLoanAmount: false,
    isLoanAmountNotWithinRangeBasedOnEmploymentType: false
}

Calculator.propTypes = {
    scroll: PropTypes.bool,
    loanAmount: PropTypes.number,
    tenureOptions: PropTypes.array,
    selectedTenure: PropTypes.object,
    selectedLoanType: PropTypes.object,
    monthlyInstallment: PropTypes.number,
    maxLoanAmount: PropTypes.number,
    minLoanAmount: PropTypes.number,
    interestRate: PropTypes.number,
    grossMonthlyIncome: PropTypes.number,
    loanTypes: PropTypes.array,
    onMonthlyInstallmentChange: PropTypes.func.isRequired,
    onInstallmentBeforeCashback: PropTypes.func.isRequired,
    onLoanAmountChange: PropTypes.func.isRequired,
    onGrossIncomeChange: PropTypes.func.isRequired,
    onTenureChange: PropTypes.func.isRequired,
    onLoanTypeChange: PropTypes.func.isRequired,
    isAgeMoreThanMaxAgeEligibility: PropTypes.bool,
    isIncomeLessThanInstalment: PropTypes.bool,
    isIncomeLessThanMinIncome: PropTypes.bool,
    isLoanAmountLessThanMinLoanAmount: PropTypes.bool,
    isLoanAmountNotWithinRangeBasedOnEmploymentType: PropTypes.bool
}

export default Calculator;