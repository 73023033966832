import React, { Component } from "react";
import { Button } from "reactstrap";
import { ErrorList } from '../../constant';
import { Getter } from '../../services/formHelper';
import Routes from '../../routes';
import { Session } from '../../services';

class BaseComponent extends React.Component {

    //must use withCustomAlert hoc in child class component
    baseErrorHandler = (error, onClick) => {
        let errorCode = Getter.getErrorCodeFromMessage(error.message);
        this.props.alert(error, this.shouldRenderExtraButton(errorCode, onClick),
            this.getAlertTitle(errorCode), () => { this.handleError(errorCode) });
    }

    shouldRenderExtraButton = (errorCode, onClick = () => { }) => {
        if (ErrorList[errorCode] === 'ACTIVE_APPLICATION')
           return (
                <Button color="primary" style={{ width: 'initial' }} onClick={onClick}>CHECK STATUS</Button>
            )
        return null;
    }

    getAlertTitle = (errorCode) => {
        if (ErrorList[errorCode] === 'SESSION_EXPIRED')
            return 'Session Expired';
        if (ErrorList[errorCode] === 'CCRIS_DOWN_TIME')
            return 'Service Temporarily Unavailable';
        return 'Alert';
    }

    //must be one of the route page in react router
    handleError = (errorCode) => {
        if (ErrorList[errorCode] === 'SESSION_EXPIRED') {
            if (Session.isValidSession())
                this.props.history.push(Routes.login)
            else
                this.props.history.push(Routes.default)
        }
    }
}

export default BaseComponent