import React, { Component } from 'react';
import { isValidSession, clearSession } from '../../services/session';
import Routes from '../../routes';
import { FormHelper } from '../../services';

const withRefreshHandler = (WrappedComponent) => {
    return class extends Component {

        componentDidMount() {
            window.addEventListener('beforeunload', this.onUnload);
            this.determinIfNeedRedirection();
        }

        componentWillUnmount() {
            window.removeEventListener('beforeunload', this.onUnload)
        }

        onUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Any string other than null or undefined will do';
            sessionStorage.setItem('ploan:handler:isRefreshed', "true");
        }

        determinIfNeedRedirection = () => {
            let isRefreshed = sessionStorage.getItem('ploan:handler:isRefreshed');

            if (!!isRefreshed) {
                sessionStorage.removeItem('ploan:handler:isRefreshed');

                if (isValidSession()) {
                    clearSession();
                    this.props.history.replace({
                        pathname: Routes.login
                    })
                } else {
                    let { pathname } = this.props.history.location;
                    let newPathName;
                    let ecid;
                    let xuid;
                    let cmsid;
                    let aopId;

                    if (this.props.location) {
                        ecid = FormHelper.UrlHelper.parseQueryParams(this.props.location.search).ecid;
                        xuid = FormHelper.UrlHelper.parseQueryParams(window.location.search).dxid;
                        cmsid = FormHelper.UrlHelper.parseQueryParams(window.location.search).cmsid;
                        aopId = FormHelper.UrlHelper.parseQueryParams(this.props.location.search).aopId;
                    }

                    switch (pathname) {
                        case Routes.initialOffer:
                        case Routes.submitMoreInfo:
                        case Routes.receiveMoney:
                        case Routes.complete:
                            newPathName = Routes.checkstatus;
                            break;

                        case Routes.login:
                            newPathName = Routes.login;
                            break;

                        case Routes.checkstatus:
                            newPathName = Routes.checkstatus;
                            break;

                        case Routes.aipOffcheckstatus:
                            newPathName = Routes.aipOffcheckstatus;
                            break;

                        default:
                            if(ecid || xuid || cmsid || aopId)
                                newPathName = Routes.default.substring(0, Routes.default.length - 1);
                            else

                                newPathName = Routes.default;
                    }

                    let searchParams;

                    if (ecid || xuid || cmsid || aopId) {

                        const allArray = [
                            { label: 'ecid', value: ecid },
                            { label: 'dxid', value: xuid },
                            { label: 'cmsid', value: cmsid },
                            { label: 'aopId', value: aopId },
                        ]
                        // remove from array if value is empty
                        const newArray = allArray.filter(o => !!o.value);

                        const labelValue = newArray.map(item => {
                            const endValue = item.label + `=` + item.value
                            return endValue
                        })

                        searchParams = { search: `?` + labelValue.join('&') }

                        // if(ecid && xuid && cmsid)
                        // {
                        //     searchParams = { search: `?ecid=${ecid}&dxid=${xuid}&cmsid=${cmsid}`}

                        // }
                        // if(ecid && xuid)
                        // {
                        //     searchParams = { search: `?ecid=${ecid}&dxid=${xuid}`}

                        // }
                        // if(xuid && cmsid)
                        // {
                        //     searchParams = { search: `?dxid=${xuid}&cmsid=${cmsid}`}

                        // }
                        // if(ecid && cmsid)
                        // {
                        //     searchParams = { search: `?ecid=${ecid}&cmsid=${cmsid}`}

                        // }else{
                        //     if(ecid)
                        //     {
                        //         searchParams = { search: `?ecid=${ecid}`}
                        //     }

                        //     if(xuid)
                        //     {
                        //         searchParams = { search: `?dxid=${xuid}`}
                        //     }

                        //     if(cmsid)
                        //     {
                        //         searchParams = { search: `?cmsid=${cmsid}`}
                        //     }
                        // }
                    }
                    console.log("searchParams::", searchParams)
                    this.props.history.replace({
                        pathname: newPathName,
                        ...searchParams ? searchParams : {}
                    })
                }
            }
        }

        /*
            shouldRedirect  = insert true or false condition to determine whether or not to redirect
            route = insert the route to redirect to
            params = optional, allow to send in external params to the destination route

        */
        onAfterRefreshed = (shouldRedirect = false, route = '/', params = {}) => {
            if (shouldRedirect) {
                this.props.history.replace({
                    pathname: route,
                    ...params
                })
            }
        }

        render() {
            return <WrappedComponent {...this.props} onAfterRefreshed={this.onAfterRefreshed} />
        }
    }
}

export default withRefreshHandler;