export const types = {
    UPDATE_AMENDMENTS: 'UPDATE_AMENDMENTS',
    CLEAR_AMENDMENTS: 'CLEAR_AMENDMENTS',
}

export function updateAmendments(amendments) {
    return {
        type: types.UPDATE_AMENDMENTS,
        amendments
    }
}

export function clearAmendments() {
    return { type: types.CLEAR_AMENDMENTS }
}