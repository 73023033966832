export const types = {
    UPDATE_MORE_ABOUT_YOURSELF_INFO: 'UPDATE_MORE_ABOUT_YOURSELF_INFO',
    CLEAR_MORE_ABOUT_YOURSELF_INFO: 'CLEAR_MORE_ABOUT_YOURSELF_INFO',
}

export function updateMoreAboutYourselfInfo(info) {
    return {
        type: types.UPDATE_MORE_ABOUT_YOURSELF_INFO,
        info: info
    }
}

export function clearMoreAboutYourselfInfo() {
    return { type: types.CLEAR_MORE_ABOUT_YOURSELF_INFO }
}