import calculator from './calculator';
import documents from './documents';
import eligibility from './eligibility';
import webserviceConfig from './webserviceConfig';
import moreAboutYourself from './moreAboutYourself';
import employmentDetails from './employmentDetails';
import emergencyContact from './emergencyContact';
import preferredBank from './preferredBank';
import fatcaCrs from './fatcaCrs';
import initialLoanOffer from './initialLoanOffer';
import amendments from './amendments';

export default {
    calculator,
    documents,
    eligibility,
    webserviceConfig,
    moreAboutYourself,
    employmentDetails,
    emergencyContact,
    preferredBank,
    fatcaCrs,
    initialLoanOffer,
    amendments
}