import { types } from '../actions/initialLoanOffer';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    minLoanAmount: 5000,
    proposedInsuranceAmount: 0,
    interestRateMapping: { 1: 3.99, 2: 5.99, 3: 5.99, 4: 8.38, 5: 8.38, 6: 8.38, 7: 8.38 },
    proposedLoanAmount: 0,
    proposedMonthlyInstallmentAmount: 0,
    proposedTenure: 1,
    insuranceRateMapping: { 1: 0.004, 2: 0.004, 3: 0.0085, 4: 0.0085, 5: 0.018, 6: 0.018, 7: 0.018 },
    proposedStampDuty:0,
    proposedSellingAmount:0,
    stampDutyRate: 0.5,
    selectedInsuranceAmount: 0,
    selectedLoanAmount:0,
    selectedMonthlyInstallmentAmount:0,
    selectedTenure:1,
    selectedStampDuty:0,
    selectedSellingAmount:0,
    selectedInterestRate:3.99,
    cashbackRate: {},
    cashback: 0,
    totalCashback: 0,
    selectedCashBack: 0,
    selectedTotalCashBack: 0,
    installmentAfterCashback: 0
}

export default function initialLoanOffer(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_INITIAL_LOAN_OFFER_INFO:
            return assign({}, modifiedState, action.info);

        case types.CLEAR_INITIAL_LOAN_OFFER_INFO:
            return initialState;

        default:
            return state;
    }
}