import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const html2Pdf = (outerDomId, innerDomId, isIslamic) =>{
    
    let pdfFileName = isIslamic?'Signed_Financing_Offer.pdf':'Signed_Loan_Offer.pdf';
    let fileLabel = isIslamic?'signedFinancingOffer':'signedLoanOffer';

    if(!outerDomId){
        return Promise.reject('outerDomId is required')
    }
    let pdfDom = document.getElementById(outerDomId);

    return html2canvas(pdfDom, {
        onclone: (clonedDom) => {
            clonedDom.getElementById(outerDomId).style.display = 'block';
            let width =  clonedDom.getElementById(innerDomId).scrollWidth;
            let height =  clonedDom.getElementById(innerDomId).scrollHeight;
            clonedDom.getElementById(outerDomId).style.width = (width + 50) + 'px';
            clonedDom.getElementById(outerDomId).style.height = height + 'px';
            // document.body.appendChild(clonedDom.getElementById(outerDomId))
        },
    }).then((canvas)=>{
    const pdfImage = canvas.toDataURL('image/jpeg', 0.7);
    let pdf = new jsPDF("p", "mm", "a4");
    let pdfHeight = pdf.internal.pageSize.getHeight();
    let pdfWidth = pdf.internal.pageSize.getWidth();
    pdf.addImage(pdfImage, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    let pdfBase64 = pdf.output('dataurlstring');
    console.log('pdfBase64:',pdfBase64)
    let signedLoanOffer = {
        base64: pdfBase64,
        file: dataURLtoFile(pdfBase64, pdfFileName),
        key: btoa(Date.now() + pdfFileName),
        label:fileLabel,
        name:pdfFileName,
        type:"doc"
    }
    return Promise.resolve(signedLoanOffer)
    })
}

export const html2PdfFile = (outerDomId, innerDomId, isIslamic) =>{
    
    let fileName = isIslamic?'Alliance Financing Offer.pdf':'Alliance Loan Offer.pdf'

    if(!outerDomId){
        return Promise.reject('outerDomId is required')
    }
    let pdfDom = document.getElementById(outerDomId);

    return html2canvas(pdfDom, {
        onclone: (clonedDom) => {
            clonedDom.getElementById(outerDomId).style.display = 'block';
            let width =  clonedDom.getElementById(innerDomId).scrollWidth;
            let height =  clonedDom.getElementById(innerDomId).scrollHeight;
            clonedDom.getElementById(outerDomId).style.width = (width + 50) + 'px';
            clonedDom.getElementById(outerDomId).style.height = height + 'px';
            // document.body.appendChild(clonedDom.getElementById(outerDomId))
        },
    }).then((canvas)=>{
    const pdfImage = canvas.toDataURL('image/jpeg', 0.7);
    let pdf = new jsPDF("p", "mm", "a4");
    let pdfHeight = pdf.internal.pageSize.getHeight();
    let pdfWidth = pdf.internal.pageSize.getWidth();
    pdf.addImage(pdfImage, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(fileName);
    })
}

export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export const calculateImageSize = (base64String) => {
    let padding, inBytes, base64StringLength;
    if (base64String.endsWith("==")) padding = 2;
    else if (base64String.endsWith("=")) padding = 1;
    else padding = 0;

    base64StringLength = base64String.length;
    console.log(base64StringLength)
    inBytes = (base64StringLength / 4) * 3 - padding;
    console.log(inBytes);
    return inBytes;
  }