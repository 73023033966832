import { types } from '../actions/documents';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    frontId:[],
    backId:[],
    signedLoanOffer:[],
    incomeStatements:[]
}

export default function documents(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_DOCUMENTS:
            return assign({}, modifiedState, action.info);

        case types.CLEAR_DOCUMENTS:
            return initialState;

        default:
            return state;
    }
}