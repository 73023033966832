import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import './style.css';
import PropTypes from 'prop-types';

class Tabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0'
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    renderNavItem = () => {
        return this.props.tabs.map((tab, index) => {
            return (
                <NavItem>
                    <NavLink active={(this.state.activeTab === index.toString())?true:false} className='tab-header' onClick={() => { this.toggle(index.toString()); }}>
                        <span className='tabName'>{tab.tabName}</span>
                    </NavLink>
                </NavItem>
            )
        })
    }

    renderTabPanes = () => {
        return this.props.tabs.map((tab, index) => {
            return (
                <TabPane tabId={index.toString()}>
                    {tab.tabComponent}
                </TabPane>
            )
        })
    }

    render() {
        return (
            <React.Fragment>
                <Nav tabs className="tabBar">
                    {this.renderNavItem()}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {this.renderTabPanes()}
                </TabContent>
            </React.Fragment>
        )
    }
}

Tabs.defaultProps = {
    tabs: []
}

Tabs.propTypes = {
    tac: PropTypes.array
}

export default Tabs;