export const types = {
    UPDATE_INITIAL_LOAN_OFFER_INFO: 'UPDATE_INITIAL_LOAN_OFFER_INFO',
    CLEAR_INITIAL_LOAN_OFFER_INFO: 'CLEAR_INITIAL_LOAN_OFFER_INFO',
}

export function updateInitialLoanOfferInfo(info) {
    return {
        type: types.UPDATE_INITIAL_LOAN_OFFER_INFO,
        info: info
    }
}

export function clearInitialLoanOfferInfo() {
    return { type: types.CLEAR_INITIAL_LOAN_OFFER_INFO }
}