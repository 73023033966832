import React from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../../components/Header/index";
import Section from "../../components/Section";
import "./pageNotFound.scss";
import { withCustomAlert } from '../../hoc';
import BaseComponent from '../../class/baseComponent';

class pageNotFound extends BaseComponent {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Header version="e" />
        <Section>
          <Container>
            <Row className="justify-content-center" style={{ minHeight: '91vh', alignItems: 'center' }}>
              <Col md="6">
                <div className="clearfix text-center">
                  <span className="display-3 ttitle">404</span>
                  <div class="mb-4 lead secondTitle">The page you are looking for was not found.</div>
                  <div class="mb-4 lead">
                    <a href="/" class="btn btn-link">Back to Home</a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </>
    );
  }
}

export default withCustomAlert(pageNotFound);
