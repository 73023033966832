import React from 'react';
import { Card, CardText, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';

class Pdpa extends React.PureComponent {

    render() {
        return (
            <Card className="share-info-wrapper">
                <CardText className="share-info-desc">
                Personal Data Usage Consent
                <br /><br />
                I/We hereby further give consent and authorise the Bank to disclose any information concerning me/us, my/our facilities, products and/or services for the purposes of strategic alliances, cross selling, marketing, and promotions to other departments and/or units within the Bank, other companies in the Bank*, credit and debts collection agencies, third parties service providers, system vendors, professional advisors i.e. solicitors, property valuer firms, insurers, takaful operators, unit trust management companies, brokers, issuing house, fulfilment companies, merchants or business partners who offer services to the Bank’s customers. With reference to the above, I/we understand the information which shall be limited to my/our name, contact details, products and services information and financial information shall be held by the Bank from time to time and may only be used by the Bank for direct marketing purposes. I/We understand that I/we have the right to withdraw or revoke my/ our consent for my/our information to be used for direct marketing at any time without affecting the performance of the contract by the Bank by notifying the Bank at 03-5516 9988. For avoidance of doubt, the consent given herein shall supersede all prior/previous consent(s) given by me/us to any other entities within the Bank.
                <br /><br />
                Akta Perlindungan Data Peribadi
                <br /><br />
                Saya/Kami dengan ini seterusnya memberi kebenaran dan membenarkan Bank untuk mendedahkan sebarang maklumat berkaitan saya/kami, kemudahan saya/kami, produk dan/atau perkhidmatan saya/kami bagi tujuan pertalian strategik, jualan silang, pemasaran dan promosi, kepada jabatan dan/atau unit lain di Bank, syarikat lain dalam Alliance Bank*, agensi pengutipan kredit dan hutang, penyedia perkhidmatan pihak ketiga, sistem vendor, penasihat professional iaitu peguam cara, firma penilai hartanah, penangung insuran, pengendali takaful, syarikat pengurusan unit amanah, broker, syarikat penerbit, syarikat pelaksanaan, peniaga atau rakan kongsi perniagaan yang menawarkan perkhidmatan kepada pelanggan bank.
                <br /><br />
                Merujuk kepada perkara di atas, saya/kami memahami maklumat yang terhad kepada nama saya/kami, butiran hubungan, maklumat produk dan perkhidmatan serta maklumat kewangan hendaklah dipegang oleh Bank dari semasa ke semasa dan hanya boleh digunakan oleh Bank untuk tujuan pemasaran langsung. Saya/Kami memahami  bahawa saya/kami mempunyai hak untuk menarik balik atau membatalkan persetujuan saya/kami untuk maklumat saya/kami digunakan untuk pemasaran langsung pada bila-bila masa tanpa menjejaskan prestasi kontrak oleh Bank dengan meghubungi Bank di talian 03-5516 9988.
                <br /><br />
                Bagi mengelakkan keraguan, kebenaran yang diberikan di sini akan mengatasi semua kebenaran terdahulu/sebelum ini yang diberikan oleh saya/kami kepada mana-mana entiti lain dalam Bank.
                </CardText>
                <Row className="share-info-input-wrapper">
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="isAcceptedPDPDConsent"
                                onClick={()=>{this.props.onChange(true)}}
                                className={`box-checker ${
                                    this.props.isAcceptedPDPDConsent !== undefined && this.props.isAcceptedPDPDConsent ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">YES</label>
                        </div> 
                    </Col>
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="isAcceptedPDPDConsent"
                                onClick={()=>{this.props.onChange(false)}}
                                className={`box-checker ${
                                    this.props.isAcceptedPDPDConsent !== undefined && this.props.isAcceptedPDPDConsent === false ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">NO</label>
                        </div>
                    </Col>
                </Row>
                <CardText className="mt-4 share-info-desc">
                    *Bank herein refers to Alliance Bank Malaysia Berhad and its wholly-owned subsidiaries, Alliance Islamic Bank Berhad.<br />
                    *Bank di sini merujuk kepada Alliance Bank Malaysia Berhad dan subsidiari-subsidiari milik penuhnya, iaitu Alliance Islamic Bank Berhad.
                </CardText>
            </Card>
        );
    }
}

Pdpa.defaultProps = {
    onChange: (value) => value,
    isAcceptedPDPDConsent: undefined
}

Pdpa.propTypes = {
    onChange: PropTypes.func,
    isAcceptedPDPDConsent: PropTypes.bool
}

export default Pdpa;