export const types = {
    UPDATE_DOCUMENTS: 'UPDATE_DOCUMENTS',
    CLEAR_DOCUMENTS: 'CLEAR_DOCUMENTS',
}

export function updateDocuments(info) {
    return {
        type: types.UPDATE_DOCUMENTS,
        info: info
    }
}

export function clearDocuments() {
    return { type: types.CLEAR_DOCUMENTS }
}