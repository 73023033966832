import React, { Component } from "react";
import SectionTitle from "../../components/SectionTitle/index";
import Section from "../../components/Section";
import { documents } from '../../reduxStore/actions';
import "./receiveMoney.css";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Collapse,
  Badge,
  Modal, ModalBody, ModalHeader, ModalFooter, Table
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import CustomDropzone from '../../components/Dropzone';
import { connect } from 'react-redux';
import { Webservice, PayloadGenerator, Util, ImageCompressor } from '../../services';
import Tabs from '../../components/Tabs';
import Routes from '../../routes';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoanOfferTemplate from "../../components/loanOfferTemplate";
import PLOANPrint from "../../components/Print";
import SignatureCanvas from 'react-signature-canvas'
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import BaseComponent from '../../class/baseComponent';
import { PloanScreensList, FormStatusList } from '../../constant';
import NeedHelp from "../../components/NeedHelp";
import completedReceiveMoney from "./completedReceiveMoney";

pdfjs.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.js`;

const UploadOfferRef = React.createRef();
const NRICFrontRef = React.createRef();
const NRICBackRef = React.createRef();
const TabRef = React.createRef();
const loanTemplateRef = React.createRef();

class ReceiveMoney extends BaseComponent {

  signatureRef = null;
  // maximumDocumentsSizeInKB = 2400000; //for testing purpose only
  maximumDocumentsSizeInKB = 8000000;

  constructor(props) {
    super(props);

    const { frontId, backId, signedLoanOffer, incomeStatements } = this.props.documents;

    this.state = {
      collapse1: true,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      index: 1,
      downloadAndPrint: false,
      uploadedLoanOffer: signedLoanOffer,
      incomeStatements: incomeStatements,
      mykadFront: frontId,
      mykadBack: backId,
      isCallingWebservice: false,
      isOpenSignaturePad: false,
      signature: '',
      signaturePadHeight: 300,
      signaturePadWidth: 400,
      documentToPreview: '',
      isOpenDocumentPreview: false,
      pdfWidth: 700,
      numPages: null,
      pageNumber: 1,
      showFileSizeModal: false,
      isExceedTotalFileSize: false
    };
  }

  componentDidMount() {
    this.props.clearDocumentsStore();
    window.scrollTo(0, 0);
  }

  toggleTabIcon = index => {
    if (this.state[`collapse${index}`]) {
      return <FontAwesomeIcon className="mr-2 mt-4" icon={faMinus} />;
    } else {
      return <FontAwesomeIcon className="mr-2 mt-4" icon={faPlus} />;
    }
  };

  toggleSubmitMoreInfo = e => {
    const index = e.currentTarget.dataset.index;
    this.setState({ [`collapse${index}`]: !this.state[`collapse${index}`] });
  };

  toggleStatus = index => {
    let status = "";
    let className = "";
    if (this.state.index < index) {
      status = "NOT COMPLETED";
      className = "badge-not-completed";
    } else if (this.state.index === index) {
      status = "IN PROGRESS";
      className = "badge-in-progress";
    } else {
      status = "COMPLETED";
      className = "badge-completed";
    }
    return <Badge className={`p-2 ml-4 ${className}`}>{status}</Badge>;
  };

  footerProceedButton = index => {
    const {
      uploadedLoanOffer,
      incomeStatements,
      mykadFront,
      mykadBack,
      completedReceiveMoney,
      isCallingWebservice,
      isExceedTotalFileSize
    } = this.state;
    if (!this.props.eligibility.isAW && index === 4) {
      if (
        uploadedLoanOffer.length > 0 &&
        incomeStatements.length > 0 &&
        mykadFront.length > 0 &&
        mykadBack.length > 0 &&
        completedReceiveMoney
      ) {
        return (
          <Section className="proceed-button-footer">
            <Container>
              <Row className="mt-5">
                <Col className="mt-5">
                  <div className="proceed-button-wrapper">
                    <Button className="proceedButton back-button">BACK</Button>
                    <Button
                      id="gtm-receive-money"
                      className="proceedButton"
                      onClick={this.onFooterContinueButtonClick}
                      disabled={isCallingWebservice || isExceedTotalFileSize}
                    >
                      {isCallingWebservice ? "SENDING..." : "CONTINUE "}
                      <img
                        alt="arrow right"
                        src={require("../../assets/img/arrow-right.png")}
                      />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        );
      }
    }
    else if (this.props.eligibility.isAW && index === 2) {
      if (uploadedLoanOffer.length > 0 && completedReceiveMoney) {
        return (
          <Section className="proceed-button-footer">
            <Container>
              <Row className="mt-5">
                <Col className="mt-5">
                  <div className="proceed-button-wrapper">
                    <Button className="proceedButton back-button">BACK</Button>
                    <Button
                      id="gtm-receive-money"
                      className="proceedButton"
                      onClick={this.onFooterContinueButtonClick}
                      disabled={isCallingWebservice || isExceedTotalFileSize}
                    >
                      {isCallingWebservice ? "SENDING..." : "CONTINUE "}
                      <img
                        alt="arrow right"
                        src={require("../../assets/img/arrow-right.png")}
                      />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        )
      }
    }
  };

  onLoanOfferContinueButton = () => {

    loanTemplateRef.current.getLoanTemplatePDFInBase64(this.state.signature).then(base64 => {

      let fileName = this.isIslamic() ? 'Signed_Financing_Offer.pdf' : 'Signed_Loan_Offer.pdf';
      let fileLabel = this.isIslamic() ? 'signedFinancingOffer' : 'signedLoanOffer';

      let signedLoanOffer = {
        base64: base64,
        file: Util.dataURLtoFile(base64, fileName),
        key: btoa(Date.now() + fileName),
        label: fileLabel,
        name: fileName,
        type: "doc"
      }

      this.setState({
        uploadedLoanOffer: [signedLoanOffer],
        downloadAndPrint: true,
        collapse1: false,
        collapse2: true,
        collapse3: false,
        collapse4: false,
        index: 2,
        completedReceiveMoney: this.props.documents.signedLoanOffer.length < 1 ? true : false
      });


      this.props.updateDocumentsStore({ signedLoanOffer: [signedLoanOffer] })
      this.triggerExceedTotalSizeModal();
    }).catch(error => {
      console.log('receiveMoney pdf error:', error);
      this.baseErrorHandler(error)
    })
  }

  onMyKadContinueButton = () => {
    this.props.updateDocumentsStore({ frontId: this.state.mykadFront })
    this.props.updateDocumentsStore({ backId: this.state.mykadBack })
    this.setState({
      completedReceiveMoney: true, collapse1: this.props.documents.signedLoanOffer.length < 1 ? true : false, collapse2: false, collapse3: false, collapse4: false, index: 4
    });
    this.triggerExceedTotalSizeModal();

  }

  onIncomeStatementsContinueButton = () => {
    this.props.updateDocumentsStore({ incomeStatements: this.state.incomeStatements })
    this.setState({
      collapse1: this.props.documents.signedLoanOffer.length < 1 ? true : false, collapse2: false, collapse3: true, collapse4: false, index: 3
    });
    this.triggerExceedTotalSizeModal();
  }

  triggerExceedTotalSizeModal = () => {
    const { mykadFront, mykadBack, uploadedLoanOffer, incomeStatements } = this.state;
    let documentsArrayToSend = [...mykadFront, ...mykadBack, ...uploadedLoanOffer, ...incomeStatements]

    let sumTotalDocSize = documentsArrayToSend.map(doc => doc.compressedSize ? doc.compressedSize : doc.file.size).reduce((prev, next) => { return prev + next; }, 0)

    if (sumTotalDocSize > this.maximumDocumentsSizeInKB) {
      this.setState({ showFileSizeModal: true, isExceedTotalFileSize: true })
    }
    else {
      this.setState({ showFileSizeModal: false, isExceedTotalFileSize: false })
    }
  }

  continueToUploadMykadDocument = (e) => {
    const { incomeStatements } = this.state;
    const { selectedDoc } = this.props.calculator;
    let selectedDocArray = selectedDoc.value.split('+');
    let activeTabIndex = null;

    selectedDocArray.map((doc, index) => {
      let sectionIncomeStatement = incomeStatements.filter(item => item.section === doc);
      if (sectionIncomeStatement.length < 1)
        activeTabIndex = activeTabIndex ? activeTabIndex : index;
    })

    if (activeTabIndex !== null) {
      console.log(TabRef)
      TabRef.current.toggle(activeTabIndex.toString());
      return;
    }

    this.onIncomeStatementsContinueButton();

  }

  onFooterContinueButtonClick = async () => {
    if (! await this.sendAllDocuments())
      return;
    if (! await this.updateApplication())
      return;

    this.props.history.push(Routes.complete);
  }

  onSignedLoanOfferUpdate = (uploadedLoanOffer) => {
    this.setState({ uploadedLoanOffer })
  }

  onIncomeStatementsUpdate = (incomeStatements) => {
    this.setState({ incomeStatements });
  }

  onMykadFrontUpdate = (mykadFront) => {
    this.setState({ mykadFront });
  }

  onMykadBackUpdate = (mykadBack) => {
    this.setState({ mykadBack });
  }

  canProceedNextOnUploadSignedLoanOffer = () => {
    const { uploadedLoanOffer } = this.state;

    if (!uploadedLoanOffer)
      return false;

    if (!uploadedLoanOffer.length > 0)
      return false;

    return !uploadedLoanOffer[0].uploading
  }

  canProceedNextOnUploadIncomeDocument = (item) => {
    const { incomeStatements } = this.state;
    const sectionIncomeStatements = incomeStatements.filter(doc => doc.section === item)

    if (!sectionIncomeStatements)
      return false;

    if (!sectionIncomeStatements.length > 0)
      return false;

    let isUploading = false;
    sectionIncomeStatements.map(item => {
      isUploading = item.uploading || isUploading;
    })

    return !isUploading;
  }

  toggleFileSizeModal = () => {
    if (this.state.isExceedTotalFileSize) {
      this.setState(prevState => ({
        collapse1: false, collapse2: true, collapse3: false, collapse4: false, index: 2, showFileSizeModal: !prevState.showFileSizeModal
      }));
    }
    else {
      this.setState(prevState => ({
        collapse1: false, collapse2: false, collapse3: true, collapse4: false, index: 3, showFileSizeModal: !prevState.showFileSizeModal
      }))
    }
  }

  modalFileSizeComponent = () => {
    return (
      <React.Fragment>
        <Modal className="error-modal" isOpen={this.state.showFileSizeModal} toggle={this.toggleFileSizeModal}>
          <ModalHeader toggle={this.toggleFileSizeModal}>Ops! <br />Your total uploaded files size are more than {Math.round(this.maximumDocumentsSizeInKB / 1000000)}MB</ModalHeader>
          <ModalBody>
            Please remove / re-upload the file to ensure total file size is less than {Math.round(this.maximumDocumentsSizeInKB / 1000000)}MB.
          </ModalBody>
          <ModalFooter>
            <Button className="primary" data-name="declaration" onClick={this.toggleFileSizeModal}>OK</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  };

  checkEmptyDocuments = () => {
    const { frontId, backId, signedLoanOffer, incomeStatements } = this.props.documents;
    const { isAW } = this.props.eligibility;
    console.log("this.props.documents------------", frontId, backId, signedLoanOffer, incomeStatements, isAW)

    if (isAW) {
      if (signedLoanOffer.length < 1) {
        console.log("isEmptySignedLoanOffer")
        this.props.alert("Please check and sign financing offer and click 'Continue' button at bottom left again.")
        this.setState({
          collapse1: true,
          collapse2: false,
          collapse3: false,
          collapse4: false,
          index: 1,
          completedReceiveMoney: false
        });
        return false;
      }
      else {
        console.log("isNotEmpty")
        return true;
      }
    }
    else {
      if (frontId.length < 1 || backId.length < 1) {
        console.log("isEmptyFrontId")
        this.props.alert("Please check uploaded MyKad and click 'Continue' button at bottom left again.")
        this.setState({
          collapse1: false,
          collapse2: false,
          collapse3: true,
          collapse4: false,
          index: 3
        });
        return false;
      }

      if (signedLoanOffer.length < 1) {
        console.log("isEmptySignedLoanOffer")
        this.props.alert("Please check and sign financing offer and click 'Continue' button at bottom left again.")
        this.setState({
          collapse1: true,
          collapse2: false,
          collapse3: false,
          collapse4: false,
          index: 1
        });
        return false;
      }

      if (incomeStatements.length < 1) {
        console.log("isEmptyIncomeStatements")
        this.props.alert("Please check uploaded " + this.props.calculator.selectedDoc.label + " and click 'Continue' button at bottom left again.")
        this.setState({
          collapse1: false,
          collapse2: true,
          collapse3: false,
          collapse4: false,
          index: 2
        });
        return false;
      }

      if (frontId.length > 0 && backId.length > 0 && signedLoanOffer.length > 0 && incomeStatements.length > 0) {
        console.log("isNotEmpty")
        return true;
      }
    }
  }

  sendAllDocuments = async () => {

    if (!this.checkEmptyDocuments()) return;

    this.triggerExceedTotalSizeModal();

    this.setState({ isCallingWebservice: true })

    const { frontId, backId, signedLoanOffer, incomeStatements } = this.props.documents;

    const { eformUuid } = this.props.webserviceConfig;
    let documentsArrayToSend = [...frontId, ...backId, ...signedLoanOffer, ...incomeStatements]

    let documentPayloadArray = await Promise.all(documentsArrayToSend.filter(item => !item.hasSent).map(async (document) => {
      return await PayloadGenerator.genAttachmentPayload(document);
    })).then(payloadArray => payloadArray)
    console.log('documentPayloadArray:', documentPayloadArray)

    try {
      await Webservice.Attachments.sendMultipleAttachments(eformUuid, documentPayloadArray).then(resp => {
        console.log('sendAllDocuments:resp:', resp)
        this.updateDocumentUploadStatus(resp)
        resp.map(item => {
          if (item.error)
            throw new Error(`Error uploading ${item.fileName}:${item.error}`)
        })
      }).catch(error => {
        throw error;
      })
    } catch (error) {
      console.log('sendAllDocuments:error:', error)
      this.baseErrorHandler(error);
      this.setState({ isCallingWebservice: false })
      return false;
    }

    this.setState({ isCallingWebservice: false })
    return true;
  }

  updateDocumentUploadStatus = (status) => {
    status.map(item => {
      const { frontId, backId, signedLoanOffer, incomeStatements } = this.props.documents;
      switch (item.label) {
        case 'signedLoanOffer':
          let newSignedLoanOffer = signedLoanOffer.map(element => element.key === item.key ? ({ ...element, hasSent: item.hasSent }) : element)
          this.props.updateDocumentsStore({ signedLoanOffer: newSignedLoanOffer })
          break;

        case 'incomeStatements':
          let newIncomeStatements = incomeStatements.map(element => element.key === item.key ? ({ ...element, hasSent: item.hasSent }) : element)
          this.props.updateDocumentsStore({ incomeStatements: newIncomeStatements })
          break;

        case 'mykadFront':
          let newFrontId = frontId.map(element => element.key === item.key ? ({ ...element, hasSent: item.hasSent }) : element)
          this.props.updateDocumentsStore({ frontId: newFrontId })
          break;

        case 'mykadBack':
          let newBackId = backId.map(element => element.key === item.key ? ({ ...element, hasSent: item.hasSent }) : element)
          this.props.updateDocumentsStore({ backId: newBackId })
          break;
      }

    })
  }

  updateApplication = async () => {
    try {
      this.setState({ isCallingWebservice: true })
      let eformUuid = this.props.webserviceConfig.eformUuid;
      let isAipOff = this.props.calculator.isAipOff;
      if (isAipOff) {
        await Webservice.Eform.updateAipOffCompletedApplication(eformUuid).then(resp => {
          console.log('updateAipOffCompletedApplication:resp:', resp)
        }).catch(error => {
          throw error;
        })
      }
      else {
        await Webservice.Eform.updateRPAApplication(eformUuid).then(resp => {
          console.log('updateApplication:resp:', resp)
        }).catch(error => {
          throw error;
        })
      }
    } catch (error) {
      console.log('updateApplication:', error);
      this.setState({ isCallingWebservice: false })
      this.baseErrorHandler(error);
      return false;
    }
    this.setState({ isCallingWebservice: false })
    return true;
  }

  toogleSignaturePad = () => {
    this.setState(previousState => ({
      isOpenSignaturePad: !previousState.isOpenSignaturePad
    }))
  }

  toogleDocumentPreview = () => {
    this.setState(previousState => ({
      isOpenDocumentPreview: !previousState.isOpenDocumentPreview,
      documentToPreview: ''
    }))
  }

  clearSignature = () => {
    this.signatureRef.clear()
  }

  onSign = () => {

    this.setState(previousState => ({
      isOpenSignaturePad: !previousState.isOpenSignaturePad,
      signature: this.signatureRef.isEmpty() ? "" : this.signatureRef.getTrimmedCanvas().toDataURL()
    }))
  }

  onDocumentClick = (document) => {
    this.setState({
      documentToPreview: document,
      isOpenDocumentPreview: true
    })
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  onFileSizeExceedError = () => {
    this.props.alert('Oops,the file you choose has exceeded the maximum size.')
  }

  onFileTypeError = () => {
    this.props.alert('Oops,the file you choose has invalid format. Only PDF, JPG and PNG is allowed.')
  }

  changePage = offset => this.setState(prevState => ({
    pageNumber: prevState.pageNumber + offset,
  }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  renderIncomeStatementDropzoneElement = (item, documentName, documentDescription, icon) => {
    const IncomeStatementsRef = React.createRef();
    const { incomeStatements } = this.state;
    let sectionIncomeStatements = incomeStatements.filter(doc => doc.section === item);
    let nonSectionIncomeStatements = incomeStatements.filter(doc => doc.section !== item);
    let maxSizeInByte = 2097152;
    return (
      <>
        <div className="upload-kwsp-container" id="epf" style={{ borderTopLeftRadius: '0px' }}>
          <div className="left-panel-upload">
            <img
              alt="kwsp logo"
              className="upload-panel-logo"
              src={icon}
              style={{ height: '50px', width: 'auto' }}
            />
            <h1>{documentName}</h1>
            <div className="upload-desc" style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FontAwesomeIcon icon={faCheck} />
                <p>{documentDescription}</p>
              </div>
              {
                documentName === 'LHDN' ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FontAwesomeIcon icon={faCheck} />
                      <p>{'Acknowledgement receipt'}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FontAwesomeIcon icon={faCheck} />
                      <p>{'Validated receipt of payment to LHDN OR print screen of bank statement if it is online bank statement (if applicable)'}</p>
                    </div>
                  </>
                ) : (null)
              }
            </div>
            <span>Supported format files are JPG, PNG, PDF. Max {Math.round(maxSizeInByte / 1000000)}MB for PDF file.</span>
            <Button
              onClick={() => IncomeStatementsRef.current.open()}
            >
              Upload
            </Button>
          </div>
          <div className="right-panel-upload">
            <CustomDropzone label='incomeStatements'
              categoryFiles={sectionIncomeStatements}
              updateCategoryFiles={(result) => { this.onIncomeStatementsUpdate(result.concat(nonSectionIncomeStatements)) }}
              fileLimit={100}
              maxSize={maxSizeInByte}
              ref={IncomeStatementsRef}
              section={item}
              onItemClick={this.onDocumentClick}
              onFileSizeExceedError={this.onFileSizeExceedError}
              onFileTypeError={this.onFileTypeError}
            />
          </div>
        </div>
        <Button
          id="gtm-upload-income-doc"
          data-index="2"
          className="proceedButton"
          disabled={!this.canProceedNextOnUploadIncomeDocument(item)}
          onClick={this.continueToUploadMykadDocument}
        >
          CONTINUE
        </Button>
      </>
    )
  }

  renderIncomeStatementsDropzone = () => {
    const { selectedDoc } = this.props.calculator;
    let selectedDocArray = selectedDoc.value.split('+');

    let toRender = selectedDocArray.map(item => {

      let isUnitMonths = item.substring(item.indexOf('[') + 1, item.indexOf(']')).includes('M');
      let durationUnit = item.substring(item.indexOf('[') + 1, item.indexOf(']')).replace(/[^\d]/gi, '');
      let documentType = item.substring(item.indexOf(']') + 1);
      let documentName = '';
      let documentDescription = '';
      let icon;

      switch (documentType) {
        case 'SSM':
          documentName = "SSM";
          documentDescription = 'SSM Registration';
          icon = require('../../assets/img/ssm-icon.png')
          break;

        case 'LHDN':
          documentName = "LHDN";
          documentDescription = 'Form B/BE';
          icon = require('../../assets/img/lhdn-icon.png')
          break;

        case 'BORANG_B':
          documentName = "BORANG B";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/lhdn-icon.png')
          break;

        case 'BORANG_BE':
          documentName = "BORANG BE";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/lhdn-icon.png')
          break;

        case 'BANK_STATEMENT':
          documentName = "BANK STATEMENT";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/bank-icon.png')
          break;

        case 'SALARY':
          documentName = "SALARY SLIP";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/payslip-icon.png')
          break;

        case 'TAX_RECEIPT':
          documentName = "TAX RECEIPT";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/receipt-icon.png')
          break;

        case 'EPF':
          documentName = "EPF";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/kwsp-icon.png')
          break;

        case 'COMMISION_STATEMENT':
          documentName = "COMMISION STATEMENT";
          documentDescription = `Latest ${durationUnit} ${isUnitMonths ? parseInt(durationUnit) > 1 ? 'months' : 'month' : parseInt(durationUnit) > 1 ? 'years' : 'year'} ${documentName}`;
          icon = require('../../assets/img/commission-icon.png')
          break;
      }

      return { tabName: documentName, tabComponent: this.renderIncomeStatementDropzoneElement(item, documentName, documentDescription, icon) }
    })

    return <Tabs tabs={toRender} ref={TabRef} />
  }

  renderPrintContent = () => {
    return (
      <LoanOfferTemplate
        ref={loanTemplateRef}
        id='loanOfferTemplate'
        calculator={this.props.calculator}
        basicInfo={this.props.eligibility}
        moreAboutYourself={this.props.moreAboutYourself}
        employmentDetails={this.props.employmentDetails}
        emergencyContact={this.props.emergencyContact}
        disbursementBank={this.props.preferredBank}
        fatcaCrs={this.props.fatcaCrs}
        initialLoanOffer={this.props.initialLoanOffer}
        signature={this.state.signature}
      />
    )
  }

  renderSignaturePadModal = () => {

    const { signaturePadHeight, signaturePadWidth } = this.state;
    return (
      <React.Fragment>
        <Modal className="signature-modal" isOpen={this.state.isOpenSignaturePad} toggle={this.toogleSignaturePad} size='lg' id='signature-modal' onOpened={this.setSignatureSize}>
          <ModalHeader toggle={this.toogleSignaturePad}>Please Sign</ModalHeader>
          <ModalBody>
            <SignatureCanvas ref={(ref) => this.signatureRef = ref} canvasProps={{ width: signaturePadWidth, height: signaturePadHeight }} />
          </ModalBody>
          <ModalFooter>
            <Button className="back-btn" onClick={this.clearSignature}>CLEAR</Button>
            <Button color="primary" onClick={this.onSign}>APPLY CHANGES</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  };

  renderDocumentPreviewModal = () => {
    const { documentToPreview, numPages, pageNumber, pdfWidth } = this.state;
    const isPdf = documentToPreview && documentToPreview.file.type.indexOf('application/pdf') > -1;
    const screenRatio = Math.round(window.innerWidth / window.innerHeight);

    return (
      <React.Fragment>
        <Modal className="signature-modal" isOpen={this.state.isOpenDocumentPreview} toggle={this.toogleDocumentPreview}
          size='lg' id='document-preview-modal' onOpened={() => { isPdf && this.setPdfWidth() }}>
          <ModalHeader toggle={this.toogleDocumentPreview}>Preview</ModalHeader>
          <ModalBody>
            {
              (isPdf) ? (
                <React.Fragment>
                  <Document
                    file={documentToPreview.base64}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    onLoadError={error => { console.log('loadPdf:error:', error) }}
                  >
                    <div style={{ overflow: 'auto', maxHeight: (pdfWidth / screenRatio) }}>
                      <Page pageNumber={pageNumber} width={pdfWidth} />
                    </div>
                  </Document>
                  {(numPages > 1) && (<div>
                    <p>
                      Page {pageNumber} of {numPages}
                    </p>
                    <button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={this.previousPage}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={this.nextPage}
                    >
                      Next
                    </button>
                  </div>)}
                </React.Fragment>
              ) : (
                  <div style={{ overflow: 'auto', maxHeight: (pdfWidth / screenRatio), display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <object
                      data={documentToPreview.base64}
                    />
                  </div>
                )
            }
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  };

  setPdfWidth = () => {
    let pdfWidth = document.getElementById('document-preview-modal').offsetWidth * 0.95;
    this.setState({
      pdfWidth
    })
  }

  setSignatureSize = () => {
    let signaturePadWidth = document.getElementById('signature-modal').offsetWidth * 0.9;
    let signaturePadHeight = signaturePadWidth * 0.4;

    this.setState({
      signaturePadHeight, signaturePadWidth
    })
  }

  isIslamic = () => {
    return this.props.calculator.selectedLoanType.value === '2';
  }

  // downloadPdf = () => {
  //   Util.html2PdfFile('pdfviewer', 'loanOfferTemplate', this.isIslamic())
  // }

  render() {
    const { selectedDoc, isAipOff } = this.props.calculator
    const { name } = this.props.eligibility;
    const { signature } = this.state;
    return (
      <>
        <Header
          version="b"
          currentStage={5}
          progressBarPercentage={100}
        />
        <Section className="receive-money-section">
          <Container>
            <Row>
              <Col>
                { isAipOff ? <SectionTitle text={`Hi, ${name}!`} /> : <SectionTitle text={`GOOD NEWS ${name}!`} /> }
                <p className="section-description">What you need to do next:</p>
                <ul className="section-info-description-list">
                  <li>1. Sign on the financing offer.</li>
                  <li hidden={this.props.eligibility.isAW}>2. Upload your {selectedDoc.label}</li>
                  <li hidden={this.props.eligibility.isAW}>3. Upload your MyKad.</li>
                </ul>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="mb-4">
                <ListGroup className="submit-more-info-form-list">
                  <ListGroupItem className="justify-content-between">
                    <div className="submit-more-info-form-label" data-index="1"
                      onClick={
                        this.state.downloadAndPrint ? this.toggleSubmitMoreInfo : null
                      }
                    >
                      {this.toggleTabIcon(1)}
                      Sign on the financing offer
                      {this.toggleStatus(1)}
                    </div>
                    <Collapse
                      className="ml-4 mb-responsive-collapse"
                      isOpen={this.state.collapse1}
                    >
                      <Row className="mt-4 mb-responsive">
                        <Col>
                          <div className="section-description-2 section-description-3">
                            Click "<strong>SIGN</strong>" button
                            below, to sign the Bank financing offer.
                          </div>
                          {/* <Row>
                            <div style={{marginLeft:'20px'}}>
                              <PLOANPrint
                                toPrint={this.renderPrintContent()}
                                continueProcess={this.continueProcess}
                              />
                            </div>
                            <div style={{marginLeft:'20px'}}>
                              <Button className="download-print-button" onClick={this.toogleSignaturePad} data-index="1"> SIGN </Button>
                            </div>
                          </Row> */}
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-responsive" id="pdfviewer">
                        <Col>
                          {this.renderPrintContent()}
                        </Col>
                      </Row>
                      <Row>
                        {signature ? (
                          <React.Fragment>
                            <div className="signature-buttons">
                              <Button id="gtm-upload-signed-loan-offer" className="proceedButton" onClick={this.onLoanOfferContinueButton} data-index="1"> CONTINUE </Button>
                            </div>
                            <div className="signature-buttons">
                              {/* <PLOANPrint toPrint={this.renderPrintContent()} buttonText={'SAVE A COPY'}/> */}
                              <PDFDownloadLink document={loanTemplateRef.current.renderLoanOfferPdf(signature)} fileName={this.isIslamic() ? 'Alliance Financing Offer.pdf' : 'Alliance Loan Offer.pdf'}>
                                <Button className="secondaryButton" data-index="1"> DOWNLOAD </Button>
                              </PDFDownloadLink>
                            </div>
                          </React.Fragment>
                        ) : (null)}
                        <div className="signature-buttons">
                          <Button className={signature ? "secondaryButton" : "proceedButton"} onClick={this.toogleSignaturePad} data-index="1"> {signature ? "RE-SIGN" : "SIGN"} </Button>
                        </div>
                      </Row>
                    </Collapse>
                  </ListGroupItem>
                  {/* <ListGroupItem className="justify-content-between">
                    <div className="submit-more-info-form-label" data-index="2"
                      onClick={
                        this.state.uploadedLoanOffer && this.state.uploadedLoanOffer.length > 0 ? this.toggleSubmitMoreInfo : null
                      }
                    >
                      {this.toggleTabIcon(2)}
                      Sign & Upload Financing Offer
                      {this.toggleStatus(2)}
                    </div>
                    <Collapse
                      className="ml-4 mb-responsive-collapse"
                      isOpen={this.state.collapse2}
                    >
                      <Row className="mt-4 mb-responsive">
                        <Col>
                          <CustomDropzone label='signedLoanOffer'
                            sectionUploadDescription='Sign the printed financing offer and upload.'
                            categoryFiles={this.state.uploadedLoanOffer}
                            updateCategoryFiles={this.onSignedLoanOfferUpdate}
                            maxSize={2097152}
                            ref={UploadOfferRef}
                          />
                          <Button
                            id="gtm-upload-signed-loan-offer"
                            data-index="2"
                            className="proceedButton"
                            disabled={!this.canProceedNextOnUploadSignedLoanOffer()}
                            onClick={this.continueProcess}
                          >
                            CONTINUE
                          </Button>
                        </Col>
                      </Row>
                    </Collapse>
                    </ListGroupItem> */
                    this.props.eligibility.isAW ? (null) : (
                      <ListGroupItem className="justify-content-between">
                        <div className="submit-more-info-form-label" data-index="2"
                          onClick={
                            this.toggleSubmitMoreInfo
                          }
                        >
                          {this.toggleTabIcon(2)}
                      Upload {selectedDoc.label}
                          {this.toggleStatus(2)}
                        </div>
                        <Collapse
                          className="ml-4 mb-responsive-collapse"
                          isOpen={this.state.collapse2}
                        >
                          <Row className="mt-4 mb-responsive">
                            <Col>
                              {this.renderIncomeStatementsDropzone()}
                            </Col>
                          </Row>
                        </Collapse>
                      </ListGroupItem>
                    )
                  }

                  {
                    this.props.eligibility.isAW ? (null) : (
                      <ListGroupItem className="justify-content-between">
                        <div className="submit-more-info-form-label" data-index="3"
                          onClick={
                            this.state.incomeStatements && this.state.incomeStatements.length > 0 ? this.toggleSubmitMoreInfo : null
                          }
                        >
                          {this.toggleTabIcon(3)}
                      Upload MyKad
                      {this.toggleStatus(3)}
                        </div>
                        <Collapse
                          className="ml-4 mb-responsive-collapse"
                          isOpen={this.state.collapse3}
                        >
                          <Row className="mt-4 mb-responsive">
                            <Col className="col-3 pl-1 nric-col">
                              <div className="upload-nric-container">
                                <CustomDropzone label='mykadFront'
                                  categoryFiles={this.state.mykadFront}
                                  updateCategoryFiles={this.onMykadFrontUpdate}
                                  type='image'
                                  imageSectionLabel={'MyKad Front'}
                                  maxSize={2097152}
                                  ref={NRICFrontRef}
                                  onFileSizeExceedError={this.onFileSizeExceedError}
                                  onFileTypeError={this.onFileTypeError}
                                />
                                <Button onClick={() => NRICFrontRef.current.open()}>
                                  Upload
                            </Button>
                              </div>
                            </Col>
                            <Col className="col-3 pl-1 nric-col">
                              <div className="upload-nric-container">
                                <CustomDropzone label='mykadBack'
                                  categoryFiles={this.state.mykadBack}
                                  updateCategoryFiles={this.onMykadBackUpdate}
                                  type='image'
                                  imageSectionLabel={'MyKad Back'}
                                  maxSize={2097152}
                                  ref={NRICBackRef}
                                  onFileSizeExceedError={this.onFileSizeExceedError}
                                  onFileTypeError={this.onFileTypeError}
                                />
                                <Button onClick={() => NRICBackRef.current.open()}>
                                  Upload
                            </Button>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            id="gtm-upload-myKad"
                            data-index="3"
                            className="proceedButton"
                            disabled={this.state.mykadFront.length <= 0 || this.state.mykadBack.length <= 0}
                            onClick={this.onMyKadContinueButton}
                          >
                            CONTINUE
                      </Button>
                        </Collapse>
                      </ListGroupItem>
                    )
                  }
                </ListGroup>
              </Col>
            </Row>
            {this.renderSignaturePadModal()}
            {this.renderDocumentPreviewModal()}
            {this.modalFileSizeComponent()}
          </Container>
          <NeedHelp
            name={this.props.eligibility.name}
            mobile={this.props.eligibility.mobile}
            email={this.props.eligibility.email}
            currentScreen={PloanScreensList.SCREEN_UPLOAD_DOCUMENTS}
          />
        </Section>
        {this.footerProceedButton(this.state.index, this.state.completedReceiveMoney)}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  documents: state.documents,
  webserviceConfig: state.webserviceConfig,
  calculator: state.calculator,
  eligibility: state.eligibility,
  moreAboutYourself: state.moreAboutYourself,
  employmentDetails: state.employmentDetails,
  emergencyContact: state.emergencyContact,
  preferredBank: state.preferredBank,
  fatcaCrs: state.fatcaCrs,
  initialLoanOffer: state.initialLoanOffer
});

const mapDispatchToProps = dispatch => ({

  updateDocumentsStore: (info) => {
    dispatch(documents.updateDocuments(info))
  },
  clearDocumentsStore: () => {
    dispatch(documents.clearDocuments())
  }
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(ReceiveMoney)));
