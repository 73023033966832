import React from 'react';
import { StateList, CountriesList } from '../../constant';
import { Row, Col, Button } from "reactstrap";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormHelper } from '../../services';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import * as data from "../../data/globalData";

class EmploymentDetails extends React.PureComponent {

    render() {
        const {
            currentEmployer, dateJoinedCompany, companyTel, companyEmail,
            companyAddress, companyAddress2, companyPostcode, companyCity,
            companyCountry, companyLocalState, companyForeignState,
            selectedEmploymentType, companyAddressVersion
        } = this.props;

        return (

            <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col>
                        <div className="section-description-2">
                            Current Employer: {currentEmployer}
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2">
                        <label htmlFor="dateJoined" className={`date-label ${dateJoinedCompany ? "show" : ""}`}>
                            Date Joined
                        </label>
                        <span data-name="dateJoined">
                            <DatePicker
                                maxDate={new Date()}
                                className="field-input date-picker"
                                selected={dateJoinedCompany}
                                onChange={this.props.onDateJoinedCompanyChange}
                                placeholderText="Date Joined"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="d-MMM-yyyy"
                            />
                        </span>
                        {dateJoinedCompany && this.props.isValidMonthDifference && (
                            <div className="form-tick-date">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                        )}
                        {dateJoinedCompany && !this.props.isValidMonthDifference && (
                            <p className="tips-msg">
                                Date Joined must at least {selectedEmploymentType.value == 'SESB' || selectedEmploymentType.value == 'SESP' ? '24' : '1'} month.
                            </p>
                        )}
                    </Col>
                    <Col className="mt-2">
                        <Input
                            type="tel"
                            label="Company Tel"
                            specialClass="field-input"
                            name="companyTel"
                            onChange={this.props.onCompanyTelChange}
                            placeholder="Company Tel"
                            value={companyTel}
                            maxLength={data.maxlength.companyTel}
                            isValidInput={FormHelper.Validation.isValidCompanyTel}
                            // errText={'Please enter a Malaysia/Singapore phone number'}
                            errText={'Please enter a Malaysia phone number'}
                        />
                    </Col>
                    <Col className="mt-2 col-6">
                        <Input
                            specialClass="field-input"
                            label="Company Email"
                            name="companyEmail"
                            onChange={this.props.onCompanyEmailChange}
                            placeholder="Company Email"
                            value={companyEmail}
                            isValidInput={FormHelper.Validation.isEmailFormat}
                            errText="Please enter a correct company Email"
                            maxLength={data.maxlength.companyEmail}
                        />
                    </Col>
                </Row>
                {companyAddressVersion === 1 ? 
                <>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2 col-6">
                        <Input
                            specialClass="field-input"
                            label="Company Address Line 1"
                            name="companyAddress"
                            onChange={this.props.onCompanyAddressChange}
                            placeholder="Company Address Line 1"
                            value={companyAddress}
                            maxLength={data.maxlength.addressLine1}
                            isValidInput={FormHelper.Validation.isValidAddress}
                            errText={`Only # - : ' , . / symbols are allowed`}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="Company Address Line 2"
                            name="companyAddress2"
                            onChange={this.props.onCompanyAddress2Change}
                            placeholder="Company Address Line 2"
                            value={companyAddress2}
                            isValidInput={FormHelper.Validation.isValidAddress}
                            errText={`Only # - : ' , . / symbols are allowed`}
                            maxLength={data.maxlength.addressLine2}
                            optional={companyAddress2 ? false : true}
                        />
                    </Col>
                </Row>
                <Row className="mt-4 mb-responsive pb-2">
                    <Col className="mt-2">
                        <Input
                            type={companyCountry && companyCountry.value === 'MY' ? "tel" : "text"}
                            specialClass="field-input"
                            label="Post Code"
                            name="companyPostcode"
                            onChange={this.props.onCompanyPostcodeChange}
                            placeholder="Post Code"
                            value={companyPostcode}
                            isValidInput={this.props.getPostCodeValidation}
                            maxLength={data.maxlength.postcode}
                            errText="Please enter a correct postcode"
                            optional={this.props.isPostCodeOptional}
                        />
                    </Col>
                    <Col className="mt-2">
                        <Input
                            specialClass="field-input"
                            label="City"
                            name="companyCity"
                            onChange={this.props.onCompanyCityChange}
                            placeholder="City"
                            value={companyCity}
                            maxLength={data.maxlength.city}
                            isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                            errText="Only alphabet is allowed"
                        />
                    </Col>
                    <Col className="mt-2">
                        {companyCountry && companyCountry.value === 'MY' ?
                            (<Dropdown
                                label="State"
                                name="companyLocalState"
                                placeholder="State"
                                options={StateList}
                                value={companyLocalState}
                                onChange={this.props.onCompanyLocalStateChange}
                            />) :
                            (<Input
                                specialClass="field-input"
                                label="State"
                                name="companyForeignState"
                                onChange={this.props.onCompanyForeignStateChange}
                                placeholder="State"
                                value={companyForeignState}
                                maxLength={data.maxlength.foreignState}
                                isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                                errText="Only alphabet is allowed"
                                optional
                            />)
                        }
                    </Col>
                    <Col className="mt-2">
                        <Dropdown
                            label="Country"
                            name="companyCountry"
                            placeholder="Country"
                            options={CountriesList}
                            value={companyCountry}
                            onChange={this.props.onCompanyCountryChange}
                        />
                    </Col>
                </Row>
                </>
                 : '' }
                <Button
                    id="gtm-update-employment-details"
                    data-index="2"
                    className="proceedButton"
                    disabled={!this.props.isValidEmploymentDetails}
                    color="secondary"
                    onClick={this.props.onEmploymentDetailsContinuePressed}
                >
                    CONTINUE
                </Button>
            </>
        );
    }
}

export default EmploymentDetails;