import { AmendmentsCategoryList, EformDBList } from '../../constant';
import { Eform } from '../webservice';

export const getAmendmentsDisplayingFieldLabel = (category = '', text = '') => {
    switch (category) {
        case AmendmentsCategoryList.Name:
            return 'Full Name (as per NRIC)';
        case AmendmentsCategoryList.Gender:
            return 'Gender';
        case AmendmentsCategoryList.Email:
            return 'Email';
        case AmendmentsCategoryList.MaritalStatus:
            return 'Marital Status';
        case AmendmentsCategoryList.Race:
            return 'Race';
        case AmendmentsCategoryList.Education:
            return 'Highest Education Level';
        case AmendmentsCategoryList.Nationality:
            return 'Nationality';
        case AmendmentsCategoryList.MailingAddress:
            return 'Mailing Address';
        case AmendmentsCategoryList.Company:
            return 'Company Name';
        case AmendmentsCategoryList.Occupation:
            return 'Occupation';
        case AmendmentsCategoryList.Business:
            return 'Business Sector Information';
        case AmendmentsCategoryList.MobileNo:
            return 'Mobile No.';
        case AmendmentsCategoryList.ResidenceType:
            return 'Residence Type';
        case AmendmentsCategoryList.LengthOfStay:
            return 'Length of Stay at Current Residence';
        case AmendmentsCategoryList.ResidenceStatus:
            return 'Residence Status';
        case AmendmentsCategoryList.PurposeOfBorrowing:
            return 'Purpose of Borrowing';
        case AmendmentsCategoryList.SourceOfFund:
            return 'Source of Fund';
        case AmendmentsCategoryList.SourceOfWealth:
            return 'Source of Wealth';
        case AmendmentsCategoryList.EmploymentDateJoined:
            return 'Employment Date Joined';
        case AmendmentsCategoryList.CompanyTel:
            return 'Company Telephone No.';
        case AmendmentsCategoryList.CompanyEmail:
            return 'Company Email';
        case AmendmentsCategoryList.CompanyAddress:
            return 'Company Address';
        case AmendmentsCategoryList.EmergencyContact:
            return 'Emergency Contact';
        case AmendmentsCategoryList.SpouseDetail:
            return 'Spouse Detail';
        case AmendmentsCategoryList.DisbursementDetail:
            return 'Disbursement Bank Detail';
        case AmendmentsCategoryList.IdentityDocuments:
            return 'Upload MyKad Front & Back';
        case AmendmentsCategoryList.IncomeDocuments:
            return `Upload ${text}`;
        case AmendmentsCategoryList.AdditionalDocuments:
            return `Upload ${text}`;
        default:
            return 'Error! Description not found.'
    }
}

export const getAmendmentsQuestionsLabel = (category = '', text = '') => {
    switch (category) {
        case AmendmentsCategoryList.Name:
            return 'Is this your name?';
        case AmendmentsCategoryList.MobileNo:
            return 'Is this your mobile number?';
        case AmendmentsCategoryList.Email:
            return 'Is this your email?';
        case AmendmentsCategoryList.Gender:
            return 'Is this your gender?';
        case AmendmentsCategoryList.Race:
            return 'Is this your race?';
        case AmendmentsCategoryList.MaritalStatus:
            return 'Is this your marital status?';
        case AmendmentsCategoryList.Education:
            return 'Is this your highest degree of education?';
        case AmendmentsCategoryList.MailingAddress:
            return 'Is this your mailing address details?';
        case AmendmentsCategoryList.Nationality:
            return 'Is this your nationality?';
        case AmendmentsCategoryList.Occupation:
            return 'Is this your occupation details?';
        case AmendmentsCategoryList.Business:
            return 'Is this your nature of business details?';
        case AmendmentsCategoryList.Company:
            return 'Is this your company name?';
        case AmendmentsCategoryList.CompanyAddress:
            return 'Is this your company address details?';
        case AmendmentsCategoryList.CompanyTel:
            return 'Is this your company\'s telephone number?';
        case AmendmentsCategoryList.EmploymentDateJoined:
            return 'Is this your employment joined date?';
        case AmendmentsCategoryList.CompanyEmail:
            return 'Is this your company\'s email?';
        case AmendmentsCategoryList.ResidenceStatus:
            return 'Is this your residence status?';
        case AmendmentsCategoryList.LengthOfStay:
            return 'Is this your length of stay at current place?';
        case AmendmentsCategoryList.PurposeOfBorrowing:
            return 'Is this your purpose of borrowing?';
        case AmendmentsCategoryList.ResidenceType:
            return 'Is this your residence type?';
        case AmendmentsCategoryList.SourceOfFund:
            return 'Is this your source of fund?';
        case AmendmentsCategoryList.SourceOfWealth:
            return 'Is this your source of wealth?';
        case AmendmentsCategoryList.EmergencyContact:
            return 'Is this your emergency contact details?';
        case AmendmentsCategoryList.SpouseDetail:
            return 'Is this your spouse details?';
        case AmendmentsCategoryList.DisbursementDetail:
            return 'Is this your disbursement bank details?';
        default:
            return 'Error! Description not found.'
    }
}