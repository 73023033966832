import React from 'react';
import { Card, CardText, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import PropTypes from 'prop-types';
import { FormHelper} from '../../services';
import * as data from "../../data/globalData";

class Tax extends React.PureComponent {

    render() {
        const {
            tinNumber
        } = this.props;

        return (
            <Card className="share-info-wrapper">
                <CardText className="share-info-desc">
                    Do you have Tax Identification Number (TIN)?
                </CardText>
                <Row className="share-info-input-wrapper">
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="hasTinNo"
                                onClick={() => { this.props.onChange(true) }}
                                className={`box-checker ${
                                    this.props.hasTinNo !== undefined && this.props.hasTinNo ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">YES</label>
                        </div>
                    </Col>
                    <Col className="share-info-content-wrapper">
                        <div className="share-info-check-wrapper">
                            <div data-name="hasTinNo"
                                onClick={() => { this.props.onChange(false) }}
                                className={`box-checker ${
                                    this.props.hasTinNo !== undefined && this.props.hasTinNo === false ? "box-checked" : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <label className="box-checker-label">NO</label>
                        </div>
                    </Col>
                </Row>
                {this.props.hasTinNo ?
                    <Row className="my-3 mb-responsive">
                        <Col className="mb-4">
                            <p> </p>
                            <Input
                                specialClass="field-input"
                                name="tinNumber"
                                label="What is your TIN Number?"
                                placeholder="Enter Tax Identification Number (TIN)"
                                onChange={this.props.onTinNoChange}
                                value={tinNumber}
                                isValidInput={FormHelper.Validation.isOnlyAlphanumeric}
                                maxLength={14}
                                errText="Please enter a valid TIN (maximum of 14 characters, no special characters allowed)"
                            />
                        </Col>
                    </Row>
                    : (null)
                }
            </Card>
        );
    }

}

export default Tax;