import React from 'react';
import { Switch, Route } from 'react-router-dom'
import Landing from './pages/Landing/'
import CheckStatus from './pages/CheckStatus/checkstatus'
import AipOffCheckStatus from './pages/AipOffCheckStatus/aipOffcheckstatus'
import SubmitMoreInfo from './pages/Eligibility/submitMoreInfo'
import InitialThanks from './pages/Thanks/InitialThanks'
import InitialOffer from './pages/InitialOffer';
import Routes from './routes';
import VerifyIdentity from './pages/Eligibility/verifyIdentity';
import ReceiveMoney from './pages/Eligibility/receiveMoney';
import CompletedReceiveMoney from './pages/Eligibility/completedReceiveMoney';
import SecurityQuestions from './pages/SecurityQuestions/SecurityQuestions';
// import Login from './pages/Login/login';
// import SalesStatusList from './pages/SalesStatusList/salesStatusList';
import PageNotFound from './pages/pageNotFound/pageNotFound';
import Ending from './pages/ending/ending';
import Amendments from './pages/Amendments';
import NeedHelpThanks from './pages/NeedHelpThanks/NeedHelpThanks';

function Main() {
    return (
      <main>
        <Switch>
          <Route exact path={Routes.amendments} component={Amendments}/>
          <Route exact path={Routes.securityQuestion} component={SecurityQuestions}/>
          <Route exact path={Routes.complete} component={CompletedReceiveMoney}/>
          <Route exact path={Routes.receiveMoney} component={ReceiveMoney}/>
          <Route exact path={Routes.verifyIdentity} component={VerifyIdentity}/>
          <Route exact path={Routes.submitMoreInfo} component={SubmitMoreInfo}/>
          <Route exact path={Routes.checkstatus} component={CheckStatus}/>
          <Route exact path={Routes.aipOffcheckstatus} component={AipOffCheckStatus}/>
          <Route exact path={Routes.initialthanks} component={InitialThanks}/>
          <Route exact path={Routes.initialOffer} component={InitialOffer}/>
          {/* <Route exact path={Routes.login} component={Login}/> */}
          <Route exact path={Routes.pageNotFound} component={PageNotFound}/>
          <Route exact path={Routes.ending} component={Ending}/>
          <Route exact path={Routes.needHelpThanks} component={NeedHelpThanks}/>
          {/* <Route exact path={Routes.salesStatusList} component={SalesStatusList}/> */}
          <Route exact path={Routes.default} component={Landing}/>
          <Route exact path={Routes.dev} component={Landing}/>
          <Route path={Routes.dev} component={PageNotFound}/>
        </Switch>
      </main>
    );
  }

export default Main;