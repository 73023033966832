import { types } from '../actions/employmentDetails';
import { assign, cloneDeep } from 'lodash';

const initialState = {
    currentEmployer: '',
    dateJoinedCompany: null,
    companyTel: '',
    companyEmail: ''
}

export default function employementDetails(state = initialState, action) {
    let modifiedState = cloneDeep(state);
    switch (action.type) {
        case types.UPDATE_EMPLOYMENT_DETAILS_INFO:
        console.log("EMPLOYMENT DETAILS::::", action.info)
            return assign({}, modifiedState, action.info);

        case types.CLEAR_EMPLOYMENT_DETAILS_INFO:
            return initialState;

        default:
            return state;
    }
}