import React, { Component } from "react";
import posed from "react-pose";
import PropTypes from 'prop-types';
import { Container } from "reactstrap";

const Flash = posed.div({
    hidden: {
        opacity: 0,
        applyAtEnd: { display: "none" }
    },
    visible: {
        opacity: 1,
        applyAtStart: { display: "block" }
    }
});

const slidingErrorFlashStyle = {
    position: 'fixed',
    top: 63,
    background: '#243869',
    color: 'white',
    width: '100%',
    padding: '15px 0px',
    zIndex: 101,
    fontSize: 16
}

const slidingReminderFlashStyle = {
    position: 'fixed',
    top: 63,
    background: '#243869',
    color: 'white',
    width: '100%',
    padding: '15px 0px',
    zIndex: 100,
    fontSize: 16
}

const slidingErrorFlashContainerStyle = {
    display: 'flex',
    alignItems: 'center'
}

const flashIconStyle = {
    marginRight: '10px'
}

class SlidingErrorAlert extends Component {
    render() {
        return (
            <Flash style={this.props.slidingErrorFlashStyle} className={this.props.flashClassName} pose={this.props.isShow ? "visible" : "hidden"}>
                <Container style={this.props.containerStyle} className={this.props.containerClassName}>
                    <img
                        style={this.props.flashIconStyle}
                        src={this.props.image}
                        alt={this.props.alt}
                        draggable={this.props.draggable}
                    />
                    {this.props.message}
                    {this.props.showDismissButton ?
                        (<button style={this.props.buttonStyle} className={this.props.buttonClassName} onClick={this.props.buttonOnClick}>
                            {this.props.buttonText}
                        </button>) : false
                    }
                </Container>
            </Flash>
        )
    }
}

SlidingErrorAlert.defaultProps = {
    slidingErrorFlashStyle: slidingErrorFlashStyle,
    flashClassName: '',
    containerStyle: slidingErrorFlashContainerStyle,
    containerClassName: '',
    isShow: false,
    image: require("../../assets/img/exclamation-circle.png"),
    alt: "Attention",
    draggable: "false",
    message: "This is default error message",
    flashIconStyle: flashIconStyle,
    buttonStyle: { },
    buttonClassName: "ab-btn ml-3",
    buttonText: "Dismiss",
    buttonOnClick: () => { },
    showDismissButton: false
}

SlidingErrorAlert.propTypes = {
    slidingErrorFlashStyle: PropTypes.object,
    flashClassName: PropTypes.string,
    containerStyle: PropTypes.object,
    containerClassName: PropTypes.string,
    isShow: PropTypes.bool,
    image: PropTypes.any,
    alt: PropTypes.string,
    draggable: PropTypes.string,
    message: PropTypes.string,
    flashIconStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    buttonClassName: PropTypes.string,
    buttonText: PropTypes.string,
    buttonOnClick: PropTypes.func,
    showDismissButton: PropTypes.bool
}

export default SlidingErrorAlert;
