import React, { Component } from "react";
import styles from "./Landing.module.scss";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import posed from "react-pose";
import Header from "../../components/Header";
import Footer from "../../components/Footer/index";
import Section from "../../components/Section";
import SectionTitle from "../../components/SectionTitle";
import Calculator from "../../components/Calculator"
import { FormHelper, Webservice, ReduxHelper } from '../../services';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import SlidingErrorAlert from '../../components/SlidingErrorAlert';
import { EmploymentTypeList, EmploymentTypeDocument, LoanTypeList, MinimumEmploymentEligibilityList } from '../../constant';
import Routes from '../../routes';
import * as data from "../../data/globalData";
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import {
  calculator, documents, eligibility, emergencyContact, employmentDetails,
  fatcaCrs, initialLoanOffer, moreAboutYourself, preferredBank, webserviceConfig
} from '../../reduxStore/actions';
import Environment from '../../environment';
import BaseComponent from '../../class/baseComponent';

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused
      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
      : state.isSelected
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : "",
    borderColor: state.isFocused
      ? "#80bdff"
      : state.isSelected
        ? "#80bdff"
        : "#ced4da",
    "&:hover": {
      borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      transition: "all 200ms"
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "hsl(0, 0%, 20%)" : "#495057",
    fontSize: '1rem',
    fontWeight: '500'
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#333333",
    color: "white"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#2096de"
      : state.isSelected
        ? "#2096de"
        : "rgba(255,255,255,0)",
    ":active": {
      backgroundColor: "#20aaff"
    }
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '1rem',
    fontWeight: '400',
  })
};

const Flash = posed.div({
  hidden: {
    opacity: 0,
    applyAtEnd: { display: "none" }
  },
  visible: {
    opacity: 1,
    applyAtStart: { display: "block" }
  }
});

class Landing extends BaseComponent {
  constructor(props) {
    super(props);
    this.resetAll();
    this.scrollRef = React.createRef()
    const { loanAmount, selectedTenure, selectedLoanType, monthlyInstallment,
      maxLoanAmount, minLoanAmount, tenureOptions, tenureInterestMapping, interestRate,
      minAgeEligibility, grossMonthlyIncome, icNo, selectedEmploymentType, selectedDoc,
      isCurrentEmploymentSufficient, maxLoanAmountMapping, minMonthlyIncome, maxAgeMapping, maxAgeEligibility, isAipOff } = this.props;

    this.state = {
      selectedLoanType: '', loanAmount: 0, selectedTenure: '', monthlyInstallment: 0, maxLoanAmount,
      minLoanAmount, tenureOptions, tenureInterestMapping, interestRate, minAgeEligibility,
      grossMonthlyIncome: 0, icNo: '', selectedEmploymentType: '', selectedDoc: '', isCurrentEmploymentSufficient: null,
      maxLoanAmountMapping, minMonthlyIncome, maxAgeMapping, maxAgeEligibility,
      lowestInterest: tenureInterestMapping[1],
      flash: false,
      heroText: 'Express Financing',
      showCookieModal: false,
      isResidingMalaysia: null,
      isFromAOP: false,
      isAipOff,
      // is_MNC_GLC_PLC: null
      isCashBackCampaign: false,
      cashBackTenureInterestMapping: '',
      installmentBeforeCashBack: 0,
      installmentAfterCashBack: 0,
      cashBack: 0,
      totalCashBack: 0,
      cashBackMonth: 0,
      withoutCashBackMonthBefore: 0,
      withoutCashBackMonthAfter: 0,
    };

    this.props.updateCalculatorStore({
      ecid: FormHelper.UrlHelper.parseQueryParams(this.props.location.search).ecid,
      xuid: FormHelper.UrlHelper.parseQueryParams(this.props.location.search).dxid,
      cmsid: FormHelper.UrlHelper.parseQueryParams(this.props.location.search).cmsid,
    })
  }

  componentDidMount() {
    const aopIdFromUrl = FormHelper.UrlHelper.parseQueryParams(this.props.location.search).aopId
    if (aopIdFromUrl) {
      this.retrieveAopId(aopIdFromUrl);
    }
    else { 
      this.retrieveLandingPageParams(false);
      this.onCalculatingMonth();
    }
    window.scrollTo(0, 0);
    this.modalPopUp()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedEmploymentType } = this.state;

    if (JSON.stringify(selectedEmploymentType) !== JSON.stringify(prevState.selectedEmploymentType)) {
      this.setState({
        maxLoanAmount: prevState.maxLoanAmountMapping[selectedEmploymentType.value],
        maxAgeEligibility: prevState.maxAgeMapping[selectedEmploymentType.value],
        selectedDoc: {},
        isCurrentEmploymentSufficient: null
      })
    }

    //use timer to determine whether or not scroll to calculator component after state updated, otherwise will hit weird scrolling issues
    setTimeout(() => {
      if (this.isAgeMoreThanMaxAgeEligibility() ||
        this.isIncomeLessThanInstalment() ||
        this.isIncomeLessThanMinIncome() ||
        this.isLoanAmountLessThanMinLoanAmount() ||
        this.isLoanAmountNotWithinRangeBasedOnEmploymentType()
      ) {
        document.getElementById('ploan-calculator').scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }, 100)

  }

  renderCookiesModal = () => {
    return (
      <Modal isOpen={this.state.showCookieModal}>
        <ModalHeader><b>We use cookies to offer you a better experience.</b></ModalHeader>
        <ModalBody>
          <div className={`${styles.privacyPolicy}`}>
            By continuing to use our website, you consent to the use of cookies in accordance with our <a href="https://www.alliancebank.com.my/privacy-policy.aspx" target="_blank">Privacy Policy</a>.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="proceedButton mt-0" onClick={this.onCookieModalAccept}>Accept</Button>
        </ModalFooter>
      </Modal>
    );
  }

  modalPopUp = () => {
    let acceptedCookie = localStorage.getItem('PLOAN:acceptedCookie');
    if (acceptedCookie) {
      this.setState({ showCookieModal: false })
    } else {
      //this is the first time
      localStorage.setItem('PLOAN:acceptedCookie', true);
      this.setState({ showCookieModal: true });
    }
  }

  onCookieModalAccept = () => {
    this.setState(prevState => ({
      showCookieModal: !prevState.showCookieModal
    }))
  }

  resetAll = () => {
    console.log('resetAll')
    this.props.clearCalculatorStore();
    this.props.clearDocumentsStore();
    this.props.clearEligibilityStore();
    this.props.clearEmergencyContactStore();
    this.props.clearEmploymentDetailsStore();
    this.props.clearFatcaCrsStore();
    this.props.clearInitialLoanOfferStore();
    this.props.clearMoreAboutYourselfStore();
    this.props.clearPreferredBankStore();
    this.props.clearWebServiceConfigStore();
  }

  retrieveAopId = (aopId) => {
    Webservice.Eform.getAopId(aopId).then(resp => {
      const newpayload = FormHelper.removeCharacter(resp.payload, 9)
      const decryptedPlainText = FormHelper.Getter.getDecryptedDataFromPayload(newpayload, resp.iv)

      this.retrieveAopCustomerInfo(JSON.parse(decryptedPlainText))

    }).catch(error => {
      this.baseErrorHandler(error)
    })
  }

  retrieveAopCustomerInfo = (resp) => {
    console.log("retrieveAopCustomerInfo:::", resp)
    this.setState({ isFromAOP : true, icNo: FormHelper.icDashSeparator(resp.nricNo) })
    let eligibilityStore = ReduxHelper.genEligibilityStoreFromPayload(resp);

    const isWrongAddressFields = this.isWrongAddress(resp.address1) || this.isWrongAddress2(resp.address2) || this.isWrongPostcode(resp.postcode) || this.isWrongCity(resp.city)
    || this.isWrongState(resp.state, resp.postcode) || this.isWrongCountry(resp.country)
    
    this.props.updateEligibilityStore({
      ...eligibilityStore,
      isFromAOP: this.state.isFromAOP,
      email: this.isWrongEmail(resp.email) ? '' : resp.email,
      bumi: resp.race ? FormHelper.Getter.getBumiStatusBasedOnRace(resp.race) : "",
      address: resp.address1 ? resp.address1 : "",
      address2: resp.address2 ? resp.address2 : "",
      postcode: resp.postcode ? resp.postcode : "",
      city: resp.city ? resp.city : "",
      state: resp.state ? FormHelper.Getter.getStateFromValue(resp.state) : "",
      country: isWrongAddressFields ? { value: "MY", label: "Malaysia" } : FormHelper.Getter.getCountryFromValue(resp.country),
      insurancePremium: { value: false, label: "No, I do not want to include insurance premium" } // if not set insurancePremium, it will become undefined
    });

    this.retrieveLandingPageParams(resp.awCustomer);
  }

  isWrongEmail = (email) => {
    return email === null || email === undefined || email === "" || (email && (!FormHelper.Validation.isEmailFormat(email) || email.length > 30))
  }

  isWrongAddress = (address) => {
    return address === null || address === undefined || address === "" || (address && (!FormHelper.Validation.isValidAddress(address) || address.length > 40))
  }

  isWrongAddress2 = (address) => {
    return address && (!FormHelper.Validation.isValidAddress(address) || address.length > 40)
  }

  isWrongPostcode = (postcode) => {
    return postcode === null || postcode === undefined || postcode === "" || (postcode && (!FormHelper.Validation.isLocalPostcode(postcode) || postcode.length > 5))
  }

  isWrongState = (state, postcode) => {
    const stateObj = FormHelper.Getter.getStateFromValue(state)
    return stateObj === null || stateObj === undefined || stateObj === "" || (stateObj && ( FormHelper.getStateByPostcode(postcode) !== stateObj.value))
  }

  isWrongCity = (city) => {
    return city === null || city === undefined || city === "" || (city && (!FormHelper.Validation.isOnlyAlphabetAndSpace(city) || city.length > 20))
  }

  isWrongCountry = (country) => {
    return country === null || country === undefined || country === ""
  }

  retrieveLandingPageParams = (isAW) => {
    Webservice.Product.getLandingPageParams(isAW).then(resp => {
      console.log('getLandingPageParams:', resp)
      const minLoanAmount = parseFloat(resp.min_loan_amount);
      const maxLoanAmount = this.state.maxLoanAmountMapping['PE'];//default is private employee

      let tenureInterestMapping = {};
      for (let key in resp.interestRate) {
        tenureInterestMapping[key] = parseFloat(resp.interestRate[key])
      }

      //cashBackCampaign
      let cashBackTenureInterestMapping = {};
      for (let key in resp.cashBackRate) {
        cashBackTenureInterestMapping[key] = parseFloat(resp.cashBackRate[key])
      }

      let maxLoanAmountMapping = {
        SESB: Number(resp.max_loan_amount_sesb),
        SESP: Number(resp.max_loan_amount_sesp),
        GS: Number(resp.max_loan_amount_gs),
        PE: Number(resp.max_loan_amount_pe),
        CE: Number(resp.max_loan_amount_ce)
      }

      let maxAgeMapping = {
        SESB: Number(resp.max_age_sesb),
        SESP: Number(resp.max_age_sesp),
        GS: Number(resp.max_age_gs),
        PE: Number(resp.max_age_pe),
        CE: Number(resp.max_age_ce)
      }

      //if cashBackCampaign = true, then choose cashBack rate else default rate
      const tenureOptions = !!resp.isCashBackCampaign ? 
        Object.keys(resp.cashBackRate).map(item => { return { value: Number(item), label: item } }) :
        Object.keys(resp.interestRate).map(item => { return { value: Number(item), label: item } });
      const selectedTenure = this.state.selectedTenure;
      const interestRate = !!resp.isCashBackCampaign ? cashBackTenureInterestMapping[selectedTenure.value] : tenureInterestMapping[selectedTenure.value];
      const minAgeEligibility = parseFloat(resp.min_age_eligibility);
      const maxAgeEligibility = maxAgeMapping['PE'];
      const minMonthlyIncome = parseFloat(resp.min_monthly_income);
      const lowestInterest = !!resp.isCashBackCampaign ? cashBackTenureInterestMapping[Object.keys(cashBackTenureInterestMapping)[0]] : tenureInterestMapping[Object.keys(tenureInterestMapping)[0]];
      const isAipOff = resp.isAipOff;
      const isCashBackCampaign = resp.isCashBackCampaign;

      this.setState({
        minLoanAmount, maxLoanAmount, tenureInterestMapping, cashBackTenureInterestMapping,
        tenureOptions, selectedTenure, minAgeEligibility, minMonthlyIncome, interestRate, lowestInterest,
        maxLoanAmountMapping, maxAgeMapping, maxAgeEligibility, isAipOff, isCashBackCampaign
      })
    }).catch(error => {
      this.baseErrorHandler(error)
    })
  }

  isLoanWithinMinAndMax = () => {
    return this.isMoreThanEqualToMinimumLoanAmount() && this.isLessThanEqualToMaximumLoanAmount()
  }

  isMoreThanEqualToMinimumLoanAmount = () => {
    const { minLoanAmount, loanAmount } = this.state;
    return loanAmount >= minLoanAmount;
  }

  isLessThanEqualToMaximumLoanAmount = () => {
    const { maxLoanAmount, loanAmount } = this.state;
    return loanAmount <= maxLoanAmount;
  }

  isInvalidIC = () => {
    const { icNo } = this.state;
    return icNo && (!FormHelper.Validation.isICFormat(icNo) || !FormHelper.Validation.isValidICBasedOnFirst6Digit(icNo));
  }

  isIncomeLessThanInstalment = () => {
    const { grossMonthlyIncome, monthlyInstallment } = this.state;
    return grossMonthlyIncome && (grossMonthlyIncome < monthlyInstallment);
  }

  isIncomeLessThanMinIncome = () => {
    const { grossMonthlyIncome, minMonthlyIncome } = this.state;
    return grossMonthlyIncome && (grossMonthlyIncome < minMonthlyIncome)
  }

  isAgeMoreThanMaxAgeEligibility = () => {
    const { icNo, selectedTenure, maxAgeEligibility, selectedEmploymentType } = this.state;
    const customerAge = FormHelper.Getter.getAgeFromICNo(icNo);
    const customerAgeUponLoanMaturity = customerAge + Number(selectedTenure.value);

    return icNo && selectedEmploymentType && (customerAgeUponLoanMaturity > (maxAgeEligibility - 1))
  }

  isAgeNotWithinRange = () => {
    const { icNo, minAgeEligibility, maxAgeEligibility } = this.state;
    const customerAge = FormHelper.Getter.getAgeFromICNo(icNo);

    return icNo && (customerAge < minAgeEligibility || customerAge > maxAgeEligibility)
  }

  isLoanAmountLessThanMinLoanAmount = () => {
    const { loanAmount } = this.state;
    return loanAmount && !this.isMoreThanEqualToMinimumLoanAmount()
  }

  isLoanAmountNotWithinRangeBasedOnEmploymentType = () => {
    const { loanAmount, selectedEmploymentType } = this.state;
    return loanAmount && selectedEmploymentType && !this.isLoanWithinMinAndMax()
  }

  isNoHaveAnyDocuments = () => {
    const { selectedDoc } = this.state;
    return selectedDoc && selectedDoc.value === "DONT_HAVE"
  }

  getLoanAmountErrorMessage = () => {
    const { selectedEmploymentType, minLoanAmount, maxLoanAmount } = this.state;

    if (this.isLoanAmountLessThanMinLoanAmount())
      return `Minimum loan amount is RM${FormHelper.thousandSeparator(minLoanAmount)}`;

    if (this.isLoanAmountNotWithinRangeBasedOnEmploymentType())
      return `Your financing amount should be within RM${FormHelper.thousandSeparator(minLoanAmount)} and RM${FormHelper.thousandSeparator(maxLoanAmount)}`
  }

  isAgeEligibleToApplyLoan = () => {
    const { minAgeEligibility, maxAgeEligibility } = this.state;

    if (this.isAgeNotWithinRange()) {
      return `Your age must be between ${minAgeEligibility} and ${maxAgeEligibility}`;
    }

    if (this.isAgeMoreThanMaxAgeEligibility()) {
      return `Your age will more than ${maxAgeEligibility} upon financing maturity date, please reduce your tenure years.`;
    }

    return "OK";
  }

  hasFilledInAll = () => {
    const { 
      grossMonthlyIncome, monthlyInstallment, icNo, selectedEmploymentType, loanAmount, selectedLoanType, selectedTenure, 
      selectedDoc, isCurrentEmploymentSufficient, isResidingMalaysia
      // is_MNC_GLC_PLC 
    } = this.state;
    
    return grossMonthlyIncome &&
      monthlyInstallment &&
      icNo &&
      selectedEmploymentType &&
      loanAmount &&
      selectedLoanType &&
      selectedTenure &&
      FormHelper.Validation.isNotEmptyObject(selectedDoc) &&
      isResidingMalaysia &&
      // is_MNC_GLC_PLC &&
      isCurrentEmploymentSufficient
  }

  onNext = () => {
    const { loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, interestRate,
      maxLoanAmount, minLoanAmount, tenureOptions, tenureInterestMapping, minAgeEligibility,
      grossMonthlyIncome, icNo, selectedEmploymentType, selectedDoc, isCurrentEmploymentSufficient,
      maxLoanAmountMapping, maxAgeMapping, maxAgeEligibility, isAipOff, 
      isCashBackCampaign, cashBackTenureInterestMapping, installmentBeforeCashBack, installmentAfterCashBack, cashBack, totalCashBack } = this.state;

    this.props.updateCalculatorStore({
      loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, interestRate,
      maxLoanAmount, minLoanAmount, tenureOptions, tenureInterestMapping, minAgeEligibility,
      grossMonthlyIncome, icNo, selectedEmploymentType, selectedDoc, isCurrentEmploymentSufficient,
      maxLoanAmountMapping, maxAgeMapping, maxAgeEligibility, isAipOff,
      isCashBackCampaign, cashBackTenureInterestMapping, installmentBeforeCashBack, installmentAfterCashBack, cashBack, totalCashBack
    })

    this.props.history.push({
      pathname: Routes.verifyIdentity,
      fromLanding: true
    })
  };

  toggleSwitch = option => {
    this.setState({
      isCurrentEmploymentSufficient: option
    });
  };

  toggleResidingSwitch = option => {
    this.setState({
      isResidingMalaysia: option
    });
  };

  // toggleMNCSwitch = option => {
  //   this.setState({
  //     is_MNC_GLC_PLC: option
  //   });
  // };

  toggleDoc = selectedDoc => {
    console.log("toggleDoc:::", selectedDoc)
    this.setState({
      selectedDoc: selectedDoc
    });
  };

  toggleFlash = () => {
    const { isClickedBefore } = this.state
    if (!isClickedBefore) {
      this.setState(prevState => ({
        flash: true,
        isClickedBefore: !prevState.isClickedBefore
      }));
    }
  };

  closeFlash = () => {
    this.setState({
      flash: false
    });
  };

  onIcNoChange = e => {
    this.setState({
      icNo: e.target.value
    });
  };

  onEmploymentTypeChange = (value) => {
    this.setState({
      selectedEmploymentType: value
    });
  };

  onMonthlyInstallmentChange = (monthlyInstallment) => {
    this.setState({ monthlyInstallment })
  }

  onInstallmentBeforeCashback = (installmentBeforeCashBack) => {
    this.setState({ installmentBeforeCashBack, monthlyInstallment: installmentBeforeCashBack })
  }

  onInstallmentAfterCashback = (installmentAfterCashBack) => {
    this.setState({ installmentAfterCashBack})
  }

  onCashBack = (cashBack) => {
    this.setState({ cashBack })
  }

  onTotalCashBack = (totalCashBack) => {
    this.setState({ totalCashBack })
  }

  onLoanAmountChange = (loanAmount) => {
    this.setState({ loanAmount })
  }

  onGrossIncomeChange = (grossMonthlyIncome) => {
    this.setState({ grossMonthlyIncome })
  }

  onTenureChange = (selectedTenure) => {
    let { isCashBackCampaign } = this.state;
    let calculateCashBackMonthAfter;
    let calculateWithoutCashBackMonthBefore;
    let calculateWithoutCashBackMonthAfter;

    console.log('selectedTenure', selectedTenure)
    if(isNaN(selectedTenure.value)) {
      calculateCashBackMonthAfter = (12 * 1) - 6;
      calculateWithoutCashBackMonthBefore = (12 * 1) - 6 + 1;
      calculateWithoutCashBackMonthAfter = (12 * 1);
    } else {
      calculateCashBackMonthAfter = (12 * selectedTenure.value) - 6;
      calculateWithoutCashBackMonthBefore = (12 * selectedTenure.value) - 6 + 1;
      calculateWithoutCashBackMonthAfter = (12 * selectedTenure.value);
    }
    

    console.log('checkhere status 123', calculateCashBackMonthAfter, calculateWithoutCashBackMonthBefore, calculateWithoutCashBackMonthAfter)
    this.setState({
      selectedTenure,
      interestRate: !!isCashBackCampaign? this.state.cashBackTenureInterestMapping[selectedTenure.value] : this.state.tenureInterestMapping[selectedTenure.value],
      cashBackMonth: calculateCashBackMonthAfter,
      withoutCashBackMonthBefore: calculateWithoutCashBackMonthBefore,
      withoutCashBackMonthAfter: calculateWithoutCashBackMonthAfter,
    })
  }

  onCalculatingMonth = () => {
    let { selectedTenure } = this.state;
    let calculateCashBackMonthAfter;
    let calculateWithoutCashBackMonthBefore;
    let calculateWithoutCashBackMonthAfter;
    console.log('selectedTenure', selectedTenure)

    if(isNaN(selectedTenure.value)) {
      calculateCashBackMonthAfter = (12 * 1) - 6;
      calculateWithoutCashBackMonthBefore = (12 * 1) - 6 + 1;
      calculateWithoutCashBackMonthAfter = (12 * 1);
    } else {
      calculateCashBackMonthAfter = (12 * selectedTenure.value) - 6;
      calculateWithoutCashBackMonthBefore = (12 * selectedTenure.value) - 6 + 1;
      calculateWithoutCashBackMonthAfter = (12 * selectedTenure.value);
    }
    this.setState({
      cashBackMonth: calculateCashBackMonthAfter,
      withoutCashBackMonthBefore: calculateWithoutCashBackMonthBefore,
      withoutCashBackMonthAfter: calculateWithoutCashBackMonthAfter,
    })
  }

  onLoanTypeChange = (selectedLoanType) => {
    this.setState({ selectedLoanType })
  }

  canProceed = () => {
    const { 
      isCurrentEmploymentSufficient, icNo, isResidingMalaysia, 
      // is_MNC_GLC_PLC 
    } = this.state;
    console.log('status now', this.hasFilledInAll())
    return !this.isIncomeLessThanInstalment()
      && this.hasFilledInAll()
      && this.isLoanWithinMinAndMax()
      && this.isAgeEligibleToApplyLoan() === 'OK'
      && isCurrentEmploymentSufficient
      && isResidingMalaysia
      // && is_MNC_GLC_PLC
      && !this.isIncomeLessThanMinIncome()
      && FormHelper.Validation.isICFormat(icNo)
      && FormHelper.Validation.isValidICBasedOnFirst6Digit(icNo)
      && !this.isNoHaveAnyDocuments()
  }

  renderCanProceedInstruction = () => {

    const { icNo, isCurrentEmploymentSufficient, minMonthlyIncome,
      selectedEmploymentType, loanAmount, grossMonthlyIncome, isResidingMalaysia, 
      // is_MNC_GLC_PLC 
    } = this.state;

    if (this.isLoanAmountNotWithinRangeBasedOnEmploymentType()) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle-invalid.png")}
            alt="Attention"
            draggable="false"
          />
          <span>
            Sorry! We can not proceed with your financing amount.
        </span>
        </div>
      )
    }

    if (this.isIncomeLessThanMinIncome()) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle.png")}
            alt="Attention"
            draggable="false"
          />
          <span>{`Minimum gross monthly income is RM${FormHelper.thousandSeparator(minMonthlyIncome)}`}</span>
        </div>
      )
    }

    if (this.isIncomeLessThanInstalment()) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle.png")}
            alt="Attention"
            draggable="false"
          />
          <span>Your monthly gross income is less then monthly installment, please reduce your financing amount or increase tenure year.</span>
        </div>
      )
    }

    if (this.isInvalidIC()) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle.png")}
            alt="Attention"
            draggable="false"
          />
          <span>Please enter a valid Malaysia IC Number.</span>
        </div>
      )
    }

    if (selectedEmploymentType && icNo && this.isAgeEligibleToApplyLoan() !== 'OK') {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle.png")}
            alt="Attention"
            draggable="false"
          />
          <span>{this.isAgeEligibleToApplyLoan()}</span>
        </div>
      )
    }

    if (this.isNoHaveAnyDocuments()) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle.png")}
            alt="Attention"
            draggable="false"
          />
          <span>Sorry ! You are not eligible to apply if you don't have any document, our sales will contact you on the next working day.</span>
        </div>
      )
    }

    if (isCurrentEmploymentSufficient === false) {
      let employmentValue = selectedEmploymentType.value
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle-invalid.png")}
            alt="Attention"
            draggable="false"
          />
          <span>
            Sorry! If your current {employmentValue == 'SESB' || employmentValue == 'SESP' ? 'business' : 'employment'} is less than {employmentValue == 'SESB' || employmentValue == 'SESP' ? '24' : '1'} month,
            you are not eligible to apply.
          </span>
        </div>
      )
    }

    if (isResidingMalaysia === false) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle-invalid.png")}
            alt="Attention"
            draggable="false"
          />
          <span>
            Sorry! If you are not residing in Malaysia, you are not eligible to apply.
        </span>
        </div>
      )
    }

    // if (is_MNC_GLC_PLC === false) {
    //   return (
    //     <div className={`${styles["eligibility-footer-caption"]}`}>
    //       <img
    //         src={require("../../assets/img/exclamation-circle-invalid.png")}
    //         alt="Attention"
    //         draggable="false"
    //       />
    //       <span>
    //         Sorry! The campaign is applicable to employee of Multi-National Company, Government Linked-Companies or Public Listed Companies only.
    //     </span>
    //     </div>
    //   )
    // }

    if (!this.hasFilledInAll()) {
      return (
        <div className={`${styles["eligibility-footer-caption"]}`}>
          <img
            src={require("../../assets/img/exclamation-circle.png")}
            alt="Attention"
            draggable="false"
          />
          <span>Please answer all questions above.</span>
        </div>
      )
    }

    return (
      <div className={`${styles["eligibility-footer-caption"]}`}>
        <img
          src={require("../../assets/img/happy.png")}
          alt="Attention"
          draggable="false"
        />
        <span style={{ color: "#4a6ebd" }}>
          Great! Click button and proceed to fill up basic
          information in next page.{" "}
        </span>
      </div>
    )
  }

  renderDocumentListBasedOnEmploymentType = () => {
    let documentOptionsToRender = [];
    let documentOptionListAccordingToEmploymentType = EmploymentTypeDocument[this.state.selectedEmploymentType.value]
      .filter(item => item.version.includes(Environment.incomeDocumentDropdownVersion));

    for (let index in documentOptionListAccordingToEmploymentType) {

      let item = documentOptionListAccordingToEmploymentType[index];
      let itemValue = documentOptionListAccordingToEmploymentType[index].value;
      let itemLabel = documentOptionListAccordingToEmploymentType[index].label;

      let documentOption =
        <div className={styles["radio-question"]} key={`${itemValue}:${itemLabel}`}>
          <div
            className={`mr-3 ${styles.check} ${this.state.selectedDoc.value ===
              itemValue && styles["check-active"]}`}
            onClick={() => {
              this.toggleDoc(item);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <span>
            {itemLabel}
          </span>
        </div>

      documentOptionsToRender.push(documentOption)
    }

    return (
      <div className="mt-2 ml-5">
        {documentOptionsToRender}
      </div>
    )
  }

  renderSlidingErrorIfNeeded = () => {

    const { 
      icNo, minMonthlyIncome, flash, isCurrentEmploymentSufficient, selectedEmploymentType, selectedDoc, isResidingMalaysia, 
      // is_MNC_GLC_PLC 
    } = this.state;
    const isErrorMessagePopUp = this.isIncomeLessThanInstalment() || (FormHelper.Validation.isICFormat(icNo) && this.isAgeEligibleToApplyLoan() !== 'OK') ||
    (this.isLoanAmountLessThanMinLoanAmount() || this.isLoanAmountNotWithinRangeBasedOnEmploymentType()) ||
    this.isIncomeLessThanMinIncome() || this.isInvalidIC() || (isCurrentEmploymentSufficient === false) || this.isNoHaveAnyDocuments() || isResidingMalaysia === false 
    // || is_MNC_GLC_PLC === false
    if (flash && !isErrorMessagePopUp )
      return (
        <SlidingErrorAlert
          isShow={flash && !isErrorMessagePopUp}
          message={'Please provide your accurate information as it might affect your financing approval result.'}
          showDismissButton={true}
          buttonOnClick={this.closeFlash}
        />
      )

    if (this.isIncomeLessThanInstalment())
      return (
        <SlidingErrorAlert
          isShow={true}
          message={'Your monthly gross income is less then monthly installment, please reduce your financing amount or increase tenure year.'}
          showDismissButton={false}
        />
      )

    if ((FormHelper.Validation.isICFormat(icNo) && this.isAgeEligibleToApplyLoan() !== 'OK'))
      return (
        <SlidingErrorAlert
          isShow={true}
          message={this.isAgeEligibleToApplyLoan()}
          showDismissButton={false}
        />
      )

    if ((this.isLoanAmountLessThanMinLoanAmount() || this.isLoanAmountNotWithinRangeBasedOnEmploymentType()))
      return (
        <SlidingErrorAlert
          isShow={true}
          message={this.getLoanAmountErrorMessage()}
          showDismissButton={false}
        />
      )

    if (this.isIncomeLessThanMinIncome())
      return (
        <SlidingErrorAlert
          isShow={true}
          message={`Minimum gross monthly income is RM${FormHelper.thousandSeparator(minMonthlyIncome)}.`}
          showDismissButton={false}
        />
      )

    if (this.isInvalidIC())
      return (
        <SlidingErrorAlert
          isShow={true}
          message={`Please enter a valid Malaysia IC Number.`}
          showDismissButton={false}
        />
      )

    if (isCurrentEmploymentSufficient === false) 
    return (
      <SlidingErrorAlert
        isShow={true}
        message={`Sorry! You are not eligible to apply if your current ${selectedEmploymentType.value == 'SESB' || selectedEmploymentType.value == 'SESP' ? 'business' : 'employment'} is less than ${selectedEmploymentType.value == 'SESB' || selectedEmploymentType.value == 'SESP' ? '24' : '1'} month.`}
        showDismissButton={false}
      />
    )

    if (this.isNoHaveAnyDocuments()) 
    return (
      <SlidingErrorAlert
        isShow={true}
        message={`Sorry! You are not eligible to apply if you don't have any document, our sales will contact you on the next working day.`}
        showDismissButton={false}
      />
    )

    if ( isResidingMalaysia === false) 
    return (
      <SlidingErrorAlert
        isShow={true}
        message={`Sorry! If you are not residing in Malaysia, you are not eligible to apply.`}
        showDismissButton={false}
      />
    )

    // if ( is_MNC_GLC_PLC === false) 
    // return (
    //   <SlidingErrorAlert
    //     isShow={true}
    //     message={`Sorry! The campaign is applicable to employee of Multi-National Company, Government Linked-Companies or Public Listed Companies only.`}
    //     showDismissButton={false}
    //   />
    // )
  }

  render() {
    const { loanAmount, selectedTenure, selectedLoanType, monthlyInstallment,
      maxLoanAmount, minLoanAmount, tenureOptions, interestRate, selectedEmploymentType,
      grossMonthlyIncome, lowestInterest, minMonthlyIncome, icNo, isCashBackCampaign, installmentBeforeCashBack, installmentAfterCashBack, cashBackMonth, withoutCashBackMonthBefore, withoutCashBackMonthAfter } = this.state;

    return (
      <div style={{ background: "#fff" }}>
        <Header
          version="a"
          offset={-63}
          items={["why", "eligibility", "fees", "docs"]}
        />

        {this.renderSlidingErrorIfNeeded()}

        {this.state.isAipOff ? <Section className={`${ !!isCashBackCampaign ? styles.heroWtMinsCashBack : styles.heroWithoutMins}`}>
          <Container className={`${styles["hero-container"]}`}>
            <div className={styles.circles}>
              {/* <div className={styles["circle-one"]}>
                <span>
                  As low as <strong style={{ fontSize: "50px" }}>{lowestInterest}%</strong> flat rate p.a
                </span>
              </div>
              <div className={styles["circle-two"]}>
                <span>
                  <strong style={{ fontSize: "30px" }}>ZERO</strong> Processing{" "}
                  <br />
                  Fees
                </span>
              </div>
              <div className={styles["circle-three"]}>
                <span>
                  up to <strong style={{ fontSize: "30px" }}>48hrs</strong> to
                  receive <br />
                  your money
                </span>
              </div> */}
              <div className={styles["apply-now"]}>
                <i
                  onClick={() => {
                    const scrollPos = (this.scrollRef.current.offsetTop - 75)
                    window.scrollTo(0, scrollPos);
                  }}
                  onMouseOver={
                    () => { this.setState({ heroText: 'Apply Now' }) }
                  }
                  onMouseLeave={
                    () => { this.setState({ heroText: 'Express Financing' }) }
                  }>
                  {this.state.heroText}
                </i>
              </div>
            </div>
          </Container>
        </Section> : <Section className={`${ !!isCashBackCampaign ? styles.heroWtMinsCashBack : styles.hero}`}>
          <Container className={`${styles["hero-container"]}`}>
            <div className={styles.circles}>
              {/* <div className={styles["circle-one"]}>
                <span>
                  As low as <strong style={{ fontSize: "50px" }}>{lowestInterest}%</strong> flat rate p.a
                </span>
              </div>
              <div className={styles["circle-two"]}>
                <span>
                  <strong style={{ fontSize: "30px" }}>ZERO</strong> Processing{" "}
                  <br />
                  Fees
                </span>
              </div>
              <div className={styles["circle-three"]}>
                <span>
                  up to <strong style={{ fontSize: "30px" }}>48hrs</strong> to
                  receive <br />
                  your money
                </span>
              </div> */}
              <div className={styles["apply-now"]}>
                <span>10 minutes approval*</span>
                <i
                  onClick={() => {
                    const scrollPos = (this.scrollRef.current.offsetTop - 75)
                    window.scrollTo(0, scrollPos);
                  }}
                  onMouseOver={
                    () => { this.setState({ heroText: 'Apply Now' }) }
                  }
                  onMouseLeave={
                    () => { this.setState({ heroText: 'Express Financing' }) }
                  }>
                  {this.state.heroText}
                </i>
              </div>
            </div>
          </Container>
        </Section>
  }

        <Section id="why">
          <Container>
            <SectionTitle text="Why choose Alliance Personal Financing?" />
            <div className={`pl-4 ${styles["flow-container"]}`}>
              <h1>Alliance Bank Personal Financing application flow</h1>
              <h2>
                Complete 3 <strong>Simple</strong> steps to receive your money fast.
              </h2>
              <Row className="check-status-container" style={{ margin: '0' }}>
                <Col className="">
                  <ul className="check-status-list-wrapper" style={{ margin: '0' }}>
                    <div className="line" style={{ bottom: '-26px' }}>
                      <ul>
                        <li>
                          <div className="status-label">10mins</div>
                          <img src={require('../../assets/img/arrow.png')} />
                        </li>
                        <li>
                          <div className="status-label">5mins</div>
                          <img src={require('../../assets/img/arrow.png')} />
                        </li>
                        <li>
                          <div className="status-label">1-2days</div>
                          <img src={require('../../assets/img/arrow.png')} />
                        </li>
                      </ul>
                    </div>
                    <li className="check-status-list z-index-reset">
                      <div className="check-status-list-item">
                        <div className="check-status-img">
                          <img src={require('../../assets/img/verify-identity-icon.png')} />
                        </div>
                        <div className="check-status-icon-wrapper">
                          <div className="check-status-icon">
                            1
                          </div>
                        </div>
                        <p className="check-status-label">Verify Identity</p>
                      </div>
                    </li>
                    <div className="status-time-spend-mb-responsive"><span>10mins</span></div>
                    {this.state.isAipOff ? null : <>
                    <li className="check-status-list z-index-reset">
                      <div className="check-status-list-item">
                        <div className="check-status-img">
                          <img src={require('../../assets/img/initial-loan-offer-icon.png')} />
                        </div>
                        <div className="check-status-icon-wrapper">
                          <div className="check-status-icon">
                            2
                          </div>
                        </div>
                        <p className="check-status-label">Get Initial Financing Offer</p>
                      </div>
                    </li>
                    <div className="status-time-spend-mb-responsive"><span>5mins</span></div> 
                    </>}
                    <li className="check-status-list z-index-reset">
                      <div className="check-status-list-item">
                        <div className="check-status-img">
                          <img src={require('../../assets/img/submit-more-info-icon.png')} />
                        </div>
                        <div className="check-status-icon-wrapper">
                          <div className="check-status-icon">
                          {this.state.isAipOff ? 2 : 3}
                                </div>
                        </div>
                        <p className="check-status-label">Submit More Info & Document</p>
                      </div>
                    </li>
                    <div className="status-time-spend-mb-responsive"><span>1-2days</span></div>
                    <li className="check-status-list z-index-reset">
                      <div className="check-status-list-item">
                        <div className="check-status-img">
                          <img src={require('../../assets/img/receive-money-icon.png')} />
                        </div>
                        <div className="check-status-icon-wrapper">
                          <div className="check-status-icon check-status-icon-2">
                            <img src={require('../../assets/img/happy.png')} />
                          </div>
                        </div>
                        <p className="check-status-label">Receive Money</p>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
        </Section>

        <Section id="fees">
          <Container>
            <SectionTitle text="Fees & Charges" />
            <div className={`pl-4 ${styles["fees-container"]}`}>
              <h2>
                Apply now to enjoy your personalised rate from as low as {lowestInterest}%
                flat rate p.a.
              </h2>
              <table>
                <tbody>
                  <tr>
                    <th>Items</th>
                    <th>Charges</th>
                  </tr>
                  <tr>
                    <td>Processing Fee</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Early Settlement Penalty</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>Late Payment Penalty</td>
                    <td>
                      1% p.a on instalment amount overdue
                      <span>
                        accruing from the due date up to the date of settlement
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Stamp Duty Fee
                      <span>as per Stamp Duty Act 1949 (revised 1989)</span>
                    </td>
                    <td>
                      0.5% Stamp duty
                      <span>
                        On total approved financing amount, to be deducted upfront
                        from the financing facility
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Container>
        </Section>

        <Section id="docs">
          <Container>
            <SectionTitle text="Important Documents" />
            <div className={`pl-4 pt-2`}>
              { !!isCashBackCampaign ? (
                  <div className={`ab-btn ${styles["docs-btn"]}`}>
                    <a href={data.docPath.cashback} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.cashback)} download>Terms & Conditions (Cash Back)</a>
                  </div>
                ) : (
                  <>
                  <div className={`ab-btn ${styles["docs-btn"]}`}>
                    <a href={data.docPath.tnc} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.tnc)} download>Terms & Conditions (Conventional)</a>
                  </div>
                  <div className={`ab-btn ${styles["docs-btn"]}`}>
                    <a href={data.docPath.tnc_islamic} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.tnc_islamic)} download>Terms & Conditions (Islamic)</a>
                  </div>
                  </>
                )
              }
              <div className={`ab-btn ${styles["docs-btn"]}`}>
                <a href={data.docPath.pds} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.pds)} download>Product Disclosure Sheet (Conventional)</a>
              </div>
              <div className={`ab-btn ${styles["docs-btn"]}`}>
                <a href={data.docPath.pds_islamic} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.pds_islamic)} download>Product Disclosure Sheet (Islamic)</a>
              </div>
              <div className={`ab-btn ${styles["docs-btn"]}`}>
                <a href={data.docPath.ncp_eng} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.ncp_eng)} download>Notice & Choice Principle Statement PDPA - 2010</a>
              </div>
            </div>
          </Container>
        </Section>

        <Section id="eligibility" className="mb-3 pb-3">
          <Container>
            <SectionTitle text="Eligibility" />
            <div className={`pl-4 ${styles["eligibility-container"]}`} ref={this.scrollRef} id='ploan-calculator'>
              <Calculator scroll={true} loanAmount={loanAmount} tenureOptions={tenureOptions}
                selectedTenure={selectedTenure} selectedLoanType={selectedLoanType}
                monthlyInstallment={monthlyInstallment} maxLoanAmount={maxLoanAmount} minLoanAmount={minLoanAmount}
                interestRate={interestRate} grossMonthlyIncome={grossMonthlyIncome} loanTypes={LoanTypeList}
                onMonthlyInstallmentChange={this.onMonthlyInstallmentChange} onLoanAmountChange={this.onLoanAmountChange}
                onGrossIncomeChange={this.onGrossIncomeChange} onTenureChange={this.onTenureChange} onLoanTypeChange={this.onLoanTypeChange}
                isAgeMoreThanMaxAgeEligibility={this.isAgeMoreThanMaxAgeEligibility()}
                isIncomeLessThanInstalment={this.isIncomeLessThanInstalment()}
                isIncomeLessThanMinIncome={this.isIncomeLessThanMinIncome()}
                isLoanAmountLessThanMinLoanAmount={this.isLoanAmountLessThanMinLoanAmount()}
                isLoanAmountNotWithinRangeBasedOnEmploymentType={this.isLoanAmountNotWithinRangeBasedOnEmploymentType()}
                isCashBackCampaign={isCashBackCampaign}
                installmentBeforeCashBack={installmentBeforeCashBack}
                installmentAfterCashBack={installmentAfterCashBack}
                onInstallmentBeforeCashback={this.onInstallmentBeforeCashback}
                onInstallmentAfterCashback={this.onInstallmentAfterCashback}
                onCashBack={this.onCashBack}
                onTotalCashBack={this.onTotalCashBack}
                cashBackMonth={cashBackMonth}
                withoutCashBackMonthBefore={withoutCashBackMonthBefore}
                withoutCashBackMonthAfter={withoutCashBackMonthAfter}
              />
            </div>

            <div className={`mt-4 pl-4 ${styles["eligibility-container"]}`}>
              <h1>
                <strong>2. Am I eligible?</strong> Please select.
              </h1>
              <div className={`mt-4 ${styles["question-row"]}`}>
                <img
                  src={require("../../assets/img/checkmark.png")}
                  draggable="false"
                  alt="Valid"
                  className={`${
                    (icNo && FormHelper.Validation.isICFormat(icNo) && FormHelper.Validation.isValidICBasedOnFirst6Digit(icNo)) ? styles["question-valid"] : ""
                    }`}
                />
                <span className="ml-4">I have my Malaysian identity card.</span>
                {
                  this.state.isFromAOP ?
                    (
                      // <div className={`form-control ${styles["ic-field"]} ${this.isInvalidIC() || this.isAgeNotWithinRange() ? "is-invalid" : styles["ic-field"]}`}>
                      //   {FormHelper.maskICNumber(icNo)}
                      // </div>
                      null
                    ) :
                    <NumberFormat
                      type="tel"
                      name="icNo"
                      className={`form-control ${styles["ic-field"]} ${this.isInvalidIC() || this.isAgeNotWithinRange() ? "is-invalid" : styles["ic-field"]}`}
                      onChange={this.onIcNoChange}
                      value={icNo}
                      placeholder="enter identity card number"
                      onFocus={this.toggleFlash}
                      format="######-##-####"
                      mask="_"
                    />
                }
              </div>
              <div className={`mt-4 ${styles["question-row"]}`}>
                <img
                  src={require("../../assets/img/checkmark.png")}
                  draggable="false"
                  alt="Valid"
                  className={`${
                    this.state.selectedDoc.value ? styles["question-valid"] : ""
                    }`}
                />
                <span className="ml-4">
                  I am
                  {!this.state.selectedEmploymentType
                    ? ""
                    : (this.state.selectedEmploymentType.value !== "SESB" && this.state.selectedEmploymentType.value !== "SESP") &&
                    " a"}
                </span>
                <Select
                  name="selectedEmploymentType"
                  value={this.state.selectedEmploymentType}
                  onChange={this.onEmploymentTypeChange}
                  // options={EmploymentTypeList.filter(option => option.value === "GS" || option.value === "PE")}
                  options={EmploymentTypeList}
                  classNamePrefix="employment-select"
                  className="employment-select-container"
                  isSearchable={false}
                  placeholder="select employment type"
                  onFocus={this.toggleFlash}
                  styles={selectStyles}
                />

                {(this.state.selectedEmploymentType !== undefined && Object.keys(this.state.selectedEmploymentType).length !== 0) && (
                  <React.Fragment>
                    <span className="ml-2">
                      and I have softcopies of the documents below.
                    </span>
                    {this.renderDocumentListBasedOnEmploymentType()}
                    <div className={`mt-4 ${styles["question-row"]}`}>
                      <img
                        src={require("../../assets/img/checkmark.png")}
                        draggable="false"
                        alt="Valid"
                        className={`${
                          this.state.isCurrentEmploymentSufficient ? styles["question-valid"] : ""
                          }`}
                      />
                      <span className="ml-4">
                        {MinimumEmploymentEligibilityList[this.state.selectedEmploymentType.value]}
                      </span>
                      <div className={`${styles["switch"]}`}>
                        <span
                          className={`${this.state.isCurrentEmploymentSufficient === true &&
                            styles["switch-active"]} ${styles["switch-label"]}`}
                          onClick={() => this.toggleSwitch(true)}
                        >
                          Yes
                        </span>
                        <span
                          className={`${this.state.isCurrentEmploymentSufficient === false &&
                            styles["switch-active"]} ${styles["switch-label"]}`}
                          onClick={() => this.toggleSwitch(false)}
                        >
                          No
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className={`mt-4 ${styles["question-row"]}`}>
                  <img
                    src={require("../../assets/img/checkmark.png")}
                    draggable="false"
                    alt="Valid"
                    className={`${
                      this.state.isResidingMalaysia ? styles["question-valid"] : ""
                      }`}
                  />
                  <span className="ml-4">
                    I am a Malaysian residing in Malaysia
                    </span>
                  <div className={`${styles["switch"]}`}>
                    <span
                      className={`${this.state.isResidingMalaysia === true &&
                        styles["switch-active"]} ${styles["switch-label"]}`}
                      onClick={() => this.toggleResidingSwitch(true)}
                    >
                      Yes
                      </span>

                    <span
                      className={`${this.state.isResidingMalaysia === false &&
                        styles["switch-active"]} ${styles["switch-label"]}`}
                      onClick={() => this.toggleResidingSwitch(false)}
                    >
                      No
                      </span>
                    </div>
                </div>
                {/* <div className={`mt-4 ${styles["question-row"]}`}>
                  <img
                    src={require("../../assets/img/checkmark.png")}
                    draggable="false"
                    alt="Valid"
                    className={`${
                      this.state.is_MNC_GLC_PLC ? styles["question-valid"] : ""
                      }`}
                  />
                  <span className="ml-4">
                    I am working in Multi-National Company, Government Linked-Companies or Public Listed Companies
                  </span>
                  <div className={`${styles["switch"]}`}>
                    <span
                      className={`${this.state.is_MNC_GLC_PLC === true &&
                        styles["switch-active"]} ${styles["switch-label"]}`}
                      onClick={() => this.toggleMNCSwitch(true)}
                    >
                      Yes
                    </span>
                    
                    <span
                      className={`${this.state.is_MNC_GLC_PLC === false &&
                        styles["switch-active"]} ${styles["switch-label"]}`}
                      onClick={() => this.toggleMNCSwitch(false)}
                    >
                      No
                    </span>
                  </div>
                </div> */}
              </div>
              <div className={`pl-4 pb-4 mt-4 ${styles["eligibility-footer"]}`}>
                {/* {this.renderCanProceedInstruction()} */}
                <div className="pt-2" />
                <button
                  id="gtm-calculator-apply"
                  className="mt-3 ab-btn"
                  disabled={!this.canProceed()}
                  onClick={this.onNext}
                >
                  Next Step{" "}
                  <img alt="arrow right" src={require("../../assets/img/arrow-right.png")} />
                </button>
              </div>
            </div>
            {
              !!isCashBackCampaign ? (
                <div className={`pl-4 mt-5 ${styles["google-facebook-ad"]}`}>
                  <h7> What do I get from this product? </h7>
                  <ul>
                    <li><strong>Financing Amount</strong>: Minimum RM5,000, Maximum RM200,000.</li>
                    <li><strong>Tenure</strong>: From 1 Year (12 months) to 7 Years (84 months)</li>
                    <li><strong>Interest/Profit Rate</strong>: Approximately 4.99% per annum to 16.68% per annum (flat rate). (Effective Interest/Profit Rate: Approximately 9.14% per annum to 27.05% per annum based on 5 Years Financing tenure). The Interest/Profit Rate quoted and/or prescribed by the Bank hereunder is subject to the Bank’s right to vary the same at any time and from time to time with prior notice to you.</li>
                    <li><strong>Total Interest/Profit Payable</strong>: Loan/Financing Amount (RM) x Interest/Profit Rate (%) x Tenure (Years).</li>
                    <li><strong>Cashback</strong>: 30% Cashback on Total Interest/Profit Rate Payable.</li>
                  </ul>
                  <h7><strong>Example :</strong></h7>
                  <div><h7>Loan/Financing Amount: RM100,000</h7></div>
                  <div><h7>Interest/Profit Rate: 12.68% p.a.</h7></div>
                  <div><h7>Tenure: 5 Years.</h7></div>
                  <div><h7>Total Interest/Profit Payable = RM100,000 x 12.68% p.a. x 5 Years = RM63,400.</h7></div>
                  <div style={{ paddingTop: 10 }}><h7>Cashback Percentage: 30%</h7></div>
                  <div><h7>Total Cashback Amount = RM63,400 x 30% = RM19,020</h7></div>
                </div>
              ) : (
                <div className={`pl-4 mt-5 ${styles["google-facebook-ad"]}`}>
                  <h7> What do I get from this product? </h7>
                  <ul>
                    <li><strong>Financing Amount</strong>: Minimum RM5,000, Maximum RM200,000.</li>
                    <li><strong>Tenure</strong>: From 1 Year (12 months) to 7 Years (84 months)</li>
                    <li><strong>Interest/Profit Rate</strong>: Approximately {lowestInterest}% per annum to 14.78% per annum (flat rate). (Effective Interest/Profit Rate: Approximately 7.40% per annum to 24.37% per annum based on 5 Years Financing tenure). The Interest/Profit Rate quoted and/or prescribed by the Bank hereunder is subject to the Bank’s right to vary the same at any time and from time to time with prior notice to you.</li>
                    <li><strong>Total Interest/Profit Payable</strong>: Loan/Financing Amount (RM) x Interest/Profit Rate (%) x Tenure (Years).</li>
                  </ul>
                  <h7><strong>Example</strong>: Financing Amount: RM20,000, Interest/Profit Rate: 10.68% p.a., Tenure: 5 Years. Therefore Total Interest/Profit Rate Payable = RM20,000 x 10.68% p.a. x 5 Years = RM10,680.</h7>
                </div>
              )
            }
            
          </Container>
        </Section>
        {this.renderCookiesModal()}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.calculator
});

const mapDispatchToProps = dispatch => ({
  updateCalculatorStore: (info) => { dispatch(calculator.updateCalculatorInfo(info)) },
  updateEligibilityStore: (info) => { dispatch(eligibility.updateEligibilityInfo(info)) },
  clearCalculatorStore: () => { dispatch(calculator.clearCalculatorInfo()) },
  clearDocumentsStore: () => { dispatch(documents.clearDocuments()) },
  clearEligibilityStore: () => { dispatch(eligibility.clearEligibilityInfo()) },
  clearEmergencyContactStore: () => { dispatch(emergencyContact.clearEmergencyContactInfo()) },
  clearEmploymentDetailsStore: () => { dispatch(employmentDetails.clearEmploymentDetailsInfo()) },
  clearFatcaCrsStore: () => { dispatch(fatcaCrs.clearFatcaCrsInfo()) },
  clearInitialLoanOfferStore: () => { dispatch(initialLoanOffer.clearInitialLoanOfferInfo()) },
  clearMoreAboutYourselfStore: () => { dispatch(moreAboutYourself.clearMoreAboutYourselfInfo()) },
  clearPreferredBankStore: () => { dispatch(preferredBank.clearPreferredBankInfo()) },
  clearWebServiceConfigStore: () => { dispatch(webserviceConfig.clearWebserviceConfig()) },
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(Landing)));
