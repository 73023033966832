import React, { Component } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "./dropdown.module.scss";
import PropTypes from 'prop-types';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
  }

  validTick = state => {
    if (state) {
      if (this.props.verifyCode === undefined || this.props.verifyCode) {
        return (
          <div className={styles["form-check-dropdown"]}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        );
      }
    }
  };

  handleSelectFocus = () => {
    this.setState({
      focus: this.state.focus ? false : true
    });
  };

  handleChange = value => {
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    const selectStyles = {
      placeholder: (provided, state) => ({
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "calc(100% - 45px)",
        fontWeight: 400,
        color: "#888888"
      }),
      singleValue: (provided, state) => ({
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "calc(100% - 45px)",
      }),
      control: (provided, state) => ({
        ...provided,
        height: "50px",
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
          : state.isSelected
            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25"
            : "",
        borderColor: state.isFocused
          ? "#80bdff"
          : state.isSelected
            ? "#80bdff"
            : "#ced4da",
        "&:hover": {
          borderColor: state.isFocused ? "#80bdff" : "#ced4da",
          transition: "all 200ms"
        },
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "#333333",
        color: "white"
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? "#2096de"
          : state.isSelected
            ? "#2096de"
            : "rgba(255,255,255,0)",
        ":active": {
          backgroundColor: "#20aaff"
        }
      })
    };

    const {
      name, label, value, className, isClearable, options, placeholder, isDisabled, errText
    } = this.props;

    return (
      <div className={`${styles["drop-down-wrapper"]} ${className}`}>
        <label
          htmlFor={name}
          className={`${styles["dropdrop-label"]} ${value || this.state.focus ? styles["show"] : ""}`}
        >
          {label}
        </label>
        <Select
          className={`${styles["dropdown-select"]}`}
          isClearable={isClearable}
          isSearchable="true"
          styles={selectStyles}
          name={name}
          options={options}
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleSelectFocus}
          onBlur={this.handleSelectFocus}
          isDisabled={isDisabled}
        />
        {this.validTick(value)}
        {errText ? <p className="tips-msg">{errText}</p> : ""}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  name: 'dropdown-1',
  label: '',
  value: '',
  className: '',
  isClearable: false,
  options: [],
  placeholder: '',
  isDisabled: false,
  errText: '',
  onChange: () => { },
}

Dropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  errText: PropTypes.string,
  onChange: PropTypes.func,
}

export default Dropdown;
